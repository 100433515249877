/**
 * Hook to update weblink status in bulk
 *
 * @param {boolean} isSubmitted - Whether the form is submitted
 * @param {Object} payload - Request payload
 * @param {Function} updateWeblinksStatus - Callback to update weblink status
 */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/stateHooks';
import { axiosPrivateInstance } from 'libs/axios';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { UPDATE_WEBLINK_STATUS } from 'utils/serviceUrls';

const useWeblinkStatusUpdate = (isSubmitted: boolean, payload: any, updateWeblinksStatus: any) => {
	const [loadingUpdateWeblinkStatus, setLoadingUpdateWeblinkStatus] = useState<boolean>(false);
	const [updateWeblinkStatus, setUpdateWeblinkStatus] = useState<any>(false);

	const dispatch = useAppDispatch();
	const { t } = useTranslation('batchPOModule');

	useEffect(() => {
		if (isSubmitted) {
			setLoadingUpdateWeblinkStatus(true);
			axiosPrivateInstance
				.post(UPDATE_WEBLINK_STATUS, payload)
				.then((res: any) => {
					if (res?.status) {
						setUpdateWeblinkStatus(true);
						dispatch(
							setMessage(t(res?.output?.resultText || 'WEBLINK_ACTIVATION_SUCCESS')),
						);
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(t(res?.output?.resultText || 'WEBLINK_DEACTIVATION_FAILED')),
						);
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('WEBLINK_DEACTIVATION_FAILED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					updateWeblinksStatus();
					dispatch(setShowNotification(true));
					setLoadingUpdateWeblinkStatus(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		loadingUpdateWeblinkStatus,
		updateWeblinkStatus,
	};
};
export default useWeblinkStatusUpdate;
