import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_MANUFACTURER_IDENTIFIER_TYPE } from 'utils/serviceUrls';
import { ERROR } from 'utils/constants';

import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetAllIdentifierType = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('manufacturerModule');

	const [identifierTypeResponseData, setIdentifierTypeResponseData] = useState<any>([]);
	const [identifierTypeloading, setIdentifierTypeLoading] = useState<boolean>(false);

	const ApiRespone = async () => {
		setIdentifierTypeLoading(true);

		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(GET_ALL_MANUFACTURER_IDENTIFIER_TYPE)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setIdentifierTypeResponseData(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			})
			.finally(() => {
				setIdentifierTypeLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		identifierTypeResponseData,
		identifierTypeloading,
	};
};

export default useGetAllIdentifierType;
