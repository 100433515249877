import { IRecentlAddedProduct } from 'components/common/model';
import NoData from 'pages/NoData';
import RecentProductSkeleton from 'components/common/skeleton/RecentProductSkeleton';
import RecentProductCard from './RecentProductCard';
import useProductRecentlyAdded from './hooks/useProductRecentlyAdded';

const RecentlyAddedProduct = () => {
	const { recentProduct, loading } = useProductRecentlyAdded();

	return (
		<ul className='dsb-card-wrapper'>
			{loading ? (
				<RecentProductSkeleton />
			) : (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{recentProduct && recentProduct.length > 0 ? (
						recentProduct?.map((product: IRecentlAddedProduct, k: any) => {
							return (
								<RecentProductCard
									product={product}
									// eslint-disable-next-line react/no-array-index-key, no-unsafe-optional-chaining
									key={k + product?.productName}
								/>
							);
						})
					) : (
						<NoData />
					)}
				</>
			)}
		</ul>
	);
};

export default RecentlyAddedProduct;
