/**
 * CustomLoader component to display a loading spinner.
 * @param {Object} props - The props passed to the component.
 * @param {('small'|'medium'|'large')} [props.size='medium'] - The size of the loading spinner.
 * @returns {JSX.Element} - The rendered component displaying the loading spinner.
 */
import { Loader } from '@progress/kendo-react-indicators';

import { ICustomLoader } from '../model';

const CustomLoader = ({ size }: ICustomLoader) => <Loader size={size || 'medium'} type='pulsing' />;
export default CustomLoader;
