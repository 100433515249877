import { memo } from 'react';
import { ICustomRadioButton } from '../model';

/**
 * Custom radio button component.
 *
 * @param {object} props - Component props
 * @param {function} props.handleChange - Change handler function
 * @param {string} props.id - Id of radio input
 * @param {string} props.label - Label text
 * @param {number|string} props.value - Value of radio input
 * @param {boolean} props.checked - Whether radio is checked
 * @param {string} props.className - CSS class names
 * @param {string} props.name - Name of radio input
 * @param {number} props.index - Index of radio input
 * @param {boolean} props.disabled - Whether radio is disabled
 */
const CustomRadioButton = ({
	handleChange,
	id,
	label,
	value,
	checked,
	className,
	name,
	index,
	disabled,
}: ICustomRadioButton) => {
	/**
	 * Handles change event for radio button
	 * @param newVal - The new value when radio button is selected
	 */
	const onChange = (newVal: number | string) => {
		handleChange(newVal);
	};
	return (
		<span className='form-control-radio-btn'>
			<input
				id={id}
				onChange={() => onChange(value)}
				value={value}
				type='radio'
				disabled={disabled || false}
				name={name}
				key={index}
				checked={checked || false}
				className={disabled ? `${className} disabled` : className || ''}
			/>
			<label
				// eslint-disable-next-line jsx-a11y/no-noninteractive-element-to-interactive-role
				role='button'
				onKeyDown={() => onChange(value)}
				onClick={() => onChange(value)}
				className='ms-1'
				htmlFor={id}>
				<span className='check-outer'>
					<span className='check-inner' />
				</span>
				{label}
			</label>
		</span>
	);
};
export default memo(CustomRadioButton);
