import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_PRODUCT_FILE_DOWNLOAD } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { getELeafletFileName } from 'slices/productmoduleSlice';

import { useAppSelector } from 'hooks/stateHooks';

const useDownloadEleafletFile = (
	downloadStatus: boolean,
	encProductId: string,
	resetDownloadStatus: () => void,
) => {
	const { t } = useTranslation('productModule');
	const dispatch = useDispatch();
	const eLeafletFileName = useAppSelector(getELeafletFileName);
	const [eLeafletDownloadloading, setELeafletDownloadloading] = useState<boolean>(false);

	const downloadFile = (fileData) => {
		// Create a Blob object from the file data with the specified MIME type
		const blob = new Blob([fileData], { type: 'application/pdf' });

		// Create a temporary URL for the Blob
		const fileUrl = URL.createObjectURL(blob);

		// Create a temporary link element
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = eLeafletFileName; // Set the desired filename for the downloaded file
		link.target = '_blank'; // Open the file in a new tab (optional)
		link.rel = 'noopener noreferrer'; // Set the link relationship (optional)
		// Trigger the download by programmatically clicking the link
		link.click();
		// Clean up the URL object after the file has been downloaded
		URL.revokeObjectURL(fileUrl);
	};

	useEffect(() => {
		if (downloadStatus) {
			setELeafletDownloadloading(true);
			axiosPrivateInstance
				.request({
					method: 'POST',
					url: GET_PRODUCT_FILE_DOWNLOAD,
					responseType: 'blob',
					data: {
						encProductId,
					},
				})
				.then((response: any) => {
					setELeafletDownloadloading(false);
					resetDownloadStatus();

					// download file
					downloadFile(response);
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
					setELeafletDownloadloading(false);
					resetDownloadStatus();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadStatus]);

	return {
		eLeafletDownloadloading,
	};
};

export default useDownloadEleafletFile;
