import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	serialNumberStatusUpdate: false,
};

export const serialNumberModuleSlice = createSlice({
	name: 'serialNumberModule',
	initialState,
	reducers: {
		setSerialNumberStatusUpdate: (state, action: PayloadAction<any>) => {
			state.serialNumberStatusUpdate = action.payload;
		},
	},
});

export const { setSerialNumberStatusUpdate } = serialNumberModuleSlice.actions;

export const getSerialNumberStatusUpdate = (state: RootState) =>
	state.serialNumberModule.serialNumberStatusUpdate;

export default serialNumberModuleSlice.reducer;
