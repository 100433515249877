import { useTranslation } from 'react-i18next';

import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import { axiosBasicInstance } from 'libs/axios';
import { E_LEAFLET_DOWNLOAD_URL } from 'utils/serviceUrls';

const DownloadELeaflet = ({ encProductId, productName, versionId }: any) => {
	const { t } = useTranslation('endUserScan');

	const eLeafletFileName =
		productName && productName !== '' ? `${productName}-e-leaflet` : 'e-leaflet';

	const downloadFile = (fileData: any) => {
		const blob = new Blob([fileData], { type: 'application/pdf' });
		const fileUrl = URL.createObjectURL(blob);
		const link = document.createElement('a');
		link.href = fileUrl;
		link.download = eLeafletFileName;
		link.target = '_blank';
		link.rel = 'noopener noreferrer';
		link.click();
		URL.revokeObjectURL(fileUrl);
	};

	const eLeafletDownloadService = async () => {
		// eslint-disable-next-line no-return-await
		return await axiosBasicInstance
			.request({
				method: 'POST',
				url: E_LEAFLET_DOWNLOAD_URL,
				responseType: 'blob',
				data: { encProductId, encVersionId: versionId },
			})
			.then((res: any) => {
				downloadFile(res);
			})
			.catch((err: any) => {
				console.log(err);
			});
	};

	const onDownloadELeafletClick = () => {
		eLeafletDownloadService();
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<span className='download-file-wrap' onClick={() => onDownloadELeafletClick()}>
			<span className='download-lbl-wrap'>
				<ShowIcon name='faFilePdf' />
				<span className='mfg-value-lbl'>{t('DOWNLOAD_E_LEAFLET')}</span>
			</span>

			<ShowIcon name='faAngleRight' />
		</span>
	);
};

export default DownloadELeaflet;
