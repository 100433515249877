import { useEffect, useState } from 'react';
import { axiosPrivateInstance } from 'libs/axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { ADD_PRODUCT_GROUP_URL } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useAddProductGroup = (
	isSubmitted: boolean,
	formData: any,
	resetAddProductGroupStatus: any,
) => {
	const { t } = useTranslation('productGroup');

	const params = {
		productGroupName: formData?.groupName,
		productDescription: formData?.groupDescription,
		generalFieldIds:
			formData?.generalField &&
			formData?.generalField?.map((item: any) => {
				return item?.generalFieldId?.toString();
			}),
		iVedaFieldIds:
			formData?.iVedaPropertiesField &&
			formData?.iVedaPropertiesField?.map((item: any) => {
				return item?.generalFieldId?.toString();
			}),
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [addpGrpResponse, setAddpGrpResponse] = useState<any>({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSubmitted) {
			setLoading(true);
			axiosPrivateInstance
				.post(ADD_PRODUCT_GROUP_URL, params)
				.then((response: any) => {
					setAddpGrpResponse(response?.output);
					if (response?.status) {
						dispatch(setMessage(t('PRODUCT_GROUP_ADDED_SUCCESSFULLY')));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText || 'PRODUCT_GROUP_NOT_SAVED'),
							),
						);
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('PRODUCT_GROUP_NOT_SAVED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setLoading(false);
					dispatch(setShowNotification(true));
					resetAddProductGroupStatus();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		addpGrpResponse,
		loading,
	};
};

export default useAddProductGroup;
