/**
 * Renders the custom error component.
 * @param {ICustomError} props - The props passed to the component.
 * @param {string} props.value - The error message to display.
 * @returns {JSX.Element} - The rendered component displaying the error message.
 */
import { ICustomError } from '../model';

const CustomError = ({ value }: ICustomError) => {
	/**
	 * Returns the error message wrapped in a paragraph.
	 */
	return <p className='error-msg pos-left'>{value}</p>;
};
export default CustomError;
