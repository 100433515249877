import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_MANUFACTURER_MANAGEMENT } from 'utils/serviceUrls';

import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';
import { ERROR, RESULT_ID_105, SUCCESS, WARNING } from '../../../../utils/constants';

const useUpdateManufacturer = (
	manufacturerData: any,
	isSubmitted: boolean,
	setIsSubmitted: any,
) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('manufacturerModule');

	const params = {
		name: manufacturerData.name?.trim(),
		street1Address: manufacturerData.street1Address
			? manufacturerData.street1Address?.trim()
			: null,
		street2Address: manufacturerData.street2Address
			? manufacturerData.street2Address?.trim()
			: null,
		zipCode: manufacturerData.zipCode ? manufacturerData.zipCode?.trim() : null,
		city: manufacturerData.city ? manufacturerData.city?.trim() : null,
		stateName: manufacturerData.stateName ? manufacturerData.stateName?.trim() : null,
		encCountryId: manufacturerData.encCountryId ? manufacturerData.encCountryId : null,
		subjectDescription: manufacturerData.subjectDescription
			? manufacturerData.subjectDescription?.trim()
			: null,
		gs1GtinPrefix: manufacturerData.gs1GtinPrefix ? manufacturerData.gs1GtinPrefix : null,
		contactName: manufacturerData.contactName ? manufacturerData.contactName?.trim() : null,
		emailId: manufacturerData.emailId ? manufacturerData.emailId?.trim() : null,
		phoneNo: manufacturerData.phoneNo ? manufacturerData.phoneNo?.trim() : null,
		identifierTypeList: manufacturerData.identifierTypeList
			? manufacturerData.identifierTypeList
			: null,
		encId: manufacturerData.encId ? manufacturerData.encId : null,
		isManufacturer: true,
		encManufacturerDataTypeId: manufacturerData?.encManufacturerDataTypeId
			? manufacturerData?.encManufacturerDataTypeId
			: null,
		encBusinessTypeId: manufacturerData?.encBusinessTypeId
			? manufacturerData?.encBusinessTypeId
			: null,
		actualFileName: manufacturerData?.actualFileName
			? manufacturerData?.actualFileName?.trim()
			: null,
		fileBase64: manufacturerData?.fileBase64 ? manufacturerData?.fileBase64 : null,
		encParentManufacturerId: manufacturerData?.encParentManufacturerId
			? manufacturerData?.encParentManufacturerId
			: null,

		district: manufacturerData?.district ? manufacturerData?.district?.trim() : null,
	};
	const [updateManufacturerLoading, setUpdateManufacturerLoading] = useState<boolean>(false);
	const [updateManufacturerResponse, setUpdateManufacturerResponse] = useState<any>({});

	useEffect(() => {
		if (isSubmitted) {
			setUpdateManufacturerLoading(true);
			axiosPrivateInstance
				.post(UPDATE_MANUFACTURER_MANAGEMENT, params)
				.then((response: any) => {
					setUpdateManufacturerResponse(response);

					if (response?.status) {
						dispatch(setMessage(t('MANUFACTURER_UPDATED_SUCCESSFULLY')));
						dispatch(setNotificationType(SUCCESS));
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText || 'MANUFACTURER_UPDATING_FAILED'),
							),
						);
						/**
						 * checking error result id to show alert as warning or error
						 */
						if (response?.output?.resultId === RESULT_ID_105) {
							dispatch(setNotificationType(WARNING));
						} else {
							dispatch(setNotificationType(ERROR));
						}
					}
				})
				.catch(() => {
					dispatch(setMessage(t('MANUFACTURER_UPDATING_FAILED')));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					dispatch(setShowNotification(true));
					setUpdateManufacturerLoading(false);
				});
		}

		return () => {
			setIsSubmitted(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		updateManufacturerLoading,
		updateManufacturerResponse,
	};
};

export default useUpdateManufacturer;
