import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import {
	getPasswordVerificationClicked,
	setPasswordVerificationClicked,
} from 'slices/authUserSlice';
import { PASSWORD_VERIFICATION_URL } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

/**
 *
 * @param params which include password,remark,actionId,transaction,referenceId,
 * @returns passwordVerification response ,password verification loading
 */

const usePasswordVerification = (params: any) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('authModule');
	const passwordVerificationClicked = useAppSelector(getPasswordVerificationClicked);
	const [passwordVerificationRes, setPasswordVerificationRes] = useState<any>(false);
	const [passwordVerificationLoading, setPasswordVerificationLoading] = useState<boolean>(false);

	useEffect(() => {
		if (passwordVerificationClicked) {
			setPasswordVerificationLoading(true);
			axiosPrivateInstance
				.post(PASSWORD_VERIFICATION_URL, params)
				.then((response: any) => {
					if (response?.status) {
						setPasswordVerificationRes(response?.status);
					} else {
						dispatch(setMessage(t(response.output || response.errorMessage)));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('PASSWORD_VERIFICATION_FAILED')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
					setPasswordVerificationRes(false);
				})
				.finally(() => {
					dispatch(setPasswordVerificationClicked(false));
					setPasswordVerificationLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordVerificationClicked]);

	return {
		passwordVerificationRes,
		passwordVerificationLoading,
	};
};

export default usePasswordVerification;
