import { useTranslation } from 'react-i18next';

import ShowIcon from 'components/common/fontAwesome/ShowIcon';

import ELeafletBase64 from './assets/eLeafletBase64';

const DemoDownloadELeaflet = () => {
	const { t } = useTranslation('endUserScan');

	// const eLeafletFileName =
	// 	productName && productName !== '' ? `${productName}-e-leaflet` : 'e-leaflet';

	// const downloadFile = (fileData: any) => {
	// 	const blob = new Blob([fileData], { type: 'application/pdf' });
	// 	const fileUrl = URL.createObjectURL(blob);
	// 	const link = document.createElement('a');
	// 	link.href = fileUrl;
	// 	link.download = eLeafletFileName;
	// 	link.target = '_blank';
	// 	link.rel = 'noopener noreferrer';
	// 	link.click();
	// 	URL.revokeObjectURL(fileUrl);
	// };

	const downloadFile = (pdf: any) => {
		// const linkSource = `data:application/pdf;base64,${pdf}`;
		const demoFileName = 'ACG AGRO Chem Sales - Deepak Pal';
		const linkSource = `data:application/pdf;base64,${pdf}`;
		const downloadLink = document.createElement('a');
		// const fileName = eLeafletFileName; // 'ParaGlucose_1-e-leaflet';
		downloadLink.href = linkSource;
		downloadLink.download = demoFileName;
		downloadLink.click();
	};

	const eLeafletDownloadService = () => {
		downloadFile(ELeafletBase64);
	};

	const onDownloadELeafletClick = () => {
		eLeafletDownloadService();
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
		<span className='download-file-wrap' onClick={() => onDownloadELeafletClick()}>
			<span className='download-lbl-wrap'>
				<ShowIcon name='faFilePdf' />
				<span className='mfg-value-lbl'>{t('DOWNLOAD_E_LEAFLET')}</span>
			</span>

			<ShowIcon name='faAngleRight' />
		</span>
	);
};

export default DemoDownloadELeaflet;
