import { useEffect, useState } from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { AUTHENTICATE } from 'utils/serviceUrls';
import { axiosBasicInstance } from 'libs/axios';
import { storeRefreshToken, storeToken } from 'utils/utils';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';

const useAuthenticate = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('userModule');
	const params = useParams();
	const [loginValue, setLoginValue] = useState<any>({});
	const [loginStatus, setLoginStatus] = useState<boolean>(false);
	const [loginLoading, setLoginLoading] = useState<boolean>(false);
	const navigate = useNavigate();

	useEffect(() => {
		setLoginLoading(true);
		axiosBasicInstance
			.request({
				method: 'POST',
				url: AUTHENTICATE,
				responseType: 'json',
				data: { token: params.token },
			})
			.then((response: any) => {
				setLoginStatus(true);
				setLoginValue(response?.output ? response.output : response?.statusCode);
				storeToken(response?.output?.token);
				storeRefreshToken(response?.output?.refreshToken);
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setLoginLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [navigate, params.token]);
	return { loginLoading, loginValue, loginStatus };
};
export default useAuthenticate;
