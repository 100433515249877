/**
 *
 * @param extension
 * @returns true if valid
 */
export const validateSGLNExtension = (extension: string) => {
	// Check if the extension is a string of up to 20 alphanumeric characters
	if (!/^[0-9a-zA-Z]{1,20}$/.test(extension)) {
		return false;
	}

	return true;
};

/**
 *
 * @param gln
 * @returns true if valid
 */

export const validateGLN = (gln: string) => {
	// Check if the GLN is a string of 13 digits
	if (!/^\d{13}$/.test(gln)) {
		return false;
	}
	return true;
};

/**
 *
 * @param value
 * @returns checkdigit
 */

export const calculateCheckDigit = (value: string) => {
	let sum = 0;
	let weight = 3;

	// Iterate over the value in reverse order and calculate the weighted sum
	for (let i = value.length - 1; i >= 0; i -= 1) {
		const digit = parseInt(value.charAt(i), 10);
		sum += digit * weight;
		weight = weight === 3 ? 1 : 3;
	}

	// Calculate the check digit as the difference from the next highest multiple of 10
	const checkDigit = (10 - (sum % 10)) % 10;

	return checkDigit.toString();

	// another approach - need to confirm which one to use

	// let sum = 0;
	// let shouldDouble = true;

	// // Iterate over the value in reverse order and calculate the sum
	// for (let i = value.length - 1; i >= 0; i--) {
	//   let digit = parseInt(value.charAt(i), 10);

	//   if (shouldDouble) {
	//     digit *= 2;
	//     if (digit > 9) {
	//       digit -= 9;
	//     }
	//   }

	//   sum += digit;
	//   shouldDouble = !shouldDouble;
	// }

	// // Calculate the check digit as the difference from the next highest multiple of 10
	// const checkDigit = (Math.ceil(sum / 10) * 10 - sum) % 10;

	// return checkDigit.toString();
};

/**
 *
 * @param gln
 * @param sglnExt
 * @returns sgln number
 */
export const sglnGenerate = (gln: string, sglnExt: string) => {
	// Concatenate the GLN and SGLN extension
	const concatenated = gln + sglnExt;

	// Calculate the check digit
	const checkDigit = calculateCheckDigit(concatenated);

	// Construct the final SGLN by appending the check digit
	const sgln = concatenated + checkDigit;

	return sgln;
};

// new function for calculate check digit

export const calculateCheckDigitFun = (input: string): number | undefined => {
	if (input?.length < 12) {
		return undefined;
	}

	const digits: number[] = input?.split('')?.map(Number);

	let evenPositionSum = 0;
	let oddPositionSum = 0;

	// eslint-disable-next-line no-plusplus
	for (let i = 0; i < digits?.length; i++) {
		if (i % 2 === 0) {
			oddPositionSum += digits[i];
		} else {
			evenPositionSum += digits[i];
		}
	}

	const oddEvenSum = evenPositionSum * 3 + oddPositionSum;
	const nextHighestMultipleOfTen = Math.ceil(oddEvenSum / 10) * 10;

	return nextHighestMultipleOfTen - oddEvenSum;
};

// Exract the last digit (check digit)
export const splitLastDigit = (inputString) => {
	const lastDigit = Number(inputString?.slice(-1));
	return lastDigit;
};

// Extract the first 12 letters (without check digit)
export const split12Digit = (inputString) => {
	const firstPart = inputString?.slice(0, 12);
	return firstPart;
};

// Extract the reference number using total length & gs1 length

export const splitLocationReference = (inputString, length) => {
	const firstPart = inputString?.slice(length, 12);
	return firstPart;
};
