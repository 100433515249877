/**
 * Custom HTML button component.
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.onClick - Callback function triggered on click event.
 * @param {ReactNode} props.children - Content within the button.
 * @param {string} props.type - Button type, i.e., submit, reset, button.
 * @param {string} props.buttonClassName - Class name for the button.
 * @param {boolean} props.disabled - Whether the button is disabled or not.
 * @returns {JSX.Element} The rendered component.
 */
import { MouseEvent } from 'react';

import { ICustomhtmlButton } from '../model';

const CustomhtmlButton = ({
	onClick,
	children,
	type,
	buttonClassName,
	disabled,
}: ICustomhtmlButton) => {
	/**
	 * Handles click event on button.
	 * @param {MouseEvent<HTMLButtonElement>} event - The click event.
	 */
	const handleClick = (event: MouseEvent<HTMLButtonElement>) => {
		// eslint-disable-next-line no-unused-expressions
		onClick ? onClick(event) : null;
	};
	return (
		// eslint-disable-next-line react/button-has-type
		<button
			onClick={(event: MouseEvent<HTMLButtonElement>) => handleClick(event)}
			// eslint-disable-next-line react/button-has-type
			type={type}
			disabled={disabled}
			className={buttonClassName || 'custom-action-dropdown'}>
			{children}
		</button>
	);
};

export default CustomhtmlButton;
