import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_COUNTRY } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';

const useGetAllCountry = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('userModule');
	const [dropdownCountryValues, setDropdownCountryValues] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		setLoading(true);
		axiosPrivateInstance
			.request({
				method: 'POST',
				url: GET_ALL_COUNTRY,
				responseType: 'json',
			})
			.then((response: any) => {
				if (response?.output && response?.status) {
					setDropdownCountryValues(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	return { dropdownCountryValues, loading };
};
export default useGetAllCountry;
