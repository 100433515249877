import { useState, useEffect } from 'react';

import { UPDATE_NOTIFICATION_STATUS } from 'utils/serviceUrls';

import { axiosPrivateInstance } from '../../../libs/axios';
/**
 *hook for updtaing notification list and for updating notification count
 * @param params-updated notificationdetails  data and  readAll key -to identify whwther mark all read is clicked
 * @param isSubmitted-to identify the status whether update is clicked
 * @param updateIsSubmitted-update button click status change function
 * @returns 
 */
const useUpdateNotificationStatus = (params: any, isSubmitted: boolean, updateIsSubmitted: any) => {
	const [loadingUpdateNotificationStatus, setLoadingUpdateNotificationStatus] =
		useState<boolean>(false);
	const [updateNotificationStatusRes, setUpdateNotificationStatusRes] = useState<boolean>(false);
	useEffect(() => {
		if (isSubmitted) {
			setLoadingUpdateNotificationStatus(true);
			axiosPrivateInstance
				.post(UPDATE_NOTIFICATION_STATUS, params)
				.then((response: any) => {
					if (response?.output && response?.status) {
						setUpdateNotificationStatusRes(response?.status);
					}
				})
				.catch(() => {})
				.finally(() => {
					updateIsSubmitted();
					setLoadingUpdateNotificationStatus(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		loadingUpdateNotificationStatus,
		updateNotificationStatusRes,
	};
};
export default useUpdateNotificationStatus;
