import { axiosBasicInstance } from 'libs/axios';
import { SSO_LOGIN_GENERATE_TOKEN, SSO_LOGIN_URL_REQUEST } from 'utils/serviceUrls';

/**
 * Service function to redirect to SSO auth page
 * @returns API response output object
 */
export const requestSsoUrlService = async () => {
	return axiosBasicInstance
		.post(SSO_LOGIN_URL_REQUEST, {
			url: 'https://acgitenant1.acg-world.com/', // temporary
		})
		.then((response: any) => {
			if (response?.status && response?.output) {
				return response.output;
			}
			return 'error';
		})
		.catch(() => {
			return 'error';
		});
};

/**
 *Function to get auth token and refresh token
 * @param token
 * @returns API response output object - same out put of login API is expected
 */
export const ssoLoginTokenGenerateService = async (token: string) => {
	return axiosBasicInstance
		.post(SSO_LOGIN_GENERATE_TOKEN, { token })
		.then((response: any) => {
			if (response?.status && response?.output) {
				return response.output;
			}
			return 'error';
		})
		.catch(() => {
			return 'error';
		});
};
