import LoginLogo from 'assets/images/ALSC-Logo-loginpage.svg';

const LeftSidebar = () => {
	return (
		<div className='left-box'>
			<div className='logo-container'>
				<img src={LoginLogo} alt='Panax Pharma' />
			</div>
			<h3>Harnessing the potential of digital traceability.</h3>
		</div>
	);
};
export default LeftSidebar;
