import ErrorImage from 'assets/images/error-image.svg';

const Page404 = () => {
	return (
		<>
			<div className='error-page '>
				<div className='error-body text-center'>
					<img src={ErrorImage} alt='Error' />
					<h1>404 Error</h1>
					<p>Page not found.</p>
				</div>
			</div>
			<div className='dummy-skelton d-none'>
				<div className='box'>
					<div className='skeleton'>
						<div className='skeleton-left flex1'>
							<div className='square' />
						</div>
						<div className='skeleton-right flex2'>
							<div className='line h70 w40 m15' />
							<div className='line h30' />
							<div className='line h30 w50' />
							<div className='line h30  w75' />
						</div>
					</div>
				</div>
				<div className='box'>
					<div className='skeleton'>
						<div className='skeleton-left flex1'>
							<div className='square' />
						</div>
						<div className='skeleton-right flex2'>
							<div className='line h70 w40 m15' />
							<div className='line h30' />
							<div className='line h30 w50' />
							<div className='line h30  w75' />
						</div>
					</div>
				</div>
				<div className='box'>
					<div className='skeleton'>
						<div className='skeleton-left flex1'>
							<div className='square' />
						</div>
						<div className='skeleton-right flex2'>
							<div className='line h70 w40 m15' />
							<div className='line h30' />
							<div className='line h30 w50' />
							<div className='line h30  w75' />
						</div>
					</div>
				</div>
			</div>
			<div className='dummy-skelton d-none'>
				<div className='d-flex w-100'>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
				</div>
				<div className='d-flex w-100 mtop-15'>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
				</div>
				<div className='d-flex w-100 mtop-15'>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
					<div className='box'>
						<div className='skeleton'>
							<div className='skeleton-left circle-left flex1'>
								<div className='circle' />
							</div>
							<div className='skeleton-right flex2'>
								<div className='line h24 w40 m15' />
								<div className='line h12' />
								<div className='line h12 w50' />
								<div className='line h12  w75' />
							</div>
						</div>
					</div>
				</div>
			</div>
			<div className='dummy-skelton '>
				<div className='box'>
					<div className='skeleton'>
						<div className='skeleton-left flex1'>
							<div className='square' />
						</div>
						<div className='skeleton-right flex1 h-100'>
							<div className='reset-square'>
								<div className='reset-line h70 w50' />
								<div className='reset-line h70 w50' />
								<div className='reset-line h70 w50' />
								<div className='reset-line h70 w50 mb-0' />
							</div>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};
export default Page404;
