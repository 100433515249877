import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_PRODUCT_ORDER_APPROVE_STATUS } from 'utils/serviceUrls';

const updateApprovePOStatusService = async (
	id: string,
	message: string,
	isProductUpdate: number,
) => {
	const paramData = {
		encLotNumberId: id,
		statusId: 4,
		comment: message,
		workFlowTypeId: isProductUpdate,
	};
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(UPDATE_PRODUCT_ORDER_APPROVE_STATUS, paramData)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default updateApprovePOStatusService;
