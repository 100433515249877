import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import CustomInput from 'components/common/forms/CustomInput';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, RouteUrl } from 'utils/enum';
import NoData from 'pages/NoData';
import ViewAllAwaitingActionSkeleton from 'components/common/skeleton/ViewAllAwaitingActionSkeleton';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import {
	dateAddition,
	dateSubstract,
	formateDateForApi,
	getAwaitingActionSearch,
	utcFormat,
} from 'utils/utils';
import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import * as constants from '../../../utils/constants';
import DateRangePickerComponent from '../../common/dateRangePicker/DateRangePicker';
import useGetAllAwaitingActionStatus from './hooks/useGetAllAwaitingActionStatus';
import AwaitingReviewCard from './AwaitingReviewCard';

const initialType: 'numeric' | 'input' = 'numeric';

const ViewAllAwaitingActions = () => {
	const { t } = useTranslation('dashboard');
	const getCurrentDate = useAppSelector(getDataFormat);

	const skip = 0;
	const take = constants.AWAITINGACTIONPAGESIZE;
	const dataItemKey = ['text', 'customName1', 'customName2', 'customName3', 'actionDate'];
	const enable24Hr = useAppSelector(getEnable24Hr);

	/**
	 * date picker values
	 */

	const startDate = dateSubstract(new Date(), constants.LAST_30_DAYS);
	const minDate = dateSubstract(new Date(), constants.LAST_90_DAYS);
	/**
	 * value for dtae range picker
	 */
	const [value, setValue] = useState<any>({
		start: startDate,
		end: new Date(),
	});
	const [minDt, setMinDt] = useState<any>(minDate);
	const [maxDt, setMaxDt] = useState<any>(new Date());
	/**
	 * pagination total state
	 */
	const [total, setTotal] = useState<number>(0);

	/**
	 * pageination state
	 */
	const [pageDataState, setpageDataState] = useState<any>({
		skip: skip || 0,
		take: take || constants.SMALLGRIDPAGESIZE,
		// buttonCount: 5,
		type: initialType,
		previousNext: true,
	});

	const [searchCopyData, setSearchCopyData] =
		useState<any>(); /** state for search functionality */
	const [searchInput, setSearchInput] = useState<string>(''); /** search input state */

	const [pagerData, setPagerData] = useState<any>([]); /** main state for data */

	const [isUpdated, setIsUpdated] = useState<boolean>(false);
	const [awaitingActionInfo, setAwaitingActionInfo] = useState<string>('');

	const [awaitingActionsGridLoading, setawaitingActionsGridLoading] = useState<boolean>(true);

	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.DASHBOARD,
		ICON_DESCRIPTION_ID.AWAITING_REVIEW,
	);

	/**
	 * sets icon description data in state
	 */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setAwaitingActionInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		setIsUpdated(true);
	}, []);

	const updateIsUpdated = () => {
		setIsUpdated(false);
	};
	const { awaitingActionsData, loading } = useGetAllAwaitingActionStatus(
		isUpdated,
		updateIsUpdated,
		formateDateForApi(value.start, constants.COMMON_DATE_FORMAT_API),
		formateDateForApi(value.end, constants.COMMON_DATE_FORMAT_API),
		true,
	);

	useEffect(() => {
		setTotal(awaitingActionsData?.length);
	}, [awaitingActionsData]);

	useEffect(() => {
		setpageDataState((prev) => ({ ...prev, skip, take }));
	}, [skip, take]);

	useEffect(() => {
		if (awaitingActionsData && awaitingActionsData.length > 0) {
			const tempfileData: any = [];
			awaitingActionsData.map((item: any) => {
				const tempMessage = getAwaitingActionSearch(item.type, item, enable24Hr);
				tempfileData.push({ ...item, text: tempMessage });
				return false;
			});
			/**
			 * notificationMsg will return false when response is []
			 */
			setSearchCopyData(tempfileData);
			setPagerData(tempfileData);
		} else {
			setSearchCopyData([]);
			setPagerData([]);
		}
		setawaitingActionsGridLoading(loading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [awaitingActionsData, loading]);

	const onGetDateChangeHandler = (dateRange: any) => {
		const newMinDt = dateSubstract(new Date(dateRange.start), constants.LAST_90_DAYS);
		setMinDt(newMinDt);

		// adjust max date, maximum 90 days from start date
		const newMaxDt = dateAddition(new Date(dateRange.start), constants.LAST_90_DAYS);

		// check if newMaxDt greater than current date
		if (new Date(newMaxDt) > new Date()) {
			setMaxDt(new Date());
		} else setMaxDt(newMaxDt);
		setValue(dateRange);

		if (dateRange?.end) {
			setIsUpdated(true);
			setSearchInput('');
		}
	};

	/**
	 * search handing fn
	 * @param e : HTMLInputElement
	 */
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const keyword = e.target.value;

		if (keyword !== '') {
			const results =
				pagerData?.length > 0 &&
				pagerData?.filter((item: any) => {
					// eslint-disable-next-line array-callback-return
					return dataItemKey.some((searchKey) => {
						if (
							// eslint-disable-next-line no-restricted-globals
							!isNaN(Date.parse(item[searchKey])) &&
							getCurrentDate &&
							enable24Hr !== null
						) {
							return utcFormat(item[searchKey], getCurrentDate)
								?.toLowerCase()
								.includes(keyword.toLowerCase());
						}
						return item[searchKey]?.toLowerCase()?.includes(keyword.toLowerCase());
					});
				});

			setSearchCopyData(results);
			const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
			setpageDataState(clearedPagerDataState);
			setTotal(results?.length);
		} else {
			// If the text field is empty, show all users
			setSearchCopyData(pagerData);
			const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
			setpageDataState(clearedPagerDataState);
			setTotal(awaitingActionsData?.length);
		}
		setSearchInput(keyword);
	};

	/**
	 * page handling fn
	 * @param event : PageChangeEvent
	 */
	const handlePageChange = (event: PageChangeEvent) => {
		// eslint-disable-next-line no-shadow
		const { skip, take } = event;
		setpageDataState({ ...pageDataState, skip, take });
	};

	return (
		<div className='page-content '>
			<div className='page-header with-right-form'>
				<div className='back-btn-header'>
					<PageBackButton url={RouteUrl.DASHBOARD} />
					<h3>
						{t('AWAITING_ACTIONS')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={awaitingActionInfo}
							moduleId={ICON_DESCRIPTION_ID.AWAITING_REVIEW}
						/>
					</h3>
				</div>

				<div className='default-form-style tenant-search notification-search-form'>
					<div className='form-group'>
						<DateRangePickerComponent
							getDataRange={onGetDateChangeHandler}
							disabled={false}
							value={value}
							min={minDt}
							max={maxDt}
						/>

						<CustomInput
							placeholder={t('SEARCH_HERE_PLACEHOLDER')}
							type='text'
							value={searchInput}
							name='searchtenant'
							label={t('SEARCH_CARD_LABEL')!!}
							onChange={handleSearchChange}
							required={false}
							autocompleteDisabled
						/>
					</div>
				</div>
			</div>

			<div className='page-content-body pagination-content-body'>
				<div className='box-container pagination-box-container awaiting-actions-container'>
					{/* eslint-disable-next-line no-nested-ternary */}
					{awaitingActionsGridLoading ? (
						<ViewAllAwaitingActionSkeleton />
					) : searchCopyData && searchCopyData.length > 0 ? (
						<ul className='inline-list-card box-card-type-3'>
							{searchCopyData
								.slice(pageDataState.skip, pageDataState.take + pageDataState.skip)
								.map((item: any, i: any) => {
									return (
										<AwaitingReviewCard
											// eslint-disable-next-line react/no-array-index-key
											key={i}
											item={item}
											setIsUpdated={setIsUpdated}
										/>
									);
								})}
						</ul>
					) : (
						searchCopyData?.length === 0 && <NoData />
					)}
				</div>

				<Pager
					skip={pageDataState.skip}
					take={pageDataState.take}
					total={total}
					buttonCount={pageDataState.buttonCount}
					type={pageDataState.type}
					previousNext={pageDataState.previousNext}
					onPageChange={handlePageChange}
				/>
			</div>
		</div>
	);
};
export default ViewAllAwaitingActions;
