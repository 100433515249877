import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { GET_ALL_CONFIGURABLE } from '../../../../utils/serviceUrls';
import { axiosPrivateInstance } from '../../../../libs/axios';
import {
	setMessage,
	setNotificationType,
	setShowNotification,
} from '../../../../slices/roleModuleSlice';
import { useAppDispatch } from '../../../../hooks/stateHooks';

const useGetAllConfigurableData = (groupId: string) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('productGroup');

	const [getAllConfigurableRes, setGetallConfigurableRes] = useState<any>([]);
	const [getAllConfigurableResLoading, setGetAllConfigurableResLoading] =
		useState<boolean>(false);

	useEffect(() => {
		if (groupId) {
			setGetAllConfigurableResLoading(true);
			axiosPrivateInstance
				.post(GET_ALL_CONFIGURABLE, { encProductGroupId: groupId })
				.then((response: any) => {
					if (response?.output && response?.status) {
						setGetallConfigurableRes(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setGetAllConfigurableResLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupId]);

	return {
		getAllConfigurableRes,
		getAllConfigurableResLoading,
	};
};

export default useGetAllConfigurableData;
