import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as constants from '../utils/constants';

const initialState: any = {
	downloadWeblinkStatusValue: '',
	downloadWeblinkStatusFilterValue: '',
	l3SystemUpdateStatus: false,
	l3SystemStatusUpdate: false,
	selectedConnectionType: constants.SFTP,
	connectionMethodHost: '',
	connectionMethodUsername: '',
	connectionMethodPassword: '',
	connectionMethodPortno: '',
	connectionMethodTenantid: '',
	l3SystemName: '',
	address: '',
	country: '',
	description: '',
	l3Location: '',
	soapUsername: '',
	soapPassword: '',
	soapIdentifier: '',
	weblinkInclusionCharacters: '',
	weblinkIdentifierLimit: '',
	weblinkPoolSize: '',
	createL3SubmitButtonStatus: false,
	updateL3SubmitButtonStatus: false,
	l3SystemStatusChangeToggle: false,
	currentL3SystemStatus: {
		encSystemID: '',
		isActive: false,
	},
	l3systemUpdatedResStatus: false,
	sftpPasswordErrorMsg: '',
	soapPasswordErrMsg: '',
	checkWeblinkUpdateStatus: false,
	statusUpdateloading: false,
	availablePoolSize: '',
	expiryDateValue: '',
	expiryDateFilterValue: '',
	createdDateValue: '',
	createdDateFilterValue: '',
};

export const l3SystemModuleSlice = createSlice({
	name: 'l3SystemModule',
	initialState,
	reducers: {
		setDownloadWeblinkStatusValue: (state, action: PayloadAction<any>) => {
			state.downloadWeblinkStatusValue = action.payload;
		},
		setDownloadWeblinkStatusFilterValue: (state, action: PayloadAction<any>) => {
			state.downloadWeblinkStatusFilterValue = action.payload;
		},
		setCreatedDateValue: (state, action: PayloadAction<any>) => {
			state.createdDateValue = action.payload;
		},
		setCreatedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.createdDateFilterValue = action.payload;
		},
		setExpiryDateValue: (state, action: PayloadAction<any>) => {
			state.expiryDateValue = action.payload;
		},
		setExpiryDateFilterValue: (state, action: PayloadAction<any>) => {
			state.expiryDateFilterValue = action.payload;
		},
		setL3SystemUpdateStatus: (state, action: PayloadAction<any>) => {
			state.l3SystemUpdateStatus = action.payload;
		},
		setl3SystemStatusUpdate: (state, action: PayloadAction<any>) => {
			state.l3SystemStatusUpdate = action.payload;
		},
		setSelectedConnectionType: (state, action: PayloadAction<any>) => {
			state.selectedConnectionType = action.payload;
		},
		setConnectionMethodHost: (state, action: PayloadAction<any>) => {
			state.connectionMethodHost = action.payload;
		},
		setConnectionMethodUsername: (state, action: PayloadAction<any>) => {
			state.connectionMethodUsername = action.payload;
		},
		setConnectionMethodPassword: (state, action: PayloadAction<any>) => {
			state.connectionMethodPassword = action.payload;
		},
		setConnectionMethodPortno: (state, action: PayloadAction<any>) => {
			state.connectionMethodPortno = action.payload;
		},
		setConnectionMethodTenantid: (state, action: PayloadAction<any>) => {
			state.connectionMethodTenantid = action.payload;
		},
		setL3SystemName: (state, action: PayloadAction<any>) => {
			state.l3SystemName = action.payload;
		},
		setAddress: (state, action: PayloadAction<any>) => {
			state.address = action.payload;
		},
		setCountry: (state, action: PayloadAction<any>) => {
			state.country = action.payload;
		},
		setDescription: (state, action: PayloadAction<any>) => {
			state.description = action.payload;
		},
		setL3Location: (state, action: PayloadAction<any>) => {
			state.l3Location = action.payload;
		},
		setSoapUsername: (state, action: PayloadAction<any>) => {
			state.soapUsername = action.payload;
		},
		setSoapPassword: (state, action: PayloadAction<any>) => {
			state.soapPassword = action.payload;
		},
		setSoapIdentifier: (state, action: PayloadAction<any>) => {
			state.soapIdentifier = action.payload;
		},
		setWeblinkInclusionCharacters: (state, action: PayloadAction<any>) => {
			state.weblinkInclusionCharacters = action.payload;
		},
		setWeblinkIdentifierLimit: (state, action: PayloadAction<any>) => {
			state.weblinkIdentifierLimit = action.payload;
		},
		setweblinkPoolSize: (state, action: PayloadAction<any>) => {
			state.weblinkPoolSize = action.payload;
		},
		setCreateL3SubmitButtonStatus: (state, action: PayloadAction<any>) => {
			state.createL3SubmitButtonStatus = action.payload;
		},
		setUpdateL3SubmitButtonStatus: (state, action: PayloadAction<any>) => {
			state.updateL3SubmitButtonStatus = action.payload;
		},
		setL3SystemStatusChangeToggle: (state, action: PayloadAction<any>) => {
			state.l3SystemStatusChangeToggle = action.payload;
		},
		setCurrentL3SystemStatus: (state, action: PayloadAction<any>) => {
			state.currentL3SystemStatus = action.payload;
		},
		setL3systemUpdatedResStatus: (state, action: PayloadAction<any>) => {
			state.l3systemUpdatedResStatus = action.payload;
		},
		setSftpPasswordErrorMsg: (state, action: PayloadAction<any>) => {
			state.sftpPasswordErrorMsg = action.payload;
		},
		setSoapPasswordErrMsg: (state, action: PayloadAction<any>) => {
			state.soapPasswordErrMsg = action.payload;
		},
		setCheckWeblinkUpdateStatus: (state, action: PayloadAction<any>) => {
			state.checkWeblinkUpdateStatus = action.payload;
		},

		setStatusUpdateLoading: (state, action: PayloadAction<any>) => {
			state.statusUpdateloading = action.payload;
		},
		setAvailablePoolSize: (state, action: PayloadAction<any>) => {
			state.availablePoolSize = action.payload;
		},
	},
});

export const {
	setDownloadWeblinkStatusFilterValue,
	setDownloadWeblinkStatusValue,
	setl3SystemStatusUpdate,
	setL3SystemUpdateStatus,
	setSelectedConnectionType,
	setConnectionMethodHost,
	setConnectionMethodUsername,
	setConnectionMethodPassword,
	setConnectionMethodPortno,
	setConnectionMethodTenantid,
	setL3SystemName,
	setAddress,
	setCountry,
	setDescription,
	setL3Location,
	setSoapUsername,
	setSoapPassword,
	setSoapIdentifier,
	setWeblinkInclusionCharacters,
	setWeblinkIdentifierLimit,
	setweblinkPoolSize,
	setCreateL3SubmitButtonStatus,
	setUpdateL3SubmitButtonStatus,
	setL3SystemStatusChangeToggle,
	setCurrentL3SystemStatus,
	setL3systemUpdatedResStatus,
	setSftpPasswordErrorMsg,
	setSoapPasswordErrMsg,
	setCheckWeblinkUpdateStatus,
	setStatusUpdateLoading,
	setAvailablePoolSize,
	setExpiryDateFilterValue,
	setExpiryDateValue,
	setCreatedDateFilterValue,
	setCreatedDateValue,
} = l3SystemModuleSlice.actions;

export const getDownloadWeblinkStatusValue = (state: RootState) =>
	state.l3SystemModule.downloadWeblinkStatusValue;
export const getDownloadWeblinkStatusFilterValue = (state: RootState) =>
	state.l3SystemModule.downloadWeblinkStatusFilterValue;
export const getExpiryDateValue = (state: RootState) => state.l3SystemModule.expiryDateValue;
export const getExpiryDateFilterValue = (state: RootState) =>
	state.l3SystemModule.expiryDateFilterValue;
export const getCreatedDateValue = (state: RootState) => state.l3SystemModule.createdDateValue;
export const getCreatedDateFilterValue = (state: RootState) =>
	state.l3SystemModule.createdDateFilterValue;
export const getL3SystemUpdateStatus = (state: RootState) =>
	state.l3SystemModule.l3SystemUpdateStatus;
export const getl3SystemStatusUpdate = (state: RootState) =>
	state.l3SystemModule.l3SystemStatusUpdate;
export const getSelectedConnectionType = (state: RootState) =>
	state.l3SystemModule.selectedConnectionType;
export const getConnectionMethodHost = (state: RootState) =>
	state.l3SystemModule.connectionMethodHost;
export const getConnectionMethodUsername = (state: RootState) =>
	state.l3SystemModule.connectionMethodUsername;
export const getConnectionMethodPassword = (state: RootState) =>
	state.l3SystemModule.connectionMethodPassword;
export const getConnectionMethodPortno = (state: RootState) =>
	state.l3SystemModule.connectionMethodPortno;
export const getConnectionMethodTenantid = (state: RootState) =>
	state.l3SystemModule.connectionMethodTenantid;
export const getL3SystemName = (state: RootState) => state.l3SystemModule.l3SystemName;
export const getAddress = (state: RootState) => state.l3SystemModule.address;
export const getCountry = (state: RootState) => state.l3SystemModule.country;
export const getDescription = (state: RootState) => state.l3SystemModule.description;
export const getL3Location = (state: RootState) => state.l3SystemModule.l3Location;
export const getSoapUsername = (state: RootState) => state.l3SystemModule.soapUsername;
export const getSoapPassword = (state: RootState) => state.l3SystemModule.soapPassword;
export const getSoapIdentifier = (state: RootState) => state.l3SystemModule.soapIdentifier;
export const getWeblinkInclusionCharacters = (state: RootState) =>
	state.l3SystemModule.weblinkInclusionCharacters;
export const getWeblinkIdentifierLimit = (state: RootState) =>
	state.l3SystemModule.weblinkIdentifierLimit;
export const getweblinkPoolSize = (state: RootState) => state.l3SystemModule.weblinkPoolSize;
export const getCreateL3SubmitButtonStatus = (state: RootState) =>
	state.l3SystemModule.createL3SubmitButtonStatus;
export const getUpdateL3SubmitButtonStatus = (state: RootState) =>
	state.l3SystemModule.updateL3SubmitButtonStatus;
export const getL3SystemStatusChangeToggle = (state: RootState) =>
	state.l3SystemModule.l3SystemStatusChangeToggle;
export const getCurrentL3SystemStatus = (state: RootState) =>
	state.l3SystemModule.currentL3SystemStatus;
export const getL3systemUpdatedResStatus = (state: RootState) =>
	state.l3SystemModule.l3systemUpdatedResStatus;
export const getSftpPasswordErrorMsg = (state: RootState) =>
	state.l3SystemModule.sftpPasswordErrorMsg;
export const getSoapPasswordErrMsg = (state: RootState) => state.l3SystemModule.soapPasswordErrMsg;
export const getCheckWeblinkUpdateStatus = (state: RootState) =>
	state.l3SystemModule.checkWeblinkUpdateStatus;
export const getStatusUpdateLoading = (state: RootState) =>
	state.l3SystemModule.statusUpdateloading;
export const getAvailablePoolSize = (state: RootState) => state.l3SystemModule.availablePoolSize;
export default l3SystemModuleSlice.reducer;
