/**
 * Custom multi-select component.
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.options - The options array.
 * @param {Array} props.selected - The selected values array.
 * @param {Function} props.onChange - Change handler.
 * @param {string} props.placeholder - The placeholder text.
 * @param {string} props.label - The label text.
 * @param {string} props.idField - The id field name.
 * @param {string} props.nameField - The name field name.
 * @param {string} props.className - CSS class names.
 * @param {boolean} props.isMandatory - Whether field is mandatory.
 * @param {boolean} props.disabled - Whether field is disabled.
 * @param {boolean} props.loading - Whether loading state is enabled.
 * @returns {React.ReactElement} The custom multi-select component.
 */
import { memo, ReactElement, cloneElement } from 'react';
import { ListItemProps, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { MultiSelect } from '@progress/kendo-react-dropdowns/dist/npm/MultiSelect/MultiSelect';

import { ICustomMultiSelect } from '../model';

const CustomMultiSelect = ({
	options,
	selected,
	onChange,
	placeholder,
	label,
	idField,
	nameField,
	className,
	isMandatory,
	disabled,
	loading,
}: ICustomMultiSelect) => {
	/**
	 * Handles change event.
	 * @param {MultiSelectChangeEvent} event - The change event.
	 */
	const handleChange = (event: MultiSelectChangeEvent) => {
		const id = event.value.map((item: any) => {
			return item[idField];
		}, []);

		onChange(id);
	};

	/**
	 * Renders the list item.
	 * @param {ReactElement} li - The li element.
	 * @param {ListItemProps} itemProps - The item props.
	 * @returns {ReactElement} The rendered li element.
	 */
	const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const itemChildren = (
			<div className='k-animation-container-select-box'>
				<input
					type='checkbox'
					name={itemProps.dataItem[nameField]}
					checked={selected.includes(itemProps.dataItem[idField])}
					onChange={(e: any) => itemProps.onClick(itemProps.index, e)}
				/>
				<span className='custom-checkbox-label'>{itemProps.dataItem[nameField]}</span>
			</div>
		);
		return cloneElement(li, li.props, itemChildren);
	};

	/**
	 * @returns the selected values as kendo multiselect required
	 */
	const selectedValue = () => {
		const val = options.filter((item: any) => {
			return selected.includes(item[idField]);
		}, []);
		return val || [];
	};

	return (
		<>
			<label className='form-label'>{label}</label>
			{isMandatory && <span>*</span>}
			<div className={disabled ? 'kWrapper disabled' : 'kWrapper'}>
				<MultiSelect
					className={`default-multi-select ${className || ''}`}
					data={options}
					disabled={disabled}
					onChange={handleChange}
					itemRender={itemRender}
					value={selectedValue()}
					textField={nameField}
					dataItemKey={idField}
					placeholder={placeholder}
					loading={loading}
				/>
			</div>
		</>
	);
};

export default memo(CustomMultiSelect);
