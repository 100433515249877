import { configureStore } from '@reduxjs/toolkit';
import authUserSlice from 'slices/authUserSlice';
import configSlice from 'slices/configSlice';
import roleModuleSlice from 'slices/roleModuleSlice';
import tenantModuleSlice from 'slices/tenantModuleSlice';
import userModuleSlice from 'slices/userModuleSlice';
import packageLevelModuleSlice from 'slices/packageLevelSlice';
import userLoginDataSlice from 'slices/userLoginDataSlice';
import manufacturerModuleSlice from 'slices/manufacturerModuleSlice';
import productModuleSlice from 'slices/productmoduleSlice';
import productGroupSlice from 'slices/productGroupSlice';
import l3SystemModuleSlice from 'slices/l3SystemSlice';
import batchPoModuleSlice from 'slices/batchPoSlice';
import infoIconsDataModuleSlice from 'slices/infoIconsDataSlice';
import fileFailureModuleSlice from 'slices/fileFailureSlice';
import notificationModuleSlice from 'slices/notificationSlice';
import epcisFileSlice from 'slices/epcisFileSlice';
import reportModuleSlice from 'slices/reportSlice';
import locationSlice from 'slices/locationSlice';
import l4SystemModuleSlice from 'slices/l4SystemSlice';
import serialNumberSlice from 'slices/serialNumberSlice';
import loginExpirySlice from 'slices/expirySlice';

export const store = configureStore({
	reducer: {
		roleModule: roleModuleSlice,
		tenantModule: tenantModuleSlice,
		userModule: userModuleSlice,
		authUser: authUserSlice,
		loginData: userLoginDataSlice,
		globalConfig: configSlice,
		productModule: productModuleSlice,
		manufacturerModule: manufacturerModuleSlice,
		productGroup: productGroupSlice,
		packageLevelModule: packageLevelModuleSlice,
		l3SystemModule: l3SystemModuleSlice,
		batchPoModule: batchPoModuleSlice,
		infoIconsDataModule: infoIconsDataModuleSlice,
		fileFailureModule: fileFailureModuleSlice,
		notification: notificationModuleSlice,
		epcisFile: epcisFileSlice,
		reportModule: reportModuleSlice,
		locationModule: locationSlice,
		l4SystemModule: l4SystemModuleSlice,
		serialNumberModule: serialNumberSlice,
		loginExpiryModule: loginExpirySlice,
	},
});
export type RootState = ReturnType<typeof store.getState>;
// Inferred type: {posts: PostsState, comments: CommentsState, users: UsersState}
export type AppDispatch = typeof store.dispatch;
