// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable dot-notation */
const userAgentData = navigator.userAgent.toLowerCase();
// getting the browser of system
export const getBrowserVendor = () => {
	try {
		// Get the browser's vendor or company name
		const isEdge = userAgentData.includes('edg') || userAgentData.includes('edga');
		const isChrome = !isEdge && userAgentData.includes('chrome');
		const isSafari = !isEdge && !isChrome && userAgentData.includes('safari');
		const isMozilla = userAgentData.includes('firefox');
		const isOpera = userAgentData.includes('opera') || userAgentData.includes('opr');

		if (isChrome) {
			return 'Google Chrome';
		}
		if (isMozilla) {
			return 'Mozilla Firefox';
		}
		if (isSafari) {
			return 'Apple Safari';
		}
		if (isEdge) {
			return 'Microsoft Edge';
		}
		if (isOpera) {
			return 'Opera';
		}
		return 'N/A';
	} catch (error) {
		return 'N/A';
	}
};

// 	// Get the platform or operating system of the device

export const getPlatform = () => {
	try {
		// Get the platform or operating system of the device
		if (userAgentData.includes('win')) {
			return 'Windows';
		}
		if (userAgentData.includes('mac')) {
			return 'Mac';
		}
		if (userAgentData.includes('linux')) {
			return 'Linux';
		}
		if (userAgentData.includes('android')) {
			return 'Android';
		}
		if (userAgentData.includes('ios')) {
			return 'iOS';
		}
		return 'N/A';
	} catch (error) {
		return 'N/A';
	}
};
// 	// Get the Network information

export const getNetworkInfo = () => {
	try {
		if ('connection' in navigator) {
			const { connection } = navigator;

			if (connection && connection['effectiveType']) {
				return `Online: ${navigator.onLine}. Type: ${
					connection['type'] || 'unknown'
				}. Effective Type: ${connection['effectiveType']}. Downlink: ${
					connection['downlink']
				} mbps.  RTT: ${connection['rtt']} ms`;
			}
		}
		return 'N/A';
	} catch (error) {
		return 'N/A';
	}
};

export const showOrientation = (event: any) => {
	try {
		const orientation = `Alpha: ${event?.alpha?.toFixed(2)}, Beta: ${event?.beta?.toFixed(
			2,
		)}, Gamma: ${event?.gamma?.toFixed(2)}`;
		if (event?.alpha != undefined && event?.beta != undefined && event?.gamma != undefined) {
			return orientation;
		}
		return 'N/A';
	} catch (error) {
		return 'N/A';
	}
};

// 	 Get the operating system of the mobile

export const getMobilePlatform = () => {
	try {
		const isIOS = /iPad|iPhone|iPod/.test(navigator.userAgent);
		const isAndroid = /Android/.test(navigator.userAgent);

		// Get the operating system of the mobile
		if (isAndroid) {
			return 'Android';
		}
		if (isIOS) {
			return 'iOS';
		}
		return 'N/A';
	} catch (error) {
		return 'N/A';
	}
};

// get the device type whether it is mobile,laptop,tab or lap

export const getDevice = () => {
	try {
		// Check if window and window.innerWidth are available
		if (typeof window !== 'undefined' && window.innerWidth) {
			const width = window.innerWidth;

			if (width < 768) {
				return 'Mobile';
			}
			if (width < 1024) {
				return 'Tablet';
			}
			if (width < 1366) {
				return 'Laptop';
			}
		}

		return 'Desktop';
	} catch (error) {
		return 'N/A';
	}
};

//  get the battery status of device

// eslint-disable-next-line consistent-return
export const getBatteryStatus = async () => {
	try {
		let batteryText: string = 'N/A';

		if ('getBattery' in navigator) {
			const newVariable: any = window.navigator;

			const status = await newVariable.getBattery();
			if (status) {
				batteryText = `Charging: ${status.charging}, Level: ${(status.level * 100).toFixed(
					2,
				)}%`;
			}
			return batteryText;
		}
		return batteryText;
	} catch (error) {
		return 'N/A';
	}
};

// getting the time zone

export const getTimeZone = () => {
	try {
		const { timeZone } = Intl.DateTimeFormat().resolvedOptions();
		return timeZone;
	} catch (error) {
		return 'N/A';
	}
};
// function to check whether the device is mobile or not
export const getIsMobileOrNot = () => {
	try {
		return /Mobi|Android/i.test(navigator.userAgent);
	} catch (error) {
		return false;
	}
};
// for geting useragent
export const getUserAgent = () => {
	try {
		return navigator.userAgent;
	} catch (error) {
		return 'N/A';
	}
};
