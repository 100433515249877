import React, { ChangeEvent, useState, useEffect } from 'react';

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import * as constants from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import {
	getPasswordVerificationClicked,
	setPasswordVerificationClicked,
} from 'slices/authUserSlice';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomInput from '../common/forms/CustomInput';
import CustomModal from '../common/modals/CustomModal';
import CustomTextArea from '../common/forms/CustomTextArea';
import { IpasswordVerification } from '../common/model';
import usePasswordVerification from './hooks/usePasswordVerification';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const PasswordVerification = ({
	toggleDialog,
	onVerificationSuccess,
	actions,
	transactions,
	referenceId,
}: IpasswordVerification) => {
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();
	const passwordVerificationClicked = useAppSelector(getPasswordVerificationClicked);

	/** States */
	const [verifyPassword, setVerifyPassowrd] = useState<string>('');
	const [remark, setRemark] = useState<string>('');
	const [passwordShown, setPasswordShown] = useState<boolean>(false);
	const [passwordError, setPasswordError] = useState<string>('');

	// hooks
	const { passwordVerificationRes, passwordVerificationLoading } = usePasswordVerification({
		password: verifyPassword,
		remark,
		actionId: actions,
		transaction: transactions,
		referenceId,
	});

	/**
	 * verification api response handling
	 */
	useEffect(() => {
		if (passwordVerificationRes) {
			onVerificationSuccess(remark);
			setVerifyPassowrd('');
			setRemark('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [passwordVerificationRes, passwordVerificationClicked]);

	// All handles
	const togglePasswordVisiblity = () => {
		setPasswordShown((prevState) => !prevState);
	};

	/** Password change */
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		setVerifyPassowrd(e.target.value);
	};

	/** Remark change */
	const handleRemark = (e: ChangeEvent<HTMLTextAreaElement>) => {
		setRemark(e.target.value);
	};

	const handleSubmit = () => {
		if (verifyPassword) {
			dispatch(setPasswordVerificationClicked(true));
		} else {
			setPasswordError('Please enter password');
		}
	};

	return (
		<CustomModal
			className='alert-modal confirmation'
			title={constants.PLEASECONFIRM}
			toggleDialog={toggleDialog}
			closeIcon={!passwordVerificationLoading}>
			<div className='confirmation-modal modal-content'>
				<div className='modal-body-content'>
					<div className='default-form-style w-100'>
						<div className='form-group '>
							<CustomInput
								value={verifyPassword}
								onChange={handleChange}
								className={passwordError !== '' ? 'input-validation-error' : ''}
								name='password'
								label={t('PASSWORD')!!}
								placeholder={t('PASSWORD')}
								required
								type={passwordShown ? 'text' : 'password'}
							/>

							<p className='error-msg'>{passwordError}</p>
							<i
								className='show-password'
								role='button'
								onKeyDown={togglePasswordVisiblity}
								tabIndex={0}
								onClick={togglePasswordVisiblity}>
								{passwordShown ? eye : eyeSlash}
							</i>
						</div>
						<div className='form-group'>
							<CustomTextArea
								textAreaId='details'
								placeholder={t('REMARK')}
								textAreaName='Company Details'
								required={false}
								rows={4}
								cols={50}
								label={t('REMARK')!!}
								onChange={handleRemark}
								value={remark}
							/>
						</div>
					</div>
				</div>

				<DialogActionsBar>
					<button
						type='button'
						disabled={passwordVerificationLoading}
						className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-outline-secondary h-44px'
						onClick={toggleDialog}>
						{t('CANCEL')}
					</button>
					<button
						type='button'
						disabled={passwordVerificationLoading}
						className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px'
						onClick={handleSubmit}>
						{passwordVerificationLoading ? <CustomLoader /> : t('SUBMIT')}
					</button>
				</DialogActionsBar>
			</div>
		</CustomModal>
	);
};

export default PasswordVerification;
