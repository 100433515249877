import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';

import { RouteUrl } from 'utils/enum';
import CustomButton from 'components/common/button/CustomButton';
import { getNotificationCount, setNotificationCount } from 'slices/notificationSlice';
import { useAppSelector } from 'hooks/stateHooks';
import FileFailedDropdownSkeleton from 'components/common/skeleton/FileFailedDropdownSkeleton';
import { LAST_30_DAYS } from 'utils/constants';
import { INotificationList } from 'components/common/model';
import ViewAllNotificationCard from './ViewAllNotificationCard';
import useUpdateNotificationStatus from './hooks/useUpdateNotificationStatus';
import useGetAllNotifications from './hooks/useGetAllNotifications';

const NotificationDropdown = ({ hidePopover }: any) => {
	const { t } = useTranslation('dashboard');
	const navigate = useNavigate();
	const dispatch = useDispatch();
	const notificationCount = useAppSelector(getNotificationCount);

	const [notificationData, setNotificationData] = useState<INotificationList[]>([]);
	const [params, setParams] = useState<any>({ readAll: false, notificationDetails: [] });
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

	const updateIsSubmitted = () => {
		setIsSubmitted(false);
		setParams({ readAll: false, notificationDetails: [] });
	};
	const startDate = new Date();
	startDate.setDate(startDate.getDate() - LAST_30_DAYS);
	useUpdateNotificationStatus(params, isSubmitted, updateIsSubmitted);
	const { loading, notificationList } = useGetAllNotifications(null, null, false);

	// setting notification data from hook to local state

	useEffect(() => {
		setNotificationData(notificationList);
	}, [notificationList]);

	/**
	 * To handle the notification click
	 * @param e Event params
	 */
	const onDivClick = (e: any) => {
		e.preventDefault();
		e.stopPropagation();
	};

	/**
	 * View more button logic
	 */
	const onViewMoreClick = () => {
		hidePopover();
		navigate(RouteUrl.ALLNOTIFICATIONS);
	};

	// mark all read functionality
	const markAllAsRead = () => {
		const tempDataState: any =
			notificationData &&
			notificationData.length > 0 &&
			notificationData.map((data: any) => {
				return { ...data, isRead: true };
			}, []);

		dispatch(setNotificationCount(0));
		setParams({ ...params, readAll: true });
		setIsSubmitted(true);
		setNotificationData(tempDataState);
	};
	// updating notification individually
	const handleUpdateReadStatus = (id: number) => {
		const tempDataState: any = [...notificationData];
		notificationData.forEach((data: any, index: number) => {
			if (id === data.notificationId) tempDataState[index].isRead = true;
		});
		setNotificationData(tempDataState);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div onClick={(e: any) => onDivClick(e)}>
				{loading ? (
					<FileFailedDropdownSkeleton />
				) : (
					// eslint-disable-next-line react/jsx-no-useless-fragment
					<>
						<h3>
							{`${t('NOTIFICATIONS')!!} (${
								// eslint-disable-next-line no-unsafe-optional-chaining
								notificationCount
							})`}
							{notificationCount > 0 && (
								<CustomButton
									buttonName={t('MARK_ALL_AS_READ')}
									handleClick={markAllAsRead}
									buttonClassName='mark-all-read'
									type='button'
								/>
							)}
						</h3>
						<div className='notification-list-card-wrapper'>
							{notificationData && notificationData.length > 0 ? (
								notificationData.map((item: any, index: number) => {
									return (
										<div
											className='notification-list-card'
											// eslint-disable-next-line react/no-array-index-key
											key={`${item.notificationId} ${index}`}>
											<ViewAllNotificationCard
												updateReadStatus={handleUpdateReadStatus}
												item={item}
											/>
										</div>
									);
								})
							) : (
								<div className='no-notification'>{t('NO_NOTIFICATION')}</div>
							)}
						</div>
						{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
						<CustomButton
							buttonName={t('VIEW_ALL')}
							handleClick={() => onViewMoreClick()}
							buttonClassName='view-all'
							type='button'
						/>
					</>
				)}
			</div>
		</>
	);
};

export default NotificationDropdown;
