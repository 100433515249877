import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	roleOptions: [],
	userUpdateStatus: false,
};

export const userModuleSlice = createSlice({
	name: 'userModule',
	initialState,
	reducers: {
		setActiveRoles: (state, action: PayloadAction<any>) => {
			state.roleOptions = action.payload;
		},
		setUserUpdateStatus: (state, action: PayloadAction<any>) => {
			state.userUpdateStatus = action.payload;
		},
		setUserStatusUpdate: (state, action: PayloadAction<any>) => {
			state.userStatusUpdate = action.payload;
		},
	},
});

export const { setActiveRoles, setUserUpdateStatus, setUserStatusUpdate } = userModuleSlice.actions;

export const getRoleOptions = (state: RootState) => state.userModule.roleOptions;
export const getUserUpdateStatus = (state: RootState) => state.userModule.userUpdateStatus;
export const getUserStatusUpdate = (state: RootState) => state.userModule.userStatusUpdate;

export default userModuleSlice.reducer;
