import { useEffect } from 'react';
import { Carousel } from 'react-bootstrap';

import NoImage from '../../assets/images/NoImage.svg.png';

const ProductImageCarousel = ({ image }: any) => {
	useEffect(() => {
		const carouselButtonElement = document.getElementsByClassName('carousel-indicators');
		carouselButtonElement[0].classList.add('hide-carousel-slide');
	}, []);

	return (
		<Carousel fade>
			<Carousel.Item>
				<Carousel.Caption>
					<img
						className='scan-img-width'
						src={image && image !== '' ? image : NoImage}
						alt='no-data'
					/>
				</Carousel.Caption>
			</Carousel.Item>
		</Carousel>
	);
};

export default ProductImageCarousel;
