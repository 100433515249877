import { useState, useEffect } from 'react';

import { UPDATE_LANGUAGE_URL } from 'utils/serviceUrls';
import { axiosPrivateInstance } from '../libs/axios/index';

/**
 *
 * @param selectedLanguage -key which indicates the language which have selecetd
 * @param languageChanged-key which indicates that language have changed
 * @param setLanguageChanged--language click status change function
 * @returns language change response and language change loading
 */
const useLanguageSwitcher = (
	selectedLanguage: any,
	languageChanged: boolean,
	setLanguageChanged: any,
) => {
	const [languageSwitchResponse, setLanguageSwitchResponse] = useState<boolean>(false);
	const [languageSwitchloading, setLanguageSwitchLoading] = useState<boolean>(false);

	const params = {
		encLanguageId: selectedLanguage?.encLanguageId,
	};

	useEffect(() => {
		if (languageChanged) {
			setLanguageSwitchLoading(true);
			axiosPrivateInstance
				.post(UPDATE_LANGUAGE_URL, params)
				.then((response: any) => {
					if (response?.status) {
						setLanguageSwitchResponse(true);
					} else {
						setLanguageSwitchResponse(false);
					}
				})
				.catch(() => {
					setLanguageSwitchResponse(false);
				})
				.finally(() => {
					setLanguageSwitchLoading(false);
				});
		}
		return () => {
			setLanguageSwitchLoading(false);
			setLanguageChanged(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [languageChanged]);

	return {
		languageSwitchResponse,
		languageSwitchloading,
	};
};
export default useLanguageSwitcher;
