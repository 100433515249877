import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	auditReportStatusValue: '',
	auditReportStatusFilterValue: '',
	createdDateValue: '',
	createdDateFilterValue: '',
	reviewedDateValue: '',
	reviewedDateFilterValue: '',
	approvedDateValue: '',
	approvedDateFilterValue: '',
	returnedDateValue: '',
	returnedDateFilterValue: '',
	weblinkReportUpdateStatus: false,
	weblinkGeneratedDateValue: '',
	weblinkGeneratedDateFilterValue: '',

	weblinkCommissionedOnDateValue: '',
	weblinkCommissionedOnDateFilterValue: '',

	weblinkProvidedToL3OnDateValue: '',
	weblinkProvidedToL3OnDateFilterValue: '',

	weblinkShippedOnDateValue: '',
	weblinkShippedOnDateFilterValue: '',

	// endUserManufacturingDateValue: '',
	// endUserManufacturingDateFilterValue: '',

	// endUserExpiryDateValue: '',
	// endUserExpiryDateFilterValue: '',

	// endUserScanDateValue: '',
	// endUserScanDateFilterValue: '',
};
export const reportModuleSlice = createSlice({
	name: 'reportModule',
	initialState,
	reducers: {
		setAuditReportStatusValue: (state, action: PayloadAction<any>) => {
			state.auditReportStatusValue = action.payload;
		},
		setAuditReportStatusFilterValue: (state, action: PayloadAction<any>) => {
			state.auditReportStatusFilterValue = action.payload;
		},
		setCreatedDateValue: (state, action: PayloadAction<any>) => {
			state.createdDateValue = action.payload;
		},
		setCreatedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.createdDateFilterValue = action.payload;
		},
		setReviewedDateValue: (state, action: PayloadAction<any>) => {
			state.reviewedDateValue = action.payload;
		},
		setReviewedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.reviewedDateFilterValue = action.payload;
		},
		setApprovedDateValue: (state, action: PayloadAction<any>) => {
			state.approvedDateValue = action.payload;
		},
		setApprovedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.approvedDateFilterValue = action.payload;
		},
		setReturnedDateValue: (state, action: PayloadAction<any>) => {
			state.returnedDateValue = action.payload;
		},
		setReturnedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.returnedDateFilterValue = action.payload;
		},
		setWeblinkReportUpdateStatus: (state, action: PayloadAction<any>) => {
			state.weblinkReportUpdateStatus = action.payload;
		},
		setWeblinkGeneratedDateValue: (state, action: PayloadAction<any>) => {
			state.weblinkGeneratedDateValue = action.payload;
		},
		setWeblinkGeneratedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.weblinkGeneratedDateFilterValue = action.payload;
		},

		setWeblinkCommissionedOnDateValue: (state, action: PayloadAction<any>) => {
			state.weblinkCommissionedOnDateValue = action.payload;
		},
		setWeblinkCommissionedOnDateFilterValue: (state, action: PayloadAction<any>) => {
			state.weblinkCommissionedOnDateFilterValue = action.payload;
		},

		setWeblinkProvidedToL3OnDateValue: (state, action: PayloadAction<any>) => {
			state.weblinkProvidedToL3OnDateValue = action.payload;
		},
		setWeblinkProvidedToL3OnDateFilterValue: (state, action: PayloadAction<any>) => {
			state.weblinkProvidedToL3OnDateFilterValue = action.payload;
		},

		setWeblinkShippedOnDateValue: (state, action: PayloadAction<any>) => {
			state.weblinkShippedOnDateValue = action.payload;
		},
		setWeblinkShippedOnDateFilterValue: (state, action: PayloadAction<any>) => {
			state.weblinkShippedOnDateFilterValue = action.payload;
		},
		// setEndUserManufacturingDateValue: (state, action: PayloadAction<any>) => {
		// 	state.endUserManufacturingDateValue = action.payload;
		// },
		// setEndUserManufacturingDateFilterValue: (state, action: PayloadAction<any>) => {
		// 	state.endUserManufacturingDateFilterValue = action.payload;
		// },
		// setEndUserExpiryDateValue: (state, action: PayloadAction<any>) => {
		// 	state.endUserExpiryDateValue = action.payload;
		// },
		// setEndUserExpiryDateFilterValue: (state, action: PayloadAction<any>) => {
		// 	state.endUserExpiryDateFilterValue = action.payload;
		// },
		// setEndUserScanDateValue: (state, action: PayloadAction<any>) => {
		// 	state.endUserScanDateValue = action.payload;
		// },
		// setEndUserScanDateFilterValue: (state, action: PayloadAction<any>) => {
		// 	state.endUserScanDateFilterValue = action.payload;
		// },
	},
});
export const {
	setAuditReportStatusValue,
	setAuditReportStatusFilterValue,
	setCreatedDateValue,
	setCreatedDateFilterValue,
	setReviewedDateValue,
	setReviewedDateFilterValue,
	setApprovedDateValue,
	setApprovedDateFilterValue,
	setReturnedDateValue,
	setReturnedDateFilterValue,
	setWeblinkReportUpdateStatus,
	setWeblinkGeneratedDateValue,
	setWeblinkGeneratedDateFilterValue,
	setWeblinkCommissionedOnDateValue,
	setWeblinkCommissionedOnDateFilterValue,
	setWeblinkProvidedToL3OnDateValue,
	setWeblinkProvidedToL3OnDateFilterValue,
	setWeblinkShippedOnDateValue,
	setWeblinkShippedOnDateFilterValue,
	// setEndUserManufacturingDateValue,
	// setEndUserManufacturingDateFilterValue,
	// setEndUserExpiryDateFilterValue,
	// setEndUserExpiryDateValue,
	// setEndUserScanDateFilterValue,
	// setEndUserScanDateValue,
} = reportModuleSlice.actions;
export const getAuditReportStatusValue = (state: RootState) =>
	state.reportModule.auditReportStatusValue;
export const getAuditReportStatusFilterValue = (state: RootState) =>
	state.reportModule.auditReportStatusFilterValue;
export const getCreatedDateValue = (state: RootState) => state.reportModule.createdDateValue;
export const getCreatedDateFilterValue = (state: RootState) =>
	state.reportModule.createdDateFilterValue;
export const getReviewedDateValue = (state: RootState) => state.reportModule.reviewedDateValue;
export const getReviewedDateFilterValue = (state: RootState) =>
	state.reportModule.reviewedDateFilterValue;
export const getApprovedDateValue = (state: RootState) => state.reportModule.approvedDateValue;
export const getApprovedDateFilterValue = (state: RootState) =>
	state.reportModule.approvedDateFilterValue;
export const getReturnedDateValue = (state: RootState) => state.reportModule.returnedDateValue;
export const getReturnedDateFilterValue = (state: RootState) =>
	state.reportModule.returnedDateFilterValue;
export const getWeblinkReportUpdateStatus = (state: RootState) =>
	state.reportModule.weblinkReportUpdateStatus;

export const getWeblinkGeneratedDateValue = (state: RootState) =>
	state.reportModule.weblinkGeneratedDateValue;
export const getWeblinkGeneratedDateFilterValue = (state: RootState) =>
	state.reportModule.weblinkGeneratedDateFilterValue;

export const getWeblinkCommissionedOnDateValue = (state: RootState) =>
	state.reportModule.weblinkCommissionedOnDateValue;
export const getWeblinkCommissionedOnDateFilterValue = (state: RootState) =>
	state.reportModule.weblinkCommissionedOnDateFilterValue;

export const getWeblinkProvidedToL3OnDateValue = (state: RootState) =>
	state.reportModule.weblinkProvidedToL3OnDateValue;

export const getWeblinkProvidedToL3OnDateFilterValue = (state: RootState) =>
	state.reportModule.weblinkProvidedToL3OnDateFilterValue;

export const getWeblinkShippedOnDateValue = (state: RootState) =>
	state.reportModule.weblinkShippedOnDateValue;

export const getWeblinkShippedOnDateFilterValue = (state: RootState) =>
	state.reportModule.weblinkShippedOnDateFilterValue;
// export const getEndUserManufacturingDateValue = (state: RootState) =>
// 	state.reportModule.endUserManufacturingDateValue;
// export const getEndUserManufacturingDateFilterValue = (state: RootState) =>
// 	state.reportModule.endUserManufacturingDateFilterValue;
// export const getEndUserExpiryDateValue = (state: RootState) =>
// 	state.reportModule.endUserExpiryDateValue;
// export const getEndUserExpiryDateFilterValue = (state: RootState) =>
// 	state.reportModule.endUserExpiryDateFilterValue;
// export const getEndUserScanDateValue = (state: RootState) =>
// 	state.reportModule.endUserScanDateValue;
// export const getEndUserScanDateFilterValue = (state: RootState) =>
// 	state.reportModule.endUserScanDateFilterValue;
export default reportModuleSlice.reducer;
