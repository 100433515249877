import { useCallback, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import {
	GS1_CHECK_ID,
	GS1_RADIO_ID,
	GS1_WEBLINK_TYPE_ID,
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	MODE_VALUES,
} from 'utils/enum';
import { getBaseUrl, productFormValidations } from 'utils/utils';
import { getEncProductId } from 'slices/productmoduleSlice';
import { useAppSelector } from 'hooks/stateHooks';
import { useLocation } from 'react-router';

import CustomButton from 'components/common/button/CustomButton';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomInput from 'components/common/forms/CustomInput';
import CustomRadioButton from 'components/common/forms/CustomRadioButton';
import CustomCheckBox from 'components/common/forms/CustomCheckBox';
import CustomDropdown from 'components/common/forms/CustomDropdown';
import CustomError from 'components/common/errorLabel/CustomError';
import Gs1Skelton from 'components/common/skeleton/Gs1Skelton';
import CustomQRCode from 'components/common/qrCode/CustomQRCode';
import {
	WEBLINK_BATCH,
	WEBLINK_EXP_DATE,
	WEBLINK_GTIN_CODE,
	WEBLINK_SN_CODE,
} from 'utils/constants';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import useGetAllWeblinkType from './weblink/hooks/useGetAllWeblinkType';
import useGetAllGS1 from './weblink/hooks/useGetAllGS1';
import useGetAllModuleSize from './weblink/hooks/useGetAllModuleSize';
import useSaveGs1 from './weblink/hooks/useSaveGs1';

const Gs1Weblink = () => {
	const { t } = useTranslation('gs1Weblink');
	const location = useLocation();
	const encProductId = useAppSelector(getEncProductId);
	const qrCodeSize = 121;

	// States
	const [gs1WeblinkInfo, setGs1WeblinkInfo] = useState<string>('');
	const [weblinkType, setWeblinkType] = useState<string>('');
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [selectedRadio, setSelectedRadio] = useState<string>('');
	const [selectedCheck, setSelectedCheck] = useState<string[]>([]);
	const [radioData, setRadioData] = useState<any[]>([]);
	const [checkData, setCheckData] = useState<any[]>([]);
	const [moduleSize, setModuleSize] = useState<string>('');
	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [mode, setMode] = useState<'add' | 'edit' | 'view' | 'approve' | 'review'>('add');

	// Icon description
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.GS1_WEBLINK,
		ICON_DESCRIPTION_ID.GS1_WEBLINK,
	);

	// custom hooks for api calls
	const { gs1WeblinkList, gs1WeblinkLoading } = useGetAllGS1(
		location?.state?.productId ? location?.state?.productId : encProductId,
	);
	const { moduleSizeResponse } = useGetAllModuleSize();
	const { weblinkTypeResponse } = useGetAllWeblinkType();

	/**
	 * Callback to update the submit trigger state
	 */
	const updateIsSubmitted = () => {
		setIsSubmitted(false);
	};

	useEffect(() => {
		if (location?.state) {
			setMode(location?.state?.mode);
		} else {
			setMode('add');
		}
	}, [location?.state]);

	/**
	 * fuction to create url on data change
	 */
	const createUrl = useCallback(() => {
		let url = getBaseUrl();
		checkData.forEach((item: any) => {
			if (selectedCheck.includes(item?.encSettingsId)) {
				if (item?.encSettingsId === GS1_CHECK_ID.GTIN) {
					const stringData = 'x'.repeat(item?.length);
					url = `${url}/${WEBLINK_GTIN_CODE}/${stringData}`;
				}
			}
		});

		if (weblinkType === GS1_WEBLINK_TYPE_ID.GS1_SN) {
			radioData.forEach((item: any) => {
				if (selectedRadio === item?.encSettingsId) {
					if (selectedRadio === GS1_RADIO_ID.SN) {
						const stringData = 'x'.repeat(item?.length);
						url = `${url}/${WEBLINK_SN_CODE}/${stringData}`;
					} else {
						const stringData = 'x'.repeat(item?.length);
						url = `${url}/${WEBLINK_SN_CODE}/${stringData}`;
					}
				}
			});
		}
		checkData.forEach((item: any) => {
			if (selectedCheck.includes(item?.encSettingsId)) {
				if (item?.encSettingsId === GS1_CHECK_ID.BATCH) {
					const stringData = 'x'.repeat(item?.length);
					url = `${url}/${WEBLINK_BATCH}/${stringData}`;
				}
				if (item?.encSettingsId === GS1_CHECK_ID.EXP_DATE) {
					const stringData = 'yymmdd';
					url = `${url}?${WEBLINK_EXP_DATE}=${stringData}`;
				}
			}
		});

		return url;
	}, [checkData, radioData, selectedCheck, selectedRadio, weblinkType]);

	/**
	 * fuction to pass module size id to the update hook
	 */

	const getEstimatedSizeId = useCallback(() => {
		let size = '';
		const urlLength = createUrl().length;

		if (moduleSize) {
			moduleSizeResponse.forEach((item: any) => {
				if (moduleSize) {
					if (item?.encModuleSizeId === moduleSize) {
						item?.qrDataList.forEach((qrData: any) => {
							if (
								urlLength >= qrData.UrlLengthFrom &&
								urlLength <= qrData.UrlLengthTo
							) {
								size = qrData.encQRCodeSizeId;
							}
						});
					}
				}
			});
		}
		return size;
	}, [createUrl, moduleSize, moduleSizeResponse]);

	/** Custom hook to generate the report */
	const { submitGs1WeblinkResponseLoading } = useSaveGs1(
		isSubmitted,
		{
			encProductId,
			encWebLinkTypeId: weblinkType,
			encSnoId: selectedRadio,
			encSettingsIds: selectedCheck,
			gs1SettingsUrl: createUrl(),
			encModuleSizeId: moduleSize,
			encQRCodeSizeId: getEstimatedSizeId(),
		},
		updateIsSubmitted,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setGs1WeblinkInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	// handling response of the get on load
	useEffect(() => {
		if (gs1WeblinkList) {
			const tempRadioData: any[] = [];
			const tempCheckData: any[] = [];
			const tempSelectedCheckData: string[] = [];
			// Setting the radio and check field data
			gs1WeblinkList?.settingsList?.forEach((item: any) => {
				if (item?.type === 1) {
					tempRadioData.push(item);
					if (item?.isApplicable) {
						setSelectedRadio(item?.encSettingsId);
					}
				} else {
					tempCheckData.push(item);
					if (
						item?.isApplicable ||
						((gs1WeblinkList?.encWebLinkTypeId === GS1_WEBLINK_TYPE_ID.GS1_SN ||
							gs1WeblinkList?.encWebLinkTypeId ===
								GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN) &&
							item?.encSettingsId === GS1_CHECK_ID.GTIN) ||
						(gs1WeblinkList?.encWebLinkTypeId === GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN &&
							item?.encSettingsId === GS1_CHECK_ID.BATCH)
					) {
						tempSelectedCheckData.push(item?.encSettingsId);
					}
				}
			});
			setRadioData(tempRadioData);
			setCheckData(tempCheckData);
			setSelectedCheck(tempSelectedCheckData);
			setModuleSize(gs1WeblinkList?.encModuleSizeId ? gs1WeblinkList?.encModuleSizeId : '');
			setWeblinkType(
				gs1WeblinkList?.encWebLinkTypeId ? gs1WeblinkList?.encWebLinkTypeId : '',
			);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gs1WeblinkList]);

	// Handle changes
	const handleRadioChange = (e: any) => {
		setErrorState((prev) => ({ ...prev, estimatedSize: '' }));
		setSelectedRadio(e);
	};

	const onModuleSizeChange = (e: any) => {
		setErrorState((prev) => ({ ...prev, moduleSize: '', estimatedSize: '' }));
		setModuleSize(e);
	};

	const handleCheckBox = (e: any, id: string) => {
		let tempSelected = [...selectedCheck];
		if (selectedCheck.includes(id.toString())) {
			tempSelected = selectedCheck.filter((itemVal: any) => {
				return itemVal !== id;
			}, []);
		} else {
			tempSelected.push(id);
		}
		setErrorState((prev) => ({ ...prev, estimatedSize: '' }));
		setSelectedCheck(tempSelected);
	};

	// Getting the estimated field from url length and the module size
	const getEstimatedSize = useCallback(() => {
		let size = '';
		const urlLength = createUrl().length;

		if (moduleSize) {
			moduleSizeResponse?.forEach((item: any) => {
				if (moduleSize) {
					if (item?.encModuleSizeId === moduleSize) {
						item?.qrDataList.forEach((qrData: any) => {
							if (
								urlLength >= qrData?.UrlLengthFrom &&
								urlLength <= qrData?.UrlLengthTo
							) {
								size = qrData?.qrCodeSize;
								// setQrCodeId(qrData.encQRCodeSizeId);
							}
						});
					}
				}
			});
		}
		return size;
	}, [createUrl, moduleSize, moduleSizeResponse]);

	// string to maths expression eg("10*10") will return 100
	const stringMathExpression = (s: string) => {
		let total = 0;
		const stringArr: string[] = s?.match(/[+-]*(\.\d+|\d+(\.\d+)?)/g) || [];

		if (stringArr?.length > 0) {
			total += parseFloat(stringArr[0]) * parseFloat(stringArr[0]);
		}
		return total;
	};

	// handle submit with validation
	const handleSubmit = () => {
		const error = productFormValidations(
			{
				moduleSize,
				estimatedSize: getEstimatedSize(),
				weblinkType,
			},
			{
				weblinkType: { required: true },
				moduleSize: {
					required:
						weblinkType === GS1_WEBLINK_TYPE_ID.GS1_SN ||
						weblinkType === GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN,
				},
				estimatedSize: {
					required:
						weblinkType === GS1_WEBLINK_TYPE_ID.GS1_SN ||
						weblinkType === GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN,
				},
			},
		);

		setErrorState(error);
		if (Object.keys(error).length === 0) {
			setIsSubmitted(true);
		}
	};

	const handleWeblinkType = (e: any) => {
		setErrorState((prev) => ({ ...prev, weblinkType: '' }));
		setWeblinkType(e);

		// Changing the selected on weblink type change
		let tempSelectedCheck: any = [...selectedCheck];
		if (e === GS1_WEBLINK_TYPE_ID.GS1_SN) {
			setSelectedRadio(GS1_RADIO_ID.SN);
			tempSelectedCheck = [GS1_CHECK_ID.GTIN];
		}
		if (e === GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN) {
			setSelectedRadio('');
			if (!selectedCheck.includes(GS1_CHECK_ID.GTIN)) {
				tempSelectedCheck.push(GS1_CHECK_ID.GTIN);
			}
			if (!selectedCheck.includes(GS1_CHECK_ID.BATCH)) {
				tempSelectedCheck.push(GS1_CHECK_ID.BATCH);
			}
		}
		if (e === GS1_WEBLINK_TYPE_ID.WITHOUT_GS1) {
			setSelectedRadio('');
			setModuleSize('');
			tempSelectedCheck = [];
		}
		setSelectedCheck(tempSelectedCheck);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{gs1WeblinkLoading ? (
				<Gs1Skelton />
			) : (
				<>
					{/* as tooltip changed to index component */}

					<div className='form-body'>
						<h4 className='form-section-title'>
							{t('GS1_WEBLINK_SETTINGS')}
							<CustomEditableTooltip
								showIcon='faInfoCircle'
								text={gs1WeblinkInfo}
								moduleId={ICON_DESCRIPTION_ID.GS1_WEBLINK}
							/>
						</h4>

						<div className='section-wrapper'>
							<div className='default-form-style h-100'>
								<div className='row'>
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomDropdown
												disabled={
													mode === MODE_VALUES.VIEW ||
													mode === MODE_VALUES.APPROVE ||
													mode === MODE_VALUES.REVIEW
												}
												popupSettings={{
													popupClass: 'default-select-dropdown',
												}}
												isId
												isMandatory
												dropdownValue={weblinkType}
												options={weblinkTypeResponse}
												handleDropDownChange={handleWeblinkType}
												className={`form-control default-select-dropdown ${
													errorState?.packageLevel &&
													errorState?.packageLevel !== '' &&
													'input-validation-error'
												}`}
												label={t('WEBLINK_TYPE')!!}
												dataItemKey='encWebLinkTypeId'
												dataTextField='webLinkType'
											/>
											{errorState?.weblinkType &&
												errorState?.weblinkType !== '' && (
													<CustomError value={errorState?.weblinkType} />
												)}
										</div>
									</div>
								</div>

								{(weblinkType === GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN ||
									weblinkType === GS1_WEBLINK_TYPE_ID.GS1_SN) && (
									<>
										<div className='row'>
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomInput
														value={createUrl()}
														disabled
														name='urlPreview'
														label={t('URL_PREVIEW')!!}
														placeholder={t('URL_PREVIEW')!!}
														required={false}
														type='text'
														onChange={() => {}}
														isMandatory
														error={errorState?.urlPreview}
														className={
															errorState?.urlPreview &&
															errorState?.urlPreview !== ''
																? 'input-validation-error'
																: ''
														}
													/>
												</div>
											</div>
										</div>
										<div className='row'>
											<div className='col-xl-6 col-lg-6'>
												<label className='form-label-cb'>
													{t('SELECT_IDS_TO_INCLUDE_IN_URL')}
												</label>
												{weblinkType === GS1_WEBLINK_TYPE_ID.GS1_SN && (
													<div className='form-group flex-wrap d-flex gap-3 gs-cb-wrapper mb-3'>
														{radioData &&
															radioData?.length > 0 &&
															radioData.map((item: any) => {
																return (
																	<CustomRadioButton
																		className=''
																		checked={
																			item.encSettingsId ===
																			selectedRadio
																		}
																		// remove when the enc sn implemented
																		disabled={
																			mode ===
																				MODE_VALUES.VIEW ||
																			mode ===
																				MODE_VALUES.APPROVE ||
																			mode ===
																				MODE_VALUES.REVIEW
																		}
																		handleChange={
																			handleRadioChange
																		}
																		id={item.idName}
																		value={item.encSettingsId}
																		label={item.idName}
																	/>
																);
															})}
													</div>
												)}
												<div className='gs-cb-wrapper mb-3'>
													<div className='form-group pb-0 d-flex flex-column'>
														{checkData &&
															checkData?.length > 0 &&
															checkData.map((item: any) => {
																return (
																	<div className='custom-checkbox-list mb-2'>
																		<CustomCheckBox
																			key={`check${item.encSettingsId}`}
																			disabled={
																				item?.encSettingsId ===
																					GS1_CHECK_ID.GTIN ||
																				(weblinkType ===
																					GS1_WEBLINK_TYPE_ID.GS1_WITHOUT_SN &&
																					item?.encSettingsId ===
																						GS1_CHECK_ID.BATCH) ||
																				mode ===
																					MODE_VALUES.VIEW ||
																				mode ===
																					MODE_VALUES.APPROVE ||
																				mode ===
																					MODE_VALUES.REVIEW ||
																				weblinkType ===
																					GS1_WEBLINK_TYPE_ID.GS1_SN
																			}
																			checked={selectedCheck.includes(
																				item?.encSettingsId,
																			)}
																			name='productionOrder'
																			labelPlacement='after'
																			label={item.idName}
																			handleChange={(e) =>
																				handleCheckBox(
																					e,
																					item.encSettingsId,
																				)
																			}
																		/>
																	</div>
																);
															})}
													</div>
												</div>
											</div>
											<div className='col-xl-6 col-lg-6'>
												<div className='form-group'>
													<CustomDropdown
														popupSettings={{
															popupClass: 'default-select-dropdown',
														}}
														isId
														isMandatory
														dropdownValue={moduleSize}
														options={moduleSizeResponse}
														handleDropDownChange={onModuleSizeChange}
														className={`form-control default-select-dropdown ${
															errorState?.moduleSize &&
															errorState?.moduleSize !== '' &&
															'input-validation-error'
														}`}
														label={t('MODULE_SIZE')!!}
														dataItemKey='encModuleSizeId'
														dataTextField='moduleSize'
														disabled={
															mode === MODE_VALUES.VIEW ||
															mode === MODE_VALUES.APPROVE ||
															mode === MODE_VALUES.REVIEW
														}
													/>
													{errorState?.moduleSize &&
														errorState?.moduleSize !== '' && (
															<CustomError
																value={errorState?.moduleSize}
															/>
														)}
												</div>
												<div className='form-group'>
													<CustomInput
														value={getEstimatedSize()}
														name='estimatedSize'
														label={t('ESTIMATED_SIZE')!!}
														placeholder={t('ESTIMATED_SIZE')!!}
														required={false}
														type='text'
														onChange={() => {}}
														isMandatory
														error={errorState.estimatedSize}
														className={
															errorState?.estimatedSize &&
															errorState?.estimatedSize !== ''
																? 'input-validation-error'
																: ''
														}
														disabled
													/>
												</div>
												<div className='form-group text-center'>
													<p className='form-label-cb mb-7px'>
														{t('PREVIEW')}
													</p>
													<CustomQRCode
														value={createUrl()}
														size={
															getEstimatedSize()
																? stringMathExpression(
																		getEstimatedSize(),
																  )
																: qrCodeSize
														}
													/>
												</div>
											</div>
										</div>
									</>
								)}
							</div>
						</div>
					</div>
					{encProductId &&
						mode !== MODE_VALUES.VIEW &&
						mode !== MODE_VALUES.APPROVE &&
						mode !== MODE_VALUES.REVIEW && (
							<div className='bottom-btn-wrapper'>
								<CustomButton
									buttonClassName='btn btn-primary h-44px icon-add-btn'
									buttonName={
										submitGs1WeblinkResponseLoading ? (
											<CustomLoader />
										) : (
											t('SAVE')!!
										)
									}
									disabled={submitGs1WeblinkResponseLoading}
									handleClick={handleSubmit}
									type='button'
								/>
							</div>
						)}
				</>
			)}
		</>
	);
};
export default Gs1Weblink;
