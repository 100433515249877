import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { RouteUrl } from 'utils/enum';
import { useTranslation } from 'react-i18next';

import CustomLoader from 'components/common/loader/CustomLoader';
import { RESEND_MINUTES, RESEND_SECONDS } from 'utils/constants';
import OtpVerification from './OtpVerification';
import useOtpVerification from './hooks/useOtpVerification';
import useResendOtp from './hooks/useResendOtp';

const Index = () => {
	const navigate = useNavigate();
	const location = useLocation();

	const { t } = useTranslation('l4Module');

	const [otp, setOtp] = useState<string>('');
	const [minutes, setMinutes] = useState<number>(RESEND_MINUTES);
	const [seconds, setSeconds] = useState<number>(RESEND_SECONDS);
	const [mfaSubmitClicked, setMfaSubmitClicked] = useState<boolean>(false);
	const [mfaSubmitValid, setMfaSubmitValid] = useState<boolean>(false);
	const [resendClicked, setResendClicked] = useState<boolean>(false);
	const [showMsg, setShowMsg] = useState<string>('');

	const [mfaUniqueID, setMfaUniqueID] = useState<any>('');

	const resetOtpVerification = () => {
		setMfaSubmitClicked(false);
		setMfaSubmitValid(false);
	};

	const { mfaLoginValue, loginStatus, mfaloginLoading } = useOtpVerification(
		mfaUniqueID,
		otp,
		mfaSubmitValid,
		resetOtpVerification,
	);

	/**
	 * hook for resend otp
	 */
	const resetResendClick = () => {
		setResendClicked(false);
	};
	useResendOtp(resendClicked, mfaUniqueID, resetResendClick);

	useEffect(() => {
		setMfaUniqueID(location.state && location.state?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [location.state]);

	// useEffect(() => {
	// 	if (mfaLoginValue?.status === false) {
	// 		setShowMsg(t('INVALID_OTP')!!);
	// 	}
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [mfaLoginValue?.status]);

	useEffect(() => {
		const interval = setInterval(() => {
			if (seconds > 0) {
				setSeconds(seconds - 1);
			}

			if (seconds === 0) {
				if (minutes === 0) {
					clearInterval(interval);
				} else {
					setSeconds(59);
					setMinutes(minutes - 1);
				}
			}
		}, 1000);

		return () => {
			clearInterval(interval);
		};
	}, [seconds, minutes]);

	const onChange = (value: string) => {
		setMfaSubmitValid(false);
		setOtp(value.slice(0, 6));
		setShowMsg('');
	};
	const resendOTP = () => {
		setResendClicked(true);
		setMinutes(RESEND_MINUTES);
		setSeconds(RESEND_SECONDS);
	};

	useEffect(() => {
		if (loginStatus) {
			if (mfaLoginValue.status && mfaLoginValue.token) {
				navigate(RouteUrl.DASHBOARD);
			} else if (mfaLoginValue?.status === false) {
				setShowMsg(t('INVALID_OTP')!!);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus, mfaLoginValue]);

	const handleSubmit = (e: any) => {
		e.preventDefault();
		setMfaSubmitClicked(true);
		if (otp !== '' && otp.length === 6) {
			setMfaSubmitValid(true);
		}
	};

	return (
		<form>
			<div>
				<label className='form-label'>{t('ENTER_OTP')}</label>
				<OtpVerification
					value={otp}
					valueLength={6}
					onChange={onChange}
					submitClicked={mfaSubmitClicked}
				/>
				<div className='remeber-reset-password-row countdown-text'>
					{seconds > 0 || minutes > 0 ? (
						<p>
							{t('TIME_LEFT')}: {minutes < 10 ? `0${minutes}` : minutes}:
							{seconds < 10 ? `0${seconds}` : seconds}
						</p>
					) : (
						<p>{t('DIDNT_RECEIVE_OTP')}?</p>
					)}
					<button
						disabled={seconds > 0 || minutes > 0}
						style={{
							color: seconds > 0 || minutes > 0 ? '#DFE3E8' : '#FF5630',
						}}
						onClick={resendOTP}
						className='forgot-password-btn'
						type='button'>
						{t('RESEND_OTP')}
					</button>
				</div>
			</div>

			<div className='remeber-reset-password-row'>
				<div className='cmn-weblink weblink-invalid'>
					<div className='error-msg-wrapper'>{showMsg}</div>
				</div>
			</div>

			<div>
				<button
					className='btn btn-primary h-44px w-100'
					type='submit'
					onClick={handleSubmit}>
					{mfaloginLoading ? <CustomLoader /> : t('SUBMIT')}
				</button>
			</div>
		</form>
	);
};

export default Index;
