import { useEffect } from 'react';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';

import InactiveLogoutTimer from 'components/idleDetection/InactiveLogoutTimer';
import ErrorBoundaryWrapper from 'libs/errorBoundary/ErrorBoundaryWrapper';
import CustomNotification from 'components/common/notification/CustomNotification';
import { getFavIcon, getTenantTitle } from 'slices/configSlice';
// import Footer from './footer/Index';
import LoginExpiryAlert from 'components/login/LoginExpiryAlert';
import Header from './header/Index';
import SideBar from './sidebar/Index';

import {
	getShowNotification,
	getMessage,
	setShowNotification,
	setMessage,
	getNotificationType,
	setNotificationType,
} from '../slices/roleModuleSlice';

const Index = () => {
	const dispatch = useAppDispatch();
	const showNotification = useAppSelector(getShowNotification);
	const getErrorMsg = useAppSelector(getMessage);
	const getNotifiType = useAppSelector(getNotificationType);
	const favIcon = useAppSelector(getFavIcon);
	const tenantTitle = useAppSelector(getTenantTitle);

	/**
	 * Closes the notification modal.
	 */
	const handleClose = () => {
		dispatch(setShowNotification(false));
		dispatch(setMessage(''));
		dispatch(setNotificationType(''));
	};

	/**
	 * Sets favicon and page title on mount using tenant config.
	 */
	useEffect(() => {
		/**
		 * Get link element for favicon.
		 */
		const link: any = document.querySelector('link[rel="icon"]');
		if (favIcon) {
			link.setAttribute('href', favIcon);
		}
		/**
		 * If title exists in tenant config, set page title.
		 */
		if (tenantTitle) {
			document.title = tenantTitle;
		}

		// return () => {
		// 	link.setAttribute('href', 'data:image/png;base64,');
		// };
	}, [favIcon, tenantTitle]);

	return (
		<ErrorBoundaryWrapper>
			{showNotification && (
				<CustomNotification
					message={getErrorMsg}
					onClose={handleClose}
					closable
					type={{ style: getNotifiType, icon: true }}
				/>
			)}
			<LoginExpiryAlert />
			<InactiveLogoutTimer>
				<Header />
				<SideBar>
					<Outlet />
				</SideBar>
				{/* Uncomment show footer */}
				{/* <Footer /> */}
			</InactiveLogoutTimer>
		</ErrorBoundaryWrapper>
	);
};
export default Index;
