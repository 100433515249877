import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosBasicInstance } from 'libs/axios';
import { RESET_PASSWORD_URL } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';

import { ERROR } from 'utils/constants';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
/**
 *
 * @param paramData -which includes new password,token and username
 * @returns reset password response and loading state
 */
const useResetPassword = (paramData: any) => {
	const dispatch = useAppDispatch();

	const { t } = useTranslation('userModule');
	const [resetPasswordResponse, setResetPasswordResponse] = useState<any>();
	const [resetPasswordLoading, setResetPasswordLoading] = useState<boolean>(false);

	useEffect(() => {
		if (paramData) {
			setResetPasswordLoading(true);
			axiosBasicInstance
				.post(RESET_PASSWORD_URL, paramData)
				.then((response: any) => {
					if (response?.status) {
						setResetPasswordResponse(response);
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText) || t('PASSWORD_NOT_CHANGED'),
							),
						);
						dispatch(setNotificationType(ERROR));
						dispatch(setShowNotification(true));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('PASSWORD_NOT_CHANGED')));
					dispatch(setNotificationType(ERROR));
					dispatch(setShowNotification(true));
				})
				.finally(() => {
					setResetPasswordLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramData]);

	return {
		resetPasswordLoading,
		resetPasswordResponse,
	};
};

export default useResetPassword;
