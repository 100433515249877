export const privilegesDataDetails = [
	{ AreaID: '0', PrivilegeArea: 'All' },
	{ AreaID: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09', PrivilegeArea: 'Production' },
	{ AreaID: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09', PrivilegeArea: 'Shipping' },
	{ AreaID: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09', PrivilegeArea: 'Store' },
];
export const RouteUrl = {
	DASHBOARD: '/dashboard',
	USERMODULE: '/user-management',
	ROLEMODULE: '/role-management',
	PRODUCTMODULE: '/product-management',
	SETTINGS: '/settings',
	TENANTMODULE: '/tenant-management',
	RESETPASSWORD: '/reset-password/:token',
	LOGINPAGE: '/',
	FORGOTPASSWORD: '/forgot-password',
	UNAUTHORIZEDACCESS: '/unauthorized-access',
	ADDPRODUCTS: '/product-management/manage-product',
	PRODUCTGROUP: '/product-group-management',
	ADDPRODUCTGROUP: '/product-group-management/manage-product-group',
	MANUFACTURER: '/manufacturer-management',
	PACKAGELEVEL: '/package-level-management',
	L3SYSTEM: '/l3-management',
	ADDL3SYSTEM: '/l3-management/manage-l3-management',
	PRODUCTORDER: '/product-order-management',
	WEBLINKVALIDATOR: '/weblink-validator',
	OTPVERIFICATION: '/otp-verification',
	AWAITINGACTIONS: '/awaiting-actions',
	EPCISMODULE: '/epcis-file-management',
	PODETAILS: '/product-order-management/manage-product-order',
	FILE_FAILURE_LIST: '/file-failure-list',
	WEBLINKSTATUS: '/weblink-status',
	ALLNOTIFICATIONS: '/notifications',
	AUDIT_TRIAL: '/audit-trail-report',
	REPORT_ACTION_PAGE: '/report-action',
	MODULECONFIGURATIONS: '/module-configuration',
	PORETRIEVAL: '/product-order-management/product-order-retrieval',
	AUDIT_SHOW: '/manage-audit-trail-report',
	BATCH_SUMMARY_REPORT: '/batch-summary-report',
	BATCH_DETAILS: '/batch-details-report',
	BATCH_DETAILS_SHOW: '/manage-batch-details-report',
	GENERATE_BATCH_SUMMARY: '/manage-batch-summary-report',
	LOCATION_DETAILS: '/location-management',
	L4SYSTEM: '/l4-management',
	ADDL4SYSTEM: '/l4-management/manage-l4-management',
	ADD_EDIT_LOCATION: '/location-management/manage-location',
	EXPORT_HISTORY_PAGE: '/export-history',
	SERIAL_NUMBER_MANAGEMENT: '/serial-number',
	ADD_SERIAL_NUMBER_TEMPLATE: '/manage-serial-number',
	PRODUCT_ORDER_SERIAL_NUMBER: '/product-order-serial-number',
	WEBLINK_REPORT: '/weblink-report',
	SSO: '/sso-management',
	SSO_LOGIN: 'sso/login',
	SSO_SUCCESS: 'sso/login-success/:token',
	GS1_WEBLINK_SETTINGS: '/gs1-weblink',
	WEBLINK_DOWNLOAD: '/weblink-download',
	GENERATE_WEBLINK_DOWNLOAD: '/generate-weblink-download',
	WEBLINK_SETTINGS: '/weblink-settings',
	ENDUSER_REPORT: '/enduser-scan-report',
	ENDUSER_REPORT_SHOW: '/manage-enduserscan-report',
	PAGE_404: '/page-not-found',
	MULTIPLE_SESSION: '/multiple-session',
	PRODUCT_GROUP_CONFIGURABLE: '/product-group-management/configurable',
	WEBLINK_FLUSHING: '/weblink-flushing',
	PRODUCT_VERSION_VIEW: '/product-history-management/view',
	PRODUCT_HISTORY_VIEW: '/product-history-management',

	ADD_EDIT_MANUFACTURER: '/manage-manufacturer',
	VIEW_LOCATION: '/location-management/view-location',
	VIEW_MANUFACTURER: '/view-manufacturer',
};

/**
 * privilege ids are for list view,  not encAreaId.
 */
export const URL_PERMISSIONS = [
	{
		encPrivilgeID: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
		encAreaId: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
		module: 'USER_MODULE',
		url: RouteUrl.USERMODULE,
	},
	{
		encPrivilgeID: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09', // Role Menu View - custom made
		encAreaId: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
		module: 'ROLE_MODULE',
		url: RouteUrl.ROLEMODULE,
	},
	{
		encPrivilgeID: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
		encAreaId: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
		module: 'DASHBOARD',
		url: RouteUrl.DASHBOARD,
	},
	{
		encPrivilgeID: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		module: 'PRODUCT_MODULE',
		url: RouteUrl.PRODUCTMODULE,
	},
	{
		encPrivilgeID: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
		encAreaId: '', // to be obtained from DB
		module: 'SETTINGS',
		url: RouteUrl.SETTINGS,
	},
	{
		encPrivilgeID: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
		encAreaId: '', // to be obtained from DB
		module: 'TENENT_MODULE',
		url: RouteUrl.TENANTMODULE,
	},
	{
		encPrivilgeID: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
		module: 'ADDPRODUCTS',
		url: RouteUrl.ADDPRODUCTS,
	},

	{
		encPrivilgeID: 'TkNMVXFISzJrZ2ZHcDRya3VxTUUxUT09',
		module: 'PRODUCTGROUP',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		url: RouteUrl.PRODUCTGROUP,
	},
	{
		encPrivilgeID: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
		module: 'DASHBOARD',
		url: RouteUrl.ADDPRODUCTGROUP,
	},
	{
		encPrivilgeID: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
		module: 'MANUFACTURER_MODULE',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		url: RouteUrl.MANUFACTURER,
	},
	{
		encPrivilgeID: 'cFVSY1B3QUhheUJZalc4QjRWeU1uUT09',
		module: 'PACKAGE_LEVEL_MODULE',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		url: RouteUrl.PACKAGELEVEL,
	},
	{
		encPrivilgeID: 'cmFqUWlyOGpHRHlWSUNZY2JvMjdnZz09',
		module: 'L3_SYSTEM',
		url: RouteUrl.L3SYSTEM,
	},
	{
		encPrivilgeID: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
		module: 'ADDL3SYSTEM',
		url: RouteUrl.ADDL3SYSTEM,
	},
	{
		encPrivilgeID: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09', // to be obtained from DB
		module: 'PRODUCTORDER',
		url: RouteUrl.PRODUCTORDER,
	},
	{
		encPrivilgeID: 'cnoyVmsrQUxhUFhiVk5KT1Zhb2d1Zz09',
		module: 'WEBLINKVALIDATOR',
		url: RouteUrl.WEBLINKVALIDATOR,
	},
	{
		encPrivilgeID: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09',
		module: 'OTPVERIFICATION',
		url: RouteUrl.OTPVERIFICATION,
	},
	{
		encPrivilgeID: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09', // to be change
		module: 'AWAITINGACTIONS',
		url: RouteUrl.AWAITINGACTIONS,
	},
	{
		encPrivilgeID: 'YmVTbWlCZUpDZUR0NUdRVTJ2bWdNdz09',
		encAreaId: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
		module: 'EPCISFILE',
		url: RouteUrl.EPCISMODULE,
	},
	{
		encPrivilgeID: 'a1g2U2EzSHlSL1lDV0lUZW9qNlh6UT09',
		encAreaId: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
		module: 'FILE_FAILURE_LIST',
		url: RouteUrl.FILE_FAILURE_LIST,
	},
	{
		encPrivilgeID: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09',
		module: 'WEBLINKSTATUS',
		url: RouteUrl.WEBLINKSTATUS,
	},
	{
		encPrivilgeID: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09',
		module: 'PRODUCTDETAILS',
		url: RouteUrl.PODETAILS,
	},
	{
		encPrivilgeID: 'UFBHUE54d1lzbEVGZzRVbFFKTDduZz09',
		module: 'AllNPTIFICATIONS',
		url: RouteUrl.ALLNOTIFICATIONS,
	},
	{
		encPrivilgeID: 'aFE1NnhBZE9tTDJ0elVETm43YkN3Zz09',
		module: 'AUDIT_TRIAL',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		url: RouteUrl.AUDIT_TRIAL,
	},
	{
		encPrivilgeID: 'UFBHUE54d1lzbEVGZzRVbFFKTDduZz09', // to be changed
		module: 'REPORT_ACTION_PAGE',
		url: RouteUrl.REPORT_ACTION_PAGE,
	},
	{
		encPrivilgeID: 'ZitjOE5sVkMyZVpQd1JiMWhyYmh2dz09',
		module: 'ModuleConfigurations',
		encAreaId: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
		url: RouteUrl.MODULECONFIGURATIONS,
	},
	{
		encPrivilgeID: 'TWVsRWdINkRlQU1IRm5tL0UyZU0rQT09',
		module: 'PORETRIVEAL',
		url: RouteUrl.PORETRIEVAL,
	},
	{
		encPrivilgeID: 'aFE1NnhBZE9tTDJ0elVETm43YkN3Zz09',
		module: 'AUDITTRAIL',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		url: RouteUrl.AUDIT_SHOW,
	},
	{
		encPrivilgeID: 'Nlp4QkRMM0NscjRveVU2RE05L0V6UT09',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		module: 'BATCH_SUMMARY_REPORT',
		url: RouteUrl.BATCH_SUMMARY_REPORT,
	},
	{
		encPrivilgeID: 'K3VmallXWE5HMkJVRE5DUWM3c3Mvdz09',
		module: 'BATCHDETAILS',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		url: RouteUrl.BATCH_DETAILS,
	},
	{
		encPrivilgeID: 'K3VmallXWE5HMkJVRE5DUWM3c3Mvdz09',
		module: 'BATCHDETAILSSHOW',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		url: RouteUrl.BATCH_DETAILS_SHOW,
	},
	{
		encPrivilgeID: 'Nlp4QkRMM0NscjRveVU2RE05L0V6UT09',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		module: 'GENERATE_BATCH_SUMMARY',
		url: RouteUrl.GENERATE_BATCH_SUMMARY,
	},
	{
		encPrivilgeID: 'eFJDK05QMWkxU25sMDZabVdOMkNKUT09',
		encAreaId: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
		module: 'LOCATION_DETAILS',
		url: RouteUrl.LOCATION_DETAILS,
	},
	{
		encPrivilgeID: 'SDVMY0QzbTFvOFNTVmFyY3VuQm55QT09',
		encAreaId: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
		module: 'L4_SYSTEM',
		url: RouteUrl.L4SYSTEM,
	},
	{
		encPrivilgeID: 'NHRJb0NPQnBIWmNGRXZLM2xmT00zQT09',
		module: 'ADDL4SYSTEM',
		url: RouteUrl.ADDL4SYSTEM,
	},
	{
		encPrivilgeID: 'eTdPdmhObjNQSW5aOG56RndUU3E5QT09',
		encAreaId: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
		module: 'ADD EDIT LOCATION',
		url: RouteUrl.ADD_EDIT_LOCATION,
	},
	{
		encPrivilgeID: 'NC9TNUliT2FRUzN6ZkVaMVc3ZUkzUT09',
		encAreaId: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
		module: 'EXPORT HISTORY PAGE',
		url: RouteUrl.EXPORT_HISTORY_PAGE,
	},
	{
		encPrivilgeID: 'NXU5NmhTcFFnV2ROWGpBOS90M1NPQT09',
		encAreaId: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
		module: 'SERIAL_NUMBER_MANAGEMENT',
		url: RouteUrl.SERIAL_NUMBER_MANAGEMENT,
	},
	{
		encPrivilgeID: 'TTkrNGhQVGRJQnh4aWlsdkt4T0FZUT09',
		encAreaId: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
		module: 'ADD_SERIAL_NUMBER_TEMPLATE',
		url: RouteUrl.ADD_SERIAL_NUMBER_TEMPLATE,
	},
	{
		encPrivilgeID: 'aXd1RGhVMlFyQW9rS2p2bnVDcGJHZz09',
		encAreaId: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
		module: 'PRODUCT_ORDER_SERIAL_NUMBER',
		url: RouteUrl.PRODUCT_ORDER_SERIAL_NUMBER,
	},
	{
		encPrivilgeID: 'bzRYci9zRTl1ZUdIbGpiNnMvL0JyUT09',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		module: 'WEBLINK REPORT PAGE',
		url: RouteUrl.WEBLINK_REPORT,
	},
	{
		encPrivilgeID: 'TVJwMGZpOGJlcWdWeXNDZXBmRUFaZz09',
		encAreaId: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
		module: 'SSO MANAGEMENT',
		url: RouteUrl.SSO,
	},
	{
		encPrivilgeID: 'dXdDSXIxdHJpYXhGcEVncy9OY2MxUT09',
		encAreaId: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
		module: 'GS1-WEBLINK',
		url: RouteUrl.GS1_WEBLINK_SETTINGS,
	},
	{
		encPrivilgeID: 'czFyZFVyUmlDMWZzaDVHbUJHSTFldz09',
		encAreaId: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
		module: 'GENERATE WEBLINK DOWNLOAD',
		url: RouteUrl.GENERATE_WEBLINK_DOWNLOAD,
	},
	{
		encPrivilgeID: 'ZHRRT1lCalhHUExGZEFQdXZYSmJ0UT09',
		encAreaId: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
		module: 'WEBLINK DOWNLOAD',
		url: RouteUrl.WEBLINK_DOWNLOAD,
	},
	{
		encPrivilgeID: 'b3pRTFphaGZBN0sxMEs5RWFFRFRwQT09',
		encAreaId: 'Z2hzSnIxcEU1YXJlZjZlSjJSUEl3dz09',
		module: 'WEBLINK_SETTINGS',
		url: RouteUrl.WEBLINK_SETTINGS,
	},
	{
		encPrivilgeID: 'eTdPdmhObjNQSW5aOG56RndUU3E5QT09',
		encAreaId: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
		module: 'ADD EDIT LOCATION',
		url: RouteUrl.VIEW_LOCATION,
	},
	// using manufacturer list privilege for add/edit page
	{
		encPrivilgeID: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		module: 'MANUFACTURER ADD EDIT',
		url: RouteUrl.ADD_EDIT_MANUFACTURER,
	},
	// using manufacturer list privilege for view page
	{
		encPrivilgeID: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		module: 'VIEW MANUFACTURER',
		url: RouteUrl.VIEW_MANUFACTURER,
	},
	{
		encPrivilgeID: 'Yk93ZlFYc1B0RVI3M3l3bzRId0hvUT09',
		encAreaId: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
		module: 'ENDUSER SCAN REPORT PAGE',
		url: RouteUrl.ENDUSER_REPORT,
	},
	{
		encPrivilgeID: 'Z1BFckUvM2VCZFBDQ0xWWE96czJmZz09',
		module: 'Product Group Configurable',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		url: RouteUrl.PRODUCT_GROUP_CONFIGURABLE,
	},
	{
		encPrivilgeID: 'R3N1T01HSzdUc05ZR0tndkpzWS9xZz09',
		module: 'Weblink Flushing',
		encAreaId: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
		url: RouteUrl.WEBLINK_FLUSHING,
	},
	{
		encPrivilgeID: 'dWcxOEMyZDRRVTRwcDdGNGVOMzVxZz09', // need to change
		module: 'PRODUCT VERSION VIEW',
		url: RouteUrl.PRODUCT_VERSION_VIEW,
	},
	{
		encPrivilgeID: 'd21WZGlOaHVsYXVrZUREM1ZKOUlSQT09',
		module: 'Product Versioning List View',
		encAreaId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		url: RouteUrl.PRODUCT_HISTORY_VIEW,
	},
];

export const Privileges = {
	DashboardView: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
	AddNewUser: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	EditUser: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	DeleteUser: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',

	CreateRole: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	EditRole: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
	DeleteRole: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
	EditConfiguration: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
	RoleMenuView: 'TTFFc1dwaDNXbmRFcTJnWHRNUEZWQT09',
	UserListView: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
	RoleListView: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
	TenantListView: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
	TenantAddEditView: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
	ProductListView: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
	AddProductDetails: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
	ManufacturerListView: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
	EditProductGroup: 'Z2hzSnIxcEU1YXJlZjZlSjJSUEl3dz09',
	CreatePackageLevel: 'RXdJM0lOR1hCOTkwNGtpQ3M5enNyUT09',
	ProductGroupListView: 'TkNMVXFISzJrZ2ZHcDRya3VxTUUxUT09',
	ProductApprovePrivilege: 'Nk9sVHUwTGNXTmliMTZRNytraVVGUT09',
	ProductReturnPrivilege: 'VU95d3BJU2l0UUxzODZCZkFCQmpWQT09',
	AddProductGroup: 'YXkreVRiYWltaE4yRzgzQ205dGw1Zz09',
	AddManufacturer: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
	EditManufacturer: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
	SettingsMenuView: 'eEw4VnBiZHgwM2pnM0ZSM0NDSkQ5QT09',
	UserMenuView: 'Y1FrWXc3ZVFNdldZWXVBdDJaMFQzQT09',
	TenantMenuView: 'OXJJK3QvN09iTTBKRCs2aWZCOFdKUT09',
	ProductGroupMenu: 'N1hhaE9ZZG5VZ1NuMUM1clJFeFRKdz09',
	ProductGroupActivate: 'YlpEeFJERk95Q3dyZUJTSFVRbTRFQT09',
	// AddPackageLevel: 'cFVSY1B3QUhheUJZalc4QjRWeU1uUT09',
	PackageLevelMenu: 'MkNPQXdZMDZoWENOS3hwcWRnRUV3QT09',
	ManufacturerMenu: 'b2lXWW5VQXFZUG5wYWt1UDJOMElRdz09',
	ManufacturerActivate: 'aFg0ait5QXFMbkp6NEljVnVBMzRyZz09',
	ActivateDeactivateManufacturer: 'aFg0ait5QXFMbkp6NEljVnVBMzRyZz09',
	DeleteProductPacking: 'aWw2eVVSeGF5TWFNR3c5RDZTY01qZz09',
	EditProductPacking: 'YTVQNWVMTTR4ZXBPcUZXc2ZmRW0xUT09',
	PackageLevelListView: 'cFVSY1B3QUhheUJZalc4QjRWeU1uUT09',
	EditPackageLevel: 'TDZUdmJ0ckd5TjBMTjlhcmFaelJmUT09',
	ProductReviewerPrivilage: 'aWljejFiNTVzL2FIY0tCVDlOOFBGUT09',
	AddManufacturerDetails: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
	EditProduct: 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09',
	ActivateDeactivateProduct: 'WFhOMGlEbG5zVkpIV0xRRk5TUnEyUT09',
	ProductMenu: 'TVMybDFCL21NVUhQNVFjOE9paTZZUT09',
	L3systemMenu: 'TS9uWUk3Y3A5Q2ZCWDlqQWVhTEUwZz09',
	L3SystemListView: 'cmFqUWlyOGpHRHlWSUNZY2JvMjdnZz09',
	EditL3System: 'OEoyYWJ2UmdYUEZidGZIY0V5NDN0dz09',
	AddL3System: 'WVdCSGRzZnpYTC9WMWJyK2FjUmZuQT09',
	ActivateDeactivateL3System: 'c3RBdkhuYVNOTnJHQ09PTGg1RitKUT09',
	BatchPOMenu: 'emJiS0lNWjYzY2pMclUxZG1zMEtKUT09',
	BatchPOListView: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09',
	BatchPODetailsView: 'SUhxWld4RENlOUswM2lEbDdtS0NmUT09',
	BatchPOEdit: 'WklRMndNMVJZbXB3UmltVTByVm5yQT09',
	BatchPOAdd: 'UDk3OXBDbmtROVBDU2lRdE1NRzFMQT09',
	ActivateDeactivateBatchPO: 'cGQxRElGV2NLZ1ZRWHNkMlJSSnRTdz09',
	BatchPOApprovePrivilege: 'SGVubVhldElBeVhFME8vTElZQnYxUT09',
	BatchPOReviewPrivilege: 'VzlNZ0FQaktlVnFxWVNmbGswSEJYQT09',
	BatchPOReturnPrivilege: 'SzVYa3M0Z1dRcjIvdkh5UkU4Nzg4QT09',
	weblinkValidatorMenu: 'NXFHZTdGNTkrR2FhdUpRajRyZGlFQT09',
	weblinkValidator: 'VnJVbStxWVpva2RyWEZWNDJ5ZFYwdz09',
	IconDescriptionEdit: 'eGM0NlNaRXhlVFBFdjlBUmZ1bytEZz09',
	weblinkActivateDeactivate: 'SmdoQmhCUmVRcjhJY0RJc3A0YU9SUT09',
	weblinkListView: 'SmdoQmhCUmVRcjhJY0RJc3A0YU9SUT09',
	weblinkApprove: 'QW8wVXFOZEgwSzN2cnM1aWVRV1hnZz09',
	weblinkReview: 'clVacnJyMU5rOFdPVzEyeHZjV1A0UT09',
	weblinkReject: 'S2xHcmM1bmsyS3NBaEtvRW9oNXljdz09',
	EPCISMENU: 'WXJka0I2VEZPOGV3MVdXS0VQY2lRUT09',
	NotificationListView: 'UFBHUE54d1lzbEVGZzRVbFFKTDduZz09',
	DashboardAwaitingAction: 'VVR3Kys4cWFadHVwaGViWG5wdm14dz09',
	DashboardScanSummary: 'Qmhhc0s1VG9EVEtOZHZFOFF3Wmk1Zz09',
	DashboardProductList: 'clZzeHFaUGFpRXNiTk8valJJTzhMdz09',
	DashboardProductRecentlyAdded: 'Y2hsQnc4cEtmV2VwRjhUcGQyYWgxdz09',
	DashboardTopProducts: 'NkM2S1FpL0x4eTlaUDVGNFNhZ1UxUT09',
	FileFailedNotificationListView: 'a1g2U2EzSHlSL1lDV0lUZW9qNlh6UT09',
	AuditTrial: 'UFBHUE54d1lzbEVGZzRVbFFKTDduZz09', // to be changed
	ModuleConfigurationMenu: 'MWdabi9lZWhldmZSNDNiT290bkNpdz09',
	ModuleConfigurationList: 'ZitjOE5sVkMyZVpQd1JiMWhyYmh2dz09',
	PORetrieval: 'TWVsRWdINkRlQU1IRm5tL0UyZU0rQT09',
	AuditTrialMenu: 'UGVHeUh3cXFHenNCbm1Uc1doZ21MUT09',
	AuditTrialList: 'aFE1NnhBZE9tTDJ0elVETm43YkN3Zz09',
	AuditReportReview: 'bjgwT3NoYkxRUnl2b2UyZjFWZXIwdz09',
	AuditReportApprove: 'cTF3ZUF2U1RGbkIyQ0p6ZXROU29iZz09',
	AuditReportReturn: 'VEJQdVFnK2I1WWF3ZDRUUW1NVlFkUT09',
	AuditReportCreate: 'L2dzL2xuTDN6c0dDYy9mVEF2bTR0Zz09',
	BatchDetailMenu: 'OFlkREExdTVXWk5mVWY3eXZZVVZZdz09',
	BatchSummaryReportCreate: 'M3JIY3VpdTNBdURubDVPQ3EzNVBxZz09',
	BatchSummaryReview: 'Y3AzV3NObXcvZzltWXdjUkxKakNHQT09',
	BatchSummaryApprove: 'SXc5ZVNqSXFkMXY0cmR0S1BoSXYyZz09',
	BatchSummaryReturn: 'V3BDYmQ2VXNSUHFVTW9mZ3NnajNGQT09',
	BatchSummarylist: 'Nlp4QkRMM0NscjRveVU2RE05L0V6UT09',
	BatchSummaryReportGenerate: 'RTNQQnNFSTNBelNqTVFManI5c2R2QT09',
	BatchDetailList: 'K3VmallXWE5HMkJVRE5DUWM3c3Mvdz09',
	BatchDetailReview: 'OUR6T2Qxb0lRblIwTDBHZjJRcGw5Zz09',
	BatchDetailReturn: 'STdwMW9yNVpHcnBwSVVXc0xYc1hOZz09',
	BatchDetailCreate: 'M3JIY3VpdTNBdURubDVPQ3EzNVBxZz09',
	BatchDetailApprove: 'MmpXNy80Z0pKSHo5NTlmL3hvVk9xdz09',
	BatchDetailDownload: 'dER4S1U0eTZTSzVYYjlkNUJMSTJGQT09',
	UpdateModuleConfiguartion: 'QURGVWRiYWs1Rjc2djJwYzJSRlVvZz09',
	AuditReportView: 'bFdBWnBHRTAveTN6amlBRkV6VWY5Zz09',
	BatchDetailView: 'NkE4b1AxbkJ2T2ZGbmlIZXdtOXRyUT09',
	BatchSummaryView: 'cEJkTkhyNWJNMHVqREU3b2FiYzhvUT09',
	BatchSummaryMenu: 'OEVBUnk2Z3g3RHhLZTQ0QTZFRG1yZz09',
	BatchSummaryDownload: 'ZXhRa3dYWGtVejhlUzNpSk1CK0JNZz09',
	AuditReportDownload: 'MksrZFdWcnFFbW5GVXBxeis2cWd1Zz09',
	AuditTrialGenerate: 'L2dzL2xuTDN6c0dDYy9mVEF2bTR0Zz09',
	BatchDetailGenerate: 'M3JIY3VpdTNBdURubDVPQ3EzNVBxZz09',
	LocationMenu: 'NUJMaG5vb1ZJTFJWUS8rbXptQjBnQT09',
	LocationDetails: 'eFJDK05QMWkxU25sMDZabVdOMkNKUT09',
	AddLocation: 'eTdPdmhObjNQSW5aOG56RndUU3E5QT09',
	EditLocation: 'ZlpEU2ZnbzdDcUVldzNXR1VmVjkyUT09',
	L4SystemMenu: 'U3FWOGVCTVJPaFQ5dHNjdCt4V0RvZz09',
	L4SystemListView: 'SDVMY0QzbTFvOFNTVmFyY3VuQm55QT09',
	EditL4System: 'MTZDVVNPc2dibndpdkNUYXdpNFU1UT09',
	CreateL4System: 'NHRJb0NPQnBIWmNGRXZLM2xmT00zQT09',
	ActivateDeactivateL4System: 'Z1hSQnRVcTRJb3FFdlp5aTcxTEpXUT09',
	l4PoFileExport: 'dHI2bXovWEExemtBK3lCeEd2a2pKUT09',
	l4PoExportHistory: 'NC9TNUliT2FRUzN6ZkVaMVc3ZUkzUT09',
	SerialNumberListView: 'NXU5NmhTcFFnV2ROWGpBOS90M1NPQT09',
	SerialNumberCreate: 'TTkrNGhQVGRJQnh4aWlsdkt4T0FZUT09',
	SerialNumberDelete: 'eG9JOUhuUjJBTG9PTEtUZHFlVVBSZz09',
	SerialNumberMenu: 'aXFGNjBoekRpUEdiaXk1RkFUQTdOQT09',
	SerialNumberActivateDeactivate: 'b3dsdHdYQjFnRHNjbHc3QXJ2TWVGZz09',
	POSerialNumberList: 'aXd1RGhVMlFyQW9rS2p2bnVDcGJHZz09',
	WebLinkReportMenu: 'UzQwSnZQVEhSUDhTL05NdmxFbzFzUT09',
	GenerateWebLinkReport: 'bzRYci9zRTl1ZUdIbGpiNnMvL0JyUT09',
	WebLinkReportDownload: 'Vit3UWRtc0FBd1I5TWtCRHNSWndjQT09',
	SSOMenu: 'REc0c0ZlRG1FNmhLd0xBdXJsckdvQT09',
	SSOCreate: 'QTlwUWd5QjdRRkU5UGMycDlPVk8wZz09',
	SSOEdit: 'RHlhUlRYdnN5WWxXQiszcVJ3aFlYdz09',
	SSOActivateDeactivate: 'VWVxTkFOZTZaVWsyWGp1alFtN05ZZz09',
	DownloadWeblink: 'cUFmY1BCSHVieWo5Qi9VZnoyNGpoQT09',
	ImportEpcis: 'LytpTGlqaFhiYW1HRy9pbVBid2NtQT09',
	DownloadEpcis: 'L1FpOXBKZU03VTR4SHZDTzByTHpwUT09',
	gs1WeblinkMenu: 'WGJMTjFNYVVaVUk0YzhyL05tWFVyZz09',
	gs2WeblinkEdit: 'dXdDSXIxdHJpYXhGcEVncy9OY2MxUT09',
	L3DownloadWeblinkMenu: 'QjJzYTh0M0RKM1NxdnJpSlR2OGk2Zz09',
	L3DownloadWeblinkList: 'ZHRRT1lCalhHUExGZEFQdXZYSmJ0UT09',
	L3DownloadWeblinkDownload: 'WXp0dmc3cVVWQW5JWHJxRFZaelc0UT09',
	GenerateDownloadWeblink: 'czFyZFVyUmlDMWZzaDVHbUJHSTFldz09',
	weblinkSettingsCreate: 'OTNVWEU1TDF5RklVME9aOVJ1VU5YUT09',
	weblinkSettingsMenu: 'b2lwcmwwdGJMLzVtd2xSY2MzU29EQT09',
	weblinkSettingsList: 'b3pRTFphaGZBN0sxMEs5RWFFRFRwQT09',
	weblinkSettingsActivate: 'QkoxRXVKbitYZGR1M0U1V0JPTkt3Zz09',
	deleteWeblinkSettings: 'VC9MNXRuV1lBcFZPV094OWVSMlZJZz09',
	ProductDetailsView: 'UjBRTzUxZFh0SHo2RUYyeUtCTmI3QT09',
	SerialNumberEdit: 'M3pXdXBEcFh3L2V0WmVxUFFCUEJUQT09',
	LocationActivateDeactivate: 'RFVSVW9BUWtGTG5FK0JORXF6SkFUdz09',
	ViewManufacturer: 'cDdvOW1FekNDRERMQk9pbm1OZjRiUT09',
	EndUserReportMenu: 'SUtBMWRlOG9ONU1sNi9pMDBoMWlndz09',
	EndUserReportCreate: 'Yk93ZlFYc1B0RVI3M3l3bzRId0hvUT09',
	EndUserReportDownload: 'bFphK0ZQOU8yczhNbXRZMnNPOU0rZz09',
	ProductGroupConfigurable: 'Z1BFckUvM2VCZFBDQ0xWWE96czJmZz09',
	WeblinkFlushMenu: 'R3N1T01HSzdUc05ZR0tndkpzWS9xZz09',
	WeblinkFlushPage: 'ZitjOE5sVkMyZVpQd1JiMWhyYmh2dz09', // to be changed
	ViewProductVersioningDetails: 'dWcxOEMyZDRRVTRwcDdGNGVOMzVxZz09',
	ProductVersioningListView: 'd21WZGlOaHVsYXVrZUREM1ZKOUlSQT09',
	viewLocation: 'RzlYNkQwY3pGeTlYS1U0b2hyUkI5dz09',
};

export const MODULE_IDS = {
	UESR_MANAGEMENT: '1',
	ROLE_MANAGEMENT: '2',
	TENANT_MANAGEMENT: '3',
	PRODUCT_MANAGEMENT: '4',
	L3_MANAGEMENT: '5',
	GENERAL_FIELD: '6',
	PRODUCT_ORDER_MANAGEMENT: '7',
	NOTIFICATION_MANAGEMENT: '8',
	DASHBOARD_MANAGEMENT: '9',
	EPCIS_FILE_MANAGEMENT: '10',
	WEBLINK_VALIDATOR: '11',
	REPORTS: '13',
	L4_MANAGEMENT: '15',
};

export const PRODUCT_STATUS = {
	COMPLETED: '2',
	REVIEWED: '3',
	APPROVED: '4',
	RETURNED: '5',
	DRAFT: '1',
};
export const PRODUCT_ORDER_STATUS = {
	COMPLETED: 1,
	REVIEWED: 2,
	APPROVED: 3,
	RETURNED: 4,
	// DRAFT: 1,
};
export const WEBLINK_STATUS = {
	DEFAULT: 1,
	INITIATED: 2,
	REVIEWED: 3,
	APPROVED: 4,
	RETURNED: 5,
};
export const WEBLINK_OPTION_STATUS = {
	BOTH: 1,
	DEACTIVE: 2,
	ACTIVATE: 3,
};
export const PRODUCT_ORDER_ACTIVE_DEACTIVE_STATUS = {
	DEFAULT: 1,
	INITIATED: 2,
	REVIEWED: 3,
	APPROVED: 4,
	RETURNED: 5,
};
export const PRODUCT_ACTIVE_DEACTIVE_STATUS = {
	ACTIVE: '1',
	INACTIVE: '2',
	PENDING_FOR_REVIEW: '3',
	PENDING_FOR_APPROVAL: '4',
	RETURNED: '5',
};

export const CONFIG_IDS = {
	PASSWORD_EXPIRY: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
	PASSWORD_MINLENGTH: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
	PASSWORD_MAXLENGTH: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
	PASSWORD_EXPIRY_NOTIFICATION: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
	PASSWORD_EXPIRY_NOTIFICATION_DAYS: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
	// FORCE_USER_TO_CHANGE_PASSWORD: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	ALLOWED_WRONG_ATTEMPT: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	AUTO_LOCK_TIME: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	DATE_FORMAT: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	ENABLE_24_HRS: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	// LOGO: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
	PASSWORD_ONE_NUMERIC: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
	PASSWORD_ONE_SPECIAL_CHAR: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
	PASSWORD_ONE_CAPITAL_LETTER: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
	MODULE_PERMISSIONS: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
	TENANT_LOGO: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
	LANGUAGE: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
	REVIEW: 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09',
	INCLUSION_CHARACTER: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
	IDENTIFIER_LIMIT: 'cUhGZVRveVZhRGtvU3RVM0pkTWhJdz09',
	URL_PREFIX: 'Z2hzSnIxcEU1YXJlZjZlSjJSUEl3dz09',
	EPCIS_FILE_NOTIFICATION: 'aWljejFiNTVzL2FIY0tCVDlOOFBGUT09',
	EPCIS_FILE_NOTIFICATION_EMAIL_ID: 'TkNMVXFISzJrZ2ZHcDRya3VxTUUxUT09',
	SERIAL_NUMBER_POOL_SIZE_ID: 'Nk9sVHUwTGNXTmliMTZRNytraVVGUT09',
	REMAINING_WEBLINK_COUNT: 'TTFFc1dwaDNXbmRFcTJnWHRNUEZWQT09',
	IS_GS1_URL: 'YXkreVRiYWltaE4yRzgzQ205dGw1Zz09',
	BATCH_NO_LIMIT: 'TDZUdmJ0ckd5TjBMTjlhcmFaelJmUT09',
	ALLOW_SERIAL_NUMBER: 'TDZUdmJ0ckd5TjBMTjlhcmFaelJmUT09', // to be changed
	FAVICONS: 'Y1FrWXc3ZVFNdldZWXVBdDJaMFQzQT09',
	TENANT_TITLE: 'OXJJK3QvN09iTTBKRCs2aWZCOFdKUT09',
	PARENT_NAME: 'eEw4VnBiZHgwM2pnM0ZSM0NDSkQ5QT09',
	MULTIPLE_WEBLINK_SCAN_NOTIFICATION: 'b2lXWW5VQXFZUG5wYWt1UDJOMElRdz09',
	MULTIPLE_WEBLINK_SCAN_NOTIFICATION_EMAIL: 'ZHJKZ1NIbE42TzNkcTU5SHVPN1lodz09',
	END_USER_DATE_FORMAT: 'cFVSY1B3QUhheUJZalc4QjRWeU1uUT09',
	WEBLINK_MULTIPLE_SCAN_COUNT: 'MkNPQXdZMDZoWENOS3hwcWRnRUV3QT09',
	ENABLE_COMPANY_LOGO_FIELD: 'aWw2eVVSeGF5TWFNR3c5RDZTY01qZz09',
	PARENT_NAME_ENABLE: 'YTVQNWVMTTR4ZXBPcUZXc2ZmRW0xUT09',
};

export const STATUS_ID = {
	DRAFT: '1',
	COMPLEATED: '2',
	REVIEWED: '3',
	APROVED: '4',
};

export const WEBLINK_DOWNLOAD_TYPE = {
	PO: 1,
	PRODUCT: 2,
};

export const ICON_DESCRIPTION_AREA_ID = {
	DASHBOARD: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	USER_MANAGEMENT: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	ACG_TENANT_MANAGEMENT: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	WEBLINK_VALIDATOR: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	TENANT_USER_MANAGEMENT: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
	ROLE_MANAGEMENT: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
	SETTINGS: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
	PRODUCT: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
	PRODUCT_GROUP: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
	MANUFACTURER: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
	PACKAGE_LEVEL: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
	PRODUCT_ORDER: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
	L3_SYSTEM: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
	EPCIS_SYSTEM: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
	MODULE_CONFIGURATION: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
	AUDIT_TRIAL: 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09',
	BATCH_SUMMARY_REPORT: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
	BATCH_DETAILS: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
	L4_SYSTEM: 'cUhGZVRveVZhRGtvU3RVM0pkTWhJdz09',
	LOCATION_DETAILS: 'Z2hzSnIxcEU1YXJlZjZlSjJSUEl3dz09',
	EXPORT_HISTORY: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
	SERIAL_NUMBER_MANAGEMENT: 'TkNMVXFISzJrZ2ZHcDRya3VxTUUxUT09',
	WEBLINK_REPORT: 'YXkreVRiYWltaE4yRzgzQ205dGw1Zz09',
	SSO_MANAGEMENT: 'aWljejFiNTVzL2FIY0tCVDlOOFBGUT09',
	GS1_WEBLINK: 'Nk9sVHUwTGNXTmliMTZRNytraVVGUT09',
	ENDUSER_SCAN_REPORT: 'TDZUdmJ0ckd5TjBMTjlhcmFaelJmUT09',
	WEBLINK_FLUSH: 'TTFFc1dwaDNXbmRFcTJnWHRNUEZWQT09',
	PRODUCT_HISTORY: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09', // need to change
};

export const ICON_DESCRIPTION_ID = {
	DASHBOARD: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	USER_MANAGEMENT: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	USER_ADD_EDIT: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	ACG_TENANT_MANAGEMENT: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	WEBLINK_VALIDATOR_PAGE: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
	TENANT_USER_LIST: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
	TENANT_USER_ADD_EDIT: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
	ROLE_LIST: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
	ROLE_ADD_EDIT: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
	SETTINGS: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
	PRODUCT_LIST: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
	PRODUCT_ADD: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
	COMMON_ATTRIBUTES: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
	GENERAL: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
	I_VEDA_PROPERTIES: 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09',
	PRODUCT_PACKING: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
	HRF: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
	PRODUCT_PRN: 'cUhGZVRveVZhRGtvU3RVM0pkTWhJdz09',
	PRODUCT_GROUP_LIST: 'Z2hzSnIxcEU1YXJlZjZlSjJSUEl3dz09',
	PRODUCT_GROUP_ADD_EDIT: 'RXdJM0lOR1hCOTkwNGtpQ3M5enNyUT09',
	MANUFACTURER_LIST: 'TkNMVXFISzJrZ2ZHcDRya3VxTUUxUT09',
	MANUFACTURER_ADD_EDIT: 'aWljejFiNTVzL2FIY0tCVDlOOFBGUT09',
	PACKAGE_LEVEL_LIST: 'Nk9sVHUwTGNXTmliMTZRNytraVVGUT09',
	PACKAGE_LEVEL_ADD_EDIT: 'YXkreVRiYWltaE4yRzgzQ205dGw1Zz09',
	PO_LIST: 'TDZUdmJ0ckd5TjBMTjlhcmFaelJmUT09',
	PO_ADD_EDIT: 'TTFFc1dwaDNXbmRFcTJnWHRNUEZWQT09',
	L3_LIST: 'Y1FrWXc3ZVFNdldZWXVBdDJaMFQzQT09',
	L3_ADD_EDIT: 'OXJJK3QvN09iTTBKRCs2aWZCOFdKUT09',
	CONNECTION_METHOD: 'eEw4VnBiZHgwM2pnM0ZSM0NDSkQ5QT09',
	SOAP_CONFIG: 'cFVSY1B3QUhheUJZalc4QjRWeU1uUT09',
	WEB_LINK_SETTINGS: 'ZHJKZ1NIbE42TzNkcTU5SHVPN1lodz09',
	SETTINGS_PAGE: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
	PASSWORD_VERIFICATION: 'b2lXWW5VQXFZUG5wYWt1UDJOMElRdz09',
	LOGIN_ATTEMPT: 'MkNPQXdZMDZoWENOS3hwcWRnRUV3QT09',
	AUTO_LOCKOUT_TIME: 'aWw2eVVSeGF5TWFNR3c5RDZTY01qZz09',
	DATE_FORMAT: 'YTVQNWVMTTR4ZXBPcUZXc2ZmRW0xUT09',
	TIME_24_HR_FORMAT: 'N1hhaE9ZZG5VZ1NuMUM1clJFeFRKdz09',
	PASSWORD_COMPLEXITY: 'aFg0ait5QXFMbkp6NEljVnVBMzRyZz09',
	WEB_LINK_IDENTIFIER_LIMIT: 'YlpEeFJERk95Q3dyZUJTSFVRbTRFQT09',
	INCLUSION_CHARACTERS: 'WVdCSGRzZnpYTC9WMWJyK2FjUmZuQT09',
	URL_PREFIX: 'OEoyYWJ2UmdYUEZidGZIY0V5NDN0dz09',
	PASSWORD_EXPIRY: 'cmFqUWlyOGpHRHlWSUNZY2JvMjdnZz09',
	PASSWORD_MIN_LENGTH: 'c3RBdkhuYVNOTnJHQ09PTGg1RitKUT09',
	PASSWORD_MAX_LENGTH: 'TVMybDFCL21NVUhQNVFjOE9paTZZUT09',
	PASSWORD_EXPIRY_NOTIFICATION_IN: 'WFhOMGlEbG5zVkpIV0xRRk5TUnEyUT09',
	ENABLE_PASSWORD_EXPIRY_NOTIFICATION: 'd3NLVFgrUWcyUUVvaDMyY3JlbXU4UT09',
	DISABLE_TENANT_REVIEWER: 'TS9uWUk3Y3A5Q2ZCWDlqQWVhTEUwZz09',
	EPCIS_LIST: 'UDk3OXBDbmtROVBDU2lRdE1NRzFMQT09',
	AWAITING_REVIEW: 'emJiS0lNWjYzY2pMclUxZG1zMEtKUT09',
	DASHBOARD_PRODUCT_LIST: 'SUhxWld4RENlOUswM2lEbDdtS0NmUT09',
	PRODUCT_RECENTLY_ADDED: 'WklRMndNMVJZbXB3UmltVTByVm5yQT09',
	SCAN_SUMMARY: 'UjBRTzUxZFh0SHo2RUYyeUtCTmI3QT09',
	TOP_PRODUCTS: 'a3hwQ3o0S1A3Y3VralFndytsTW00UT09',
	DASHBOARD_NOTIFICATION: 'VU95d3BJU2l0UUxzODZCZkFCQmpWQT09',
	DASHBOARD_FILE_FAILED: 'cGQxRElGV2NLZ1ZRWHNkMlJSSnRTdz09',
	PRODUCT_WEBLINK: 'SGVubVhldElBeVhFME8vTElZQnYxUT09',
	PO_WEBLINK: 'VzlNZ0FQaktlVnFxWVNmbGswSEJYQT09',
	MOST_SCANNED_PRODUCTS: 'SzVYa3M0Z1dRcjIvdkh5UkU4Nzg4QT09',
	MODULE_CONFIGURATION_LIST: 'VnJVbStxWVpva2RyWEZWNDJ5ZFYwdz09',
	PO_RETRIEVAL: 'NXFHZTdGNTkrR2FhdUpRajRyZGlFQT09',
	AUDIT_TRIAL_LIST: 'cUFmY1BCSHVieWo5Qi9VZnoyNGpoQT09',
	AUDIT_TRAIL_SHOW: 'eGM0NlNaRXhlVFBFdjlBUmZ1bytEZz09',
	BATCH_SUMMARY_REPORT_LIST: 'N0V1S3lKUDNtaDZsNjJsK3YyK1NZUT09',
	GENERATE_BATCH_SUMMARY: 'cnoyVmsrQUxhUFhiVk5KT1Zhb2d1Zz09',
	BATCH_DETAILS_LIST: 'SmdoQmhCUmVRcjhJY0RJc3A0YU9SUT09',
	BATCH_DETAILS_SHOW: 'QW8wVXFOZEgwSzN2cnM1aWVRV1hnZz09',
	EPCIS_FILE_PROCESSING_NOTIFICATION: 'clVacnJyMU5rOFdPVzEyeHZjV1A0UT09',
	EPCIS_NOTIFICATION_EMAIL: 'S2xHcmM1bmsyS3NBaEtvRW9oNXljdz09',
	L4_LIST: 'a1g2U2EzSHlSL1lDV0lUZW9qNlh6UT09',
	L4_ADD_EDIT: 'Qmhhc0s1VG9EVEtOZHZFOFF3Wmk1Zz09',
	L4_CONNECTION: 'NkM2S1FpL0x4eTlaUDVGNFNhZ1UxUT09',
	LOCATION_LIST: 'VVR3Kys4cWFadHVwaGViWG5wdm14dz09',
	LOCATION_ADD_EDIT: 'Y2hsQnc4cEtmV2VwRjhUcGQyYWgxdz09',
	EXPORT_HISTORY: 'clZzeHFaUGFpRXNiTk8valJJTzhMdz09',
	SERIAL_NUMBER_LIST: 'ZFJWWXYvQVZ3L2RiczJQV1hwNHZDQT09',
	SERIAL_NUMBER_ADD: 'TWVsRWdINkRlQU1IRm5tL0UyZU0rQT09',
	SERIAL_NUMBER_SETTINGS: 'YmVTbWlCZUpDZUR0NUdRVTJ2bWdNdz09',
	PO_SERIAL_NUMBER_LIST: 'MWdabi9lZWhldmZSNDNiT290bkNpdz09',
	WEBLINK_REPORT_LIST: 'L2dzL2xuTDN6c0dDYy9mVEF2bTR0Zz09',
	SSO_PAGE: 'WXJka0I2VEZPOGV3MVdXS0VQY2lRUT09',
	PARENT_COMPANY_NAME: 'VEJQdVFnK2I1WWF3ZDRUUW1NVlFkUT09',
	IS_GS1_URL: 'ZitjOE5sVkMyZVpQd1JiMWhyYmh2dz09',
	BATCH_NO_LIMIT: 'QURGVWRiYWs1Rjc2djJwYzJSRlVvZz09',
	GS1_WEBLINK: 'UGVHeUh3cXFHenNCbm1Uc1doZ21MUT09',
	ALLOW_SERIAL_NUMBER: 'UGVHeUh3cXFHenNCbm1Uc1doZ21MUT09', // need to change
	WEBLINK_DOWNLOAD: 'cTF3ZUF2U1RGbkIyQ0p6ZXROU29iZz09',
	GENERATE_WEBLINK_DOWNLOAD: 'bjgwT3NoYkxRUnl2b2UyZjFWZXIwdz09',
	WEBLINK_SETTINGS: 'MksrZFdWcnFFbW5GVXBxeis2cWd1Zz09',
	CREATE_WEBLINK_SETTINGS: 'OEVBUnk2Z3g3RHhLZTQ0QTZFRG1yZz09',
	MULTIPLE_WEBLINK_SCAN_NOTIFICATION: 'aFE1NnhBZE9tTDJ0elVETm43YkN3Zz09',
	MULTIPLE_WEBLINK_SCAN_NOTIFICATION_EMAIL: 'bFdBWnBHRTAveTN6amlBRkV6VWY5Zz09',
	SERIAL_NUMBER_EDIT: 'OFlkREExdTVXWk5mVWY3eXZZVVZZdz09',
	END_USER_DATE_FORMAT: 'RTNQQnNFSTNBelNqTVFManI5c2R2QT09',
	SCAN_COUNT: 'M3JIY3VpdTNBdURubDVPQ3EzNVBxZz09',
	ENABLE_COMPANY_LOGO: 'ZXhRa3dYWGtVejhlUzNpSk1CK0JNZz09',
	ENABLE_PARENT_COMPANY_NAME: 'NkE4b1AxbkJ2T2ZGbmlIZXdtOXRyUT09',
	ENDUSER_REPORT: 'dER4S1U0eTZTSzVYYjlkNUJMSTJGQT09',
	PRODUCT_GROUP_CONFIGURABLE: 'STdwMW9yNVpHcnBwSVVXc0xYc1hOZz09',
	WEBLINK_FLUSH_PAGE: 'cEJkTkhyNWJNMHVqREU3b2FiYzhvUT09',
	PRODUCT_HISTORY: 'OUR6T2Qxb0lRblIwTDBHZjJRcGw5Zz09',
	PRODUCT_HISTORY_VIEW: 'MmpXNy80Z0pKSHo5NTlmL3hvVk9xdz09',
};

export const MODE_VALUES = {
	APPROVE: 'approve',
	REVIEW: 'review',
	EDIT: 'edit',
	VIEW: 'view',
	ACTIVATE: 'activate',
	DEACTIVATE: 'dectivate',
	REJECT: 'reject',
	ADD: 'add',
	HISTORY: 'history',
};

export const WEBLINK_TYPE = {
	PRODUCT_ORDER: 'productorder',
	PRODUCT: 'product',
};

export const WEBLINK_TYPE_ID = {
	PRODUCT_ORDER: 2,
	PRODUCT: 1,
};

export const WEBLINK_WORKFLOW_TYPE_ID = {
	ACTIVATE: 2,
	DEACTIVATE: 3,
};

export const READ_UNREAD_STATUS = {
	UNREAD: 0,
	READ: 1,
};

export const ACTION_TYPES = {
	PRODUCT_CREATION_REVIEW: 'Product Creation Review',
	PRODUCT_CREATION_APPROVE: 'Product Creation Approve',
	PRODUCT_ACTIVATION_REVIEW: 'Product Activation Review',
	PRODUCT_ACTIVATION_APPROVE: 'Product Activation Approve',
	PRODUCT_DEACTIVATION_REVIEW: 'Product Deactivation Review',
	PRODUCT_DEACTIVATION_APPROVE: 'Product Deactivation Approve',

	PO_CREATION_REVIEW: 'PO Creation Review',
	PO_CREATION_APPROVE: 'PO Creation Approve',
	PO_ACTIVATION_REVIEW: 'PO Activation Review',
	PO_ACTIVATION_APPROVE: 'PO Activation Approve',
	PO_DEACTIVATION_REVIEW: 'PO Deactivation Review',
	PO_DEACTIVATION_APPROVE: 'PO Creation Approve',

	WEBLINK_ACTIVATION_REVIEW: 'WebLink Activation Review',
	WEBLINK_ACTIVATION_APPROVE: 'WebLink Activation Approve',
	WEBLINK_DEACTIVATION_REVIEW: 'WebLink Deactivation Review',
	WEBLINK_DEACTIVATION_APPROVE: 'WebLink Deactivation Approve',
};

export const NOTIFICATION_TYPES = {
	USER_INVALID_LOGIN_ATTEMPT: 'User_Invalid_Login_attempt',
	EMAIL_AUTHENTICATION_FAILED: 'Email_Authentication_Failed',
	PRODUCT_CREATION_NOTIFICATION: 'Product_Creation_Notification',
	PRODUCT_REVIEW_NOTIFICATION: 'Product_Review_Notification',
	PRODUCT_APPROVE_NOFICATION: 'Product_Approve_Nofication',
	PRODUCT_DEACTIVATION_NOTIFICATION: 'Product_Deactivation_Notification',
	PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION: 'Product_Deactivation_Review_Notification',
	PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION: 'Product_Deactivation_Approval_Notification',
	PRODUCT_ACTIVATION_REVIEW_NOTIFICATION: 'Product_Activation_Review_Notification',

	PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION: 'Product_Activation_Approval_Notification',
	PO_CREATION_NOTIFICATION: 'PO_Creation_Notification',
	PO_REVIEW_NOTIFICATION: 'PO_Review_Notification',
	PO_APPROVE_NOFICATION: 'PO_Approve_Nofication',
	PO_DEACTIVATION_NOTIFICATION: 'PO_Deactivation_Notification',
	PO_DEACTIVATION_REVIEW_NOTIFICATION: 'PO_Deactivation_Review_Notification',
	PO_DEACTIVATION_APPROVAL_NOTIFICATION: 'PO_Deactivation_Approval_Notification',
	WEBLINK_ACTIVATION_NOTIFICATION: 'Weblink_Activation_Notification',
	WEBLINK_ACTIVATION_REVIEW_NOTIFICATION: 'Weblink_Activation_Review_Notification',
	WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION: 'Weblink_Activation_Approval_Notification',
	WEBLINK_DEACTIVATION_NOTIFICATION: 'Weblink_Deactivation_Notification',
	WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION: 'Weblink_Deactivation_Review_Notification',
	WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION: 'Weblink_Deactivation_Approval_Notification',
	PO_ACTIVATION_NOTIFICATION: 'PO_Activation_Notification',
	PO_ACTIVATION_REVIEW_NOTIFICATION: 'PO_Activation_Review_Notification',
	PO_ACTIVATION_APPROVAL_NOTIFICATION: 'PO_Activation_Approval_Notification',
	PRODUCT_REVIEW_NOTIFICATION_ACTION: 'Product_Review_Notification_Action',
	PRODUCT_APPROVE_NOFICATION_ACTION: 'Product_Approve_Nofication_Action',
	PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
		'Product_Deactivation_Review_Notification_Action',
	PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'Product_Deactivation_Approval_Notification_Action',
	PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION: 'Product_Activation_Review_Notification_Action',
	PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'Product_Activation_Approval_Notification_Action',
	PO_REVIEW_NOTIFICATION_ACTION: 'PO_Review_Notification_Action',
	PO_APPROVE_NOFICATION_ACTION: 'PO_Approve_Nofication_Action',
	PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION: 'PO_Deactivation_Review_Notification_Action',
	PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION: 'PO_Deactivation_Approval_Notification_Action',
	WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION: 'Weblink_Activation_Review_Notification_Action',
	WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'Weblink_Activation_Approval_Notification_Action',
	WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
		'Weblink_Deactivation_Review_Notification_Action',
	WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'Weblink_Deactivation_Approval_Notification_Action',
	PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION: 'PO_Activation_Review_Notification_Action',
	PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION: 'PO_Activation_Approval_Notification_Action',
	PRODUCT_REVIEW_REJECT_NOTIFICATION: 'Product_Review_Reject_Notification',
	PRODUCT_APPROVE_REJECT_NOTIFICATION: 'Product_Approve_Reject_Notification',
	PRODUCT_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
		'Product_Deactivation_Review_Reject_Notification',
	PRODUCT_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'Product_Deactivation_Approval_Reject_Notification',
	PRODUCT_ACTIVATION_REVIEW_REJECT_NOTIFICATION: 'Product_Activation_Review_Reject_Notification',
	PRODUCT_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'Product_Activation_Approval_Reject_Notification',

	PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
		'PO_Weblink_Activation_Review_Notification_Action',
	PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'PO_Weblink_Activation_Approval_Notification_Action',
	PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
		'PO_Weblink_Deactivation_Review_Notification_Action',
	PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'PO_Weblink_Deactivation_Approval_Notification_Action',
	PO_WEBLINK_ACTIVATION_NOTIFICATION: 'PO_Weblink_Activation_Notification',
	PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION: 'PO_Weblink_Activation_Review_Notification',
	PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION: 'PO_Weblink_Activation_Approval_Notification',
	PO_WEBLINK_DEACTIVATION_NOTIFICATION: 'PO_Weblink_Deactivation_Notification',
	PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION: 'PO_Weblink_Deactivation_Review_Notification',
	PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION: 'PO_Weblink_Deactivation_Approval_Notification',
	PO_WEBLINK_REVIEW_REJECT_NOTIFICATION: 'PO_Weblink_Review_Reject_Notification',
	PO_WEBLINK_APPROVE_REJECT_NOTIFICATION: 'PO_Weblink_Approve_Reject_Notification',
	PO_WEBLINK_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
		'PO_Weblink_Deactivation_Review_Reject_Notification',
	PO_WEBLINK_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'PO_Weblink_Deactivation_Approval_Reject_Notification',
	PO_WEBLINK_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
		'PO_Weblink_Activation_Review_Reject_Notification',
	PO_WEBLINK_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'PO_Weblink_Activation_Approval_Reject_Notification',
	PO_REVIEW_REJECT_NOTIFICATION: 'PO_Review_Reject_Notification',
	PO_APPROVE_REJECT_NOTIFICATION: 'PO_Approve_Reject_Notification',
	WEBLINK_REVIEW_REJECT_NOTIFICATION: 'Weblink_Review_Reject_Notification',
	WEBLINK_APPROVE_REJECT_NOTIFICATION: 'Weblink_Approve_Reject_Notification',
	WEBLINK_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
		'Weblink_Deactivation_Review_Reject_Notification',
	WEBLINK_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'Weblink_Deactivation_Approval_Reject_Notification',
	WEBLINK_ACTIVATION_REVIEW_REJECT_NOTIFICATION: 'Weblink_Activation_Review_Reject_Notification',
	WEBLINK_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'Weblink_Activation_Approval_Reject_Notification',
	PRODUCT_ACTIVATION_NOTIFICATION: 'Product_Activation_Notification',
	PO_DEACTIVATION_REVIEW_REJECT_NOTIFICATION: 'PO_Deactivation_Review_Reject_Notification',
	PO_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION: 'PO_Deactivation_Approval_Reject_Notification',
	PO_ACTIVATION_REVIEW_REJECT_NOTIFICATION: 'PO_Activation_Review_Reject_Notification',
	PO_ACTIVATION_APPROVAL_REJECT_NOTIFICATION: 'PO_Activation_Approval_Reject_Notification',

	REPORT_APPROVE_NOFICATION_ACTION: 'Report_Approve_Notification_Action',
	REPORT_RETURN_NOTIFICATION_ACTION: 'Report_Return_Notification_Action',
	REPORT_REVIEW_NOTIFICATION_ACTION: 'Report_Review_Notification_Action',

	REPORT_REVIEW_NOTIFICATION: 'Report_Review_Notification',
	REPORT_APPROVE_NOTIFICATION: 'Report_Approve_Notification',
	// REPORT_REVIEW_RETURN_NOTIFICATION: 'Report_Return_Notification',
	// REPORT_APPROVE_RETURN_NOTIFICATION: 'Report_Return_Notification',
	REPORT_RETURN_NOTIFICATION: 'Report_Return_Notification',
	PASSWORD_NOTIFICATION: 'Password_Expiry_Notification',

	WEBLINKID_ACTIVATION_NOTIFICATION: 'WeblinkID_Activation_Notification',
	WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION: 'WeblinkID_Activation_Review_Notification',
	WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION: 'WeblinkID_Activation_Approval_Notification',
	WEBLINKID_DEACTIVATION_NOTIFICATION: 'WeblinkID_Deactivation_Notification',
	WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION: 'WeblinkID_Deactivation_Review_Notification',
	WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION: 'WeblinkID_Deactivation_Approval_Notification',
	WEBLINKID_DEACTIVATION_REVIEW_REJECT_NOTIFICATION:
		'WeblinkID_Deactivation_Review_Reject_Notification',
	WEBLINKID_DEACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'WeblinkID_Deactivation_Approval_Reject_Notification',
	WEBLINKID_ACTIVATION_REVIEW_REJECT_NOTIFICATION:
		'WeblinkID_Activation_Review_Reject_Notification',
	WEBLINKID_ACTIVATION_APPROVAL_REJECT_NOTIFICATION:
		'WeblinkID_Activation_Approval_Reject_Notification',

	WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
		'WeblinkID_Activation_Review_Notification_Action',
	WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
		'WeblinkID_Deactivation_Review_Notification_Action',

	WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'WeblinkID_Activation_Approval_Notification_Action',
	WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
		'WeblinkID_Deactivation_Approval_Notification_Action',
	EPCIS_FILE_SUCCESS: 'EPCIS_PO_Export_Success_Notification',
	EPCIS_FILE_FAILED: 'EPCIS_PO_Export_Failed_Notification',
};

export const NOTIFICATION_FILTER_TYPES = {
	ALL: 'All',
	READ: 'Read',
	UNREAD: 'Unread',
};

export const AWAITING_ACTIONS_TYPES = {
	PRODUCT: '1',
	PRODUCT_ORDER: '2',
	PRODUCT_WEBLINK: '3',
	PO_WEBLINK: '4',
	REPORT: '5',
	WEBLINK_VALIDATION: '6',
};
export const PASSWORD_VERIFICATION_ACTIONS = {
	CREATE: 1,
	EDIT: 2,
	DELETE: 3,
	APPROVE: 4,
	RETURN: 5,
	REVIEW: 6,
	ACTIVE: 7,
	INACTIVE: 8,
	ACTIVATION_REVIEW: 9,
	ACTIVATION_APPROVE: 10,
	ACTIVATION_RETURN: 11,
	DEACTIVATION_REVIEW: 14,
	DEACTIVATION_APPROVE: 13,
	DEACTIVATION_RETURN: 12,
	RETRIEVAL: 15,
	CONFIGURE: 16,
};
export const PASSWORD_VERIFICATION_TRANSACTIONS = {
	USER: 1,
	PRODUCT: 2,
	MANAGEL3SYSTEM: 3,
	PRODUCTORDER: 4,
	MANUFACTURER: 5,
	PRODUCTGROUP: 6,
	PRODUCTWEBLINK: 7,
	POWEBLINK: 8,
	AUDITLOG: 9,
	BATCHDETAILSREPORT: 10,
	BATCHSUMMERYREPORT: 11,
	PACKAGELEVEL: 12,
	WEBLINKVALIDATOR: 13,
	LOCATION: 14,
};

export const PO_RETRIEVAL_TYPE = {
	NA: 1,
};

export const REPORT_TYPE = {
	AUDIT_LOG: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	BATCH_REPORT: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	PRODUCT_BATCH_PACK_REPORT: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	BATCH_SUMMARY: '',
};

export const AUDIT_LOG_STATUS = {
	CREATED: 1,
	REVIEWED: 2,
	APPROVED: 3,
	RETURNED: 4,
};
export const BATCH_DETAILS_STATUS = {
	CREATED: 1,
	REVIEWED: 2,
	APPROVED: 3,
	RETURNED: 4,
};
export const STATUS_TYPE = {
	CREATED: 'Created',
	REVIEWED: 'Reviewed',
	APPROVED: 'Approved',
	RETURNED: 'Returned',
};

export const AWAITING_ACION_REPORT_TYPE = {
	AUDIT_LOG: '1',
	BATCH_REPORT: '2',
	PRODUCT_BATCH_PACK_REPORT: '3',
};

/** used for language changes */
export const LANGUAGE_LIST = [
	{
		encLanguageId: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
		languageName: 'English',
		locale: 'en-US',
		label: 'ENG',
	},
	{
		encLanguageId: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
		languageName: 'Deutsche (German)',
		locale: 'de',
		label: 'GER',
	},
	{
		encLanguageId: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
		languageName: 'Русский (Russia)',
		locale: 'ru',
		label: 'RUS',
	},
	{
		encLanguageId: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
		languageName: 'Suomi (Finland)',
		locale: 'fi',
		label: 'FIN',
	},
	{
		encLanguageId: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
		languageName: 'Polskie (Poland)',
		locale: 'pl_PL',
		label: 'POL',
	},
	{
		encLanguageId: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
		languageName: 'Chinese Simplified',
		locale: 'zh_CN',
		label: 'CHI(s)',
	},
	{
		encLanguageId: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
		languageName: 'Chinese Traditional',
		locale: 'zh_TW',
		label: 'CHI(t)',
	},
];
export const AWAITING_ACION_TYPES = {
	APPROVE: 'approve',
	REVIEW: 'review',
	REJECT: 'reject',
};

// for changing map theme
export const MAP_THEME = {
	DEFAULT: {
		styles: [
			{
				featureType: 'poi',
				elementType: 'labels',
				stylers: [{ visibility: 'off' }],
			},
		],
	},
	DARK: {
		styles: [
			{ elementType: 'geometry', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.stroke', stylers: [{ color: '#242f3e' }] },
			{ elementType: 'labels.text.fill', stylers: [{ color: '#746855' }] },
			{
				featureType: 'administrative.locality',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'poi',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'geometry',
				stylers: [{ color: '#263c3f' }],
			},
			{
				featureType: 'poi.park',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#6b9a76' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry',
				stylers: [{ color: '#38414e' }],
			},
			{
				featureType: 'road',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#212a37' }],
			},
			{
				featureType: 'road',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#9ca5b3' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry',
				stylers: [{ color: '#746855' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'geometry.stroke',
				stylers: [{ color: '#1f2835' }],
			},
			{
				featureType: 'road.highway',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#f3d19c' }],
			},
			{
				featureType: 'transit',
				elementType: 'geometry',
				stylers: [{ color: '#2f3948' }],
			},
			{
				featureType: 'transit.station',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#d59563' }],
			},
			{
				featureType: 'water',
				elementType: 'geometry',
				stylers: [{ color: '#17263c' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.fill',
				stylers: [{ color: '#515c6d' }],
			},
			{
				featureType: 'water',
				elementType: 'labels.text.stroke',
				stylers: [{ color: '#17263c' }],
			},
		],
	},
};

export const TEMPLATE_TYPE = {
	SERIAL_NO: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	SSCC: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
};

export const CODE_TYPE_ID = {
	GTIN: 1,
	SSCC: 2,
};
export const GS1_RADIO_ID = {
	SN: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	ENC_SN: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
};

export const GS1_CHECK_ID = {
	GTIN: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	BATCH: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	EXP_DATE: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
	MFG_DATE: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
};

export const GS1_WEBLINK_TYPE_ID = {
	WITHOUT_GS1: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	GS1_SN: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	GS1_WITHOUT_SN: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
};

export const EPCIS_STATUS_TYPE = {
	Pending: 'PENDING',
	Completed: 'COMPLETED',
	Failed: 'FAILED',
};

export const WEBLINK_DOWNLOAD_STATUS_TYPE = {
	SUCCESS: 'Success',
	FAILED: 'Failed',
	PENDING: 'Pending',
};

export const SERIAL_NUMBER_TYPE = {
	Alphanumeric: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	Numeric: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
};

/**
 * Enum for sidebar item IDs.
 */
export const SIDEBAR_IDS = {
	PRODUCT_MODULE: '3',
	L3_MODULE: '14',
	USER_MODULE: '2',
	ROLE_MODULE: '4',
	DASHBOARD: '1',
	SETTINGS: '6',
	TENANT_MODULE: '5',
	NOTIFICATIONS: '8',
	AUDIT_TRIALS: '9',
	REPORTS: '27',
	PRODUCT_ORDER: '16',
	LOCATION_DETAILS: '36',
	WEBLINK_VALIDATOR: '17',
	EPCIS: '20',
	SSO: '42',
};

/**
 * Mapping of route URLs to sidebar item IDs.
 */
export const sidebarRoutes = {
	/** Product module routes */
	[RouteUrl.PRODUCTMODULE]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.PRODUCTGROUP]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.MANUFACTURER]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.PACKAGELEVEL]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.ADDPRODUCTS]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.ADDPRODUCTGROUP]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.PRODUCT_VERSION_VIEW]: SIDEBAR_IDS.PRODUCT_MODULE,
	[RouteUrl.PRODUCT_HISTORY_VIEW]: SIDEBAR_IDS.PRODUCT_MODULE,
	/** L3 module routes */
	[RouteUrl.L3SYSTEM]: SIDEBAR_IDS.L3_MODULE,
	[RouteUrl.ADDL3SYSTEM]: SIDEBAR_IDS.L3_MODULE,

	/** Product order routes */
	[RouteUrl.EXPORT_HISTORY_PAGE]: SIDEBAR_IDS.PRODUCT_ORDER,
	[RouteUrl.PODETAILS]: SIDEBAR_IDS.PRODUCT_ORDER,
	[RouteUrl.PORETRIEVAL]: SIDEBAR_IDS.PRODUCT_ORDER,
	[RouteUrl.PRODUCTORDER]: SIDEBAR_IDS.PRODUCT_ORDER,

	/** Location details routes */
	[RouteUrl.LOCATION_DETAILS]: SIDEBAR_IDS.LOCATION_DETAILS,
	[RouteUrl.ADD_EDIT_LOCATION]: SIDEBAR_IDS.LOCATION_DETAILS,
	[RouteUrl.VIEW_LOCATION]: SIDEBAR_IDS.LOCATION_DETAILS,

	/** Reports routes */
	[RouteUrl.AUDIT_TRIAL]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.BATCH_SUMMARY_REPORT]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.BATCH_DETAILS]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.AUDIT_SHOW]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.GENERATE_BATCH_SUMMARY]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.BATCH_DETAILS_SHOW]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.WEBLINK_REPORT]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.ENDUSER_REPORT]: SIDEBAR_IDS.REPORTS,
	[RouteUrl.ENDUSER_REPORT_SHOW]: SIDEBAR_IDS.REPORTS,

	/** Settings routes */
	[RouteUrl.SETTINGS]: SIDEBAR_IDS.SETTINGS,
	[RouteUrl.GS1_WEBLINK_SETTINGS]: SIDEBAR_IDS.SETTINGS,

	/** L3 module download routes */
	[RouteUrl.WEBLINK_DOWNLOAD]: SIDEBAR_IDS.L3_MODULE,
	[RouteUrl.GENERATE_WEBLINK_DOWNLOAD]: SIDEBAR_IDS.L3_MODULE,

	/** Dashboard route */
	[RouteUrl.DASHBOARD]: SIDEBAR_IDS.DASHBOARD,

	/** User module routes */
	[RouteUrl.USERMODULE]: SIDEBAR_IDS.USER_MODULE,

	/** Role module routes */
	[RouteUrl.ROLEMODULE]: SIDEBAR_IDS.ROLE_MODULE,

	/** Tenant module routes */
	[RouteUrl.TENANTMODULE]: SIDEBAR_IDS.TENANT_MODULE,

	/** Weblink validator route */
	[RouteUrl.WEBLINKVALIDATOR]: SIDEBAR_IDS.WEBLINK_VALIDATOR,

	/** EPCIS module route */
	[RouteUrl.EPCISMODULE]: SIDEBAR_IDS.EPCIS,

	/** SSO route */
	[RouteUrl.SSO]: SIDEBAR_IDS.SSO,
};
export const MODAL_TYPE = {
	ACTIVATE: 1,
	DEACTIVATE: 2,
	DELETE: 3,
	WARNING: 4,
};

/**
 * Product section General fields
 */
export const GENERAL_FIELDS = {
	GS1_COMPANY_PREFIX: 1,
	PRODUCT_NAME: 2,
	UNIT_PRICE: 3,
	GENERIC_NAME: 4,
	DOSAGE: 5,
	STRENGTH: 6,
	FORM_TYPE: 7,
	PACK_TYPE: 8,
	STORAGE_CONDITION: 9,
	REVISION_NUMBER: 10,
	LICENSE_NUMBER: 11,
	WEIGHT: 12,
	AI_240_ADDITIONAL_ITEM_INFORMATION: 13,
	EAN13: 14,
	INTERNAL_MATERIAL_NUMBER: 15,
	AI_27_GOVERNMENT_PRODUCT_NOT_FOR_SALE: 16,
	TNVED_4: 17,
	NET_CONTENT_DESCRIPTION: 18,
	BARCODE: 19,
	DIGITAL_CODE: 20,
	SCAN_CODE_PROMPT: 21,
	CALL_ENQUIRIES: 22,
	COMPOSITION: 23,
	GROSS_WEIGHT: 24,
	NET_WEIGHT: 25,
	UNIT_PRODUCT_DIMENSIONS: 26,
	SHELF_LIFE_IN_DAYS: 27,
	BATCH_SIZE: 28,
	FG_CODE: 29,
	MARKET_COUNTRY_REGION: 30,
	AI_710_GERMANY_IFA: 31,
	AI_711_FRANCE_CIP: 32,
	AI_712_SPAIN_NATIONAL_CODE: 33,
	AT_PZN: 34,
	BE_ABP_CODE: 35,
	BR_ANVISA_REGISTRATION_NUMBER: 36,
	CA_DIN: 37,
	CH_SWISSMEDIC: 38,
	CN_SUBTYPE_CODE: 39,
	DE_PPN: 40,
	DE_PZN: 41,
	GR_EOF_CODE: 42,
	HR_CROATIA_NATIONAL_CODE: 43,
	IN_PRODUCT_CODE: 44,
	IT_BOLLINO: 45,
	KR_KFDA_CODE: 46,
	NL_KNMP: 47,
	NRD_NORDIC_VNR_DRUG_CODE: 48,
	PT_AIM_NUMBER: 49,
	SA_SAUDI_DRUG_CODE: 50,
	US_NDC442: 51,
	US_NDC532: 52,
	US_NDC541: 53,
	US_NDC542: 54,
	AI_714_PORTUGAL_AIM: 55,
	VAT: 56,
	SI_NTIN_CODE: 57,
	FDA_NDC_11: 58,
	ARTICLE_57_CODE: 59,
	TREACEBILITY_CODE_NAME: 60,
	UK_NHRN: 61,
	CN_PRODUCT_CODE: 62,
	AI_713_BRAZIL_ANVISA: 63,
	CN_PACAKGESPEC: 64,
	US_NDCPHARMA: 65,
	BRAND_NAME: 88,
	MARKETED_BY: 90,
	EXCIPIENTS: 91,
};

/**
 * Product section I-Veda Properties Fields
 */
export const IVEDA_FIELDS = {
	PRODUCT_CODE: 66,
	HS_CODE: 67,
	PRODUCT_TYPE: 68,
	REMARK: 69,
	SCHEDULED: 70,
	MANUFACTURING_SITE_CODE: 71,
	GENERIC_NAME: 72,
	STORAGE_CONDITION: 73,
	PRIMARY_LEVEL_GTIN: 74,
	SECONDARY_LEVEL_GTIN_1: 75,
	SECONDARY_LEVEL_GTIN_2: 76,
	SECONDARY_LEVEL_GTIN_3: 77,
	TERTIARY_LEVEL_GTIN: 78,
	COMPOSITION: 79,
	STRENGTH: 80,
	DOSAGE: 81,
	PRODUCT_NAME: 87,
	MANUFACTURER_CODE: 89,
};

// export const LOCATION_IDENTIFIER = {
// 	AR_CUIT: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
// 	AM_TIN: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
// 	BR_CNES: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
// 	BR_CNPJ: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
// 	BR_CPF: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
// 	BR_PROF_REG: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
// 	BY_TIN: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
// 	CN_CN_NUMBER: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
// 	CN_FOREIGN_MAH: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
// 	CN_FOREIGN_MANUFACTURER: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
// 	CN_USCID: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
// 	COMPANY_ID: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
// 	COMPANY_SITE_ID: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
// 	DE_IFA_REG_NUM: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
// 	DEA: 'Z240UWo2MUNycjR0MlBLQUE1QUFlZz09',
// 	DUNS: 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09',
// 	DUNS_4: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
// 	GCP: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
// 	GLN: 'cUhGZVRveVZhRGtvU3RVM0pkTWhJdz09',
// 	SGLN: 'Z2hzSnIxcEU1YXJlZjZlSjJSUEl3dz09',
// };

export const IDENTIFIER_TYPE = {
	AM_TIN: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	AR_CUIT: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
	BR_CNPJ: 'MGxtU0hGT3RKdUg1dlNuUjBrdVg3Zz09',
	BR_CPF: 'QkpwZHRZWnUzMzBacEUrT1pBNzFzQT09',
	BR_PROF_REG: 'RlJIc3JDWTFBc01sa29hN0toUEM2dz09',
	BY_TIN: 'cko2aE02RmRTVkhVR1VPYzA3bGNsUT09',
	CN_FOREIGN_MAH: 'TmhyM29WNitNTi96bXROWFBYcGtEZz09',
	CN_FORIEGN_MANUFACTURER: 'VFdSMUpYYXJIV0FaQUZOQkdhOTUxZz09',
	COMPANY_SITE_ID: 'dzlhWnNidEtXM2c4V1RSZklCU0Uvdz09',
	CN_USCID: 'RHJVL2NCR3FWbHNIL29mWVRjLzZBQT09',
	DE_FA_REG_NUM: 'dG9jS3NPcTAxMWVVcjhmSTlSNmM4QT09',
	DEA: 'Y1JBeUN6c2p5RDZtUkZScHk5YkxmZz09',
	DUNS: 'azhlVEwyeEZPNTlwYjlMVXhCbnhYdz09',
	DU_NS_PLUS_4: 'WTRxQTZMaFBSVWMwYjB0bHFlSk1BUT09',
	GLN: 'cHFVNTdrL2ZTNk9ZZUJ1bUZ1bmpUUT09',
	HIN: 'NVdZVm1LU0hsTWdDQWx2ZWd5WU9hdz09',
	ID_BPOM_FACILITY_ID: 'Wld3U0dvZ3drZkdwOEE3cmtuc2szZz09',
	DUNS_PLUS_4: 'cUhGZVRveVZhRGtvU3RVM0pkTWhJdz09',
	H_IN_ID_BPOM_FACILITY_ID: 'TkNMVXFISzJrZ2ZHcDRya3VxTUUxUT09',
	IN_GSTN: 'aWljejFiNTVzL2FIY0tCVDlOOFBGUT09',
	IN_IEC: 'Nk9sVHUwTGNXTmliMTZRNytraVVGUT09',
	IN_MFR: 'YXkreVRiYWltaE4yRzgzQ205dGw1Zz09',
	IN_MRCH: 'TDZUdmJ0ckd5TjBMTjlhcmFaelJmUT09',
	IN_PAN: 'TTFFc1dwaDNXbmRFcTJnWHRNUEZWQT09',
	IN_TIN: 'Y1FrWXc3ZVFNdldZWXVBdDJaMFQzQT09',
	KG_TIN: 'OXJJK3QvN09iTTBKRCs2aWZCOFdKUT09',
	KR_BUS_REG_NUM: 'eEw4VnBiZHgwM2pnM0ZSM0NDSkQ5QT09',

	KZ_BIN: 'cFVSY1B3QUhheUJZalc4QjRWeU1uUT09',
	RU_Account_Number: 'ZHJKZ1NIbE42TzNkcTU5SHVPN1lodz09',
	RU_INN_Foreign: 'b2lXWW5VQXFZUG5wYWt1UDJOMElRdz09',
	RU_INN_Individual: 'MkNPQXdZMDZoWENOS3hwcWRnRUV3QT09',
	RU_INN_Local: 'aWw2eVVSeGF5TWFNR3c5RDZTY01qZz09',
	SGLN: 'YTVQNWVMTTR4ZXBPcUZXc2ZmRW0xUT09',
	UZ_TIN: 'N1hhaE9ZZG5VZ1NuMUM1clJFeFRKdz09',
	BR_CNES: 'aFg0ait5QXFMbkp6NEljVnVBMzRyZz09',
};

export const IDENTIFIER_REGEX = {
	AR_CUIT: /^\d{2}-\d{8}-\d{1}$/,
	BR_CNPJ: /^\d{2}\.\d{3}\.\d{3}\/\d{4}-\d{2}$/,
	BR_CPF: /^\d{3}\.\d{3}\.\d{3}-\d{2}$/,
	DUNS: /^\d{2}-\d{3}-\d{4}$/,
	DUNS_4: /^[A-Za-z\d]{2}-[A-Za-z\d]{3}-[A-Za-z\d]{4}-[A-Za-z\d]{4}$/,
	SGLN: /^[a-zA-Z0-9.]+$/,
};

export const SSO_TYPE = {
	SAML: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
	OPENID: 'NGRYMThXQW5PWjBRVWpnWXp2Ynk5QT09',
};
export const HOUR_FORMAT = {
	true: 'HH:mm',
	false: 'h:mm a',
};
