const ResetPasswordSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0 mt-0'>
				<div className='skeleton p-0 m-0'>

					<div className='skeleton-right flex1 h-100'>

						<div className='reset-line mbm-25 h46 w-100' />
						<div className='reset-line mbm-25 h46 w-100' />
						<div className='reset-line mbm-25 h46 w-100' />
						<div className='reset-line h46 w-100 ' />

					</div>
				</div>
			</div>
		</div>
	);
};

export default ResetPasswordSkeleton;
