import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { REVIEW_WEBLINK_STATUS_IN_VALIDATOR } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { axiosPrivateInstance } from 'libs/axios';

import { IResDataWeblink } from 'components/common/model';

const useWeblinkReview = (isStatusSumitted: boolean, formData, updateWeblinkStatus: any) => {
	const { t } = useTranslation('l3System');
	const dispatch = useDispatch();

	const [reviewLoading, setReviewLoading] = useState<boolean>(false);
	const [resReviewStatusData, setResReviewStatusData] = useState<IResDataWeblink>();

	useEffect(() => {
		if (isStatusSumitted) {
			setReviewLoading(true);
			setResReviewStatusData(undefined);
			axiosPrivateInstance
				.post(REVIEW_WEBLINK_STATUS_IN_VALIDATOR, formData)
				.then((response: any) => {
					setResReviewStatusData(response);
					if (response?.status) {
						dispatch(
							setMessage(t(response?.output?.resultText || 'WEBLINK_ACTION_SUCCESS')),
						);
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(t(response?.output?.resultText || 'WEBLINK_ACTION_FAILED')),
						);
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})

				.catch(() => {
					setMessage(t('WEBLINK_ACTION_FAILED'));
				})
				.finally(() => {
					setReviewLoading(false);
					updateWeblinkStatus();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStatusSumitted]);

	return {
		reviewLoading,
		resReviewStatusData,
	};
};

export default useWeblinkReview;
