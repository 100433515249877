import { useState, useEffect, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { GET_NOTIFICATION_COUNT } from 'utils/serviceUrls';
import { setNotificationCount } from 'slices/notificationSlice';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

import { CANCELED_ERROR, ERROR, NOTIFICATION_COUNT_TIMER } from 'utils/constants';
import { axiosPrivateInstance } from '../../../libs/axios';

const useGetNotificationCount = () => {
	const dispatch = useDispatch();
	const { t } = useTranslation('dashboard');

	const [loadingUpdateTenantStatus, setLoadingUpdateTenantStatus] = useState<boolean>(false);
	const [updateTenantStatusRes, setUpdateTenantStatusRes] = useState<boolean>(false);

	const apiCall = useCallback(
		(controller) => {
			setLoadingUpdateTenantStatus(true);
			const { signal } = controller;

			axiosPrivateInstance
				.post(GET_NOTIFICATION_COUNT, {}, { signal })
				.then((response: any) => {
					if (response?.output && response?.status) {
						dispatch(setNotificationCount(response?.output?.notificationCount));
						setUpdateTenantStatusRes(response?.status);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType(ERROR));
					}
				})
				.catch((error) => {
					if (error?.name === CANCELED_ERROR) {
						return;
					}
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setLoadingUpdateTenantStatus(false);
				});
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[dispatch],
	);

	useEffect(() => {
		/**
		 * using abort controller for cancel api call when logout
		 */
		const controller = new AbortController();

		const interval = setInterval(() => {
			apiCall(controller);
		}, NOTIFICATION_COUNT_TIMER * 60000);
		return () => {
			clearInterval(interval);
			controller.abort();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		/**
		 * using abort controller for cancel api call when logout
		 */
		const controller = new AbortController();
		apiCall(controller);
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		loadingUpdateTenantStatus,
		updateTenantStatusRes,
	};
};
export default useGetNotificationCount;
