const ProductPrnSkeleton = () => {
	return (
		<div className='dummy-skelton '>
			<div className='box'>
				<div className='skeleton '>
					<div className='skeleton-left flex1 '>
						<div className='line product-line w-173' />
						<div className='line product-line' />
						<div className='line product-line w-150' />
					</div>
					<div className='skeleton-left flex1 h-100'>
						<div className='line product-line mtop-4' />
						{/* <div className='line product-line mleft-11' />
						<div className='line product-line w-160' /> */}
					</div>
					<div className='skeleton-left flex1 h-100'>
						<div className='line product-line bg-none' />
						<div className='line product-line' />
						<div className='line product-line mleft-12' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductPrnSkeleton;
