import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import Spinner from 'components/common/Spinner';
import CustomNotification from 'components/common/notification/CustomNotification';
import { requestSsoUrlService } from './services/ssoAuthServices';

const SsoLogin = () => {
	/**
	 * The page will show a loader during sso auth process
	 */
	const [loader, setLoader] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [errorTitle, setErrorTitle] = useState<string>('');
	const [showNotification, setShowNotification] = useState<boolean>(false);
	const { t } = useTranslation('sso');
	/**
	 * Function to call sso auth process initiating service
	 * @returns null
	 */
	const ssoLoginStarts = async () => {
		setLoader(true);
		const apiRes: any = await requestSsoUrlService();
		if (apiRes) {
			if (apiRes === 'error') {
				setShowNotification(true);
				setErrorMsg(t('API_ERROR_MESSAGE')!!);
				setErrorTitle(t('API_ERROR_TITLE')!!);
				return true;
			}
			if (apiRes.samlRequestUrl) {
				window.location.href = apiRes.samlRequestUrl;
			}
		}
		return true;
	};

	useEffect(() => {
		ssoLoginStarts();

		return () => {
			setShowNotification(false);
			setErrorMsg('');
			setErrorTitle('');
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
	if (loader) {
		return (
			<>
				<Spinner />
				{/* Kendo notification message */}
				{showNotification && (
					<CustomNotification
						message={errorMsg}
						title={errorTitle}
						onClose={() => setShowNotification(false)}
						closable
						type={{ style: 'error', icon: true }}
					/>
				)}
			</>
		);
	}

	return null;
};

export default SsoLogin;
