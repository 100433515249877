import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks/stateHooks';
import { ICON_DESCRIPTION_ID, RouteUrl } from 'utils/enum';
import { getDashboardIconsData } from 'slices/infoIconsDataSlice';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import AwaitingReview from './AwaitingReview';

const AwaitingActionsContainer = () => {
	const { t } = useTranslation('dashboard');
	const navigate = useNavigate();

	const handleViewAll = () => {
		navigate(RouteUrl.AWAITINGACTIONS);
	};

	const dashboardAreaIconsData = useAppSelector(getDashboardIconsData);

	const [awaitingReviewInfo, setAwaitingReviewInfo] = useState<string>('');

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		dashboardAreaIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			dashboardAreaIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.AWAITING_REVIEW) {
					setAwaitingReviewInfo(item?.iconDescription);
				}
			});
	}, [dashboardAreaIconsData]);

	return (
		<>
			<div className='page-header p-0'>
				<h3>
					{t('AWAITING_REVIEW')!!}
					<CustomEditableTooltip
						showIcon='faInfoCircle'
						text={awaitingReviewInfo}
						moduleId={ICON_DESCRIPTION_ID.AWAITING_REVIEW}
					/>
				</h3>
				<button type='button' onClick={() => handleViewAll()} className='btn view-all'>
					{t('VIEW_ALL')!!}
				</button>
			</div>
			<AwaitingReview />
		</>
	);
};
export default AwaitingActionsContainer;
