import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const Index = lazy(() => import('components/moduleConfiguration/index'));
const ModuleConfiguration = () => {
	return (
		<div className='page-content settings-page'>
			<Suspense fallback={<CommonSkeleton />}>
				<Index />
			</Suspense>
		</div>
	);
};

export default ModuleConfiguration;
