import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosBasicInstance } from 'libs/axios';
import { getToken, storeRefreshToken, storeToken } from 'utils/utils';
import { ERROR } from 'utils/constants';
import { LOGIN_URL } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

/**
 *
 * @param logindata -params which include username and password
 * @param loginbuttonclickstatus -key which indicates login button is clicked
 * @param updateButtonClick -login button click status change function
 * @returns  loginValue, loginStatus, token, loginLoading
 */

const useLoginUser = (
	logindata: any,
	loginbuttonclickstatus: boolean,
	updateButtonClick: () => void,
) => {
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();
	const token = getToken();

	const [loginValue, setLoginValue] = useState<any>({});
	const [loginStatus, setLoginStatus] = useState(false);
	const [loginLoading, setLoginLoading] = useState<boolean>(false);

	useEffect(() => {
		if (loginbuttonclickstatus) {
			setLoginLoading(true);
			axiosBasicInstance
				.post(LOGIN_URL, logindata)
				.then((response: any) => {
					setLoginStatus(true);
					setLoginValue(response.output);
					storeToken(response.output.token);
					storeRefreshToken(response?.output?.refreshToken);
				})
				.catch(() => {
					setLoginStatus(false);
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					updateButtonClick();
					setLoginLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginbuttonclickstatus]);

	return { loginValue, loginStatus, token, loginLoading };
};

export default useLoginUser;
