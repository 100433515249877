const Gs1Skelton = () => {
	return (
		<div className='dummy-skelton settings-skeleton'>
			<div className='box'>
				<div className='skeleton position-relative p-0'>
					<div className='skeleton-left flex1 p-0'>
						<div className='line product-line w-60' />
						<div className='d-flex'>
							<div className='line product-line w-100 me-2 h-7' />
							<div className='d-flex flex-column w-100'>
								<div className='line product-line w-100' />
								<div className='line product-line w-100' />
							</div>
						</div>
						<div className='d-flex'>
							<div className='line product-line w-100 me-2 h25' />
							<div className='d-flex flex-column w-100'>
								<div className='line product-line w-100' />
								<div className='line product-line w-100' />
							</div>
						</div>
						<div className='line product-line gs-btn' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default Gs1Skelton;
