import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import { Suspense, lazy } from 'react';

const AuditTrailDetails = lazy(() => import('components/auditTrail/AuditTrailDetails'));
const AuditTrailDetailsPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<AuditTrailDetails />
			</Suspense>
		</div>
	);
};
export default AuditTrailDetailsPage;
