import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { CANCELED_ERROR } from 'utils/constants';
import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_TEMPLATE_TYPE } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetAllTemplateType = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('serialNumber');

	const [templateTypeData, setTemplateTypeData] = useState<any>([]);
	const [templateTypeDataLoading, setTemplateTypeDataLoading] = useState<boolean>(true);

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		setTemplateTypeDataLoading(true);
		axiosPrivateInstance
			.post(GET_ALL_TEMPLATE_TYPE, {}, { signal })
			.then((response: any) => {
				if (response?.output) {
					setTemplateTypeData(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch((error: any) => {
				if (error?.name === CANCELED_ERROR) {
					return;
				}
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setTemplateTypeDataLoading(false);
			});

		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		templateTypeData,
		templateTypeDataLoading,
	};
};

export default useGetAllTemplateType;
