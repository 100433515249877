import { useEffect, useRef, FocusEvent, ChangeEvent, KeyboardEvent, memo } from 'react';

import CustomError from '../errorLabel/CustomError';
import { ICustomInput } from '../model';

/**
 * Custom input component.
 * @param {Object} props - The props passed to the component
 * @param {string} props.name - The name of the input
 * @param {string} props.label - The label for the input
 * @param {string} props.placeholder - The placeholder text
 * @param {function} props.onChange - Change event handler
 * @param {any} props.value - The value of the input
 * @param {function} props.handleBlur - Blur event handler
 * @param {string} props.type - Type of input
 * @param {string} props.size - Size of the input
 * @param {number} props.maxLength - Max length allowed
 * @param {number} props.minLength - Min length allowed
 * @param {string} props.pattern - Input pattern validation
 * @param {boolean} props.required - Whether input is required
 * @param {function} props.handleFocus - Focus event handler
 * @param {function} props.handleKeyDown - Keydown event handler
 * @param {function} props.handleKeyUp - Keyup event handler
 * @param {string} props.error - Error message
 * @param {string} props.className - CSS classnames
 * @param {boolean} props.disabled - Whether disabled
 */
const CustomInput = ({
	name,
	label,
	placeholder,
	onChange,
	value,
	handleBlur,
	type,
	size,
	maxLength,
	minLength,
	pattern,
	required,
	handleFocus,
	handleKeyDown,
	handleKeyUp,
	error,
	className,
	disabled,
	isMandatory,
	autofocus,
	autocompleteDisabled,
}: ICustomInput) => {
	/**
	 * Handle blur event on input.
	 * Calls handleBlur callback if defined.
	 * @param {FocusEvent<HTMLElement>} e - The blur event
	 */
	const onBlur = (e: FocusEvent<HTMLElement>) => {
		// eslint-disable-next-line no-unused-expressions
		handleBlur ? handleBlur(e) : null;
	};
	/**
	 * Handle change event on input.
	 * Calls onChange callback.
	 * @param {ChangeEvent<HTMLInputElement>} e - The change event
	 */
	const handleChange = (e: ChangeEvent<HTMLInputElement>) => {
		onChange(e);
	};
	/**
	 * Handle focus event on input.
	 * Calls handleFocus callback if defined.
	 * @param {FocusEvent<HTMLElement>} e - The focus event
	 */
	const onFocus = (e: FocusEvent<HTMLElement>) => {
		// eslint-disable-next-line no-unused-expressions
		handleFocus ? handleFocus(e) : null;
	};
	/**
	 * Handle keydown event on input.
	 * Calls handleKeyDown callback if defined.
	 * Disables typing non-numeric characters for number input.
	 * @param {KeyboardEvent<HTMLElement>} e - The keydown event
	 */
	const onKeyDown = (e: KeyboardEvent<HTMLElement>) => {
		// to disable typing of e , + ,_
		if (type === 'number') {
			if (e.key === 'e' || e.key === 'E' || e.key === '-' || e.key === '+' || e.key === '.') {
				e.preventDefault();
			}
		}
		// Call handleKeyDown if defined
		// eslint-disable-next-line no-unused-expressions
		handleKeyDown ? handleKeyDown(e) : null;
	};
	/**
	 * Handle keyup event on input.
	 * Calls handleKeyUp callback if defined.
	 * @param {KeyboardEvent<HTMLElement>} e - The keyup event
	 */
	const onKeyUp = (e: KeyboardEvent<HTMLElement>) => {
		// eslint-disable-next-line no-unused-expressions
		handleKeyUp ? handleKeyUp(e) : null;
	};

	/**
	 * Autofocus input field
	 */
	const inputRef = useRef<HTMLInputElement | null>(null);

	/**
	 * Autofocus input on mount if autofocus prop is true
	 */
	useEffect(() => {
		if (inputRef && inputRef.current && autofocus) {
			inputRef.current.focus();
		}
	}, [autofocus]);
	return (
		<>
			<label className='form-label'>{label}</label>
			{isMandatory && <span>*</span>}
			<input
				ref={inputRef}
				disabled={disabled}
				className={
					disabled
						? `form-control ${`${className} disabled` || 'form-control disabled'}`
						: `form-control ${className || ''}`
				}
				type={type}
				name={name}
				placeholder={placeholder}
				onChange={(e: ChangeEvent<HTMLInputElement>) => handleChange(e)}
				value={value !== null || value !== undefined ? value : ''}
				onBlur={(e: FocusEvent<HTMLElement>) => onBlur(e)}
				onFocus={(e: FocusEvent<HTMLElement>) => onFocus(e)}
				size={size}
				max={maxLength}
				min={minLength || 0}
				maxLength={maxLength}
				pattern={pattern}
				required={required}
				onKeyDown={(e: KeyboardEvent<HTMLElement>) => onKeyDown(e)}
				onKeyUp={(e: KeyboardEvent<HTMLElement>) => onKeyUp(e)}
				autoComplete={autocompleteDisabled ? 'off' : 'on'}
			/>

			{error && error !== '' && (
				<div className='input-number-error'>
					{' '}
					<CustomError value={error} />
				</div>
			)}
		</>
	);
};

export default memo(CustomInput);
