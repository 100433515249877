import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const WeblinkValidator = lazy(
	() => import('components/l3System/weblink-validator/WeblinkValidator'),
);
const WeblinkValidatorPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<WeblinkValidator />
			</Suspense>
		</div>
	);
};

export default WeblinkValidatorPage;
