/**
 * Custom hook to fetch icon description data for a given areaId and iconId.
 * @param {string} areaId - The areaId for which the icon description is requested.
 * @param {string} iconId - The iconId for which the icon description is requested. (optional)
 * @returns {Object} - An object containing iconDescriptionLoading, iconDescriptionResponse properties.
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { axiosPrivateInstance } from 'libs/axios';
import { GET_ICON_DESCRIPTION_URL } from 'utils/serviceUrls';
import { getInfoIconsDataStatus, setInfoIconsDataStatus } from 'slices/infoIconsDataSlice';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from 'utils/constants';

const useGetIconDescription = (areaId: string, iconId: string = 'string') => {
	const { t } = useTranslation('roleModule');
	const dispatch = useAppDispatch();
	const infoIconsDataStatus = useAppSelector(getInfoIconsDataStatus);

	const [iconDescriptionResponse, setIconDescriptionResponse] = useState<any>();
	const [iconDescriptionLoading, setIconDescriptionLoading] = useState<boolean>(false);

	// API call for get Icon description data
	const ApiRespone = async () => {
		setIconDescriptionLoading(true);
		axiosPrivateInstance
			.post(GET_ICON_DESCRIPTION_URL, {
				encIconId: iconId,
				encAreaId: areaId,
			})
			.then((response: any) => {
				setIconDescriptionResponse(response);
				setIconDescriptionLoading(false);
				// Dispatch the action to set infoIconsDataStatus to false. to don't repeat api calls
				dispatch(setInfoIconsDataStatus(false));
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
				setIconDescriptionLoading(false);
				// Dispatch the action to set infoIconsDataStatus to false. to don't repeat api calls
				dispatch(setInfoIconsDataStatus(false));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	};

	// Fetch icon description data if AreaId is provided.
	useEffect(() => {
		if (areaId) {
			ApiRespone();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [areaId]);

	// Fetch icon description data after update click
	useEffect(() => {
		if (areaId && infoIconsDataStatus) {
			ApiRespone();
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [areaId, infoIconsDataStatus]);

	return {
		iconDescriptionLoading,
		iconDescriptionResponse,
	};
};

export default useGetIconDescription;
