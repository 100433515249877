import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';

import { ADD_L4_SYSTEM } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppSelector } from 'hooks/stateHooks';
import { getCreateL4SubmitButtonStatus, setCreateL4SubmitButtonStatus } from 'slices/l4SystemSlice';

import { IL4FormData } from 'components/common/model';

/**
 * Custom hook to add an L4 system.
 * @param {IL4FormData} formData - The form data containing information to create the L4 system.
 * @returns {{ addpL4Response: any, loading: boolean }} Object with the response of the L4 system creation and loading state.
 */

const useAddL4System = (formData: IL4FormData) => {
	const { t } = useTranslation('l4System');
	const createL4SubmitButtonStatus = useAppSelector(getCreateL4SubmitButtonStatus);
	const params = {
		l4SystemName: formData.l4SystemName,
		address: formData.address,
		encCountryId: formData.country,
		description: formData.description,
		location: formData.l4Location,
		connectionMethodID: formData.selectedConnectionType,
		connectionDetails: {
			host: formData.sftpHost,
			userName: formData.sftpUsername,
			password: formData.sftpPassword,
			portNo: formData.sftpPortNo === '' ? null : formData.sftpPortNo,
			identifier: formData.restApiTenantId,
		},
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [addpL4Response, setAddL4Response] = useState<any>({});
	const dispatch = useDispatch();

	/**
	 * Function to add an L4 system when the createL4SubmitButtonStatus is true.
	 * It sends a POST request to the server with the provided form data.
	 * The response status determines whether the L4 system was created successfully or not.
	 * It dispatches appropriate notifications based on the response.
	 */

	useEffect(() => {
		if (createL4SubmitButtonStatus) {
			setLoading(true);
			axiosPrivateInstance
				.post(ADD_L4_SYSTEM, params)
				.then((response: any) => {
					setAddL4Response(response);
					if (response?.status) {
						dispatch(
							setMessage(
								t(response?.errorMessage || 'L4_SYSTEM_CREATED_SUCCESSFULLY'),
							),
						);
						dispatch(setNotificationType('success'));
					} else {
						dispatch(setMessage(t(response?.errorMessage)));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('L4_SYSTEM_CREATED_SUCCESSFULLY')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					dispatch(setShowNotification(true));
					setLoading(false);
					dispatch(setCreateL4SubmitButtonStatus(false));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createL4SubmitButtonStatus]);

	return {
		addpL4Response,
		loading,
	};
};

export default useAddL4System;
