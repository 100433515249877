import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import CustomErrorModal from 'components/common/modals/CustomErrorModal';

import { RouteUrl } from 'utils/enum';
import { storeLoginedUserData, removeLoginedUserData } from 'utils/utils';
import useAuthenticate from './hooks/useAuthenticate';
import useGetUserData from './hooks/useGetUserData';

const TenentRedirection = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('userModule');

	const [userDetailsParams, setUserDetailsParams] = useState(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [showNotification, setShowNotification] = useState<boolean>(false);

	const { userDetailsResponse } = useGetUserData(userDetailsParams);
	const { loginValue, loginStatus } = useAuthenticate();

	useEffect(() => {
		if (loginValue === '400') {
			setShowNotification(true);
			setErrorMsg(t('ADMIN_TENANT_LOGIN_FAIL')!!);
		}
		if (loginStatus) {
			if (loginValue.resultId === 100 && loginValue.token) {
				setUserDetailsParams(true);
			} else if (loginValue.resultId === 101) {
				setShowNotification(true);
				setErrorMsg(t('ADMIN_TENANT_LOGIN_FAIL')!!);
			} else if (
				loginValue.resultId === 102 ||
				loginValue.resultId === 103 ||
				loginValue.resultId === 104 ||
				loginValue.resultId === 105
			) {
				setShowNotification(true);
				setErrorMsg(t('ADMIN_TENANT_LOGIN_FAIL')!!);
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus, loginValue]);

	useEffect(() => {
		if (userDetailsResponse?.statusCode === '200') {
			setUserDetailsParams(false);
			const apiLoginResponse: any = {
				token: loginValue.token,
				firstName: userDetailsResponse?.output?.firstName,
				email: userDetailsResponse?.output?.email,
				listPrivilege: userDetailsResponse?.output?.privilegeDetails.map((item: any) => {
					return item.encPrivilegeId;
				}),
				userType: userDetailsResponse?.output?.userType,
			};

			removeLoginedUserData();
			storeLoginedUserData(JSON.stringify(apiLoginResponse));
			navigate(RouteUrl.USERMODULE);
		} else if (userDetailsResponse?.status === 400) {
			setUserDetailsParams(false);
			setShowNotification(true);
			setErrorMsg(t('ADMIN_TENANT_LOGIN_FAIL')!!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginValue, userDetailsResponse]);

	const handleClose = () => {
		navigate('/');
		setShowNotification(false);
	};

	return (
		<>
			<div className='default-loading'>{t('REDIRECTING')}</div>
			{showNotification && (
				<CustomErrorModal
					isModalVisible={showNotification}
					content={errorMsg}
					confirmClickNo={handleClose}
				/>
			)}
		</>
	);
};

export default TenentRedirection;
