import {
	Popover,
	PopoverActionsBar,
	PopoverCloseEvent,
	PopoverOpenEvent,
} from '@progress/kendo-react-tooltip';
import { ICustomPopover } from 'components/common/model';

const CustomPopover = ({
	show,
	anchor,
	position,
	title,
	style,
	popoverActions,
	children,
	onClose,
	onOpen,
	callout,
	className,
}: ICustomPopover) => {
	const handleClose = (event: PopoverCloseEvent) => {
		// eslint-disable-next-line no-unused-expressions
		onClose ? onClose(event) : null;
	};
	const handleOpen = (event: PopoverOpenEvent) => {
		// eslint-disable-next-line no-unused-expressions
		onOpen ? onOpen(event) : null;
	};
	return (
		<Popover
			show={show || false}
			className={className || ''}
			anchor={anchor}
			position={position}
			title={title}
			style={style}
			onClose={(event: PopoverCloseEvent) => handleClose(event)}
			onOpen={(event: PopoverOpenEvent) => handleOpen(event)}
			callout={callout}>
			{children}
			{popoverActions && <PopoverActionsBar>{popoverActions}</PopoverActionsBar>}
		</Popover>
	);
};
export default CustomPopover;
