import { useEffect } from 'react';
// eslint-disable-next-line import/no-extraneous-dependencies
import CacheBuster from 'react-cache-buster';
import { useAppSelector } from 'hooks/stateHooks';

import Index from 'routes/index';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import { getUserSavedLanguageValue } from 'slices/userLoginDataSlice';
import { changeLanguage, getUserLocale, setUserSavedLanguage, themeHandling } from 'utils/utils';

const App = () => {
	const isProduction = process.env.NODE_ENV === 'production';
	const savedLanguageId = useAppSelector(getUserSavedLanguageValue);

	useEffect(() => {
		themeHandling(false);
	}, []);

	useEffect(() => {
		const userLocale = getUserLocale();
		if (savedLanguageId !== '') {
			setUserSavedLanguage(savedLanguageId);
		} else if (userLocale) {
			changeLanguage(userLocale);
		}
	}, [savedLanguageId]);

	return (
		<CacheBuster
			currentVersion={process.env.REACT_APP_VERSION}
			isEnabled={isProduction} // If false, the library is disabled.
			isVerboseMode={false} // If true, the library writes verbose logs to console.
			loadingComponent={<CommonSkeleton />} // If not pass, nothing appears at the time of new version check.
			metaFileDirectory='.' // If public assets are hosted somewhere other than root on your server.
		>
			<Index />
		</CacheBuster>
	);
};

export default App;
