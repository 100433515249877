import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch } from 'hooks/stateHooks';
import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_LOCATION_BY_ID } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from '../../../utils/constants';

const useGetLocationById = (locationById: any) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('locationModule');

	const [locationLoading, setLocationLoading] = useState<boolean>(false);
	const [resData, setResData] = useState<any>();

	useEffect(() => {
		if (locationById) {
			setLocationLoading(true);
			axiosPrivateInstance
				.post(GET_ALL_LOCATION_BY_ID, { encLocationId: locationById })
				.then((response: any) => {
					if (response?.output && response?.status) {
						setResData(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType(ERROR));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setLocationLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [locationById]);

	return {
		locationLoading,
		resData,
	};
};

export default useGetLocationById;
