import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_MANUFACTURERE_DETAILS_BY_ID } from 'utils/serviceUrls';

import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from '../../../../utils/constants';

const useGetManufacturerDetailsById = (manufacturerId: any) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('manufacturerModule');

	const [manufacturerDetailsLoading, setManufacturerDetailsLoading] = useState<boolean>(false);
	const [resData, setResData] = useState<any>();

	useEffect(() => {
		if (manufacturerId) {
			setManufacturerDetailsLoading(true);
			axiosPrivateInstance
				.post(GET_MANUFACTURERE_DETAILS_BY_ID, { encId: manufacturerId })
				.then((response: any) => {
					if (response?.output && response?.status) {
						setResData(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType(ERROR));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setManufacturerDetailsLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [manufacturerId]);

	return {
		manufacturerDetailsLoading,
		resData,
	};
};

export default useGetManufacturerDetailsById;
