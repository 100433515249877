import { useTranslation } from 'react-i18next';
import NodataImage from 'assets/images/no-data.svg';

const NoData = () => {
	const { t } = useTranslation('roleModule');
	return (
		<div className='no-data'>
			<div className='no-data-body text-center'>
				<div className='no-data-inner'>
					<img src={NodataImage} alt='No data' />
					<h1>{t('LABEL_NOT_DATA_FOUND')}</h1>
					<p />
				</div>
			</div>
		</div>
	);
};
export default NoData;
