import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	roleOptions: [],
	batchPoUpdateStatus: false,
	productNameSearch: false,
	sourceValue: '',
	sourceFilterValue: '',
	typeValue: '',
	typeFilterValue: '',
	mfgDateValue: '',
	mfgDateFilterValue: '',
	poStatusValue: '',
	poStatusFilterValue: '',
	expDateValue: '',
	expDateFilterValue: '',
	createdDateValue: '',
	createdDateFilterValue: '',
	poRetrievalValue: '',
	poRetrievalFilterValue: '',
	poStateStatusValue: '',
	poStateStatusFilterValue: '',
	exportFileModalVisible: false,
	encLotNoId: '',
};

export const batchPoModuleSlice = createSlice({
	name: 'batchPoModule',
	initialState,
	reducers: {
		setActiveRoles: (state, action: PayloadAction<any>) => {
			state.roleOptions = action.payload;
		},
		setBatchPoUpdateStatus: (state, action: PayloadAction<any>) => {
			state.batchPoUpdateStatus = action.payload;
		},
		setBatchPoStatusUpdate: (state, action: PayloadAction<any>) => {
			state.batchPoStatusUpdate = action.payload;
		},
		setProductNameSearch: (state, action: PayloadAction<any>) => {
			state.productNameSearch = action.payload;
		},
		setSourceValue: (state, action: PayloadAction<any>) => {
			state.sourceValue = action.payload;
		},
		setSourceFilterValue: (state, action: PayloadAction<any>) => {
			state.sourceFilterValue = action.payload;
		},
		setTypeValue: (state, action: PayloadAction<any>) => {
			state.typeValue = action.payload;
		},
		setTypeFilterValue: (state, action: PayloadAction<any>) => {
			state.typeFilterValue = action.payload;
		},
		setMfgDateValue: (state, action: PayloadAction<any>) => {
			state.mfgDateValue = action.payload;
		},
		setMfgDateFilterValue: (state, action: PayloadAction<any>) => {
			state.mfgDateFilterValue = action.payload;
		},
		setPoStatusValue: (state, action: PayloadAction<any>) => {
			state.poStatusValue = action.payload;
		},
		setPoStatusFilterValue: (state, action: PayloadAction<any>) => {
			state.poStatusFilterValue = action.payload;
		},
		setPoRetrievalStatusValue: (state, action: PayloadAction<any>) => {
			state.poRetrievalValue = action.payload;
		},
		setPoRetrievalFilterValue: (state, action: PayloadAction<any>) => {
			state.poRetrievalFilterValue = action.payload;
		},
		setExpDateValue: (state, action: PayloadAction<any>) => {
			state.expDateValue = action.payload;
		},
		setExpDateFilterValue: (state, action: PayloadAction<any>) => {
			state.expDateFilterValue = action.payload;
		},
		setCreatedDateValue: (state, action: PayloadAction<any>) => {
			state.createdDateValue = action.payload;
		},
		setCreatedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.createdDateFilterValue = action.payload;
		},
		setPoStateStatusValue: (state, action: PayloadAction<any>) => {
			state.poStateStatusValue = action.payload;
		},
		setPoStateStatusFilterValue: (state, action: PayloadAction<any>) => {
			state.poStateStatusFilterValue = action.payload;
		},
		setExportFileModalVisible: (state, action: PayloadAction<any>) => {
			state.exportFileModalVisible = action.payload;
		},
		setEncLotNoId: (state, action: PayloadAction<any>) => {
			state.encLotNoId = action.payload;
		},
	},
});

export const {
	setActiveRoles,
	setBatchPoUpdateStatus,
	setBatchPoStatusUpdate,
	setProductNameSearch,
	setSourceValue,
	setSourceFilterValue,
	setTypeValue,
	setTypeFilterValue,
	setMfgDateValue,
	setMfgDateFilterValue,
	setExpDateValue,
	setExpDateFilterValue,
	setCreatedDateValue,
	setCreatedDateFilterValue,
	setPoStatusFilterValue,
	setPoStatusValue,
	setPoRetrievalFilterValue,
	setPoRetrievalStatusValue,
	setPoStateStatusValue,
	setPoStateStatusFilterValue,
	setExportFileModalVisible,
	setEncLotNoId,
} = batchPoModuleSlice.actions;

export const getRoleOptions = (state: RootState) => state.batchPoModule.roleOptions;
export const getBatchPoUpdateStatus = (state: RootState) => state.batchPoModule.batchPoUpdateStatus;
export const getBatchPoStatusUpdate = (state: RootState) => state.batchPoModule.batchPoStatusUpdate;
export const getProductNameSearch = (state: RootState) => state.batchPoModule.productNameSearch;
export const getSourceValue = (state: RootState) => state.batchPoModule.sourceValue;
export const getSourceFilterValue = (state: RootState) => state.batchPoModule.sourceFilterValue;
export const getTypeValue = (state: RootState) => state.batchPoModule.typeValue;
export const getTypeFilterValue = (state: RootState) => state.batchPoModule.typeFilterValue;
export const getMfgDateValue = (state: RootState) => state.batchPoModule.mfgDateValue;
export const getMfgDateFilterValue = (state: RootState) => state.batchPoModule.mfgDateFilterValue;
export const getExpDateValue = (state: RootState) => state.batchPoModule.expDateValue;
export const getExpDateFilterValue = (state: RootState) => state.batchPoModule.expDateFilterValue;
export const getCreatedDateValue = (state: RootState) => state.batchPoModule.createdDateValue;
export const getPoStatusValue = (state: RootState) => state.batchPoModule.poStatusValue;
export const getPoStatusFilterValue = (state: RootState) => state.batchPoModule.poStatusFilterValue;
export const getPoRetrievalValue = (state: RootState) => state.batchPoModule.poRetrievalValue;
export const getPoStateStatusValue = (state: RootState) => state.batchPoModule.poStateStatusValue;
export const getPoStateStatusFilterValue = (state: RootState) =>
	state.batchPoModule.poStateStatusFilterValue;
export const getPoRetrievalFilterValue = (state: RootState) =>
	state.batchPoModule.poRetrievalFilterValue;
export const getCreatedDateFilterValue = (state: RootState) =>
	state.batchPoModule.createdDateFilterValue;
export const getExportFileModalVisible = (state: RootState) =>
	state.batchPoModule.exportFileModalVisible;
export const getEncLotNoId = (state: RootState) => state.batchPoModule.encLotNoId;

export default batchPoModuleSlice.reducer;
