import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const WeblinkReportIndex = lazy(() => import('components/report/weblinkReport/WeblinkReportIndex'));

const WeblinkReportPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<WeblinkReportIndex />
			</Suspense>
		</div>
	);
};
export default WeblinkReportPage;
