import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import i18next from 'i18next';

import { DropDownButton } from '@progress/kendo-react-buttons';
import { AppBar, AppBarSection, MenuItemModel } from '@progress/kendo-react-layout';
import {
	removeToken,
	removeLoginedUserData,
	getBaseUrl,
	themeHandling,
	hasPrivileges,
} from 'utils/utils';
import { LANGUAGE_LIST, Privileges, RouteUrl } from 'utils/enum';

import CustomDropdown from 'components/common/forms/CustomDropdown';
import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import useForgotPassword from 'components/login/hooks/useForgotPassword';
import CustomSuccessModal from 'components/common/modals/CustomSuccessModal';
import CustomNotification from 'components/common/notification/CustomNotification';
import { getThemeValue, getUserLoginData, setUserLoginData } from 'slices/userLoginDataSlice';
import { SwitchChangeEvent } from '@progress/kendo-react-inputs';
import CustomSwitch from 'components/common/button/CustomSwitch';
import { getLogo, setDarkMode } from 'slices/configSlice';
import NotificationPopover from 'components/notifications/NotificationPopover';
import FileFailedNotification from 'components/fileFailure/FileFailedNotification';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import useThemeSwitcher from 'hooks/useThemeSwitcher';
import { ILanguageListType } from 'components/common/model';
import useLanguageSwitcher from 'hooks/useLanguageSwitcher';
import userLogoutService from 'components/login/services/userLogoutService';
import CustomLoader from 'components/common/loader/CustomLoader';
import MainLogo from 'assets/images/ALSC-Logo-menu.svg';

interface ICustmRenderItm {
	item: {
		text: string;
	};
	itemIndex: number;
}

const Header = () => {
	const [resetPasswordClicked, setResetPasswordClicked] = useState<boolean>(false);
	const getLoginUserData = useAppSelector(getUserLoginData);
	// eslint-disable-next-line no-unused-vars
	const [notificationClicked, setsetNotificationClicked] = useState<boolean>(false);

	const [resetPasswordData, setResetPasswordData] = useState<any>(null);
	const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
	const [darkThemeEnabled, setDarkThemeEnabled] = useState<boolean>(false);

	const [darkThemeClicked, setDarkThemeClicked] = useState<boolean>(false);
	const [languageChanged, setLanguageChanged] = useState<boolean>(false);

	const [showNotification, setShowNotification] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [errorTitle, setErrorTitle] = useState<string>('');
	const [dropdownShow, setDropDownShow] = useState(false);

	const navigate = useNavigate();
	const dispatch = useAppDispatch();

	const { t } = useTranslation('userModule');
	const logo = useAppSelector(getLogo);

	const initialSelectedLanguage = {
		encLanguageId: 'Tmx3THFleUNDNUhzVWtrYmM5eGFmQT09',
		languageName: 'English',
		locale: 'en-US',
		label: 'ENG',
	};

	/**
	 * callback function to reset the button click status
	 *closing the dropdown button if under error or success
	 */
	const updateResetPasswordClick = () => {
		setResetPasswordClicked(false);
		setDropDownShow(false);
	};

	const [selectedLanguage, setSelectedLanguage] =
		useState<ILanguageListType>(initialSelectedLanguage);
	const { forgotPasswordRes, forgotPasswordLoading } = useForgotPassword(
		resetPasswordClicked,
		resetPasswordData,
		updateResetPasswordClick,
	);
	const profileMenuItems: MenuItemModel[] = [
		{ text: '' },
		{ text: t('RESET_PASSWORD')!!, disabled: forgotPasswordLoading },
		{ text: t('DARK_THEME')!! },
		{ text: t('LOG_OUT')!! },
	];

	const languages: Array<ILanguageListType> = LANGUAGE_LIST;

	const userData: any = useAppSelector(getUserLoginData);

	const themeValue = useAppSelector(getThemeValue);

	const baseURL = getBaseUrl();

	// eslint-disable-next-line no-unused-vars
	const { themeSwitchResponse, themeSwitchloading } = useThemeSwitcher(
		darkThemeEnabled,
		darkThemeClicked,
		setDarkThemeClicked,
	);

	// hooks for  switching language
	useLanguageSwitcher(selectedLanguage, languageChanged, setLanguageChanged);

	// setting theme value based on switching dark mode and light mode
	useEffect(() => {
		themeHandling(themeValue);
		setDarkThemeEnabled(themeValue);
		dispatch(setDarkMode(themeValue));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [themeValue]);

	/** to change the selected language dropdown according to locale language change */
	useEffect(() => {
		const selectedLangData =
			languages &&
			languages.length > 0 &&
			languages.filter((item: any) => item.locale === i18next.language)[0];
		if (selectedLangData) {
			setSelectedLanguage(selectedLangData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [i18next.language]);

	/**
	 * passing resetPassword response for opening success modal
	 */
	useEffect(() => {
		if (forgotPasswordRes?.statusCode === '200') {
			setResetPasswordModal(forgotPasswordRes?.status);
		} else if (forgotPasswordRes) {
			setShowNotification(true);
			setErrorTitle(t('ERROR')!!);
			setErrorMsg(t('ERROR')!!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forgotPasswordRes]);

	/**
	 *
	 *  logout dropddown button button item click function
	 */
	const profileOptionChange = () => {
		// if (item.item.text === t('LOG_OUT')) {
		/** API call for logout status keep in backend */
		userLogoutService();

		removeLoginedUserData();
		removeToken();
		localStorage.clear();
		dispatch(setUserLoginData(null));
		navigate(RouteUrl.LOGINPAGE);
		themeHandling(false);
	};
	/**
	 *
	 *  reset password  dropddown button button item click function
	 *  /* @param e- event of function
	 */
	const onResetPasswordChange = (event: any) => {
		const param: any = {
			email: userData?.email,
			baseUrl: `${baseURL}/reset-password`,
			url: 'string',
		};

		setResetPasswordClicked(true);
		event.stopPropagation();
		event.preventDefault();
		setResetPasswordData(param);
	};

	/**
	 *language change  functionality
	 /* @param e- event of function
	 /* setting the language change state to true
	 /* setting event to the corresponding state which stores the language
	 /* i18 default function to switch language
	 */
	const onLanguageChange = (e: any) => {
		setLanguageChanged(true);
		setSelectedLanguage(e);
		// @ts-ignore
		i18next.changeLanguage(e.locale);
	};

	/**
	 * dark and light mdoe switch change functionality
	  /* @param e- event of function a
	  /* setting theme to local state based on  switching the theme
	  /* storing the switched theme to redux
	  /* changing the overall theme based on switching the theme by passing the value 
	  /* changing local state which stores the theme click to true if custom switch clicked
	 */
	const onDarkThemeChange = (e: SwitchChangeEvent) => {
		setDarkThemeEnabled(e.target.value);
		dispatch(setDarkMode(e.target.value));
		themeHandling(e.target.value);
		setDarkThemeClicked(true);
	};

	/**
	 * item rendering based on index of dropdown button item
	 */
	const itemRender = (item: ICustmRenderItm) => {
		switch (item.itemIndex) {
			case 0:
				return (
					<span
						className='click-span profile-click-span'
						role='button'
						tabIndex={0}
						onKeyDown={(e: any) => e.stopPropagation()}
						onClick={(e: any) => e.stopPropagation()}>
						<span className='profile-details'>
							<span className='icon-circle'>
								<ShowIcon name='faUser' />
							</span>
							<span className='profile-name'>
								<span>{userData.firstName}</span>
								<span>{userData.middleName}</span>
								<span>{userData.lastName}</span>
							</span>
						</span>
					</span>
				);
			case 1:
				return (
					<span
						className='click-span d-flex justify-content-start align-items-center with-loader'
						role='button'
						tabIndex={0}
						onKeyDown={(e: any) => onResetPasswordChange(e)}
						onClick={(e: any) => onResetPasswordChange(e)}>
						{item.item.text}
						<span className='loader-primary link-loader'>
							{forgotPasswordLoading && <CustomLoader size='small' />}
						</span>
					</span>
				);
			case 2:
				return (
					<span
						className='click-span d-flex justify-content-between align-items-center'
						role='button'
						tabIndex={0}
						onKeyDown={(e: any) => e.stopPropagation()}
						onClick={(e: any) => e.stopPropagation()}>
						{item.item.text}

						<span className='form-control-switch-btn'>
							<CustomSwitch
								checked={darkThemeEnabled}
								name='darktheme-change'
								disabled={themeSwitchloading}
								handleChange={onDarkThemeChange}
								onLabel=''
								offLabel=''
							/>
						</span>
					</span>
				);
			default:
				return (
					<span
						className='click-span'
						role='button'
						tabIndex={0}
						onKeyDown={() => profileOptionChange()}
						onClick={() => profileOptionChange()}>
						{item.item.text}
					</span>
				);
		}
	};

	/**
	 * / success modal close function
	 */
	const handleClose = () => {
		setResetPasswordModal(false);
	};
	/**
	 * custom  notification  close function
	 */
	const handleNotificationClose = () => {
		setShowNotification(false);
	};
	/**
	 * dropdown button opening function
	 */
	const onDropDownButtonClick = () => {
		setDropDownShow(true);
	};
	/**
	 *  dropdown button close function
	 */
	const onDropDownButtonClose = () => {
		setDropDownShow(false);
	};
	return (
		<>
			<div className='language-switcher-wrapper'>
				<CustomDropdown
					popupSettings={{ popupClass: 'custom-lang-dropdown' }}
					dropdownValue={selectedLanguage}
					handleDropDownChange={onLanguageChange}
					options={languages}
					dataItemKey='encLanguageId'
					dataTextField='label'
				/>
			</div>

			<AppBar className='main-header'>
				<div className='logo-box'>
					<a href={RouteUrl.DASHBOARD}>
						<img src={MainLogo} alt='ACG Logo' />
					</a>
				</div>
				<AppBarSection className='file-failed-circle'>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<div className='position-relative'>
						{hasPrivileges(
							Privileges.FileFailedNotificationListView,
							getLoginUserData,
						) && <FileFailedNotification />}
					</div>
				</AppBarSection>

				<AppBarSection className='notification-circle'>
					{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
					<div className='position-relative'>
						{hasPrivileges(Privileges.NotificationListView, getLoginUserData) && (
							<NotificationPopover />
						)}
					</div>
				</AppBarSection>

				<AppBarSection className='user-profile-circle'>
					<DropDownButton
						onOpen={() => onDropDownButtonClick()}
						onClose={() => onDropDownButtonClose()}
						popupSettings={{
							popupClass: logo
								? 'custom-dropdown profile-dropdown'
								: 'custom-dropdown profile-dropdown header-no-logo',
						}}
						className='header-dropdown'
						items={profileMenuItems}
						text={<ShowIcon name='faUser' />}
						itemRender={itemRender}
						opened={dropdownShow}
					/>
				</AppBarSection>

				{logo && (
					<AppBarSection>
						<img className='right-end-image' src={logo} alt='tenant logo' />
					</AppBarSection>
				)}
			</AppBar>
			{resetPasswordModal && (
				<CustomSuccessModal
					isModalVisible={resetPasswordModal}
					content={t('PASSWORD_RESET_LINK_SUCCESS_MESSAGE')}
					confirmClickNo={handleClose}
				/>
			)}
			{showNotification && (
				<CustomNotification
					message={errorMsg}
					title={errorTitle}
					onClose={handleNotificationClose}
					closable
					type={{ style: 'error', icon: true }}
				/>
			)}
			{notificationClicked && <NotificationPopover />}
		</>
	);
};

export default Header;
