import { useState, useEffect } from 'react';

import AwaitingActionsSkeleton from 'components/common/skeleton/AwaitingActionsSkeleton';
import NoData from 'pages/NoData';
import AwaitingReviewCard from './AwaitingReviewCard';
import useGetAllAwaitingActionStatus from './hooks/useGetAllAwaitingActionStatus';

const AwaitingReview = () => {
	const [isUpdated, setIsUpdated] = useState<boolean>(false);
	useEffect(() => {
		setIsUpdated(true);
	}, []);

	const updateIsUpdated = () => {
		setIsUpdated(false);
	};
	const { awaitingActionsData, loading } = useGetAllAwaitingActionStatus(
		isUpdated,
		updateIsUpdated,
		null,
		null,
		false,
	);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{/* eslint-disable-next-line no-nested-ternary */}
			{loading ? (
				<AwaitingActionsSkeleton />
			) : awaitingActionsData && awaitingActionsData.length > 0 ? (
				<ul className='dsb-card-wrapper'>
					{awaitingActionsData.map((item: any, i: any) => {
						// eslint-disable-next-line react/no-array-index-key
						return (
							// eslint-disable-next-line react/no-array-index-key
							<AwaitingReviewCard key={i} item={item} setIsUpdated={setIsUpdated} />
						);
					})}
				</ul>
			) : (
				<NoData />
			)}
		</>
	);
};
export default AwaitingReview;
