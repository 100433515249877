import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	manufacturerUpdateStatus: false,
	manufacturerStatusUpdate: false,
};

export const manufacturerModuleSlice = createSlice({
	name: 'manufacturerModule',
	initialState,
	reducers: {
		setManufacturerUpdateStatus: (state, action: PayloadAction<any>) => {
			state.manufacturerUpdateStatus = action.payload;
		},
		setManufacturerStatusUpdate: (state, action: PayloadAction<any>) => {
			state.manufacturerStatusUpdate = action.payload;
		},
	},
});

export const { setManufacturerStatusUpdate, setManufacturerUpdateStatus } =
	manufacturerModuleSlice.actions;

export const getManufacturerUpdateStatus = (state: RootState) =>
	state.manufacturerModule.manufacturerUpdateStatus;
export const getManufacturerStatusUpdate = (state: RootState) =>
	state.manufacturerModule.manufacturerStatusUpdate;

export default manufacturerModuleSlice.reducer;
