/**
 * CustomCheckBox component.
 * @param {Object} props - The props passed to the component.
 * @param {Function} props.handleBlur - Callback function triggered when the checkbox loses focus.
 * @param {Function} props.handleFocus - Callback function triggered when the checkbox gains focus.
 * @param {Function} props.handleChange - Callback function triggered when the checkbox value changes.
 * @param {string} props.className - Class name to apply custom styles to the checkbox.
 * @param {string} props.name - The name attribute for the checkbox.
 * @param {boolean} props.disabled - Whether the checkbox is disabled or not.
 * @param {('before'|'after')} [props.labelPlacement] - Placement of the checkbox label relative to the checkbox.
 * @param {string} props.label - The label text to display for the checkbox.
 * @param {boolean} [props.checked=false] - Whether the checkbox is checked or not.
 * @param {boolean} [props.defaultChecked=false] - The default checked state of the checkbox.
 * @param {('small'|'medium'|'large')} [props.size='medium'] - The size of the checkbox.
 * @param {string} props.value - The value attribute for the checkbox.
 * @param {string} props.eachCheckboxName - The name of the checkbox used for identifying individual checkboxes in a group (if applicable).
 * @returns {JSX.Element} The rendered component.
 */
import { memo } from 'react';
import { Checkbox, CheckboxChangeEvent, CheckboxFocusEvent } from '@progress/kendo-react-inputs';

import { CheckboxBlurEvent } from '@progress/kendo-react-inputs/dist/npm/checkbox/interfaces/CheckboxBlurEvent';
import { ICustomCheckBox } from '../model';

const CustomCheckBox = ({
	handleBlur,
	handleFocus,
	handleChange,
	className,
	name,
	disabled,
	labelPlacement,
	label,
	checked,
	defaultChecked,
	size,
	value,
	eachCheckboxName,
}: ICustomCheckBox) => {
	/**
	 * Handle blur event on checkbox. If handleBlur callback is defined, call it.
	 * @param {CheckboxBlurEvent} event - The blur event.
	 */
	const onBlur = (event: CheckboxBlurEvent) => {
		// eslint-disable-next-line no-unused-expressions
		handleBlur ? handleBlur(event) : null;
	};
	/**
	 * Handle focus event on checkbox. If handleFocus callback is defined, call it.
	 * @param {CheckboxFocusEvent} event - The focus event.
	 */
	const onFocus = (event: CheckboxFocusEvent) => {
		// eslint-disable-next-line no-unused-expressions
		handleFocus ? handleFocus(event) : null;
	};
	/**
	 * Handle change event on checkbox.returning event and checkbox name as call back parameter
	 *
	 * @param {CheckboxChangeEvent} event - The change event.
	 */
	const onChange = (event: CheckboxChangeEvent) => {
		handleChange(event, eachCheckboxName);
	};

	return (
		<Checkbox
			onBlur={(event: CheckboxBlurEvent) => onBlur(event)}
			onFocus={(event: CheckboxFocusEvent) => onFocus(event)}
			onChange={(event: CheckboxChangeEvent) => onChange(event)}
			disabled={disabled}
			label={label}
			labelPlacement={labelPlacement || 'before'}
			name={name}
			defaultChecked={defaultChecked || false}
			checked={checked || false}
			value={value}
			size={size || 'medium'}
			className={disabled ? `${className} disabled` : className || ''}
		/>
	);
};
export default memo(CustomCheckBox);
