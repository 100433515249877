import { useState, useEffect, lazy, Suspense, ElementType } from 'react';

import { useTranslation } from 'react-i18next';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import AwaitingActionsContainer from 'components/dashboard/awaitingReview/AwaitingActionsContainer';
import RecentProductContainer from 'components/dashboard/productRecentlyAdded/RecentProductContainer';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, Privileges } from 'utils/enum';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import { getDashboardIconsData, setDashboardIconsData } from 'slices/infoIconsDataSlice';
import { hasPrivileges } from 'utils/utils';
import { getUserLoginData } from 'slices/userLoginDataSlice';

const ScanSummaryContainer: ElementType = lazy(
	() => import('components/dashboard/scanSummary/ScanSummaryContainer'),
);

const TopProducts: ElementType = lazy(() => import('components/dashboard/topProducts/TopProducts'));

const ProductList: ElementType = lazy(() => import('components/dashboard/productList/ProductList'));

const DashBoard = () => {
	const { t } = useTranslation('dashboard');

	const [dashboardInfo, setDashboardInfo] = useState<string>();

	const dispatch = useAppDispatch();
	const getLoginUserData = useAppSelector(getUserLoginData);

	const { iconDescriptionResponse } = useGetIconDescription(ICON_DESCRIPTION_AREA_ID.DASHBOARD);
	const dashboardAreaIconsData = useAppSelector(getDashboardIconsData);

	// sets icon description data in redux
	useEffect(() => {
		if (iconDescriptionResponse) {
			dispatch(setDashboardIconsData(iconDescriptionResponse?.output));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconDescriptionResponse]);

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		dashboardAreaIconsData.length > 0 &&
			// eslint-disable-next-line array-callback-return
			dashboardAreaIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.DASHBOARD) {
					setDashboardInfo(item?.iconDescription);
				}
			});
	}, [dashboardAreaIconsData]);

	return (
		<div className='page-content'>
			<div className='page-header'>
				<h3>
					{t('DASHBOARD')!!}
					<CustomEditableTooltip
						showIcon='faInfoCircle'
						text={dashboardInfo}
						moduleId={ICON_DESCRIPTION_ID.DASHBOARD}
					/>
				</h3>
			</div>
			<div className='page-content-body dashboard-body'>
				<div className=' dsb-left-wrapper'>
					<div className='dsb-left'>
						{hasPrivileges(Privileges.DashboardScanSummary, getLoginUserData) && (
							<div className='box-container dashboard-container dsb-left-box1'>
								<Suspense fallback={<CommonSkeleton />}>
									<ScanSummaryContainer />
								</Suspense>
							</div>
						)}
						{hasPrivileges(Privileges.DashboardTopProducts, getLoginUserData) && (
							<div className='box-container dashboard-container dsb-left-box2'>
								<Suspense fallback={<CommonSkeleton />}>
									<TopProducts />
								</Suspense>
							</div>
						)}
						{hasPrivileges(Privileges.DashboardProductList, getLoginUserData) && (
							<div className='box-container dashboard-container scrollContainer dsb-left-box2'>
								<Suspense fallback={<CommonSkeleton />}>
									<ProductList />
								</Suspense>
							</div>
						)}
					</div>
					<div className='dsb-right'>
						{hasPrivileges(Privileges.DashboardAwaitingAction, getLoginUserData) && (
							<div className='box-container dashboard-container'>
								<AwaitingActionsContainer />
							</div>
						)}
						{hasPrivileges(
							Privileges.DashboardProductRecentlyAdded,
							getLoginUserData,
						) && (
							<div className='box-container dashboard-container'>
								<RecentProductContainer />
							</div>
						)}
					</div>
				</div>
			</div>
		</div>
	);
};
export default DashBoard;
