import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { ADD_LOCATION_IDENTIFIER_TYPE } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR, RESULT_ID_105, WARNING } from '../../../utils/constants';

const useAddIdentifierType = (
	identifierDataList: any,
	isAdded: boolean,
	updateIsAddToDefault: any,
) => {
	const { t } = useTranslation('locationModule');
	const dispatch = useDispatch();

	const [identifierValidationRes, setIdentifierValidationRes] = useState<any>({});
	const [identifierValidationResLoading, setIdentifierValidationResLoading] =
		useState<boolean>(false);

	useEffect(() => {
		if (isAdded) {
			setIdentifierValidationResLoading(true);
			axiosPrivateInstance
				.post(ADD_LOCATION_IDENTIFIER_TYPE, { identifierTypeList: [identifierDataList] })
				.then((response: any) => {
					if (response?.status) {
						setIdentifierValidationRes(response?.output);
					} else {
						dispatch(
							setMessage(t(response?.output?.resultText || 'IDENTIFIER_TYPE_FAILED')),
						);
						dispatch(setShowNotification(true));
						/**
						 * checking error result id to show alert as warning or error
						 */
						if (response?.output?.resultId === RESULT_ID_105) {
							dispatch(setNotificationType(WARNING));
						} else {
							dispatch(setNotificationType(ERROR));
						}
					}
				})
				.catch(() => {
					dispatch(setMessage(t('IDENTIFIER_TYPE_FAILED')));
					dispatch(setNotificationType(ERROR));
					dispatch(setShowNotification(true));
				})
				.finally(() => {
					setIdentifierValidationResLoading(false);
					updateIsAddToDefault();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isAdded]);

	return {
		identifierValidationRes,
		identifierValidationResLoading,
	};
};

export default useAddIdentifierType;
