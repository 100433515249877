import { useEffect, useState } from 'react';
import ProductImageCarousel from 'components/endUserScan/ProductImageCarousel';

import dummyTenantLogo from './assets/TenantLogo';
import dummyMainImage from './assets/TenantImage';
import ScanRecord from './assets/ScanDetails.json';
import DemoScannedProductDetails from './DemoScannedProductDetails';
import ImageSectionFooter from './ImageSectionFooter';

const getProductCode = {
	1: '12342312334561',
	2: '12342312334561',
	3: '12342312334561',
};

const getProductLotNo = {
	1: '123',
	2: '243',
	3: '777',
};

const getProductExpiryDate = {
	1: '08/28/2024 00:00:00',
	2: '12/20/2023 00:00:00',
	3: '08/20/2024 00:00:00',
};

const Index = ({ demoType }) => {
	const productDetails = {
		productStatus: true,
		productName: 'demo1',
		// productDescription: '',
		productCode: getProductCode[demoType],
		manufacturer: 'ACG Inspection Systems Pvt Ltd.',
		address: 'Capsule House, Plot 130, KCIEL, Charkop Kandivali West, Mumbai-400067, India',
		// productGroup: 'Product Group',
		// quantity: '10',
		manufacturingDate: '01/08/2023 00:00:00',
		expiryDate: getProductExpiryDate[demoType],
		// productionDate: '10/08/2023 00:00:00',
		// productCategory: 'Product Category',
		// productOrder: 'Product Order',
		lotNo: getProductLotNo[demoType],
		// orderLocation: 'Order Location',
		scanHistoryCount: 5,
		// brandName: 'Brand Name',
		// licenseNumber: 'License Number',
		isEndUserScanEnabled: true,
		dateFormat: 'MM-yyyy',
		timeFormat: false,
		parentCompanyName: 'ACG Inspection Systems Private Limited',
		manufacturingAndExpiryDateFormat: 'MMM.yyyy',
	};
	const [tenantLogo] = useState<any>(dummyTenantLogo);
	// const [tenantFavIcon] = useState<any>(null);
	const [tenantTitle] = useState<any>('ACG AGRO SCIENCES CLOUD');

	const [statusFieldShow] = useState<boolean>(true);
	const [footerShow] = useState<boolean>(true);

	const [image] = useState<any>(dummyMainImage);

	const [scanHistoryData] = useState<any>(ScanRecord);

	/**
	 * used to update the favicon and title
	 */
	useEffect(() => {
		// const link: any = document.querySelector('link[rel="icon"]');
		// if (tenantFavIcon) {
		// 	link.setAttribute('href', tenantFavIcon);
		// }
		if (tenantTitle) {
			document.title = tenantTitle;
		}

		// return () => {
		// 	link.setAttribute('href', 'data:image/png;base64,');
		// };
	}, [
		// tenantFavIcon,
		tenantTitle,
	]);

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<div
			className={`sc-container end-user-container static-weblink-page ${
				footerShow ? 'with-footer' : ''
			}${!tenantLogo ? ' no-tenant-logo' : ''}`}>
			<div className='first-sc-container'>
				{tenantLogo && (
					<div className='logo-container scanned-products'>
						<img
							className='tenant-scan-logo'
							src={tenantLogo && tenantLogo}
							alt='Logo'
						/>
					</div>
				)}
				<div className='scanned-products-wrapper'>
					<ProductImageCarousel image={image} />
				</div>
			</div>
			<div className='scanner-products-right-wrapper'>
				<div className='custom-scroller'>
					<DemoScannedProductDetails
						productDetails={productDetails}
						scanHistoryData={scanHistoryData}
						statusFieldShow={statusFieldShow}
					/>
					<ImageSectionFooter />
				</div>
			</div>
			{footerShow && (
				<div className='endUser-footer'>
					<label>Powered by ACG Agro Sciences Cloud</label>
				</div>
			)}
		</div>
	);
};

export default Index;
