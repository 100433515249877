import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, MODE_VALUES, RouteUrl } from 'utils/enum';
import { getProductAreaIconsData, setProductAreaIconsData } from 'slices/infoIconsDataSlice';
import { setELeafletFileName } from 'slices/productmoduleSlice';

import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';

import ProductVersionGeneral from './ProductVersionGeneral';

const ProductVersionView = () => {
	const { t } = useTranslation('productModule');
	const dispatch = useAppDispatch();
	const location = useLocation();
	const [viewProductInfo, setViewProductInfo] = useState<string>('');

	const ProductAreaIconsData = useAppSelector(getProductAreaIconsData);
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.PRODUCT,
		ICON_DESCRIPTION_ID.PRODUCT_HISTORY_VIEW,
	);

	useEffect(() => {
		return () => {
			dispatch(setELeafletFileName(null));
		};
	}, [dispatch]);

	// sets icon description data in redux
	useEffect(() => {
		if (iconDescriptionResponse) {
			dispatch(setProductAreaIconsData(iconDescriptionResponse?.output));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconDescriptionResponse]);

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		ProductAreaIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			ProductAreaIconsData?.map((item: any) => {
				if (item?.encIconId === ICON_DESCRIPTION_ID.PRODUCT_ADD) {
					setViewProductInfo(item?.iconDescription);
				}
			});
	}, [ProductAreaIconsData]);

	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setViewProductInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	return (
		<div className='page-content add-product-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton
						url={RouteUrl.PRODUCT_HISTORY_VIEW}
						state={{
							mode: MODE_VALUES.HISTORY,
							productId: location?.state?.productId,
						}}
					/>

					<h3>
						{t('VIEW_PRODUCT_VERSION_DETAILS')}

						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={viewProductInfo}
							moduleId={ICON_DESCRIPTION_ID.PRODUCT_HISTORY_VIEW}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='row add-product-row'>
					<div className='col-xl-12 right-column'>
						<div className='product-form-wrapper theme-bg-color'>
							<form className='default-form-style'>
								<ProductVersionGeneral />
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductVersionView;
