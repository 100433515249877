import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_PRODUCT_VERSION_DETAILS_BY__ID } from 'utils/serviceUrls';

const getAllProductVersionDetailsService = async (params: any) => {
	const paramData = {
		url: 'string',
		agent: 'string',
		encProductId: params.productId,
		encVersionId: params.versionId,
	};
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(GET_ALL_PRODUCT_VERSION_DETAILS_BY__ID, paramData)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default getAllProductVersionDetailsService;
