import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_L4_SYSTEM } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppSelector } from 'hooks/stateHooks';
import { getUpdateL4SubmitButtonStatus, setUpdateL4SubmitButtonStatus } from 'slices/l4SystemSlice';

import { IL4UpdateFormData } from 'components/common/model';

/**
 * Custom hook that handles the logic for updating L4 systems.
 * @param {IL4UpdateFormData} formData - The form data containing information to update the L4 system.
 * @returns {{updateL4Response: any, updateL4loading: boolean}} - An object containing updateL4Response and updateL4loading states.
 */

const useUpdateL4System = (formData: IL4UpdateFormData) => {
	const { t } = useTranslation('l4System');
	const updateL4SubmitButtonStatus = useAppSelector(getUpdateL4SubmitButtonStatus);
	const [updateL4loading, setUpdateL4Loading] = useState<boolean>(false);
	const [updateL4Response, setUpdateL4Response] = useState<any>({});
	const dispatch = useDispatch();

	// Creating a params object with the necessary data for updating the L4 system

	const params: any = {
		encSystemID: formData.encSystemID,
		l4SystemName: formData.l4SystemName,
		address: formData.address,
		encCountryId: formData.country,
		description: formData.description,
		location: formData.l4Location,
		connectionMethodID: formData.selectedConnectionType,
		connectionDetails: {
			host: formData.sftpHost,
			userName: formData.sftpUsername,
			password: formData.sftpPassword,
			portNo: formData.sftpPortNo === '' ? null : formData.sftpPortNo,
			identifier: formData.restApiTenantId,
		},
	};

	// useEffect hook to trigger the L4 system update when the submit button status changes
	useEffect(() => {
		if (updateL4SubmitButtonStatus) {
			setUpdateL4Loading(true);
			axiosPrivateInstance
				.post(UPDATE_L4_SYSTEM, params)
				.then((response: any) => {
					setUpdateL4Response(response);
					if (response?.status) {
						dispatch(setMessage(t('L4_SYSTEM_UPDATED_SUCCESSFULLY')));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(setMessage(t(response?.errorMessage || 'L4_SYSTEM_NOT_UPDATED')));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('L4_SYSTEM_NOT_UPDATED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					dispatch(setShowNotification(true));
					setUpdateL4Loading(false);
					dispatch(setUpdateL4SubmitButtonStatus(false));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateL4SubmitButtonStatus]);

	return {
		updateL4Response,
		updateL4loading,
	};
};

export default useUpdateL4System;
