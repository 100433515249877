import { useAppSelector } from 'hooks/stateHooks';
import { useTranslation } from 'react-i18next';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import { utcFormat, utcTime } from 'utils/utils';

const ViewAllFileFailCard = ({ item }: any) => {
	const { actionDate, fileName, connection, systemName, location } = item;
	const { t } = useTranslation('fileFailure');
	const enable24Hr = useAppSelector(getEnable24Hr);

	const dateFormat = useAppSelector(getDataFormat);
	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<span className='list-title '>
			<b>{actionDate ? utcFormat(actionDate, dateFormat) : t('UNKNOWN')!!}:</b>{' '}
			{t('FILE_FAILED_NOTIFICATION_TEXT1')} <b>{fileName || t('UNKNOWN')!!}</b>{' '}
			{t('FILE_FAILED_NOTIFICATION_TEXT2')} <b>{connection || t('UNKNOWN')!!}</b>{' '}
			{t('FILE_FAILED_NOTIFICATION_TEXT3')}{' '}
			<b>{actionDate ? utcTime(actionDate, enable24Hr) : t('UNKNOWN')!!}</b>.{' '}
			{t('FILE_FAILED_NOTIFICATION_TEXT4')} <b>{systemName || t('UNKNOWN')!!}</b>{' '}
			{t('FILE_FAILED_NOTIFICATION_TEXT5')} <b>{location || t('UNKNOWN')!!}</b>{' '}
		</span>
	);
};
export default ViewAllFileFailCard;
