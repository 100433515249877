import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	RouteUrl,
	SERIAL_NUMBER_TYPE,
} from 'utils/enum';
import { getUniqueString, productFormValidations } from 'utils/utils';

import CustomButton from 'components/common/button/CustomButton';
import CustomDropdown from 'components/common/forms/CustomDropdown';
import CustomInput from 'components/common/forms/CustomInput';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomSwitch from 'components/common/button/CustomSwitch';
import CustomError from 'components/common/errorLabel/CustomError';
import CustomSearchDropDown from 'components/common/forms/CustomSearchDropDown';
import * as constants from '../../utils/constants';
// import useGetAllSerialFilterValue from './hooks/useGetAllSerialFilterValue';
import useAddSerialNumberTemplate from './hooks/useAddSerialNumberTemplate';
import useGetAllTemplateType from './hooks/useGetAllTemplateType';
import useGetAllType from './hooks/useGetAllType';
import useGetAllSerialNumberGTIN from './hooks/useGetAllSerialNumberGTIN';

const AddSerialNumberTemplate = () => {
	const lengthData = constants.SERIAL_NUMBER_TEMPLATE_LENGTH;
	const { t } = useTranslation('serialNumber');
	const navigate = useNavigate();

	const [templateName, setTemplateName] = useState<string>('');
	const [serialNumberSparseness, setSerialNumberSparseness] = useState<string>('');
	const [sequenceStart, setSequenceStart] = useState<string>('');
	// const [gs1CompanyPrefix, setGs1CompanyPrefix] = useState<string>('');

	const [enctemplateTypeId, setEnctemplateTypeId] = useState<string>('');
	const [encTypeId, setEncTypeId] = useState<string>('');
	// const [encFilterValueId, setEncFilterValueId] = useState<string>('');

	const [serialNumberLengthPlaceholder, setSerialNumberLengthPlaceholder] = useState<string>(
		constants.PLEASE_SELECT_TEXT,
	);
	const [serialNumberLength, setSerialNumberLength] = useState<string>('');
	const [serialNumberAddInfo, setSerialNumberAddInfo] = useState<string>('');
	const [sequential, setSequential] = useState<boolean>(false);

	const [fieldErrors, setFieldErrors] = useState<Record<string, string>>({});
	// const [ssccfieldErrors, setSSCCFieldErrors] = useState<Record<string, string>>({});
	const [sequenceStartError, setSequenceStartError] = useState<Record<string, string>>({});

	const [inclusionCharacters, setInclusionCharacters] = useState<string>('');
	const [poolSize, setPoolSize] = useState<string>('');
	const [gtin, setGTIN] = useState<string>('');
	const [allGTINDataList, setAllGTINDataList] = useState<any>([]);
	const [allGTINDataListCopy, setAllGTINDataListCopy] = useState<any>([]);
	const [inclusionCharactersErrors, setInclusionCharactersErrors] = useState<
		Record<string, string>
	>({});

	const [isSerialNumberTemplateSubmitted, setIsSerialNumberTemplateSubmitted] =
		useState<boolean>(false);

	/**
	 * get icon description API
	 */
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.SERIAL_NUMBER_MANAGEMENT,
		ICON_DESCRIPTION_ID.SERIAL_NUMBER_ADD,
	);

	/**
	 * sets icon description data in state
	 */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setSerialNumberAddInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	/**
	 * get template type data API
	 */
	const { templateTypeData, templateTypeDataLoading } = useGetAllTemplateType();

	/**
	 * get type data API
	 */
	const { allTypeData, allTypeDataLoading } = useGetAllType();

	/**
	 * SSCC changes
	 */

	// /**
	//  * get filter value data API
	//  */
	// const { filterValueData } = useGetAllSerialFilterValue();

	// API call for get all gtin data
	const { allGTINData, gtinDataLoading } = useGetAllSerialNumberGTIN();

	/**
	 * gtin dropdown field data setting in state
	 */
	useEffect(() => {
		if (allGTINData && allGTINData?.length > 0) {
			setAllGTINDataList(allGTINData);
			setAllGTINDataListCopy(allGTINData);
		}
	}, [allGTINData]);

	/**
	 * Callback to update the submit trigger state
	 */
	const updateIsSerialNumberTemplateAdded = () => {
		setIsSerialNumberTemplateSubmitted(false);
	};

	/**
	 * Hook to add serial number template
	 */
	const { addSerialNumberTemplateLoading, addSerialNumberTemplateResponse } =
		useAddSerialNumberTemplate(
			isSerialNumberTemplateSubmitted,
			{
				templateName: templateName?.trim(),
				serialNumberSparseness:
					serialNumberSparseness === '' ? null : Number(serialNumberSparseness),
				isSequential: sequential,
				sequenceStart: sequenceStart === '' ? null : Number(sequenceStart),
				encSerialNoTypeId: encTypeId,
				encTemplateTypeId: enctemplateTypeId,
				// gs1CompanyPrefix: gs1CompanyPrefix === '' ? null : gs1CompanyPrefix,
				// encFilterValueId: encFilterValueId === '' ? null : encFilterValueId,
				serialNoLength: serialNumberLength,
				encPackageRelnId: gtin,
				inclusionCharacter: inclusionCharacters,
				poolSize,
			},
			updateIsSerialNumberTemplateAdded,
		);

	/**
	 * when serial NO template added successfully redirecting to list page
	 */
	useEffect(() => {
		if (addSerialNumberTemplateResponse?.status) {
			navigate(RouteUrl.SERIAL_NUMBER_MANAGEMENT);
		}
	}, [addSerialNumberTemplateResponse, navigate]);

	/**
	 *  Template Name change function
	 * @param e passing event into function
	 */
	const handleTemplateName = (e: any) => {
		setFieldErrors((prev) => ({ ...prev, templateName: '' }));
		setTemplateName(e.target.value);
	};

	/**
	 * Serial Number Sparseness change function
	 * @param e event passing to function
	 */
	const handleSerialNumberSparseness = (e: any) => {
		setFieldErrors((prev) => ({ ...prev, serialNumberSparseness: '' }));
		setSerialNumberSparseness(e.target.value);
	};

	/**
	 * Sequence Start change function
	 * @param e event passing to function
	 */
	const handleSequenceStart = (e: any) => {
		setSequenceStartError((prev) => ({ ...prev, sequenceStart: '' }));
		setSequenceStart(e.target.value);
	};

	/**
	 * Template Type  change function
	 * @param e  event passing to function
	 */
	const handleTemplateTypeChange = (e: any) => {
		setFieldErrors((prev) => ({ ...prev, enctemplateTypeId: '' }));
		setEnctemplateTypeId(e);

		// clearing sscc field errors when type changes
		// setSSCCFieldErrors({});
	};

	/**
	 *Type  change functions
	 * @param e  event passing to function
	 */
	const handleTypeChange = (e: any) => {
		setFieldErrors((prev) => ({ ...prev, encTypeId: '' }));
		setEncTypeId(e);

		/**
		 * clearing dynamic field states when type changes
		 */
		setSerialNumberSparseness('');
		setInclusionCharacters('');
		setSequenceStart('');
		setSequential(false);
	};

	/**
	 * SSCC changes
	 */

	// // Filter Value change function
	// const handleFilterValueChange = (e: any) => {
	// 	setSSCCFieldErrors((prev) => ({ ...prev, encFilterValueId: '' }));
	// 	setEncFilterValueId(e);
	// };

	// // Gs1 Company Prefix change function
	// const handleGs1CompanyPrefix = (e: any) => {
	// 	setSSCCFieldErrors((prev) => ({ ...prev, gs1CompanyPrefix: '' }));
	// 	setGs1CompanyPrefix(e.target.value);
	// };

	/**
	 * Sequential change function
	 * @param e  event passing to function
	 */
	const handleSequentialChange = (e: any) => {
		setSequential(e.target.value);
		setSequenceStart('');
		setSequenceStartError((prev) => ({ ...prev, sequenceStart: '' }));
	};

	/**
	 * Serial Number Length Change function
	 * @param e  event passing to function
	 */

	const handleSerialNumberLengthChange = (e: any) => {
		setFieldErrors((prev) => ({ ...prev, serialNumberLength: '' }));
		setSerialNumberLength(e);
		/**
		 * setting placeholder data
		 */
		setSerialNumberLengthPlaceholder(e);
	};

	/**
	 * Inclusion Characters change function
	 * @param e  event passing to function
	 */
	const handleInclusionCharactersChange = (e: any) => {
		/**
		 * using getUniqueString function to prevent string repeatation
		 */
		const validString = getUniqueString(e.target.value);
		setInclusionCharactersErrors((prev) => ({ ...prev, inclusionCharacters: '' }));
		setInclusionCharacters(validString);
	};

	/**
	 * pool size change function
	 * @param e  event passing to function
	 */
	const handlePoolSize = (e: any) => {
		setFieldErrors((prev) => ({ ...prev, poolSize: '' }));
		setPoolSize(e.target.value);
	};

	/**
	 * GTIN change function
	 * @param e  event passing to function
	 */
	const handleGTINChange = (e) => {
		setFieldErrors((prev) => ({ ...prev, gtin: '' }));
		setGTIN(e);
	};

	/**
	 *  GTIN search function
	 * @param e  event passing to function
	 */
	const handleGTINFilter = (e: any) => {
		if (e.length > 0) {
			const searchData =
				allGTINDataListCopy &&
				allGTINDataListCopy?.length > 0 &&
				allGTINDataListCopy?.filter((item: any) =>
					item?.gtinNo?.toLowerCase()?.includes(e.toLowerCase()),
				);
			setAllGTINDataList(searchData || []);
		} else {
			setAllGTINDataList(allGTINDataListCopy);
		}
	};

	// Add Serial Number Template function
	const handleAddSerialNumberTemplate = () => {
		/**
		 * field validation check
		 */
		const errors = productFormValidations(
			{
				templateName,
				serialNumberSparseness,
				enctemplateTypeId,
				encTypeId,
				serialNumberLength,
				poolSize,
				gtin,
			},
			{
				templateName: {
					required: true,
				},
				serialNumberSparseness: {
					numbers: true,
					negativeValue: true,
				},
				enctemplateTypeId: {
					required: true,
				},
				encTypeId: {
					required: true,
				},
				serialNumberLength: {
					required: true,
				},
				poolSize: { required: true, minValue: 1, maxValue: 1000000 },
				gtin: { required: true },
			},
		);

		setFieldErrors(errors);

		// const ssccFieldsErrorMsg = productFormValidations(
		// 	{
		// 		gs1CompanyPrefix,
		// 		encFilterValueId,
		// 	},
		// 	{
		// 		gs1CompanyPrefix: {
		// 			required: true,
		// 			negativeValue: true,
		// 			numbersOnly: true,
		// 			maxlength: 12,
		// 			minlength: 4,
		// 		},
		// 		encFilterValueId: {
		// 			required: true,
		// 		},
		// 	},
		// );
		// setSSCCFieldErrors(ssccFieldsErrorMsg);

		const inclusionCharactersErrorMsg = productFormValidations(
			{
				inclusionCharacters,
			},
			{
				inclusionCharacters: {
					required: true,
					minlength: 5,
					maxlength: 250,
					alphanumericWithSpace: true,
				},
			},
		);
		setInclusionCharactersErrors(inclusionCharactersErrorMsg);

		let sortedInclusionCharactersErrors: any = [];
		if (encTypeId === SERIAL_NUMBER_TYPE.Alphanumeric) {
			sortedInclusionCharactersErrors = { ...inclusionCharactersErrorMsg };
		} else {
			sortedInclusionCharactersErrors = {};
		}

		const sequenceStartErrorMsg = productFormValidations(
			{
				sequenceStart,
			},
			{
				sequenceStart: {
					required: true,
					numbers: true,
					negativeValue: true,
					maxValue: 9,
					minValue: 0,
				},
			},
		);
		setSequenceStartError(sequenceStartErrorMsg);

		// let sortedSSCCFieldsErrors: any = [];
		// if (enctemplateTypeId !== constants.TEMPLATE_TYPE_SSCC_ID) {
		// 	sortedSSCCFieldsErrors = {};
		// } else {
		// 	sortedSSCCFieldsErrors = { ...ssccFieldsErrorMsg };
		// }

		let sortedSequenceStartError: any = [];
		if (!sequential) {
			sortedSequenceStartError = {};
		} else {
			sortedSequenceStartError = { ...sequenceStartErrorMsg };
		}

		/**
		 * state update for API call
		 */

		if (
			Object.keys(errors)?.length === 0 &&
			Object.keys(sortedInclusionCharactersErrors)?.length === 0 &&
			// Object.keys(sortedSSCCFieldsErrors).length === 0 &&
			Object.keys(sortedSequenceStartError)?.length === 0
		) {
			setIsSerialNumberTemplateSubmitted(true);
		}
	};

	return (
		<div className='page-content common-page-content'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{t('ADD_SERIAL_NUMBER_TEMPLATE')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={serialNumberAddInfo}
							moduleId={ICON_DESCRIPTION_ID.SERIAL_NUMBER_ADD}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='box-container common-box-container'>
					<div className='default-form-style top-wrapper'>
						<div className='section-wrapper'>
							<div className='row'>
								<div className='col-xl-6 col-lg-6'>
									<div className='form-group'>
										<CustomDropdown
											popupSettings={{
												popupClass: 'default-select-dropdown',
											}}
											isId
											className='form-control default-select-dropdown'
											dropdownValue={enctemplateTypeId}
											options={templateTypeData}
											label={t('TEMPLATE_TYPE')!!}
											handleDropDownChange={handleTemplateTypeChange}
											dataItemKey='encTemplateTypeId'
											dataTextField='templateType'
											isMandatory
											loading={templateTypeDataLoading}
										/>
										{fieldErrors?.enctemplateTypeId &&
											fieldErrors?.enctemplateTypeId !== '' && (
												<CustomError
													value={fieldErrors?.enctemplateTypeId}
												/>
											)}
									</div>
								</div>

								<div className='col-xl-6 col-lg-6'>
									<div className='form-group'>
										<CustomDropdown
											popupSettings={{
												popupClass: 'default-select-dropdown',
											}}
											isId
											className='form-control default-select-dropdown'
											dropdownValue={encTypeId}
											options={allTypeData}
											label={t('TYPE')!!}
											handleDropDownChange={handleTypeChange}
											dataItemKey='encSerialNoTypeId'
											dataTextField='serialNoType'
											isMandatory
											loading={allTypeDataLoading}
										/>
										{fieldErrors?.encTypeId &&
											fieldErrors?.encTypeId !== '' && (
												<CustomError value={fieldErrors?.encTypeId} />
											)}
									</div>
								</div>

								{encTypeId === SERIAL_NUMBER_TYPE.Alphanumeric && (
									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<CustomInput
												className=''
												value={inclusionCharacters}
												label={t('INCLUSION_CHARACTERS')!!}
												onChange={(e: any) =>
													handleInclusionCharactersChange(e)
												}
												name='inclusionCharacters'
												placeholder={t('INCLUSION_CHARACTERS')!!}
												required
												type='text'
												isMandatory
												error={
													inclusionCharactersErrors?.inclusionCharacters
												}
											/>
										</div>
									</div>
								)}

								{encTypeId === SERIAL_NUMBER_TYPE.Numeric && (
									<>
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<div className='form-control-switch-btn me-0 d-flex justify-content-between align-items-center'>
													{/* eslint-disable-next-line jsx-a11y/label-has-associated-control */}
													<label className='form-label mb-0'>
														{t('SEQUENTIAL')!!}
													</label>

													<CustomSwitch
														checked={sequential}
														className='ms-2'
														name='sequential'
														handleChange={(e) =>
															handleSequentialChange(e)
														}
														onLabel=''
														offLabel=''
													/>
												</div>
											</div>
										</div>
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													className=''
													disabled={!sequential}
													value={sequenceStart}
													label={t('SEQUENCE_START')!!}
													onChange={(e: any) => handleSequenceStart(e)}
													name='sequenceStart'
													placeholder={t('SEQUENCE_START')!!}
													required
													type='number'
													isMandatory={sequential}
													error={
														sequential
															? sequenceStartError?.sequenceStart
															: ''
													}
												/>
											</div>
										</div>

										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													className=''
													value={serialNumberSparseness}
													label={t('SERIAL_NUMBER_SPARSENESS')!!}
													onChange={(e: any) =>
														handleSerialNumberSparseness(e)
													}
													name='serialNumberSparseness'
													placeholder={t('SERIAL_NUMBER_SPARSENESS')!!}
													required
													type='number'
													error={fieldErrors?.serialNumberSparseness}
												/>
											</div>
										</div>
									</>
								)}

								{/* {enctemplateTypeId === constants.TEMPLATE_TYPE_SSCC_ID && (
									<>
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomInput
													className=''
													value={gs1CompanyPrefix}
													label={t('GS1_COMPANY_PREFIX')!!}
													onChange={(e: any) => handleGs1CompanyPrefix(e)}
													name='gs1CompanyPrefix'
													placeholder={t('GS1_COMPANY_PREFIX')!!}
													required
													type='text'
													error={ssccfieldErrors?.gs1CompanyPrefix}
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<CustomDropdown
													popupSettings={{
														popupClass: 'default-select-dropdown',
													}}
													isId
													className='form-control default-select-dropdown'
													dropdownValue={encFilterValueId}
													options={filterValueData}
													label={t('FILTER_VALUE')!!}
													handleDropDownChange={handleFilterValueChange}
													dataItemKey='encFilterValueId'
													dataTextField='filterValue'
													isMandatory
												/>
												{ssccfieldErrors?.encFilterValueId &&
													ssccfieldErrors?.encFilterValueId !== '' && (
														<CustomError
															value={
																ssccfieldErrors?.encFilterValueId
															}
														/>
													)}
											</div>
										</div>
									</>
								)} */}

								<div className='col-xl-6 col-lg-6'>
									<div className='form-group'>
										<CustomSearchDropDown
											popupSettings={{
												popupClass: 'default-select-dropdown',
											}}
											isId
											dropdownValue={gtin}
											options={allGTINDataList}
											handleDropDownChange={handleGTINChange}
											className='form-control default-select-dropdown'
											label={t('GTIN')!!}
											isMandatory
											dataItemKey='encPackageRelnId'
											dataTextField='gtinNo'
											onFilterChange={handleGTINFilter}
											loading={gtinDataLoading}
										/>
										{fieldErrors?.gtin && fieldErrors?.gtin !== '' && (
											<CustomError value={fieldErrors?.gtin} />
										)}
									</div>
								</div>

								<div className='col-xl-6 col-lg-6'>
									<div className='form-group'>
										<CustomDropdown
											popupSettings={{
												popupClass: 'default-select-dropdown',
											}}
											className='form-control default-select-dropdown'
											dropdownValue={serialNumberLengthPlaceholder}
											handleDropDownChange={handleSerialNumberLengthChange}
											options={lengthData}
											label={t('LENGTH')!!}
											isMandatory
										/>
										{fieldErrors?.serialNumberLength &&
											fieldErrors?.serialNumberLength !== '' && (
												<CustomError
													value={fieldErrors?.serialNumberLength}
												/>
											)}
									</div>
								</div>

								<div className='col-xl-6 col-lg-6'>
									<div className='form-group'>
										<CustomInput
											className=''
											value={templateName}
											label={t('TEMPLATE_NAME')!!}
											onChange={(e: any) => handleTemplateName(e)}
											name='templateName'
											placeholder={t('TEMPLATE_NAME')!!}
											required
											type='text'
											error={fieldErrors?.templateName}
											isMandatory
										/>
									</div>
								</div>

								<div className='col-xl-6 col-lg-6'>
									<div className='form-group'>
										<CustomInput
											className=''
											value={poolSize}
											label={t('POOL_SIZE')!!}
											onChange={(e: any) => handlePoolSize(e)}
											name='poolSize'
											placeholder={t('POOL_SIZE')!!}
											required
											type='number'
											error={fieldErrors?.poolSize}
											maxLength={1000000}
											minLength={1}
											isMandatory
										/>
									</div>
								</div>
							</div>
						</div>
					</div>

					<div className='bottom-btn-wrapper'>
						<CustomButton
							buttonClassName='btn btn-outline-primary h-44px icon-add-btn'
							type='button'
							handleClick={handleAddSerialNumberTemplate}
							disabled={addSerialNumberTemplateLoading}
							buttonName={
								addSerialNumberTemplateLoading ? (
									<span className='loader-primary'>
										<CustomLoader />
									</span>
								) : (
									t('CREATE')
								)
							}
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddSerialNumberTemplate;
