import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	locationUpdateStatus: false,
};

export const locationSlice = createSlice({
	name: 'locationModule',
	initialState,
	reducers: {
		setLocationUpdateStatus: (state, action: PayloadAction<any>) => {
			state.locationUpdateStatus = action.payload;
		},
	},
});

export const { setLocationUpdateStatus } = locationSlice.actions;

export const getLocationUpdateStatus = (state: RootState) =>
	state.locationModule.locationUpdateStatus;

export default locationSlice.reducer;
