import ErrorImage from 'assets/images/error-image.svg'; 

const UnAuthorizedAccess = () => {

    return (

        <div className="error-page">
            <div className='error-body text-center'>
                <img src={ErrorImage} alt='Error' />
                <h1>No Access</h1>
            </div>
        </div> 
    )
}
export default UnAuthorizedAccess;