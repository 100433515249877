import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axiosBasicInstance } from 'libs/axios';
import { getToken } from 'utils/utils';
import { GENERATE_OTP } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
/**
 *
 * @param resendClicked -key which indicates resend button is clicked
 * @param mfaUniqueID-unique id
 * @param resetResendClick-function to update the button click change status
 * @returns token
 */
const useResendOtp = (resendClicked: boolean, mfaUniqueID: any, resetResendClick: any) => {
	const token = getToken();
	const dispatch = useDispatch();
	const { t } = useTranslation('l4Module');

	const params = { uniqueId: mfaUniqueID };
	useEffect(() => {
		if (resendClicked) {
			// setResendLoading(true);
			axiosBasicInstance
				.post(GENERATE_OTP, params)
				.then((response: any) => {
					if (response?.status) {
						dispatch(setMessage(t(response?.resultText)));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(setMessage(t('OTP_NOT_GENERATED')));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('OTP_NOT_GENERATED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					resetResendClick();
					dispatch(setShowNotification(true));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resendClicked]);

	return { token };
};

export default useResendOtp;
