import { useTranslation } from 'react-i18next';

import CustomInput from 'components/common/forms/CustomInput';

const ContactDetails = ({
	contactName,
	email,
	telephone,
	handleChange,
	errorState,
	allDisabled,
}: any) => {
	const { t } = useTranslation('manufacturerModule');

	return (
		<div className='row'>
			<div className='col-xl-3 col-lg-3 col-sm-6'>
				<div className='form-group'>
					<CustomInput
						disabled={allDisabled}
						className={
							errorState?.contactName && errorState?.contactName !== ''
								? 'input-validation-error'
								: ''
						}
						value={contactName}
						onChange={handleChange}
						name='contactName'
						label={t('CONTACT_NAME')!!}
						placeholder={t('CONTACT_NAME')}
						required={false}
						type='text'
						error={errorState?.contactName}
						// isMandatory
					/>
				</div>
			</div>

			<div className='col-xl-3 col-lg-3 col-sm-6'>
				<div className='form-group'>
					<CustomInput
						disabled={allDisabled}
						className={
							errorState?.email && errorState?.email !== ''
								? 'input-validation-error'
								: ''
						}
						value={email}
						onChange={handleChange}
						name='email'
						label={t('EMAIL')!!}
						placeholder={t('EMAIL')}
						required={false}
						type='text'
						error={errorState?.email}
						// isMandatory
					/>
				</div>
			</div>

			<div className='col-xl-3 col-lg-3 col-sm-6'>
				<div className='form-group'>
					<CustomInput
						disabled={allDisabled}
						className={
							errorState?.telephone && errorState?.telephone !== ''
								? 'input-validation-error'
								: ''
						}
						value={telephone}
						onChange={handleChange}
						name='telephone'
						label={t('TELEPHONE')!!}
						placeholder={t('TELEPHONE')}
						required={false}
						type='text'
						error={errorState?.telephone}
						// isMandatory
					/>
				</div>
			</div>
		</div>
	);
};

export default ContactDetails;
