import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import * as constants from 'utils/constants';
import { hasPrivileges, productFormValidations } from 'utils/utils';
import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	MODULE_IDS,
	PASSWORD_VERIFICATION_ACTIONS,
	PASSWORD_VERIFICATION_TRANSACTIONS,
	Privileges,
	RouteUrl,
} from 'utils/enum';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import { getModulesRequireAuth } from 'slices/configSlice';

import CustomButton from 'components/common/button/CustomButton';
import CustomInput from 'components/common/forms/CustomInput';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import CustomLoader from 'components/common/loader/CustomLoader';
import ProductSkeleton from 'components/common/skeleton/ProductSkeleton';
import { IVedaPropertiesData, Idata } from 'components/common/model';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import PasswordVerification from 'components/login/PasswordVerification';
import useAddProductGroup from './hooks/useAddProductGroup';
import ProductGroupListBox from './ProductGroupListBox';
import useUpdateProductGroup from './hooks/useUpdateProductGroup';
import useGroupIdDetails from './hooks/useProductGroupIdDetails';
import useGetAllGeneralFields from './hooks/useGetAllGeneralFields';
import IVedaPropertiesListBox from './IVedaPropertiesListBox';

const AddProductGroup = () => {
	const { t } = useTranslation('productGroup');
	const navigate = useNavigate();
	const { loadingGeneralField, generalAttributes } = useGetAllGeneralFields();

	const dispatch = useAppDispatch();
	const location = useLocation();
	const getLoginUserData = useAppSelector(getUserLoginData);
	const [groupName, setgroupName] = useState<string>('');
	const [groupDescription, setgroupDescription] = useState<string>('');
	const [generalField, setgeneralField] = useState<any>([]);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [errorMessage, setErrorMessage] = useState<boolean>(false);
	const [groupData, setGroupData] = useState<any>([]);
	const [isEditSubmitted, setIsEditSubmitted] = useState<boolean>(false);

	const [prevState, setPrevState] = useState<any>({});

	const [groupId, setGroupId] = useState<any>(0);
	const [productAddEditInfo, setProductAddEditInfo] = useState<string>('');
	const [iVedaPropertiesField, setIVedaPropertiesField] = useState<IVedaPropertiesData[]>([]);
	const [iVedaPropertiesSelectedData, setIVedaPropertiesSelectedData] = useState<
		IVedaPropertiesData[]
	>([]);
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);
	const [generalFields, setGeneralFields] = useState<any>([]);
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);

	const resetAddProductGroupStatus = () => {
		setIsSubmitted(false);
	};
	const resetEditProductGroupStatus = () => {
		setIsEditSubmitted(false);
	};

	/**
	 * hook for add product group
	 */
	const { addpGrpResponse, loading } = useAddProductGroup(
		isSubmitted,
		{
			groupName,
			groupDescription,
			generalField,
			iVedaPropertiesField,
		},
		resetAddProductGroupStatus,
	);

	/**
	 * hook for update product group
	 */
	const { updateProductGroupResponse, updateloading } = useUpdateProductGroup(
		isEditSubmitted,
		{
			productGroupName: groupName,
			productDescription: groupDescription,
			generalFieldIds: generalField?.map((item: any) => {
				return item.generalFieldId.toString();
			}),
			iVedaFieldIds: iVedaPropertiesField?.map((item: any) => {
				return item.generalFieldId.toString();
			}),
			encProductGroupId: groupId,
		},
		prevState,
		resetEditProductGroupStatus,
	);

	/**
	 * hook for get all product group by ID
	 */
	const { resData, dataLoading } = useGroupIdDetails(groupId);

	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.PRODUCT_GROUP,
		ICON_DESCRIPTION_ID.PRODUCT_GROUP_ADD_EDIT,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setProductAddEditInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		if (generalAttributes) {
			setGeneralFields(generalAttributes);
		}
	}, [generalAttributes]);

	/**
	 * reseting state function
	 */
	const resetStates = () => {
		setgroupName('');
		setgroupDescription('');
		setErrorState({});
	};

	useEffect(() => {
		if (resData) {
			setgroupName(resData[0]?.productGroup);
			setgroupDescription(resData[0]?.groupDescription);
			setGroupData(resData[0]?.generalFields);
			setIVedaPropertiesSelectedData(resData[0]?.iVedaFields);
			setPrevState({
				productGroupName: resData[0]?.productGroup,
				productDescription: resData[0]?.groupDescription,
				generalFieldIds: resData[0]?.generalFields?.map((item: any) => {
					return item?.generalFieldId?.toString();
				}),
				iVedaFieldIds: resData[0]?.iVedaFields?.map((item: any) => {
					return item?.generalFieldId?.toString();
				}),
				encProductGroupId: groupId,
			});
		}
	}, [groupId, resData]);

	useEffect(() => {
		setGroupId(location.state && location.state?.id);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		if (addpGrpResponse?.status) {
			// setIsSubmitted(false);
			resetStates();
			navigate(RouteUrl.PRODUCTGROUP);
		} else {
			// setIsSubmitted(false);
		}
	}, [addpGrpResponse, dispatch, navigate]);

	useEffect(() => {
		if (updateProductGroupResponse) {
			if (updateProductGroupResponse?.status) {
				// setIsEditSubmitted(false);
				resetStates();
				navigate(RouteUrl.PRODUCTGROUP);
			} else {
				// setIsEditSubmitted(false);
			}
		}
	}, [updateProductGroupResponse, dispatch, navigate]);

	/**
	 * handle change function for group name and group description
	 */
	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
			if (event.target.name === 'groupName') {
				setErrorState((prev) => ({ ...prev, groupName: '' }));
				setgroupName(event.target.value ? event.target.value.toString() : '');
			} else if (event.target.name === 'groupDescription') {
				setErrorState((prev) => ({ ...prev, groupDescription: '' }));
				setgroupDescription(event.target.value ? event.target.value.toString() : '');
			}
		},
		[],
	);

	useEffect(() => {
		if (generalField.length !== 0) {
			setErrorMessage(false);
		}
	}, [generalField]);

	/**
	 * checking validation and submit form
	 */
	const handleSubmit = () => {
		const trimmedGroupName = groupName.trim();
		const trimmedDesc = groupDescription.trim();
		const error = productFormValidations(
			{ groupName: groupName.trim(), groupDescription: groupDescription.trim() },
			{
				groupName: { required: true, maxlength: 150, alphanumericWithSpace: true },
				groupDescription: { required: true, maxlength: 250 },
			},
		);
		setErrorState(error);
		if (generalField.length === 0) {
			setErrorMessage(true);
		} else {
			setErrorMessage(false);
		}
		if (Object.keys(error).length === 0 && generalField.length !== 0) {
			if (groupId !== 0) {
				if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					setIsEditSubmitted(true);
				}
			} else if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
				setIsPasswordVerification(true);
			} else {
				setIsSubmitted(true);
			}
			setgroupName(trimmedGroupName);
			setgroupDescription(trimmedDesc);
		}
	};

	const getSelectedData = (data: Idata[]) => {
		setgeneralField(data);
	};

	const getSelectedIVedaPropertiesData = (data: Idata[]) => {
		setIVedaPropertiesField(data);
	};
	const handleToggle = () => {
		setIsPasswordVerification(false);
	};
	const handleVerificationSuccess = () => {
		// eslint-disable-next-line no-unused-expressions
		groupId === 0 ? setIsSubmitted(true) : setIsEditSubmitted(true);
		handleToggle();
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<div className='page-content add-product-group-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={RouteUrl.PRODUCTGROUP} />
					<h3 className='canvas-title'>
						{groupId !== 0
							? `${t('EDIT_PRODUCT_GROUP')}`
							: `${t('CREATE_PRODUCT_GROUP')}`}

						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={productAddEditInfo}
							moduleId={ICON_DESCRIPTION_ID.PRODUCT_GROUP_ADD_EDIT}
						/>
					</h3>
				</div>
				{hasPrivileges(Privileges.AddProductGroup, getLoginUserData) && (
					<CustomButton
						buttonClassName='btn btn-primary h-44px icon-add-btn'
						disabled={loading || updateloading}
						buttonName={
							loading || updateloading ? (
								<CustomLoader />
							) : (
								// eslint-disable-next-line react/jsx-no-useless-fragment
								t(groupId !== 0 ? `${t('UPDATE')}` : `${t('ADD')}`)
							)
						}
						handleClick={handleSubmit}
						type='button'
					/>
				)}
			</div>
			<div className='page-content-body'>
				<div className='box-container addGroup-box-container'>
					{dataLoading ? (
						<ProductSkeleton />
					) : (
						<div className='addGroup-box-container-inner product-group-scroll'>
							<div className='row'>
								<div className='col-lg-6'>
									<fieldset className='k-form-fieldset default-form-style'>
										<div className='row row-gutter-10px'>
											<div className='col-lg-6 col-sm-6 gutter-10px'>
												<div className='form-group'>
													<CustomInput
														value={groupName}
														name='groupName'
														label={t('NAME_PRODUCT_GROUP')!!}
														placeholder={t('NAME_PRODUCT_GROUP')!!}
														required={false}
														type='text'
														onChange={handleChange}
														isMandatory
														error={errorState.groupName}
														maxLength={
															constants.PRODUCT_GROUP_NAME_LIMIT
														}
														className={
															errorState?.groupName &&
															errorState?.groupName !== ''
																? 'input-validation-error'
																: ''
														}
													/>
												</div>
											</div>
											<div className='col-lg-6 col-sm-6 gutter-10px'>
												<div className='form-group product-description-group'>
													<CustomTextArea
														className={
															errorState?.groupDescription &&
															errorState?.groupDescription !== ''
																? 'input-validation-error'
																: ''
														}
														value={groupDescription}
														placeholder={t('DESCRIPTION')!!}
														label='Description'
														onChange={handleChange}
														error={errorState.groupDescription}
														maxlength={
															constants.PRODUCT_GROUP_DESCRIPTION_LIMIT
														}
														isMandatory
														textAreaName='groupDescription'
														textAreaId='groupDescription'
														required
														cols={30}
														rows={5}
													/>{' '}
												</div>
											</div>
										</div>
									</fieldset>
								</div>
							</div>
							<div className='product-group-list-box'>
								<ProductGroupListBox
									getSelectedData={getSelectedData}
									groupData={groupData}
									generalAttributesData={
										generalFields && generalFields?.generalAttributesList
									}
									loadingGeneralField={loadingGeneralField}
									errorMessage={errorMessage}
								/>
							</div>
							<div className='product-group-list-box style-type-2'>
								<IVedaPropertiesListBox
									getSelectedIVedaPropertiesData={getSelectedIVedaPropertiesData}
									iVedaPropertiesSelectedData={iVedaPropertiesSelectedData}
									iVedaPropertiesAttributesData={
										generalFields && generalFields?.ivedaAttributesList
									}
									loadingiVedaPropertiesField={loadingGeneralField}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			{isPasswordVerification &&
				modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT) && (
					<PasswordVerification
						onVerificationSuccess={handleVerificationSuccess}
						toggleDialog={handleToggle}
						actions={
							groupId === 0
								? PASSWORD_VERIFICATION_ACTIONS.CREATE
								: PASSWORD_VERIFICATION_ACTIONS.EDIT
						}
						transactions={PASSWORD_VERIFICATION_TRANSACTIONS.PRODUCTGROUP}
						referenceId={groupId !== 0 ? groupId : ''}
					/>
				)}
		</div>
	);
};

export default AddProductGroup;
