import { useRef, useState, useEffect } from 'react';

import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import CustomPopover from 'components/tenant/CustomPopover';
import { useAppSelector } from 'hooks/stateHooks';
import { getNotificationCount } from 'slices/notificationSlice';
import NotificationDropdown from './NotificationDropdown';
import useGetNotificationCount from './hooks/useGetNotificationCount';

const NotificationPopover = () => {
	const popoverRef = useRef<any>(null);
	const notificationCount = useAppSelector(getNotificationCount);
	useGetNotificationCount();
	const [showNotificationPopover, setShowNotificationPopover] = useState<boolean>(false);

	// to close the file failed popover on outside click
	useEffect(() => {
		function handleClickOutside(event) {
			const element = document.getElementsByClassName('k-popover-inner');
			const btnElement = document.getElementsByClassName('notification-btn');
			if (
				element[0] &&
				!element[0].contains(event.target) &&
				!btnElement[0].contains(event.target)
			) {
				setShowNotificationPopover(false);
			}
		}

		document.addEventListener('mousedown', handleClickOutside);

		return () => {
			document.removeEventListener('mousedown', handleClickOutside);
		};
	}, []);

	// handle change function of notification icon click

	const onNotificationIconClick = () => {
		setShowNotificationPopover(!showNotificationPopover);
	};
	return (
		<>
			{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<div
				className='notification-btn '
				ref={popoverRef}
				onClick={() => onNotificationIconClick()}>
				<ShowIcon name='faBell' />
				{/* <label>{t('FILE_FAILED')!!}</label> */}
				<span className='position-absolute start-100 translate-middle badge rounded-pill'>
					{notificationCount}
				</span>
			</div>

			<CustomPopover
				className='header-popover'
				anchor={popoverRef.current && popoverRef.current}
				show={showNotificationPopover}
				position='bottom'>
				<NotificationDropdown
					hidePopover={onNotificationIconClick}
					getNotificationCount={getNotificationCount}
				/>
			</CustomPopover>
		</>
	);
};

export default NotificationPopover;
