import { ChangeEvent, useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';
import {
	dateAddition,
	dateSubstract,
	formateDateForApi,
	getFileFailureNotificationSingle,
	hasPrivileges,
} from 'utils/utils';
import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, Privileges, RouteUrl } from 'utils/enum';
import FileFailureListSkeleton from 'components/common/skeleton/FileFailureListSkeleton';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomInput from 'components/common/forms/CustomInput';

import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';
import NoData from 'pages/NoData';
import { searchData } from 'components/common/model';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import CustomButton from 'components/common/button/CustomButton';
import { getFileFailureNotificationCount } from 'slices/fileFailureSlice';
import useGetFileFailureNotification from './hooks/useGetFileFailureNotification';
import * as constants from '../../utils/constants';
import DateRangePickerComponent from '../common/dateRangePicker/DateRangePicker';
import ViewAllFileFailCard from './ViewAllFileFailCard';
import useUpdateFailedFileNotificationClear from './hooks/useUpdateFailedFileNotificationClear';

const ChildListingComponent = ({ item }: any) => {
	return (
		<li>
			<ViewAllFileFailCard item={item} />
		</li>
	);
};

const FileFailureListContainer = () => {
	const { t } = useTranslation('fileFailure');
	const initialType: 'numeric' | 'input' = 'numeric';

	const getLoginUserData = useAppSelector(getUserLoginData);

	/**
	 * notification count for showing clear all button and clear data
	 */
	const notificationCount = useAppSelector(getFileFailureNotificationCount);

	const startDate = dateSubstract(new Date(), constants.LAST_30_DAYS);
	const minDate = dateSubstract(new Date(), constants.LAST_90_DAYS);

	const [failedFileData, setFailedFileData] = useState<any>([]);
	// eslint-disable-next-line no-unused-vars
	const [pageData, setPageData] = useState<any>({ skip: 0, take: constants.SMALLGRIDPAGESIZE });
	const [selectedStartDate, setSelectedStartDate] = useState<any>(startDate);
	const [selectedEndDate, setSelectedEndDate] = useState<any>(new Date());

	const currentDateFormat = useAppSelector(getDataFormat);
	const enable24Hr = useAppSelector(getEnable24Hr);

	const [searchInput, setSearchInput] = useState<string>(''); // search input state
	const [pagerData, setPagerData] = useState<any>([]); // main state for data
	const [searchCopyData, setSearchCopyData] = useState<Array<searchData>>(); // state for search functionality

	/**
	 * pageination state
	 */
	const [pageDataState, setpageDataState] = useState<any>({
		skip: pageData.skip || 0,
		take: pageData.take || constants.SMALLGRIDPAGESIZE,
		buttonCount: 5,
		type: initialType,
		previousNext: true,
	});
	/**
	 * pagination total state
	 */
	const [total, setTotal] = useState<number>(0);

	const [fileFailureGridLoading, setFileFailureGridLoading] = useState<boolean>(true);

	const [isClearAllSubmitted, setIsClearAllSubmitted] = useState<boolean>(false);

	/**
	 * callback function for reset the clicked failed file state
	 */
	const updateIsClearAllSubmitted = () => {
		setIsClearAllSubmitted(false);
	};

	/**
	 * API call for clear file failed notification
	 */
	useUpdateFailedFileNotificationClear(isClearAllSubmitted, updateIsClearAllSubmitted);

	/**
	 * Hook for getting File failure data
	 */
	const { fileFailureNotificationDataRes, fileFailureLoading } = useGetFileFailureNotification(
		true,
		formateDateForApi(selectedStartDate, constants.COMMON_DATE_FORMAT_API),
		formateDateForApi(selectedEndDate, constants.COMMON_DATE_FORMAT_API),
	);
	/**
	 * value for date range picker
	 */
	const [value, setValue] = useState<any>({
		start: startDate,
		end: new Date(),
	});
	const [minDt, setMinDt] = useState<any>(minDate);
	const [maxDt, setMaxDt] = useState<any>(new Date());
	const [fileFailureListInfo, setFileFailureListInfo] = useState<string>('');

	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.DASHBOARD,
		ICON_DESCRIPTION_ID.DASHBOARD_FILE_FAILED,
	);

	/**
	 * sets icon description data in state
	 */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setFileFailureListInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		setTotal(failedFileData?.length);
	}, [failedFileData]);

	/**
	 * Data setting to local state
	 */
	useEffect(() => {
		if (fileFailureNotificationDataRes && fileFailureNotificationDataRes.length > 0) {
			const tempfileData: any = [];
			fileFailureNotificationDataRes.map((item: any) => {
				const tempMessage = getFileFailureNotificationSingle(
					item,
					currentDateFormat,
					enable24Hr,
				).toString();
				tempfileData.push({ ...item, text: tempMessage });
				return false;
			});
			// notificationMsg will return false when response is []
			setFailedFileData(tempfileData);
			setSearchCopyData(tempfileData);
			setPagerData(tempfileData);
		} else {
			setFailedFileData([]);
			setSearchCopyData([]);
			setPagerData([]);
		}
		setFileFailureGridLoading(fileFailureLoading);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileFailureNotificationDataRes, fileFailureLoading]);

	// clearing data when notification clear and when its 0
	useEffect(() => {
		if (notificationCount === 0) {
			setFailedFileData([]);
			setSearchCopyData([]);
			setPagerData([]);
		}
	}, [notificationCount]);

	const getDateRange = (dateRange: any) => {
		setSelectedStartDate(dateRange.start);
		setSelectedEndDate(dateRange.end);
		// minimum date selectable from calander = start date choosen minus 90 days
		// That means  user can choose maximum 90 days period
		const newMinDt = dateSubstract(new Date(dateRange.start), constants.LAST_90_DAYS);
		setMinDt(newMinDt);

		// adjust max date, maximum 90 days from start date
		const newMaxDt = dateAddition(new Date(dateRange.start), constants.LAST_90_DAYS);
		// check if newMaxDt greater than current date
		if (new Date(newMaxDt) > new Date()) {
			setMaxDt(new Date());
		} else setMaxDt(newMaxDt);
	};
	/**
	 * date getting callback function
	 * @param dateRange - object with start date and end date properties
	 */
	const onGetDateChangeHandler = (dateRange: any) => {
		getDateRange(dateRange);
		setValue(dateRange);
		setSearchInput(''); // to clear the search text when date changes
		setSearchCopyData(pagerData);
		const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
		setpageDataState(clearedPagerDataState);
		setTotal(failedFileData?.length);
	};

	/**
	 * search handing fn
	 * @param e : HTMLInputElement
	 */
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const keyword = e.target.value;
		const dataItemKey = ['text'];
		if (keyword !== '') {
			const results =
				pagerData.length > 0 &&
				pagerData?.filter((item: any) => {
					return dataItemKey.some((searchKey) =>
						item[searchKey]?.toLowerCase().includes(keyword.toLowerCase()),
					);
				});

			setSearchCopyData(results);
			const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
			setpageDataState(clearedPagerDataState);
			setTotal(results?.length);
		} else {
			// If the text field is empty, show all users
			setSearchCopyData(pagerData);
			const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
			setpageDataState(clearedPagerDataState);
			setTotal(failedFileData?.length);
		}
		setSearchInput(keyword);
	};

	/**
	 * page change function
	 * @param event
	 */
	const handlePageChange = (event: PageChangeEvent) => {
		// eslint-disable-next-line no-shadow
		const { skip, take } = event;
		setpageDataState({ ...pageDataState, skip, take });
	};

	// function for clear all notification
	const handleClearAllNotificationCount = () => {
		setIsClearAllSubmitted(true);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{hasPrivileges(Privileges.FileFailedNotificationListView, getLoginUserData) && (
				<div className='page-content '>
					<div className='page-header with-right-form'>
						<div className='back-btn-header'>
							<PageBackButton url={RouteUrl.DASHBOARD} />
							<h3>
								{t('FILES_FAILED')!!}
								<CustomEditableTooltip
									showIcon='faInfoCircle'
									text={fileFailureListInfo}
									moduleId={ICON_DESCRIPTION_ID.DASHBOARD_FILE_FAILED}
								/>
							</h3>
						</div>

						<div className='default-form-style tenant-search notification-search-form'>
							<div className='form-group'>
								<DateRangePickerComponent
									getDataRange={onGetDateChangeHandler}
									disabled={false}
									value={value}
									min={minDt}
									max={maxDt}
								/>

								<CustomInput
									placeholder={t('SEARCH_HERE_PLACEHOLDER')}
									type='text'
									value={searchInput}
									name='searchtenant'
									label={t('SEARCH_CARD_LABEL')!!}
									onChange={handleSearchChange}
									required={false}
								/>

								{notificationCount > 0 && (
									<CustomButton
										buttonName={t('CLEAR_ALL')}
										handleClick={handleClearAllNotificationCount}
										buttonClassName='mark-all-read'
										type='button'
									/>
								)}
							</div>
						</div>
					</div>

					<div className='page-content-body pagination-content-body'>
						<div className='box-container pagination-box-container overflow-y-visible'>
							<div className='notification-list-card-wrapper'>
								{fileFailureGridLoading ? (
									<FileFailureListSkeleton />
								) : (
									<div className='inline-list-card file-failed-list '>
										{searchCopyData && searchCopyData.length > 0 ? (
											searchCopyData

												.slice(
													pageDataState.skip,
													pageDataState.take + pageDataState.skip,
												)
												.map((item: any, k: number) => {
													return (
														<ChildListingComponent
															item={item}
															// eslint-disable-next-line react/no-array-index-key
															key={k}
														/>
													);
												})
										) : (
											<NoData />
										)}
									</div>
								)}
							</div>
						</div>

						<Pager
							skip={pageDataState.skip}
							take={pageDataState.take}
							total={total}
							buttonCount={pageDataState.buttonCount}
							type={pageDataState.type}
							previousNext={pageDataState.previousNext}
							onPageChange={handlePageChange}
						/>
					</div>
				</div>
			)}
		</>
	);
};

export default FileFailureListContainer;
