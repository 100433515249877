import { useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';

// eslint-disable-next-line import/no-extraneous-dependencies
import {
	ListBox,
	ListBoxToolbar,
	processListBoxData,
	ListBoxToolbarClickEvent,
	ListBoxItemClickEvent,
} from '@progress/kendo-react-listbox';
import CustomButton from 'components/common/button/CustomButton';
import AddProductGroupSkeleton from 'components/common/skeleton/AddProductGroupSkeleton';

const SELECTED_FIELD = 'selected';

const ProductGroupListBox = ({
	getSelectedData,
	groupData,
	generalAttributesData,
	loadingGeneralField,
	errorMessage,
}: any) => {
	const { t } = useTranslation('productGroup');
	// eslint-disable-next-line no-unused-vars

	const lastSelectedIndex = useRef<number>(0);
	const [state, setState] = useState<any>({
		oldfieldset: [],
		newfieldset: [],
	});

	const [generalAttributes, setGeneralAttributes] = useState<any>([]);

	useEffect(() => {
		if (generalAttributesData) {
			setGeneralAttributes(generalAttributesData);
		}
	}, [generalAttributesData]);

	useEffect(() => {
		setState({ ...state, oldfieldset: generalAttributes });
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [generalAttributes]);

	useEffect(() => {
		if (groupData?.length > 0) {
			// eslint-disable-next-line no-shadow
			const getDifference = (groupData: any) => {
				return generalAttributes?.filter((object1: any) => {
					return !groupData?.some((object2: any) => {
						return object1?.generalFieldId === object2?.generalFieldId;
					});
				});
			};
			const tempset: any = getDifference(groupData);
			setState({ ...state, oldfieldset: tempset, newfieldset: groupData });
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [groupData, generalAttributes]);

	useEffect(() => {
		getSelectedData(state?.newfieldset);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [state]);

	/**
	 * function for selecting all all general attributes
	 */
	const handleAllSelect = () => {
		const allSelect = [...state.oldfieldset];
		if (allSelect?.length) {
			// eslint-disable-next-line no-return-assign
			allSelect?.forEach((item: any) => (item.selected = true));
			setState({
				...state,
				[generalAttributes]: allSelect,
				newfieldset: state?.newfieldset?.map((item: any) => {
					item[SELECTED_FIELD] = false;
					return item;
				}),
			});
		}
		// newfieldset
	};

	/**
	 * function for resetting all general attributes
	 */
	const handleResetAll = () => {
		setState({ ...state, oldfieldset: generalAttributes, newfieldset: [] });
	};

	/**
	 * function for item click
	 * @param event
	 * @param generalAttributes
	 * @param connectedData
	 */
	// eslint-disable-next-line no-shadow
	const handleItemClick = (event: any, generalAttributes: any, connectedData: any) => {
		let last: number = lastSelectedIndex.current;
		const newData = [...state[generalAttributes]];

		const current: any = newData?.findIndex((dataItem) => dataItem === event.dataItem);

		if (!event.nativeEvent.shiftKey) {
			// eslint-disable-next-line no-multi-assign
			lastSelectedIndex.current = last = current;
		}

		if (!event.nativeEvent.ctrlKey) {
			// eslint-disable-next-line no-return-assign
			newData?.forEach((item) => (item.selected = false));
		}
		const select = !event.dataItem.selected;
		if (last <= newData.length - 1) {
			// eslint-disable-next-line no-plusplus
			for (let i = Math.min(last, current); i <= Math.max(last, current); i++) {
				newData[i].selected = select;
			}
		}

		setState({
			...state,
			[generalAttributes]: newData,
			[connectedData]: state[connectedData]?.map((item: any) => {
				item[SELECTED_FIELD] = false;
				return item;
			}),
		});
	};

	/**
	 * function for toolbar click
	 * @param  {ListBoxToolbarClickEvent } e
	 */
	const handleToolBarClick = (e: ListBoxToolbarClickEvent) => {
		const toolName: string = e.toolName || '';
		const result: any = processListBoxData(
			state.oldfieldset,
			state.newfieldset,
			toolName,
			SELECTED_FIELD,
		);
		setState({
			...state,
			oldfieldset: result?.listBoxOneData?.map((item: any) => {
				item.selected = false;
				return item;
			}),
			newfieldset: result?.listBoxTwoData?.map((item: any) => {
				item.selected = false;
				return item;
			}),
		});
	};

	return (
		<div className='list-box-inner'>
			<div className='col-left two-column'>
				<div className='section-header'>
					<h3>
						{t('GENERAL_FIELDS')}
						<span>*</span>
					</h3>
					{errorMessage && (
						<p className='error-msg product-group-error-msg'>{t('SELECT_LIST')!!}</p>
					)}
					<CustomButton
						buttonClassName='btn btn-outline-primary'
						type='button'
						buttonName={t('SELECT_ALL')}
						handleClick={() => handleAllSelect()}
					/>
				</div>
				{loadingGeneralField ? (
					<AddProductGroupSkeleton />
				) : (
					<ListBox
						data={state?.oldfieldset}
						textField='generalFieldName'
						selectedField={SELECTED_FIELD}
						onItemClick={(e: ListBoxItemClickEvent) =>
							handleItemClick(e, 'oldfieldset', 'newfieldset')
						}
						// eslint-disable-next-line react/no-unstable-nested-components
						toolbar={() => {
							return (
								<ListBoxToolbar
									tools={['transferTo', 'transferFrom']}
									data={state?.oldfieldset}
									dataConnected={state?.newfieldset}
									onToolClick={handleToolBarClick}
								/>
							);
						}}
					/>
				)}
			</div>
			<div className='col-right two-column'>
				<div className='section-header header-two'>
					<h3>{t('SELECTED_GENERAL_PROPERTIES')}</h3>
					<CustomButton
						buttonClassName='btn btn-outline-danger'
						type='button'
						buttonName={t('REMOVE_ALL')}
						handleClick={() => handleResetAll()}
					/>
				</div>

				<ListBox
					data={state?.newfieldset}
					textField='generalFieldName'
					selectedField={SELECTED_FIELD}
					onItemClick={(e: ListBoxItemClickEvent) =>
						handleItemClick(e, 'newfieldset', 'oldfieldset')
					}
				/>
			</div>
		</div>
	);
};

export default ProductGroupListBox;
