import LoginLogo from 'assets/images/ALSC-Logo-menu.svg';
import ResetPassword from '../components/login/ResetPassword';

const ResetPasswordWrapper = () => {
	return (
		<div className='login-form reset-form'>
			<div className='logo-box-sm'>
				<img src={LoginLogo} alt='Panax Pharma' />
			</div>
			<ResetPassword />
		</div>
	);
};
export default ResetPasswordWrapper;
