import { useTranslation } from 'react-i18next';

import Index from 'components/login/Index';
import LoginLogo from 'assets/images/ALSC-Logo-menu.svg';

const Login = () => {
	const { t } = useTranslation('userModule');
	return (
		<div className='login-form'>
			<div className='logo-box-sm'>
				<img src={LoginLogo} alt='Panax Pharma' />
			</div>
			<h3>{t('HELLO')}</h3>
			<h6>{t('SIGN_IN')}</h6>
			<Index />
		</div>
	);
};
export default Login;
