import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';
import * as constants from '../utils/constants';

const initialState: any = {
	l4SystemUpdateStatus: false,
	l4SystemStatusUpdate: false,
	selectedConnectionType: constants.SFTP,
	connectionMethodHost: '',
	connectionMethodUsername: '',
	connectionMethodPassword: '',
	connectionMethodPortno: '',
	connectionMethodTenantid: '',
	l4SystemName: '',
	address: '',
	country: '',
	description: '',
	l4Location: '',
	createL4SubmitButtonStatus: false,
	updateL4SubmitButtonStatus: false,
	l4SystemStatusChangeToggle: false,
	currentL4SystemStatus: {
		encSystemID: '',
		isActive: false,
	},
	l4systemUpdatedResStatus: false,
	sftpPasswordErrorMsg: '',
	statusUpdateloading: false,
};

export const l4SystemModuleSlice = createSlice({
	name: 'l4SystemModule',
	initialState,
	reducers: {
		setL4SystemUpdateStatus: (state, action: PayloadAction<any>) => {
			state.l4SystemUpdateStatus = action.payload;
		},
		setl4SystemStatusUpdate: (state, action: PayloadAction<any>) => {
			state.l4SystemStatusUpdate = action.payload;
		},
		setSelectedConnectionType: (state, action: PayloadAction<any>) => {
			state.selectedConnectionType = action.payload;
		},
		setConnectionMethodHost: (state, action: PayloadAction<any>) => {
			state.connectionMethodHost = action.payload;
		},
		setConnectionMethodUsername: (state, action: PayloadAction<any>) => {
			state.connectionMethodUsername = action.payload;
		},
		setConnectionMethodPassword: (state, action: PayloadAction<any>) => {
			state.connectionMethodPassword = action.payload;
		},
		setConnectionMethodPortno: (state, action: PayloadAction<any>) => {
			state.connectionMethodPortno = action.payload;
		},
		setConnectionMethodTenantid: (state, action: PayloadAction<any>) => {
			state.connectionMethodTenantid = action.payload;
		},
		setL4SystemName: (state, action: PayloadAction<any>) => {
			state.l4SystemName = action.payload;
		},
		setAddress: (state, action: PayloadAction<any>) => {
			state.address = action.payload;
		},
		setCountry: (state, action: PayloadAction<any>) => {
			state.country = action.payload;
		},
		setDescription: (state, action: PayloadAction<any>) => {
			state.description = action.payload;
		},
		setL4Location: (state, action: PayloadAction<any>) => {
			state.l4Location = action.payload;
		},

		setCreateL4SubmitButtonStatus: (state, action: PayloadAction<any>) => {
			state.createL4SubmitButtonStatus = action.payload;
		},
		setUpdateL4SubmitButtonStatus: (state, action: PayloadAction<any>) => {
			state.updateL4SubmitButtonStatus = action.payload;
		},
		setL4SystemStatusChangeToggle: (state, action: PayloadAction<any>) => {
			state.l4SystemStatusChangeToggle = action.payload;
		},
		setCurrentL4SystemStatus: (state, action: PayloadAction<any>) => {
			state.currentL4SystemStatus = action.payload;
		},
		setL4systemUpdatedResStatus: (state, action: PayloadAction<any>) => {
			state.l4systemUpdatedResStatus = action.payload;
		},
		setSftpPasswordErrorMsg: (state, action: PayloadAction<any>) => {
			state.sftpPasswordErrorMsg = action.payload;
		},

		setStatusUpdateLoading: (state, action: PayloadAction<any>) => {
			state.statusUpdateloading = action.payload;
		},
	},
});

export const {
	setl4SystemStatusUpdate,
	setL4SystemUpdateStatus,
	setSelectedConnectionType,
	setConnectionMethodHost,
	setConnectionMethodUsername,
	setConnectionMethodPassword,
	setConnectionMethodPortno,
	setConnectionMethodTenantid,
	setL4SystemName,
	setAddress,
	setCountry,
	setDescription,
	setL4Location,
	setCreateL4SubmitButtonStatus,
	setUpdateL4SubmitButtonStatus,
	setL4SystemStatusChangeToggle,
	setCurrentL4SystemStatus,
	setL4systemUpdatedResStatus,
	setSftpPasswordErrorMsg,
	setStatusUpdateLoading,
} = l4SystemModuleSlice.actions;

export const getL4SystemUpdateStatus = (state: RootState) =>
	state.l4SystemModule.l4SystemUpdateStatus;
export const getl4SystemStatusUpdate = (state: RootState) =>
	state.l4SystemModule.l4SystemStatusUpdate;
export const getSelectedConnectionType = (state: RootState) =>
	state.l4SystemModule.selectedConnectionType;
export const getConnectionMethodHost = (state: RootState) =>
	state.l4SystemModule.connectionMethodHost;
export const getConnectionMethodUsername = (state: RootState) =>
	state.l4SystemModule.connectionMethodUsername;
export const getConnectionMethodPassword = (state: RootState) =>
	state.l4SystemModule.connectionMethodPassword;
export const getConnectionMethodPortno = (state: RootState) =>
	state.l4SystemModule.connectionMethodPortno;
export const getConnectionMethodTenantid = (state: RootState) =>
	state.l4SystemModule.connectionMethodTenantid;
export const getL4SystemName = (state: RootState) => state.l4SystemModule.l4SystemName;
export const getAddress = (state: RootState) => state.l4SystemModule.address;
export const getCountry = (state: RootState) => state.l4SystemModule.country;
export const getDescription = (state: RootState) => state.l4SystemModule.description;
export const getL4Location = (state: RootState) => state.l4SystemModule.l4Location;

export const getCreateL4SubmitButtonStatus = (state: RootState) =>
	state.l4SystemModule.createL4SubmitButtonStatus;
export const getUpdateL4SubmitButtonStatus = (state: RootState) =>
	state.l4SystemModule.updateL4SubmitButtonStatus;
export const getL4SystemStatusChangeToggle = (state: RootState) =>
	state.l4SystemModule.l4SystemStatusChangeToggle;
export const getCurrentL4SystemStatus = (state: RootState) =>
	state.l4SystemModule.currentL4SystemStatus;
export const getL4systemUpdatedResStatus = (state: RootState) =>
	state.l4SystemModule.l4systemUpdatedResStatus;
export const getSftpPasswordErrorMsg = (state: RootState) =>
	state.l4SystemModule.sftpPasswordErrorMsg;
export const getStatusUpdateLoading = (state: RootState) =>
	state.l4SystemModule.statusUpdateloading;

export default l4SystemModuleSlice.reducer;
