import { useEffect, useRef, useState } from 'react';
import { useNavigate } from 'react-router-dom';
// import { useTranslation } from 'react-i18next';

import { axiosBasicInstance } from 'libs/axios';
import { GET_END_USER_SCANNED_DATA_URL } from 'utils/serviceUrls';
// import { useAppDispatch } from 'hooks/stateHooks';
import {
	getBatteryStatus,
	getBrowserVendor,
	getDevice,
	getIsMobileOrNot,
	getMobilePlatform,
	getNetworkInfo,
	getPlatform,
	getTimeZone,
	getUserAgent,
	showOrientation,
} from 'utils/endUserUtils';
import { STATUS_CODE_500 } from 'utils/constants';
// import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { RouteUrl } from 'utils/enum';
import useGetGeoLocation from './useGetGeoLocation';

const useGetEndUserScannedData = (formData: any) => {
	const navigate = useNavigate();
	// const { t } = useTranslation('endUserScan');
	// const dispatch = useAppDispatch();

	const [endUserScanDataRes, setEndUserScanDataRes] = useState<any>({});
	const [endUserScanLoading, setEndUserScanLoading] = useState<boolean>(true);
	const orientationRef = useRef<string | null>('');

	/**
	 * API call for get geo-location datas
	 */
	const { geoLocationData, loader } = useGetGeoLocation();

	/**
	 * params for API call
	 */
	const params = {
		weblink: formData?.currentWeblink,

		/**
		 * device info params
		 */
		deviceOrientation: '',
		networkInfo: '',
		batteryStatus: '',
		browserVendor: '',
		operatingSystem: '',
		mobilePlatform: '',
		deviceType: '',
		deviceTimeZone: '',
		isMobileDevice: false,
		userAgent: '',
		countryCode: '',
		countryName: '',
		cityName: '',
		postal: '',
		iPv4: '',
		state: '',
		ipLatitude: '',
		ipLongitude: '',

		/**
		 * commenting because location details passing through axios
		 */
		// latitude: '',
		// longitude: '',
	};
	// function to handle null value
	const nullChecker = (value: any) => {
		if (value === 'N/A' || value === 'Not found') {
			return null;
		}
		return value;
	};
	const coreFn = async () => {
		setEndUserScanLoading(true);
		const networkInfo = getNetworkInfo();
		const battery = await getBatteryStatus();
		const browserVendor = getBrowserVendor();
		const devicePlatform = getPlatform();
		const mobilePlatform = getMobilePlatform();
		const deviceType = getDevice();
		const deviceTimeZone = getTimeZone();
		const isMobileDevice = getIsMobileOrNot();
		const userAgent = getUserAgent();
		const countryCode = geoLocationData?.country_code ? geoLocationData?.country_code : 'N/A';
		const countryName = geoLocationData?.country_name ? geoLocationData?.country_name : 'N/A';
		const cityName = geoLocationData?.city ? geoLocationData?.city : 'N/A';
		const postal = geoLocationData?.postal ? geoLocationData?.postal : 'N/A';
		const iPv4 = geoLocationData?.IPv4 ? geoLocationData?.IPv4 : 'N/A';
		const state = geoLocationData?.state ? geoLocationData?.state : 'N/A';
		const ipLatitude = geoLocationData?.latitude ? geoLocationData?.latitude : 'N/A';
		const ipLongitude = geoLocationData?.longitude ? geoLocationData?.longitude : 'N/A';

		if (
			networkInfo &&
			battery &&
			browserVendor &&
			devicePlatform &&
			mobilePlatform &&
			deviceType &&
			deviceTimeZone &&
			countryCode &&
			countryName &&
			cityName &&
			postal &&
			iPv4 &&
			state &&
			!loader
		) {
			params.batteryStatus = nullChecker(battery);
			params.browserVendor = nullChecker(browserVendor);
			params.operatingSystem = nullChecker(devicePlatform);
			params.mobilePlatform = nullChecker(mobilePlatform);
			params.deviceType = nullChecker(deviceType);
			params.deviceTimeZone = nullChecker(deviceTimeZone);
			params.isMobileDevice = isMobileDevice;
			params.userAgent = nullChecker(userAgent);
			params.countryCode = nullChecker(countryCode);
			params.countryName = nullChecker(countryName);
			params.cityName = nullChecker(cityName);
			params.postal = nullChecker(postal);
			params.iPv4 = nullChecker(iPv4);
			params.state = nullChecker(state);
			params.ipLatitude = nullChecker(ipLatitude);
			params.ipLongitude = nullChecker(ipLongitude);
			params.networkInfo = nullChecker(networkInfo);
			params.deviceOrientation = nullChecker(orientationRef.current);

			axiosBasicInstance
				.post(GET_END_USER_SCANNED_DATA_URL, params)
				.then((response: any) => {
					setEndUserScanLoading(false);

					if (response?.output && response?.status) {
						setEndUserScanDataRes(response?.output);
					} else if (!response?.status && response?.statusCode === STATUS_CODE_500) {
						navigate(RouteUrl.PAGE_404);
					} else {
						// page redirect when any error occur in API
						navigate(RouteUrl.PAGE_404);

						// dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						// dispatch(setShowNotification(true));
						// dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					setEndUserScanLoading(false);
					// page redirect when any error occur in API
					navigate(RouteUrl.PAGE_404);

					// dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					// dispatch(setShowNotification(true));
					// dispatch(setNotificationType('error'));
				});
		}
	};

	const showOrientationFn = (e: any) => {
		const orRes = showOrientation(e);
		if (orRes) {
			orientationRef.current = orRes;
		} else orientationRef.current = null;
	};

	useEffect(() => {
		window.addEventListener('deviceorientation', showOrientationFn);
		coreFn();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loader]);

	return {
		endUserScanDataRes,
		endUserScanLoading,
	};
};

export default useGetEndUserScannedData;
