import { useCallback, useEffect, useState } from 'react';
import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_WEBLINK_DETAILS } from 'utils/serviceUrls';

const useGetAllWebLinks = (params: any) => {
	const [weblinkList, setWeblinkList] = useState<any>(null);
	const [loading, setLoading] = useState<boolean>(true);

	const apiCall = useCallback(() => {
		setLoading(true);
		axiosPrivateInstance
			.post(GET_ALL_WEBLINK_DETAILS, params)
			.then((response: any) => {
				setWeblinkList(response.output);
				setLoading(false);
			})
			.catch(() => {
				setLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		apiCall();
	}, [apiCall]);

	return {
		weblinkList,
		loading,
	};
};

export default useGetAllWebLinks;
