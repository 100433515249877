import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { CANCELED_ERROR } from 'utils/constants';
import { axiosPrivateInstance } from 'libs/axios';
import { GET_SERIAL_NUMBER_GTIN_NUMBER_BY_SEARCH_TEXT } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetAllSerialNumberGTIN = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('serialNumber');

	const [allGTINData, setAllGTINData] = useState<any[]>([]);
	const [gtinDataLoading, setGTINDataLoading] = useState<boolean>(false);

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;

		setGTINDataLoading(true);
		axiosPrivateInstance
			.post(GET_SERIAL_NUMBER_GTIN_NUMBER_BY_SEARCH_TEXT, { searchText: '' }, { signal })
			.then((response: any) => {
				if (response?.output && response?.status) {
					setAllGTINData(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch((error: any) => {
				if (error?.name === CANCELED_ERROR) {
					return;
				}
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setGTINDataLoading(false);
			});

		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		allGTINData,
		gtinDataLoading,
	};
};

export default useGetAllSerialNumberGTIN;
