/**
 * Component to display an editable tooltip with a text editor.
 * @param {Object} props - The props passed to the component.
 * @param {string} showIcon - The name of the icon to show in the tooltip.
 * @param {string} text - The text content to display in the tooltip.
 * @param {string} moduleId - The ID of the module associated with the tooltip content.
 */

import { memo, useEffect, useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditorUtils } from '@progress/kendo-react-editor';

import { useAppSelector } from 'hooks/stateHooks';
import PasswordVerification from 'components/login/PasswordVerification';
import { hasPrivileges } from 'utils/utils';
import { Privileges } from 'utils/enum';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import { EDITOR_MAX_LENGTH, EDITOR_TEXT_REQUIRED } from 'utils/constants';
import ShowIcon from '../fontAwesome/ShowIcon';
import KendoTextEditor from './KendoTextEditor';
import CustomButton from '../button/CustomButton';
import useUpdateInfoIconData from './hooks/useUpdateInfoIconData';
import CustomError from '../errorLabel/CustomError';

const CustomEditableTooltip = ({ showIcon, text, moduleId }: any) => {
	const { t } = useTranslation('userModule');

	/**
	 * Ref to the OverlayTrigger component.
	 */
	const tooltipRef = useRef<any>(null);
	const [editMode, setEditMode] = useState<boolean>(false);
	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const [editedText, setEditedText] = useState<any>();
	const [isUpdated, setIsupdated] = useState<boolean>(false);
	const [editorError, setEditorError] = useState<string>('');

	const getLoginUserData = useAppSelector(getUserLoginData);

	/**
	 * Callback function to reset edit mode and tooltip visibility after successful update.
	 */
	const updateIsSave = () => {
		setIsupdated(false);
		setShowTooltip(false);
		setEditMode(false);
	};

	/**
	 * Custom hook for updating icon description data.
	 */
	const { infoIconDataLoading } = useUpdateInfoIconData(
		moduleId,
		editedText,
		isUpdated,
		updateIsSave,
	);

	/**
	 * @param e event for Callback function to handle edit button click.
	 */
	const onEditButtonClick = (e: any) => {
		e.stopPropagation();
		setEditMode(!editMode);
	};

	/**
	 * Callback function to handle tooltip close.
	 * @param e event value
	 */
	const tooltipClose = (e: any) => {
		if (showTooltip === true) {
			e.stopPropagation();
			setShowTooltip(false);
			setEditMode(false);
		} else {
			e.stopPropagation();
			setShowTooltip(true);
		}
	};

	/**
	 * Listen for click events outside the OverlayTrigger to close the tooltip.
	 */
	// eslint-disable-next-line consistent-return
	useEffect(() => {
		const handleOutsideClick = (e: any) => {
			// Check if the click target is outside the OverlayTrigger component
			if (tooltipRef.current && !tooltipRef.current.contains(e.target)) {
				setShowTooltip(false);
			}
		};
		if (!editMode) {
			// Attach the click event listener for outside click
			document.addEventListener('click', handleOutsideClick);
			return () => {
				document.removeEventListener('click', handleOutsideClick);
			};
		}
	}, [editMode]);

	/**
	 * Callback function to handle the password verification toggle.
	 */
	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	/**
	 * Editor handle change
	 * @param editor
	 */
	const updateText = (editor: any) => {
		// if password verification needed
		// setIsPasswordVerification(true);
		const { state } = editor.view;
		const { doc } = state;
		const contentLength = doc.textContent.length;

		if (contentLength > EDITOR_MAX_LENGTH) {
			setEditorError(t('MAXIMUM_500_CHARACTERS_ALLOWED')!!);
		} else if (contentLength === EDITOR_TEXT_REQUIRED) {
			setEditorError(t('FIELD_REQUIRED')!!);
		} else {
			setEditorError('');
			if (editor) {
				const { view } = editor;
				if (view) {
					setEditedText(EditorUtils.getHtml(view.state));
				}
			}
			setIsupdated(true);
		}
	};

	/**
	 * setting error state to empty string after validation
	 */
	const handleErrorUpdate = () => {
		setEditorError('');
	};

	/**
	 * Toggle password verification success
	 */
	const handleVerificationSuccess = () => {
		handleToggle();
	};

	return (
		<>
			<OverlayTrigger
				show={showTooltip}
				trigger='click'
				placement='auto'
				overlay={
					<Tooltip id='tooltip2' className={editMode ? 'editable-tooltip-wrapper' : ''}>
						<div className='tooltip-wrapper'>
							{/* Setting html content to tooltip */}
							{text !== ''
								? !editMode && (
										<div
											className='content'
											dangerouslySetInnerHTML={{ __html: text }}
										/>
								  )
								: !editMode && (
										// text
										<div
											className='content'
											dangerouslySetInnerHTML={{ __html: text }}
										/>
								  )}

							{/* checking privilege for edit info */}
							{hasPrivileges(Privileges.IconDescriptionEdit, getLoginUserData) &&
								!editMode && (
									// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
									<span className='ms-2' onClick={(e) => onEditButtonClick(e)}>
										<ShowIcon name='faEdit' />
									</span>
								)}
							{editMode && (
								<div className='editable-tooltip-content'>
									<CustomButton
										buttonClassName='btn btnClose'
										type='button'
										handleClick={tooltipClose}
										icon='faClose'
									/>
									<KendoTextEditor
										text={text}
										updateText={updateText}
										infoIconDataLoading={infoIconDataLoading}
										handleErrorUpdate={handleErrorUpdate}
									/>
									<div style={{ color: 'red' }}>
										{editorError && editorError !== '' && (
											<CustomError value={editorError} />
										)}
									</div>
								</div>
							)}
						</div>
					</Tooltip>
				}>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
				<span className='info-icon' ref={tooltipRef} onClick={(e) => tooltipClose(e)}>
					<ShowIcon name={showIcon !== '' ? showIcon : 'faInfoCircle'} prefix='fad' />
				</span>
			</OverlayTrigger>
			{isPasswordVerification && (
				<PasswordVerification
					onVerificationSuccess={handleVerificationSuccess}
					toggleDialog={handleToggle}
				/>
			)}
		</>
	);
};
export default memo(CustomEditableTooltip);
