import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_FILE_FAILURE_NOTI_URL } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import * as constants from '../../../utils/constants';
import { ERROR } from '../../../utils/constants';

const useGetFileFailureNotification = (
	viewAllNotifications: boolean,
	startDate: any,
	endDate: any,
) => {
	const { t } = useTranslation('fileFailure');
	const dispatch = useAppDispatch();

	const [fileFailureNotificationDataRes, setFileFailureNotificationDataRes] = useState<any>({});
	const [fileFailureLoading, setFileFailureLoading] = useState<boolean>(true);

	const params = {
		fromDate: startDate,
		toDate: endDate,
		viewAll: viewAllNotifications,
	};

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;
		if (
			(startDate === null && endDate === null) ||
			(endDate !== null && endDate !== constants.INVALID_DATE_TXT)
		) {
			setFileFailureLoading(true);
			axiosPrivateInstance
				.post(GET_FILE_FAILURE_NOTI_URL, params, { signal })
				.then((response: any) => {
					if (response?.output && response?.status) {
						setFileFailureNotificationDataRes(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType(ERROR));
					}
				})
				.catch((error: any) => {
					if (error?.name === constants.CANCELED_ERROR) {
						return;
					}
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setFileFailureLoading(false);
				});
		}
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [startDate, endDate]);

	return {
		fileFailureNotificationDataRes,
		fileFailureLoading,
	};
};

export default useGetFileFailureNotification;
