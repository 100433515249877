import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const AuditTrial = lazy(() => import('components/report/AuditTrial'));

const AuditTrialPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<AuditTrial />
			</Suspense>
		</div>
	);
};
export default AuditTrialPage;
