import { useState, memo, useEffect } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID } from 'utils/enum';

import { ListView, ListViewEvent } from '@progress/kendo-react-listview';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import NoData from 'pages/NoData';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import useGetSerialNumberList from './hooks/useGetSerialNumberList';

const POSerialNumberList = () => {
	// States
	const location = useLocation();
	const { t } = useTranslation('batchPOModule');

	const [poEncLotId, setPOEncLotId] = useState<any>('');
	const [serialNumberInfo, setSerialNumberInfo] = useState<string>('');
	const [serialNumberData, setSerialNumberData] = useState<any>([]);
	const [serialNumberCopy, setSerialNumberCopy] = useState<any>([]);

	// API call for get serial number list
	const { serialNumberListData, serialNumberListLoading } = useGetSerialNumberList(poEncLotId);

	// API call for get icon description
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.PRODUCT_ORDER,
		ICON_DESCRIPTION_ID.PO_SERIAL_NUMBER_LIST,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			setSerialNumberInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	// eslint-disable-next-line react/no-unstable-nested-components
	const MyCustomStateCell = (props: any) => {
		return (
			<div className='k-listview-item'>
				<div className='form-group'>
					<ul>
						<li>{props?.dataItem?.serialNo}</li>
					</ul>
				</div>
			</div>
		);
	};

	useEffect(() => {
		if (location?.state) {
			setPOEncLotId(location?.state?.encLotNoId);
		}
	}, [location]);

	useEffect(() => {
		if (serialNumberListData) {
			setSerialNumberCopy(serialNumberListData?.serialNoList);
		}
	}, [serialNumberListData]);

	useEffect(() => {
		if (serialNumberCopy) {
			setSerialNumberData(serialNumberCopy.splice(0, 50));
		}
	}, [serialNumberCopy]);

	const scrollHandler = (event: ListViewEvent) => {
		const e = event.nativeEvent;
		if (e.target.scrollTop + 25 >= e.target.scrollHeight - e.target.clientHeight) {
			const moreData = serialNumberCopy.splice(0, 15);
			if (moreData.length > 0) {
				setSerialNumberData(serialNumberData.concat(moreData));
			}
		}
	};

	return (
		<div className='page-content common-page-content'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3 className='canvas-title'>
						{t('SERIAL_NUMBER_LIST')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={serialNumberInfo}
							moduleId={ICON_DESCRIPTION_ID.PO_SERIAL_NUMBER_LIST}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='box-container common-box-container serial-number-list-container'>
					{serialNumberListLoading ? (
						<CommonSkeleton />
					) : (
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>
							{serialNumberData && serialNumberData?.length > 0 ? (
								<ListView
									onScroll={scrollHandler}
									data={serialNumberData && serialNumberData}
									item={MyCustomStateCell}
									className='po-weblink-wrap'
								/>
							) : (
								<NoData />
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};
export default memo(POSerialNumberList);
