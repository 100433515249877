import { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { OverlayTrigger, Tooltip } from 'react-bootstrap';
import { EditorUtils } from '@progress/kendo-react-editor';

import { EDITOR_MAX_LENGTH } from 'utils/constants';

import KendoTextEditor from 'components/common/tooltip/KendoTextEditor';
import CustomError from 'components/common/errorLabel/CustomError';
import CustomButton from 'components/common/button/CustomButton';

const ProductNameSuperscriptEditor = ({
	productNameSuperscript,
	updateProductNameSuperscript,
}: any) => {
	const { t } = useTranslation('userModule');

	/**
	 * Ref to the OverlayTrigger component.
	 */
	const tooltipRef = useRef<any>(null);

	const [showTooltip, setShowTooltip] = useState<boolean>(false);
	const [editorError, setEditorError] = useState<string>('');

	const handleProductNameSuperscript = (e) => {
		e?.stopPropagation();
		setShowTooltip(true);
	};

	/**
	 * Callback function to handle tooltip close.
	 * @param e event value
	 */
	const tooltipClose = (e: any) => {
		e.stopPropagation();
		setShowTooltip(false);
	};

	/**
	 * Editor handle change
	 * @param editor
	 */
	const updateText = (editor: any) => {
		const { state } = editor.view;
		const { doc } = state;
		// const contentLength = doc.textContent.length;

		// removing white space of the content
		const content = doc.textContent.trim();
		const contentLength = content.length;
		if (contentLength > EDITOR_MAX_LENGTH) {
			setEditorError(t('MAXIMUM_500_CHARACTERS_ALLOWED')!!);
		} else {
			setEditorError('');
			if (editor) {
				const { view } = editor;
				if (view) {
					// call back function to set data from editor
					const editorHtml = EditorUtils.getHtml(view.state);
					// for preventing default p tag if the editor is empty and removing the empty white space
					if (contentLength > 0 && editorHtml.trim()) {
						updateProductNameSuperscript(editorHtml);
					} else {
						updateProductNameSuperscript('');
					}
					// closes tooltip
					setShowTooltip(false);
				}
			}
		}
	};

	/**
	 * setting error state to empty string after validation
	 */
	const handleErrorUpdate = () => {
		setEditorError('');
	};

	return (
		<OverlayTrigger
			show={showTooltip}
			trigger='click'
			placement='auto'
			overlay={
				<Tooltip id='tooltip2' className='editable-tooltip-wrapper'>
					<div className='tooltip-wrapper'>
						<div className='editable-tooltip-content'>
							<CustomButton
								buttonClassName='btn btnClose'
								type='button'
								handleClick={tooltipClose}
								icon='faClose'
							/>
							<KendoTextEditor
								text={productNameSuperscript}
								updateText={updateText}
								handleErrorUpdate={handleErrorUpdate}
								copyrightAddEdit
							/>
							<div style={{ color: 'red' }}>
								{editorError && editorError !== '' && (
									<CustomError value={editorError} />
								)}
							</div>
						</div>
					</div>
				</Tooltip>
			}>
			{/* eslint-disable-next-line react/jsx-no-useless-fragment */}
			<span ref={tooltipRef}>
				<CustomButton
					buttonClassName='copyright-btn'
					type='button'
					handleClick={handleProductNameSuperscript}
					icon='faAdd'
				/>
			</span>
		</OverlayTrigger>
	);
};
export default ProductNameSuperscriptEditor;
