import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	createdDateValue: '',
	createdDateFilterValue: '',
	epcisFileStatusValue: '',
	epcisFileStatusFilterValue: '',
	epcisImportStatus: false,
};

export const epcisFileSlice = createSlice({
	name: 'epcisFile',
	initialState,
	reducers: {
		setCreatedDateValue: (state, action: PayloadAction<any>) => {
			state.createdDateValue = action.payload;
		},
		setCreatedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.createdDateFilterValue = action.payload;
		},
		setEpcisFileStatusValue: (state, action: PayloadAction<any>) => {
			state.epcisFileStatusValue = action.payload;
		},
		setEpcisStatusFilterValue: (state, action: PayloadAction<any>) => {
			state.epcisFileStatusFilterValue = action.payload;
		},
		setEpcisImportStatus: (state, action: PayloadAction<any>) => {
			state.epcisImportStatus = action.payload;
		},
	},
});

export const {
	setEpcisStatusFilterValue,
	setEpcisFileStatusValue,
	setCreatedDateValue,
	setCreatedDateFilterValue,
	setEpcisImportStatus,
} = epcisFileSlice.actions;

export const getCreatedDateValue = (state: RootState) => state.epcisFile.createdDateValue;
export const getCreatedDateFilterValue = (state: RootState) =>
	state.epcisFile.createdDateFilterValue;
export const getEpcisFileStatusValue = (state: RootState) => state.epcisFile.epcisFileStatusValue;
export const getEpcisStatusFilterValue = (state: RootState) =>
	state.epcisFile.epcisFileStatusFilterValue;
export const getEpcisImportStatus = (state: RootState) => state.epcisFile.epcisImportStatus;
export default epcisFileSlice.reducer;
