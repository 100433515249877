import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { PARTNER_DATA_TYPE } from 'utils/constants';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID } from 'utils/enum';

import CustomInput from 'components/common/forms/CustomInput';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import AddEditManufacturerSkeleton from 'components/common/skeleton/AddEditManufacturerSkeleton';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomTextArea from 'components/common/forms/CustomTextArea';
// import { ImageCropper } from 'components/common/cropper/CustomImageCrop';
import IdentifierGrid from './IdentifierGrid';
import ContactDetails from './ContactDetails';

import useGetManufacturerDetailsById from './hooks/useGetManufacturerDetailsById';

const ViewManufacturer = () => {
	const { t } = useTranslation('manufacturerModule');
	const location = useLocation();

	const [manufacturerName, setManufacturerName] = useState<string>('');
	const [manufacturerAddEditInfo, setManufacturerAddEditInfo] = useState<string>('');

	const [streetAddressA, setStreetAddressA] = useState<string>('');
	const [streetAddressB, setStreetAddressB] = useState<string>('');
	const [zip, setZip] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [subjectDescription, setSubjectDescription] = useState<string>('');
	const [gs1Prefix, setGs1Prefix] = useState<string | number>('');
	const [contactName, setContactName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [telephone, setTelephone] = useState<string>('');
	const [identifierDataList, setIdentifierDataList] = useState<any>([]);
	const [dataType, setDataType] = useState<any>('');

	const [logo, setLogo] = useState<string>('');
	const [viewState, setViewState] = useState<boolean>(false);
	const [allFieldsDisbled, setAllFieldsDisabled] = useState<boolean>(false);
	const [dataTypeName, setDataTypeName] = useState<string>('');
	const [businessTypeName, setBusinessTypeName] = useState<string>('');
	const [selectedCountryName, setSelectedCountryName] = useState<string>('');
	const [parentManufacturerName, setParentManufacturerName] = useState<string>('');
	const [district, setDistrict] = useState<string>('');

	/** hook to get data by Id */
	const { resData, manufacturerDetailsLoading } = useGetManufacturerDetailsById(
		location?.state?.encManufacturerId,
	);

	/** sets icon description data in state */
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.MANUFACTURER,
		ICON_DESCRIPTION_ID.MANUFACTURER_ADD_EDIT,
	);

	/**
	 * Icon discription response handling
	 */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setManufacturerAddEditInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		if (location?.state?.type === 'view') {
			setViewState(true);
			setAllFieldsDisabled(true);
		}
	}, [location?.state?.type]);

	/** setting states to input fields from getbyId hook */
	useEffect(() => {
		if (resData) {
			setManufacturerName(resData?.name);
			setStreetAddressA(resData?.street1Address);
			setStreetAddressB(resData?.street2Address);
			setZip(resData?.zipCode);
			setCity(resData?.city);
			setState(resData?.stateName);

			setSubjectDescription(resData?.subjectDescription);
			setGs1Prefix(resData?.gs1GtinPrefix);
			setIdentifierDataList(resData?.identifierTypeList);
			setContactName(resData?.contactName);
			setEmail(resData?.emailId ? resData?.emailId : '');
			setTelephone(resData?.phoneNo ? resData?.phoneNo : '');

			setDataType(resData?.encManufacturerDataTypeId);
			setDataTypeName(resData?.manufacturerDataType);
			setBusinessTypeName(resData?.businessType);
			setParentManufacturerName(resData?.parentManufactureName);
			setLogo(resData?.fileBase64);
			setSelectedCountryName(resData?.countryName);
			setDistrict(resData?.district);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resData]);

	/** identifier grid onchange function */

	return (
		<div className='page-content add-product-group-module add-manufacturer-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{viewState && t('VIEW_MANUFACTURER')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={manufacturerAddEditInfo}
							moduleId={ICON_DESCRIPTION_ID.MANUFACTURER_ADD_EDIT}
						/>
					</h3>
				</div>
			</div>

			<div className='page-content-body'>
				<div className='box-container product-order-details-container'>
					{manufacturerDetailsLoading ? (
						<AddEditManufacturerSkeleton />
					) : (
						<form className='h-100'>
							<fieldset className='k-form-fieldset default-form-style tenant-content-body light-bg-form-wrapper add-location-body'>
								<div className='section-wrapper mb-0 border-bottom-radius-0 pb-0'>
									<div className='row'>
										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={manufacturerName}
													onChange={() => {}}
													name='manufacturerName'
													label={t('MANUFACTURER_NAME')!!}
													placeholder={t('ENTER_MANUFACTURER_NAME')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={gs1Prefix}
													onChange={() => {}}
													name='gs1Prefix'
													label={t('GS1_PREFIX')!!}
													placeholder={t('GS1_PREFIX')}
													required
													type='number'
													isMandatory
												/>
											</div>
										</div>
										<div className='col-xl-3 col-lg-3 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={dataTypeName}
													onChange={() => {}}
													name='dataType'
													label={t('DATA_TYPE')!!}
													placeholder={t('DATA_TYPE')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={businessTypeName}
													onChange={() => {}}
													name='businessType'
													label={t('BUSINESS_TYPE')!!}
													placeholder={t('BUSINESS_TYPE')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled={allFieldsDisbled}
													className=' two-row-height'
													value={subjectDescription}
													onChange={() => {}}
													label={t('SUBJECT_DESCRIPTION')!!}
													placeholder={t('SUBJECT_DESCRIPTION')!!}
													textAreaName='subjectDescription'
													textAreaId='subjectDescription'
													required={false}
													cols={30}
													rows={5}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled={allFieldsDisbled}
													value={streetAddressA}
													onChange={() => {}}
													label={t('STREET_ADDRESS_A')!!}
													placeholder={t('STREET_ADDRESS_A')!!}
													textAreaName='streetAddressA'
													textAreaId='streetAddressA'
													required
													cols={30}
													rows={5}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled={allFieldsDisbled}
													className=' two-row-height'
													value={streetAddressB}
													onChange={() => {}}
													label={t('STREET_ADDRESS_B')!!}
													placeholder={t('STREET_ADDRESS_B')!!}
													textAreaName='streetAddressB'
													textAreaId='streetAddressB'
													required
													cols={30}
													rows={5}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={city}
													onChange={() => {}}
													name='city'
													label={t('CITY')!!}
													placeholder={t('CITY')}
													required
													type='text'
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={zip}
													onChange={() => {}}
													name='zip'
													label={t('ZIP')!!}
													placeholder={t('ZIP')}
													required
													type='text'
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={district}
													onChange={() => {}}
													name='district'
													label={t('DISTRICT')!!}
													placeholder={t('DISTRICT')}
													required
													type='text'
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={state}
													onChange={() => {}}
													name='state'
													label={t('STATE')!!}
													placeholder={t('STATE')}
													required
													type='text'
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled={allFieldsDisbled}
													value={selectedCountryName}
													onChange={() => {}}
													name='country'
													label={t('COUNTRY')!!}
													placeholder={t('COUNTRY')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										{/* checking condition for datatype , if data type is patner showing this field */}
										{dataType === PARTNER_DATA_TYPE && (
											<div className='col-xl-3 col-lg-3 col-sm-12'>
												<div className='form-group'>
													<CustomInput
														disabled={allFieldsDisbled}
														value={parentManufacturerName}
														onChange={() => {}}
														name='manufacturerName'
														label={t('PARENT_COMPANY')!!}
														placeholder={t('PARENT_COMPANY')}
														required
														type='text'
														isMandatory
													/>
												</div>
											</div>
										)}
									</div>
								</div>

								<IdentifierGrid
									selectedPrimaryIdentifier=''
									handlePrimaryIdentifierGridChange={() => {}}
									identifierData={identifierDataList}
									handleDeleteSglnSection={() => {}}
									allDisabled={allFieldsDisbled}
								/>

								<div className='section-wrapper mt-3'>
									<ContactDetails
										contactName={contactName}
										email={email}
										telephone={telephone}
										handleChange={() => {}}
										allDisabled={allFieldsDisbled}
									/>

									{logo && (
										<div className='col-xl-6 col-lg-6'>
											<div className='form-group'>
												<label className='form-label'>
													{t('COMPANY_LOGO')}
												</label>
												<div className='image-main-wrapper'>
													<div className='image-wrapper'>
														<img
															className='crop-image'
															src={logo}
															alt=''
														/>
														{/* <ImageCropper file={logo} onFileChange={() => {}} /> */}
													</div>
												</div>
											</div>
										</div>
									)}
								</div>
							</fieldset>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewManufacturer;
