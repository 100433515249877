import { useEffect } from 'react';
import { Navigate, useLocation } from 'react-router-dom';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { URL_PERMISSIONS, RouteUrl } from 'utils/enum';
import { getUserLoginData, getUserLoginDataStatus } from 'slices/userLoginDataSlice';
import { AUTH_TOKEN_NAME } from 'utils/constants';
import { setGlobalConfig, setIsConfigSet } from 'slices/configSlice';

import { IProtectedRoutes } from 'components/common/model';
import useGetUserDetails from 'components/login/hooks/useGetUserDetails';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import useFetchAllConfig from 'components/common/hooks/useFetchAllConfig';

/**
 * Protected route component that checks auth before rendering child routes.
 * Fetches user details and global config on mount.
 */
const ProtectedRoutes = ({ children }: IProtectedRoutes) => {
	/**
	 * Get current location for navigation checks
	 */
	const location = useLocation();
	/**
	 * Dispatch for redux actions
	 */
	const dispatch = useAppDispatch();
	// const userData: any = getLoginedUserData();

	/**
	 * Get auth token from localStorage
	 */
	const token = localStorage.getItem(`${AUTH_TOKEN_NAME}`);
	const userData: any = useAppSelector(getUserLoginData);
	const userDataStatus: any = useAppSelector(getUserLoginDataStatus);
	/**
	 * Fetch user details on mount
	 */
	useGetUserDetails();

	const { configList, configLoaded } = useFetchAllConfig();

	useEffect(() => {
		dispatch(setIsConfigSet(false));
	}, [dispatch]);

	useEffect(() => {
		if (configList?.length > 0) {
			dispatch(setGlobalConfig(configList));
		}
	}, [configList, dispatch]);

	/**
	 * Protected route component to check authentication
	 * and authorize access to child components.
	 */
	if (userDataStatus && configLoaded) {
		/**
		 * Check if user is authenticated and authorized to access the route.
		 */
		let isAuth = false;
		if (userData !== null && userData.token) {
			URL_PERMISSIONS.forEach((value) => {
				if (
					value.url === location.pathname &&
					userData.listPrivilege.includes(value.encPrivilgeID)
				) {
					isAuth = true;
				}
			});
			if (!isAuth) {
				return <Navigate to={RouteUrl.UNAUTHORIZEDACCESS} replace />;
			}

			/**
			 * If authenticated, render child components.
			 * Otherwise show loading skeleton.
			 */
			return (
				// eslint-disable-next-line react/jsx-no-useless-fragment
				<>
					{userDataStatus ? (
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>{children}</>
					) : (
						<div>
							<CommonSkeleton />
						</div>
					)}
				</>
			);

			// redirectPath - will update after complting the design work
		}
	}
	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{token ? (
				<div className='default-loading'>
					<CommonSkeleton />
				</div>
			) : (
				<Navigate to={RouteUrl.LOGINPAGE} replace />
			)}
		</>
	);
};

export default ProtectedRoutes;
