const FooterContainer = () => {
    return (
        <div className='login-footer'>
            <div className='left-box'>
                <p>Copyright © 2023. All rights reserved</p>
            </div>
            <div className='right-box'>
                <ul>
                    <li>Terms of use</li>
                    <li className='list-pipe'>|</li>
                    <li>Privacy Policy </li>
                    <li className='list-pipe'>|</li>
                    <li>Cookie Policy </li>
                    <li className='list-pipe'>|</li>
                    <li>Support</li>
                </ul>
            </div>
        </div>
    )
};
export default FooterContainer;
