import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosBasicInstance } from 'libs/axios';
import { FORGOT_PASSWORD_URL } from 'utils/serviceUrls';
import { ERROR } from 'utils/constants';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

/**
 *
 * @param submitClicked -key which indicates reset password is clicked or not
 * @param paramData-list of params which should go as payload
 * @param updateResetPasswordClick-reset password button click change function
 * @returns forgot password response and forgot password loading
 */

const useForgotPassword = (submitClicked: any, paramData: any, updateResetPasswordClick: any) => {
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();
	const [forgotPasswordRes, setForgotPasswordRes] = useState<any>();
	const [forgotPasswordLoading, setforgotPasswordLoading] = useState<boolean>(false);

	useEffect(() => {
		if (submitClicked) {
			setforgotPasswordLoading(true);
			axiosBasicInstance
				.post(FORGOT_PASSWORD_URL, paramData)
				.then((response: any) => {
					setForgotPasswordRes(response);
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setforgotPasswordLoading(false);
					updateResetPasswordClick();
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [submitClicked, paramData]);

	return { forgotPasswordRes, forgotPasswordLoading };
};

export default useForgotPassword;
