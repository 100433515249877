import { useNavigate } from 'react-router-dom';

import { removeToken, themeHandling } from 'utils/utils';
import { RouteUrl } from 'utils/enum';
import { ONE_MINUTE_IN_MILLISECONDS } from 'utils/constants';

import { IdleTimerProvider } from 'react-idle-timer';
import { IInactiveLogoutTimer } from 'components/common/model';
import { getAutoLockoutTime } from 'slices/configSlice';
import { useAppSelector } from 'hooks/stateHooks';
import userLogoutService from 'components/login/services/userLogoutService';
// import AlertIconImage from 'assets/images/logout-inactive.svg';
// import { DialogActionsBar } from '@progress/kendo-react-dialogs';
// import CustomModal from 'components/common/modals/CustomModal';

const InactiveLogoutTimer = ({ children }: IInactiveLogoutTimer) => {
	const timeout = useAppSelector(getAutoLockoutTime);
	const navigate = useNavigate();

	const handleLogout = () => {
		/** API call for logout status keep in backend */
		userLogoutService();

		removeToken();
		localStorage.clear();
		themeHandling(false);
		navigate(RouteUrl.LOGINPAGE);
	};

	// Functions for Confirmation popup

	// const { t } = useTranslation('userModule');
	// const [modalOpen, setModalOpen] = useState<boolean>(false);

	// const onIdle = () => {
	// 	setModalOpen(true);
	// };
	// const onclickLogout = () => {
	// 	removeToken();
	// 	localStorage.clear();
	// 	navigate(RouteUrl.LOGINPAGE);
	// };
	// const onLogout = () => {
	// 	setModalOpen(false);
	// 	onclickLogout();
	// };
	// const onLogin = () => {
	// 	setModalOpen(false);
	// };
	// const handleClose = () => {
	// 	setModalOpen(false);
	// };

	return (
		<>
			{/* Confirmation popup */}
			{/* {modalOpen && (
				<CustomModal
					className='alert-modal'
					title={t('PLEASECONFIRM')!!}
					toggleDialog={handleClose}
					closeIcon>
					<div className='success-modal modal-content'>
						<div className='modal-body-content text-center'>
							<img src={AlertIconImage} alt='Archieve' />
							<h4>{t('ARE_YOU_SURE')}</h4>
							<p>{t('TIMED_OUT')}</p>
						</div>
						<DialogActionsBar>
							<button
								type='button'
								className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-outline-secondary h-44px sign-btn'
								onClick={onLogin}>
								{t('KEEPSIGNIN')}
							</button>
							<button
								className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px'
								type='button'
								onClick={onLogout}>
								{t('LOGOUT')}
							</button>
						</DialogActionsBar>
					</div>
				</CustomModal>
			)} */}
			<IdleTimerProvider timeout={timeout * ONE_MINUTE_IN_MILLISECONDS} onIdle={handleLogout}>
				{children}
			</IdleTimerProvider>
		</>
	);
};

export default InactiveLogoutTimer;
