import { memo } from 'react';

import CustomError from '../errorLabel/CustomError';
import { ICustomTextArea } from '../model';

/**
 * Custom textarea component.
 *
 * @param {Object} props - Component props
 * @param {string} props.textAreaName - Name of textarea
 * @param {string} props.textAreaId - Id of textarea
 * @param {string} [props.text] - Inner text
 * @param {string} [props.placeholder] - Placeholder text
 * @param {boolean} [props.required] - Whether required
 * @param {number} [props.rows] - Number of rows
 * @param {number} [props.cols] - Number of columns
 * @param {string} [props.value] - Value of textarea
 * @param {function} props.onChange - Change handler
 * @param {string} [props.className] - CSS class names
 * @param {string} [props.label] - Label text
 * @param {string} [props.error] - Error message
 * @param {boolean} [props.isMandatory] - Whether required
 * @param {boolean} [props.disabled] - Whether disabled
 * @param {number} [props.maxlength] - Max length
 */
const CustomTextArea = ({
	textAreaName,
	textAreaId,
	text,
	placeholder,
	required,
	rows,
	cols,
	value,
	onChange,
	className,
	label,
	error,
	isMandatory,
	disabled,
	maxlength,
}: ICustomTextArea) => {
	return (
		<>
			<label className='form-label'>{label}</label>
			{isMandatory && <span>*</span>}
			<textarea
				id={textAreaId}
				disabled={disabled}
				name={textAreaName}
				rows={rows}
				cols={cols}
				value={value || ''}
				onChange={onChange}
				placeholder={placeholder}
				className={
					disabled
						? `form-control text-area ${
								`${className} disabled` || 'form-control text-area disabled'
						  }`
						: `form-control text-area ${className || ''}`
				}
				required={required}
				maxLength={maxlength}>
				{text || ''}
			</textarea>
			{error && error !== '' && <CustomError value={error} />}
		</>
	);
};
export default memo(CustomTextArea);
