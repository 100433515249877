import { ChangeEvent, useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { PageChangeEvent, Pager } from '@progress/kendo-react-data-tools';

import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	NOTIFICATION_FILTER_TYPES,
	RouteUrl,
} from 'utils/enum';
import ViewAllNotificationSkeleton from 'components/common/skeleton/FileFailureListSkeleton';
import {
	getNotificationCount,
	setGetAllNotification,
	setNotificationCount,
} from 'slices/notificationSlice';
import { useAppSelector } from 'hooks/stateHooks';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import NoData from 'pages/NoData';
import CustomInput from 'components/common/forms/CustomInput';
import CustomDropdown from 'components/common/forms/CustomDropdown';
import CustomButton from 'components/common/button/CustomButton';
import DateRangePicker from 'components/common/dateRangePicker/DateRangePicker';
import {
	dateAddition,
	dateSubstract,
	formateDateForApi,
	getAwaitingActionSearch,
	utcFormat,
	utcTime,
} from 'utils/utils';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';

import ViewAllNotificationCard from './ViewAllNotificationCard';
import useGetAllNotifications from './hooks/useGetAllNotifications';
import * as constants from '../../utils/constants';
import useUpdateNotificationStatus from './hooks/useUpdateNotificationStatus';

const ViewAllNotifications = () => {
	const { t } = useTranslation('dashboard');
	const dispatch = useDispatch();
	const enable24Hr = useAppSelector(getEnable24Hr);
	const getCurrentDate = useAppSelector(getDataFormat);
	const notificationCount = useAppSelector(getNotificationCount);
	const initialType: 'numeric' | 'input' = 'numeric';

	const startDate = dateSubstract(new Date(), constants.LAST_30_DAYS);
	const minDate = dateSubstract(new Date(), constants.LAST_90_DAYS);

	const [selectedStartDate, setSelectedStartDate] = useState<any>(startDate);
	const [selectedEndDate, setSelectedEndDate] = useState<any>(new Date());
	const [params, setParams] = useState<any>({ readAll: false, notificationDetails: [] });
	const [searchCopyData, setSearchCopyData] = useState<any>([]); // state for search functionality
	const [searchInput, setSearchInput] = useState<string>(''); // search input state
	const [filterValue, setFilterValue] = useState<any>({ type: 'All', status: '' }); // state for filter input
	const dataItemKey = ['text', 'customName1', 'customName2', 'customName3', 'createdOn'];
	const [pagerData, setPagerData] = useState<any>([]); // main state for data
	const [value, setValue] = useState<any>({
		start: startDate,
		end: new Date(),
	});
	const [minDt, setMinDt] = useState<any>(minDate);
	const [maxDt, setMaxDt] = useState<any>(new Date());
	/**
	 * pagination total state
	 */
	const [total, setTotal] = useState<number>(0);

	/**
	 * pagination state
	 */
	const [pageDataState, setpageDataState] = useState<any>({
		skip: 0,
		take: constants.SMALLGRIDPAGESIZE,
		buttonCount: 5,
		type: initialType,
		previousNext: true,
	});
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

	const [notificationGridLoading, setNotificationGridLoading] = useState<boolean>(true);

	const { loading, notificationList } = useGetAllNotifications(
		formateDateForApi(selectedStartDate, constants.COMMON_DATE_FORMAT_API),
		formateDateForApi(selectedEndDate, constants.COMMON_DATE_FORMAT_API),
		true,
	);

	const [notificationInfo, setNotificationInfo] = useState<string>('');

	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.DASHBOARD,
		ICON_DESCRIPTION_ID.DASHBOARD_NOTIFICATION,
	);

	/**
	 * sets icon description data in state
	 */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setNotificationInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	const getDateRange = (dateRange: any) => {
		setSelectedStartDate(dateRange.start);
		setSelectedEndDate(dateRange.end);
		// minimum date selectable from calander = start date choosen minus 90 days
		// That means  user can choose maximum 90 days period
		const newMinDt = dateSubstract(new Date(dateRange.start), constants.LAST_90_DAYS);
		setMinDt(newMinDt);
		// adjust max date, maximum 90 days from start date
		const newMaxDt = dateAddition(new Date(dateRange.start), constants.LAST_90_DAYS);

		// check if newMaxDt greater than current date
		if (new Date(newMaxDt) > new Date()) {
			setMaxDt(new Date());
		} else setMaxDt(newMaxDt);
		setValue(dateRange);
		if (dateRange.end) {
			dispatch(setGetAllNotification(true));
			setSearchInput('');
			setFilterValue({ type: 'All', status: '' });
		}
	};
	const updateIsSubmitted = () => {
		setIsSubmitted(false);
		setParams({ readAll: false, notificationDetails: [] });
	};
	useUpdateNotificationStatus(params, isSubmitted, updateIsSubmitted);

	// setting notificationdata to internalstate and internalcopy state

	useEffect(() => {
		if (notificationList && notificationList.length > 0) {
			const tempfileData: any = [];
			notificationList.map((item: any) => {
				const tempMessage = getAwaitingActionSearch(
					item?.type?.trim(),
					item,
					enable24Hr,
				).toString();
				tempfileData.push({ ...item, text: tempMessage });
				return false;
			});
			/**
			 * notificationMsg will return false when response is []
			 */
			setSearchCopyData(tempfileData);
			setPagerData(tempfileData);
			setTotal(tempfileData?.length);
		} else {
			setSearchCopyData([]);
			setPagerData([]);
			setTotal(0);
		}
		setNotificationGridLoading(loading);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [notificationList, loading, enable24Hr]);

	/**
	 * updating read status of individual notification card in view all page by setting updated data to main state and copy state
	 * @param id:any
	 */
	const handleUpdateReadStatus = (id: any) => {
		const updatedData = pagerData.map((data: any) => {
			if (data.notificationId === id) {
				return { ...data, isRead: true };
			}
			return data;
		});
		const updatedCopyData = searchCopyData.map((data: any) => {
			if (data.notificationId === id) {
				return { ...data, isRead: true };
			}
			return data;
		});
		setSearchCopyData(updatedCopyData);
		setPagerData(updatedData);
	};
	// mark all read functionality by setting updated data to main state and copy state
	const markAllAsRead = () => {
		const tempDataState: any = pagerData.map((data: any) => {
			return { ...data, isRead: true };
		}, []);

		const tempSearchData: any = searchCopyData.map((data: any) => {
			return { ...data, isRead: true };
		}, []);

		dispatch(setNotificationCount(0));
		setParams({ ...params, readAll: true });
		setIsSubmitted(true);
		setPagerData(tempDataState);
		setSearchCopyData(tempSearchData);
	};
	/**
	 * filter handling fn
	 * @param event:any
	 */
	const handleFilterChange = (event: any) => {
		const results = pagerData.filter(
			(item: any) =>
				(event.status !== '' ? item.isRead === event.status : item) &&
				(searchInput !== ''
					? dataItemKey.some((searchKey) =>
							item[searchKey]?.toLowerCase().includes(searchInput.toLowerCase()),
					  )
					: true),
		);
		setSearchCopyData(results);
		const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
		setpageDataState(clearedPagerDataState);
		setTotal(results?.length);
		setFilterValue(event);
	};

	/**
	 * page handling fn
	 * @param event : PageChangeEvent
	 */
	const handlePageChange = (event: PageChangeEvent) => {
		// eslint-disable-next-line no-shadow
		const { skip, take } = event;
		setpageDataState({ ...pageDataState, skip, take });
	};

	/**
	 * search handing fn
	 * @param e : HTMLInputElement
	 */
	const handleSearchChange = (e: ChangeEvent<HTMLInputElement>) => {
		const keyword = e.target.value;

		if (keyword !== '') {
			const results =
				pagerData?.length > 0 &&
				pagerData?.filter((item: any) => {
					// eslint-disable-next-line array-callback-return
					return dataItemKey.some((searchKey) => {
						if (
							// eslint-disable-next-line no-restricted-globals
							!isNaN(Date.parse(item[searchKey])) &&
							getCurrentDate &&
							enable24Hr !== null
						) {
							const modDate = `${utcFormat(
								item[searchKey],
								getCurrentDate,
							)} ${utcTime(item[searchKey], enable24Hr)}`;
							return modDate?.toLowerCase().includes(keyword.toLowerCase());
						}
						return item[searchKey]?.toLowerCase()?.includes(keyword.toLowerCase());
					});
				});

			setSearchCopyData(results);
			const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
			setpageDataState(clearedPagerDataState);
			setTotal(results?.length);
		} else {
			/**
			 * If the text field is empty, show all users
			 */
			setSearchCopyData(pagerData);
			const clearedPagerDataState = { ...pageDataState, take: pageDataState.take, skip: 0 };
			setpageDataState(clearedPagerDataState);
			setTotal(notificationList?.length);
		}
		setSearchInput(keyword);
	};

	return (
		<div className='page-content '>
			<div className='page-header with-right-form'>
				<div className='back-btn-header'>
					<PageBackButton url={RouteUrl.DASHBOARD} />
					<h3>
						{t('NOTIFICATIONS')!!}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={notificationInfo}
							moduleId={ICON_DESCRIPTION_ID.DASHBOARD_NOTIFICATION}
						/>
					</h3>
				</div>

				<div className='default-form-style tenant-search notification-search-form'>
					<div className='form-group'>
						<DateRangePicker
							getDataRange={getDateRange}
							disabled={false}
							value={value}
							min={minDt}
							max={maxDt}
						/>

						<CustomInput
							placeholder={t('SEARCH_HERE_PLACEHOLDER')}
							type='text'
							value={searchInput}
							name='searchtenant'
							label={t('SEARCH_CARD_LABEL')!!}
							onChange={handleSearchChange}
							required={false}
						/>

						<CustomDropdown
							className='d-flex form-control default-select-dropdown'
							dropdownValue={filterValue}
							handleDropDownChange={handleFilterChange}
							options={[
								{ type: NOTIFICATION_FILTER_TYPES.ALL, status: '' },
								{ type: NOTIFICATION_FILTER_TYPES.READ, status: true },
								{ type: NOTIFICATION_FILTER_TYPES.UNREAD, status: false },
							]}
							dataItemKey='status'
							dataTextField='type'
						/>

						{notificationCount > 0 && (
							<CustomButton
								buttonName={t('MARK_ALL_AS_READ')}
								handleClick={markAllAsRead}
								buttonClassName='mark-all-read'
								type='button'
								icon='faCheckDouble'
							/>
						)}
					</div>
				</div>
			</div>

			<div className='page-content-body pagination-content-body'>
				<div className='box-container pagination-box-container overflow-y-visible'>
					<div className='notification-list-card-wrapper'>
						{notificationGridLoading ? (
							<ViewAllNotificationSkeleton />
						) : (
							<div className='inline-list-card file-failed-list'>
								{searchCopyData && searchCopyData.length > 0
									? searchCopyData
											.slice(
												pageDataState.skip,
												pageDataState.take + pageDataState.skip,
											)
											.map((item: any, index: number) => {
												return (
													// eslint-disable-next-line react/no-array-index-key
													<div key={`${item.notificationId} ${index}`}>
														<ViewAllNotificationCard
															updateReadStatus={
																handleUpdateReadStatus
															}
															item={item}
														/>
													</div>
												);
											})
									: searchCopyData?.length === 0 && <NoData />}
							</div>
						)}
					</div>
				</div>

				<Pager
					skip={pageDataState.skip}
					take={pageDataState.take}
					total={total}
					buttonCount={pageDataState.buttonCount}
					type={pageDataState.type}
					previousNext={pageDataState.previousNext}
					onPageChange={handlePageChange}
				/>
			</div>
		</div>
	);
};
export default ViewAllNotifications;
