import HandPlantingTreeImage from './assets/HandPlantingTreeImage';
import InsectImage from './assets/InsectImage';
import PesticideJarImage from './assets/PesticideJarImage';
import QrCodeImage from './assets/QrCodeImage';

const ImageSectionFooter = () => {
	return (
		<div className='inline-img-wrapper'>
			<div className='image-box'>
				<img src={PesticideJarImage} alt='Related product for your crop' />
			</div>
			<div className='image-box'>
				<img src={QrCodeImage} alt='Cultivating Transparency ' />
			</div>
			<div className='image-box'>
				<img src={InsectImage} alt='Know your enemies' />
			</div>
			<div className='image-box'>
				<img src={HandPlantingTreeImage} alt='Empowering Smart-agriculture' />
			</div>
		</div>
	);
};

export default ImageSectionFooter;
