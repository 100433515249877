import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const UserListing = lazy(() => import('../components/user/UserListing'));

const UserModule = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<UserListing />
			</Suspense>
		</div>
	);
};
export default UserModule;
