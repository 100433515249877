import { axiosPrivateInstance } from 'libs/axios';
import { LOGOUT_URL } from 'utils/serviceUrls';
import { getToken } from 'utils/utils';

const userLogoutService = async () => {
	const token = getToken();
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(LOGOUT_URL, { token })
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default userLogoutService;
