import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axiosBasicInstance } from 'libs/axios';
import { getToken, storeRefreshToken, storeToken } from 'utils/utils';
import { MFA_LOGIN } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from 'utils/constants';

/**
 *
 * @param uniqueID-unique id
 * @param otp-otp value
 * @param mfaSubmitClicked -key which indicates submit button is clicked
 * @param resetOtpVerification-function to update the button click change status
 * @returns mfaLoginValue, loginStatus, mfaloginLoading
 */
const useOtpVerification = (
	uniqueID: string,
	otp: string,
	mfaSubmitClicked: boolean,
	resetOtpVerification: any,
) => {
	// eslint-disable-next-line no-unused-vars
	const token = getToken();
	const dispatch = useDispatch();
	const { t } = useTranslation('l4Module');
	const [mfaLoginValue, setMfaLoginValue] = useState<any>({});
	const [loginStatus, setLoginStatus] = useState(false);
	const [mfaloginLoading, setMfaLoginLoading] = useState<boolean>(false);
	const params = { uniqueId: uniqueID, otp };
	useEffect(() => {
		if (mfaSubmitClicked) {
			setMfaLoginLoading(true);
			axiosBasicInstance
				.post(MFA_LOGIN, params)
				.then((response: any) => {
					if (response?.status && response?.output) {
						setMfaLoginValue(response?.output);
						setLoginStatus(true);
						storeToken(response?.output?.token);
						storeRefreshToken(response?.output?.refreshToken);
					} else {
						dispatch(setMessage(t(response?.output?.resultText || 'INVALID_OTP')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					setLoginStatus(false);
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setMfaLoginLoading(false);
					resetOtpVerification();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [mfaSubmitClicked]);

	return { mfaLoginValue, loginStatus, mfaloginLoading };
};

export default useOtpVerification;
