import { useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { PASSWORD_EXPIRY_NOTIFICATION } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { ERROR } from 'utils/constants';
import { setExpiryDaysCount, setIsmodalVisible } from 'slices/expirySlice';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetPasswordExpiryAlert = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('userModule');

	useEffect(() => {
		axiosPrivateInstance
			.post(PASSWORD_EXPIRY_NOTIFICATION)
			.then((response: any) => {
				if (response?.output && response?.status) {
					dispatch(setIsmodalVisible(response?.output?.flag));
					dispatch(setExpiryDaysCount(response?.output?.days));
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);
};

export default useGetPasswordExpiryAlert;
