import CustomCheckBox from 'components/common/forms/CustomCheckBox';
import { MODE_VALUES } from 'utils/enum';

const CustomWeblinkList = (props: any) => {
	const { mode, selectedWeblinks, item, onChange } = props;

	const handleWeblinkCheckBox = (e: any, id: string) => {
		let tempSelectedWeblinks = [...selectedWeblinks];
		if (selectedWeblinks.includes(id.toString())) {
			tempSelectedWeblinks = selectedWeblinks.filter((itemVal: any) => {
				return itemVal !== id;
			}, []);
		} else {
			tempSelectedWeblinks.push(id);
		}
		onChange(tempSelectedWeblinks);
	};

	return (
		<div className='k-listview-item'>
			<div className='form-group'>
				<CustomCheckBox
					disabled={
						(mode === MODE_VALUES.ACTIVATE && item.isActive) ||
						(mode === MODE_VALUES.DEACTIVATE && !item.isActive) ||
						(mode !== MODE_VALUES.ACTIVATE && mode !== MODE_VALUES.DEACTIVATE)
					}
					key={`check${item.encWeblinkId}`}
					checked={selectedWeblinks.includes(item.encWeblinkId)}
					name='productionOrder'
					labelPlacement='after'
					label={item.weblink}
					handleChange={(e) => handleWeblinkCheckBox(e, item.encWeblinkId)}
				/>
			</div>
		</div>
	);
};

export default CustomWeblinkList;
