import { useTranslation } from 'react-i18next';
import { useState } from 'react';
import { useDispatch } from 'react-redux';

import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import NotificationTextCreation from 'components/common/notification/NotificationTextCreation';
import { utcFormat, utcTime } from 'utils/utils';
import { NOTIFICATION_TYPES } from 'utils/enum';

import { getNotificationCount, setNotificationCount } from 'slices/notificationSlice';
import useUpdateNotificationStatus from './hooks/useUpdateNotificationStatus';

const ViewAllNotificationCard = ({ item, updateReadStatus }: any) => {
	const dispatch = useDispatch();
	const notificationCount = useAppSelector(getNotificationCount);
	const enable24Hr = useAppSelector(getEnable24Hr);

	const dateFormat = useAppSelector(getDataFormat);
	const { t } = useTranslation('dashboard');
	const [params, setParams] = useState<any>({ readAll: false, notificationDetails: [] });
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);

	const updateIsSubmitted = () => {
		setIsSubmitted(false);
		setParams({ readAll: false, notificationDetails: [] });
	};
	useUpdateNotificationStatus(params, isSubmitted, updateIsSubmitted);

	const todatDate = new Date().setHours(0, 0, 0, 0);
	const receivedDate = new Date(item.createdOn).setHours(0, 0, 0, 0);

	// handle change of notification click on individual card
	const handleNotificattionClick = () => {
		if (!item.isRead) {
			updateReadStatus(item.notificationId);
			dispatch(setNotificationCount(notificationCount - 1));
			setParams({
				...params,
				readAll: false,
				notificationDetails: [
					{
						notificationId: item.notificationId,
						notificationTypeId: item.notificationTypeId,
					},
				],
			});
			setIsSubmitted(true);
		}
	};

	return (
		// eslint-disable-next-line jsx-a11y/click-events-have-key-events
		<div
			key={`div${item.notificationId}`}
			tabIndex={0}
			role='button'
			className={`notifictaion-item ${item.isRead ? 'read' : 'not-read'}`}>
			<span className='list-title'>
				{/* eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/control-has-associated-label */}
				<span
					className='icon'
					tabIndex={0}
					role='button'
					onClick={handleNotificattionClick}>
					<ShowIcon name='faBell' />
				</span>
				{NotificationTextCreation(item?.type?.trim(), item)}
			</span>
			<span className='right-box'>
				<span className='date-location-container'>
					<span className='date-box'>
						<span className='dsb-lbl'>
							{todatDate === receivedDate
								? `${t('TODAY')} ${utcTime(item.createdOn, enable24Hr)}`
								: `${utcFormat(item.createdOn, dateFormat)} ${utcTime(
										item.createdOn,
										enable24Hr,
								  )}`}
						</span>
					</span>
					{NOTIFICATION_TYPES.EMAIL_AUTHENTICATION_FAILED === item.type &&
						item.customName1 && (
							<span className='location-box'>
								<span className='dsb-lbl'>{t('EMAIL_ID')}</span>
								{item.customName1 && (
									<span className='dsb-value-lbl'>{item.customName1}</span>
								)}
							</span>
						)}
					{NOTIFICATION_TYPES.USER_INVALID_LOGIN_ATTEMPT === item.type &&
						item.customName1 && (
							<span className='location-box'>
								<span className='dsb-lbl'>{t('USER_ID')}</span>
								{item.customName2 && (
									<span className='dsb-value-lbl'>{item.customName1}</span>
								)}
							</span>
						)}

					{NOTIFICATION_TYPES.USER_INVALID_LOGIN_ATTEMPT !== item.type &&
					NOTIFICATION_TYPES.EMAIL_AUTHENTICATION_FAILED !== item.type &&
					item.customName3 ? (
						<span className='location-box'>
							<span className='dsb-lbl'>{t('LOCATION')}</span>
							{item.customName3 && (
								<span className='dsb-value-lbl'>{item.customName3} </span>
							)}
						</span>
					) : (
						''
					)}
				</span>
				<span className='read-checker'>
					<ShowIcon name='faCheckDouble' />
				</span>
			</span>
		</div>
	);
};
export default ViewAllNotificationCard;
