import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_GS1_DETAILS } from 'utils/serviceUrls';
import { useTranslation } from 'react-i18next';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';

const useGetAllGS1 = (productId: string) => {
	const { t } = useTranslation('epcisModule');
	const dispatch = useAppDispatch();

	const [gs1WeblinkList, setGs1WeblinkList] = useState<any>({});
	const [gs1WeblinkLoading, setGs1WeblinkLoading] = useState<boolean>(true);

	useEffect(() => {
		if (productId) {
			setGs1WeblinkLoading(true);
			axiosPrivateInstance
				.post(GET_ALL_GS1_DETAILS, { encProductId: productId })
				.then((response: any) => {
					if (response?.output) {
						setGs1WeblinkList(response?.output ? response?.output : []);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setNotificationType('error'));
						dispatch(setShowNotification(true));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setNotificationType('error'));
					dispatch(setShowNotification(true));
				})
				.finally(() => {
					setGs1WeblinkLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [productId]);

	return {
		gs1WeblinkList,
		gs1WeblinkLoading,
	};
};

export default useGetAllGS1;
