/**
 * Custom hook to update icon description data for a given moduleId and editedText.
 * @param {string} moduleId - The moduleId for which the icon description is to be updated.
 * @param {string} editedText - The updated icon description text.
 * @param {boolean} isUpdated - A flag to indicate if the icon description is updated.
 * @param {Function} updateIsSave - A callback function to be executed after successful update.
 * @returns {Object} - An object containing infoIconDataLoading and updateInfoDataResponse properties.
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/stateHooks';
import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_INFO_ICON_DATA } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { setInfoIconsDataStatus } from 'slices/infoIconsDataSlice';

const useUpdateInfoIconData = (
	moduleId: string,
	editedText: string,
	isUpdated: boolean,
	updateIsSave: any,
) => {
	const { t } = useTranslation('productModule');
	const dispatch = useAppDispatch();
	/**
	 * State to store the response data and loading status.
	 */
	const [updateInfoDataResponse, setUpdateInfoDataResponse] = useState<any>();
	const [infoIconDataLoading, setInfoIconDataLoading] = useState<boolean>(false);

	/**
	 * Parameter for api call
	 */
	const params = {
		encIconId: moduleId,
		iconDescription: editedText,
	};

	useEffect(() => {
		if (isUpdated) {
			setInfoIconDataLoading(true);
			axiosPrivateInstance
				.post(UPDATE_INFO_ICON_DATA, params)
				.then((response: any) => {
					setUpdateInfoDataResponse(response);

					if (response?.output?.status) {
						dispatch(setMessage(t('INFORMATION_UPDATED')));
						dispatch(setNotificationType('success'));
						dispatch(setInfoIconsDataStatus(true));
					} else {
						dispatch(setMessage(t('INFORMATION_UPDATE_FAILED')));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('INFORMATION_UPDATE_FAILED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setInfoIconDataLoading(false);
					updateIsSave();
					dispatch(setShowNotification(true));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUpdated]);

	return {
		infoIconDataLoading,
		updateInfoDataResponse,
	};
};

export default useUpdateInfoIconData;
