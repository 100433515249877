import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const Index = lazy(() => import('components/tenant/Index'));

const Tenant = () => {
	return (
		<Suspense fallback={<CommonSkeleton />}>
			<Index />;
		</Suspense>
	);
};
export default Tenant;
