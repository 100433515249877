import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_MANUFACTURERS_DROP_DOWN } from 'utils/serviceUrls';
import { ERROR } from 'utils/constants';

import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGellAllParentCompany = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('manufacturerModule');

	const [parentManufacturerResponseData, setParentManufacturerResponseData] = useState<any>([]);
	const [parentManufacturerLoading, setParentManufacturerLoading] = useState<boolean>(false);

	const ApiRespone = async () => {
		setParentManufacturerLoading(true);

		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(GET_ALL_MANUFACTURERS_DROP_DOWN)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setParentManufacturerResponseData(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			})
			.finally(() => {
				setParentManufacturerLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		parentManufacturerResponseData,
		parentManufacturerLoading,
	};
};

export default useGellAllParentCompany;
