import { useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomButton from 'components/common/button/CustomButton';
import CustomRadioButton from 'components/common/forms/CustomRadioButton';
import { IWebLinkDownload } from 'components/common/model';
import CustomLoader from 'components/common/loader/CustomLoader';
import useDownloadWeblink from './hooks/useDownloadWeblink';

const WebLinkDownload = ({ show, productId, weblinkDownloadType }: IWebLinkDownload) => {
	const { t } = useTranslation('productModule');

	const [selectedFormat, setSelectedFormat] = useState<string>('txt');
	const [downloadStatus, setDownloadStatus] = useState<boolean>(false);

	const resetDownloadStatus = () => {
		setDownloadStatus(false);
	};

	// API call for Download Weblink
	const { loading } = useDownloadWeblink(
		selectedFormat,
		downloadStatus,
		productId,
		weblinkDownloadType,
		resetDownloadStatus,
	);

	const handleRadioButtonChange = (e: any) => {
		setSelectedFormat(e);
	};

	const handleDownload = () => {
		setDownloadStatus(true);
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{show && (
				<>
					<label className='form-label'>{t('WEBLINK')}</label>
					<div className='web-link-downloader-wrapper'>
						<div className='radioBtnGroupr '>
							<CustomRadioButton
								className=''
								checked={selectedFormat === 'txt'}
								handleChange={handleRadioButtonChange}
								id='txt'
								value='txt'
								label={t('TEXT')!!}
							/>
							<CustomRadioButton
								className=''
								checked={selectedFormat === 'csv'}
								handleChange={handleRadioButtonChange}
								id='csv'
								value='csv'
								label={t('CSV')!!}
							/>
						</div>

						<CustomButton
							type='button'
							buttonClassName=''
							buttonName={loading ? <CustomLoader /> : t('DOWNLOAD_WEBLINK')}
							handleClick={handleDownload}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default WebLinkDownload;
