const ProductSkeleton = () => {
	return (
		<div className='dummy-skelton '>
			<div className='box'>
				<div className='product-skeleton'>
					<div className='product-breadcrumbs' />
				</div>
				<div className='skeleton h-86'>
					<div className='skeleton-left flex1 '>
						<div className='square' />
					</div>
					<div className='skeleton-right flex1 h-100'>
						<div className='reset-square' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductSkeleton;
