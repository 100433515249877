import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_RECENT_PRODUCTS } from 'utils/serviceUrls';
import { IRecentlAddedProduct } from 'components/common/model';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';
import { CANCELED_ERROR } from 'utils/constants';

const useProductRecentlyAdded = () => {
	const { t } = useTranslation('dashboard');
	const dispatch = useAppDispatch();
	const [recentProduct, setRecentProduct] = useState<Array<IRecentlAddedProduct>>([]);
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		setLoading(true);
		const controller = new AbortController();
		const { signal } = controller;
		axiosPrivateInstance
			.post(GET_ALL_RECENT_PRODUCTS, {}, { signal })
			.then((response: any) => {
				if (response?.output && response?.status) {
					setRecentProduct(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch((error: any) => {
				if (error?.name === CANCELED_ERROR) {
					return;
				}
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setLoading(false);
			});
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		recentProduct,
		loading,
	};
};

export default useProductRecentlyAdded;
