import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_MANUFACTURER_DATA_TYPE } from 'utils/serviceUrls';
import { ERROR } from 'utils/constants';

import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetAllDataType = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('manufacturerModule');

	const [dataTypeResponseData, setDataTypeResponseData] = useState<any>([]);
	const [dataTypeLoading, setDataTypeLoading] = useState<boolean>(false);

	const ApiRespone = async () => {
		setDataTypeLoading(true);

		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(GET_ALL_MANUFACTURER_DATA_TYPE)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setDataTypeResponseData(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			})
			.finally(() => {
				setDataTypeLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		dataTypeResponseData,
		dataTypeLoading,
	};
};

export default useGetAllDataType;
