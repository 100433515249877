import { useTranslation } from 'react-i18next';

import { Grid, GridColumn } from '@progress/kendo-react-grid';

import ShowIcon from 'components/common/fontAwesome/ShowIcon';
import CustomRadioButton from 'components/common/forms/CustomRadioButton';
import { IDENTIFIER_TYPE } from 'utils/enum';

const CustomCell = ({
	handlePrimaryIdentifierGridChange,
	selectedPrimaryIdentifier,
	dataItem,
	allDisabled,
}: any) => {
	const isPrimaryDisable: boolean =
		dataItem?.encManufacturerIdentifierId === IDENTIFIER_TYPE.AR_CUIT ||
		dataItem?.encManufacturerIdentifierId === IDENTIFIER_TYPE.AM_TIN ||
		dataItem?.encManufacturerIdentifierId === IDENTIFIER_TYPE.BY_TIN ||
		dataItem?.encManufacturerIdentifierId === IDENTIFIER_TYPE.DE_FA_REG_NUM;
	return (
		// eslint-disable-next-line jsx-a11y/control-has-associated-label
		<td>
			<div className='radio-btn-only'>
				<CustomRadioButton
					disabled={allDisabled || isPrimaryDisable}
					className=''
					checked={dataItem?.isPrimary === true}
					handleChange={() => handlePrimaryIdentifierGridChange(dataItem)}
					id={selectedPrimaryIdentifier}
					value={selectedPrimaryIdentifier}
					label=''
				/>
			</div>
		</td>
	);
};

const CustomCellDelete = ({ handleDeleteSglnSection, dataItem, allDisabled }: any) => {
	return (
		<td>
			{!dataItem?.isPrimary && !allDisabled && (
				// eslint-disable-next-line jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions
				<span
					className='cursor-pointer text-primary'
					onClick={() => handleDeleteSglnSection(dataItem?.encManufacturerIdentifierId)}>
					<ShowIcon name='faTrash' />
				</span>
			)}
		</td>
	);
};

const IdentifierGrid = ({
	selectedPrimaryIdentifier,
	handlePrimaryIdentifierGridChange,
	identifierData,
	handleDeleteSglnSection,
	allDisabled,
}: any) => {
	const { t } = useTranslation('manufacturerModule');

	// eslint-disable-next-line react/no-unstable-nested-components
	const RadioButtonCell = (props: any) => (
		<CustomCell
			{...props}
			handlePrimaryIdentifierGridChange={handlePrimaryIdentifierGridChange}
			selectedPrimaryIdentifier={selectedPrimaryIdentifier}
			allDisabled={allDisabled}
		/>
	);

	// eslint-disable-next-line react/no-unstable-nested-components
	const DeleteButtonCell = (props: any) => (
		<CustomCellDelete
			{...props}
			handleDeleteSglnSection={handleDeleteSglnSection}
			allDisabled={allDisabled}
		/>
	);

	return (
		<Grid className='default-kendo-grid location-grid h-auto' data={identifierData}>
			<GridColumn
				field='manufacturerIdentifier'
				title={t('IDENTIFIER_TYPE')!!}
				width='150px'
			/>
			<GridColumn field='identifierValue' title={t('IDENTIFIER_VALUE')!!} width='200px' />
			<GridColumn field='sglnExtension' title={t('SGLN_EXTENSION')!!} width='200px' />
			<GridColumn field='sgln' title={t('SGLN')!!} width='150px' />
			<GridColumn title={t('IS_PRIMARY')!!} cell={RadioButtonCell} width='150px' />
			<GridColumn title='' cell={DeleteButtonCell} width='150px' />
		</Grid>
	);
};

export default IdentifierGrid;
