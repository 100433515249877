import { useEffect, useState } from 'react';
import { useForm } from 'react-hook-form';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faEye, faEyeSlash } from '@fortawesome/free-solid-svg-icons';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/stateHooks';

import CustomNotification from 'components/common/notification/CustomNotification';
// import PasswordVerification from 'components/login/PasswordVerification';
import { Inputs } from 'components/common/model';
import { RouteUrl } from 'utils/enum';
// import CustomCheckBox from 'components/common/forms/CustomCheckBox';
import CustomLoader from 'components/common/loader/CustomLoader';
import { setLoginValue } from 'slices/userLoginDataSlice';
import { MIN_LENGTH } from 'utils/constants';
import useLoginUser from './hooks/useLoginUser';
// import useGetUserDetails from './hooks/useGetUserDetails';

const eye = <FontAwesomeIcon icon={faEye} />;
const eyeSlash = <FontAwesomeIcon icon={faEyeSlash} />;

const Index = () => {
	const navigate = useNavigate();
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();

	const [passwordShown, setPasswordShown] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [errorTitle, setErrorTitle] = useState<string>('');
	const [showNotification, setShowNotification] = useState<boolean>(false);
	// const [checkboxChecked, setCheckboxChecked] = useState<boolean>(true);
	const [paramData, setParamData] = useState(null);
	const [buttonClick, setButtonClick] = useState<boolean>(false);
	// const [userDetailsParams, setUserDetailsParams] = useState(null);

	const togglePasswordVisiblity = () => {
		setPasswordShown((prevState) => !prevState);
	};

	const updateButtonClick = () => {
		setButtonClick(false);
	};

	const { loginValue, loginStatus, loginLoading } = useLoginUser(
		paramData,
		buttonClick,
		updateButtonClick,
	);

	// const { userDetailsResponse } = useGetUserDetails(userDetailsParams);

	useEffect(() => {
		setErrorMsg('');
		setErrorTitle('');
		setPasswordShown(false);
	}, []);

	// to set login value to redux for calling user data api
	useEffect(() => {
		dispatch(setLoginValue(loginValue));
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginValue]);

	useEffect(() => {
		if (loginStatus) {
			setButtonClick(false);
			if (loginValue.resultId === 100 && loginValue.token) {
				// const UserDetailsUrl: any = {
				// 	url: 'acg.com',
				// };
				// setUserDetailsParams(UserDetailsUrl);
				navigate(RouteUrl.DASHBOARD);
				// navigate(RouteUrl.OTPVERIFICATION);
			} else if (loginValue.resultId === 101) {
				setShowNotification(true);
				if (loginValue?.remainingAttempts) {
					const errorMessage = t('INVALID_USERNAME_PASWWORD_REMAINING_ATTEMPTS', {
						remainingAttempt: loginValue.remainingAttempts,
					});
					setErrorMsg(errorMessage);
				} else {
					setErrorMsg(t(loginValue.resultText)!!);
				}
				const message = t('INVALID_USERNAME_PASSWORD');
				setErrorTitle(message);
			} else if (
				loginValue.resultId === 102 ||
				loginValue.resultId === 103 ||
				loginValue.resultId === 104 ||
				loginValue.resultId === 105
			) {
				setShowNotification(true);
				setErrorMsg(t(loginValue.resultText)!!);
				setErrorTitle(t(loginValue.resultText)!!);
			}
			if (loginValue.resultId === 250) {
				navigate(RouteUrl.OTPVERIFICATION, {
					state: {
						id: loginValue.uniqueId,
					},
				});
			}
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [loginStatus, loginValue]);

	// useEffect(() => {
	// 	if (userDetailsResponse?.statusCode === '200') {
	// 		const apiLoginResponse: any = {
	// 			token: loginValue.token,
	// 			firstName: userDetailsResponse?.output?.firstName,
	// 			email: userDetailsResponse?.output?.email,
	// 			listPrivilege: userDetailsResponse?.output?.privilegeDetails.map((item: any) => {
	// 				return item.encPrivilegeId;
	// 			}),
	// 			userType: userDetailsResponse?.output?.userType,
	// 		};

	// 		// removeLoginedUserData();
	// 		dispatch(setUserLoginData(JSON.stringify(apiLoginResponse)));
	// 		// storeLoginedUserData(JSON.stringify(apiLoginResponse));
	// 		navigate(RouteUrl.DASHBOARD);
	// 	}
	// }, [loginValue, userDetailsResponse]);

	const {
		register,
		handleSubmit,
		formState: { errors },
	} = useForm<Inputs>();

	// useEffect(() => {
	// 	if (formState.isSubmitSuccessful) {
	// 		reset({ userid: '', password: '' });
	// 	}
	// }, [formState, reset]);

	const onSubmit = (data: Inputs) => {
		const param: any = {
			userName: data.userid,
			password: data.password,
			// "encUserTypeId": "string",
			url: 'acg.com',
		};

		setParamData(param);
		setButtonClick(true);
	};

	const handleClose = () => {
		setShowNotification(false);
	};

	// const toggleDialog = () => {
	// 	setVisible(!visible);
	// };

	// const handleChecked = () => {
	// 	setCheckboxChecked(!checkboxChecked);
	// };

	const forgotClickHandler = () => {
		navigate(RouteUrl.FORGOTPASSWORD);
	};

	return (
		<>
			<form onSubmit={handleSubmit(onSubmit)}>
				<div className='form-group'>
					<label className='form-label'>{t('USER_NAME')}</label>
					<input
						// className='form-control'
						className={
							errors?.userid ? 'input-validation-error form-control' : 'form-control'
						}
						placeholder={t('USERID')!!}
						type='text'
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('userid', {
							required: true,
							minLength: MIN_LENGTH,
						})}
					/>
					{/* user id validation */}
					{errors.userid && errors.userid.type === 'required' && (
						<p className='error-msg'>{t('USERNAME_REQUIRED')}</p>
					)}
					{errors.userid && errors.userid.type === 'minLength' && (
						<p className='error-msg'>{t('USERID_MINLENGTH')}.</p>
					)}
				</div>
				<div className='form-group'>
					<label className='form-label'>{t('PASSWORD')}</label>
					<input
						// className='form-control show-password-input'
						className={
							errors?.password
								? 'input-validation-error form-control show-password-input'
								: 'form-control show-password-input'
							// ? 'form-control show-password-input' // : errors?.password === undefined // ? 'form-control show-password-input' // : 'input-validation-error form-control show-password-input'
						}
						type={passwordShown ? 'text' : 'password'}
						placeholder={t('PASSWORD')!!}
						// eslint-disable-next-line react/jsx-props-no-spreading
						{...register('password', {
							required: true,
							minLength: 8,
						})}
					/>
					<i
						className='show-password'
						role='button'
						onKeyDown={togglePasswordVisiblity}
						tabIndex={0}
						onClick={togglePasswordVisiblity}>
						{passwordShown ? eye : eyeSlash}
					</i>
					{/* password validation */}
					{errors.password && errors.password.type === 'required' && (
						<p className='error-msg'>{t('PASSWORD_REQUIRED')}</p>
					)}
					{errors.password && errors.password.type === 'minLength' && (
						<p className='error-msg'>{t('PASSWORD_MINLENGTH')}</p>
					)}
				</div>

				<div className='remeber-reset-password-row'>
					<span />
					{/* <CustomCheckBox
						checked={checkboxChecked}
						labelPlacement='after'
						label='Remember me'
						disabled={false}
						handleChange={handleChecked}
						name='checkbox'
					/> */}
					<button
						className='forgot-password-btn'
						type='button'
						onClick={forgotClickHandler}>
						{t('FORGOT_PASSWORD')}
					</button>
				</div>
				<div>
					<button className='btn btn-primary h-44px w-100' type='submit'>
						{loginLoading ? <CustomLoader /> : t('LOGIN')}
					</button>
				</div>
			</form>

			{/* Kendo notification message */}
			{showNotification && (
				<CustomNotification
					message={errorMsg}
					title={errorTitle}
					onClose={handleClose}
					closable
					type={{ style: 'error', icon: true }}
				/>
			)}

			{/* {visible && <PasswordVerification toggleDialog={toggleDialog} />} */}
		</>
	);
};

export default Index;
