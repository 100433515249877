import { useState, memo, useEffect, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, MODE_VALUES } from 'utils/enum';

import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import BatchTrailSkelton from 'components/common/skeleton/BatchTrailSkelton';

const GenerateBatchDetails = lazy(() => import('./GenerateBatchDetails'));
const ViewBatchReport = lazy(() => import('./ViewBatchReport'));

const BatchReportDetails = () => {
	const location = useLocation();
	const { t } = useTranslation('reports');

	/**
	 * States
	 */
	const [mode, setMode] = useState<'view' | 'add' | 'approve' | 'review'>('add');
	const [auditTrailInfo, setBatchDetailsInfo] = useState<string>('');

	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.BATCH_DETAILS,
		ICON_DESCRIPTION_ID.BATCH_DETAILS_SHOW,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setBatchDetailsInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	/**
	 * Setting state from location(Passed when navigating)
	 */
	useEffect(() => {
		if (location?.state) {
			setMode(location?.state?.mode);
		}
	}, [location]);

	return (
		<div className='page-content add-product-group-module page-scroll'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{mode === MODE_VALUES.ADD && t('GENERATE_BATCH_DETAILS_REPORT')}
						{mode === MODE_VALUES.VIEW && t('VIEW_BATCH_DETAILS_REPORT')}
						{mode === MODE_VALUES.REVIEW && t('REVIEW_BATCH_DETAILS_REPORT')}
						{mode === MODE_VALUES.APPROVE && t('APPROVE_BATCH_DETAILS_REPORT')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={auditTrailInfo}
							moduleId={ICON_DESCRIPTION_ID.BATCH_DETAILS_SHOW}
						/>
					</h3>
				</div>
			</div>
			{location?.state?.mode === MODE_VALUES.ADD ? (
				<Suspense fallback={<BatchTrailSkelton />}>
					<GenerateBatchDetails />
				</Suspense>
			) : (
				<Suspense fallback={<BatchTrailSkelton />}>
					<ViewBatchReport />
				</Suspense>
			)}
		</div>
	);
};
export default memo(BatchReportDetails);
