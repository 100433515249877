import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const SerialNumberIndex = lazy(() => import('components/serialNumberManagement/SerialNumberIndex'));
const SerialNumberPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<SerialNumberIndex />
			</Suspense>
		</div>
	);
};

export default SerialNumberPage;
