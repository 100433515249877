import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_WEBLINK_DOWNLOAD_FILE } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useDownloadWeblink = (
	selectedFormat: String,
	downloadStatus: boolean,
	productId: string,
	weblinkDownloadType: number,
	resetDownloadStatus: () => void,
) => {
	const { t } = useTranslation('productModule');
	const dispatch = useDispatch();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (downloadStatus) {
			setLoading(true);
			axiosPrivateInstance
				.post(GET_WEBLINK_DOWNLOAD_FILE, {
					encIdValue: productId,
					fileType: selectedFormat,
					type: weblinkDownloadType,
				})
				.then((response: any) => {
					setLoading(false);
					resetDownloadStatus();

					// download file
					const blob: Blob = new Blob([response], {
						type: 'application/octet-stream',
					});

					const url: string = window.URL.createObjectURL(blob);
					const link: HTMLAnchorElement = document.createElement('a');
					link.href = url;
					link.download = `Web Link File.${selectedFormat}`;
					document.body.appendChild(link);
					link.click();
					document.body.removeChild(link);
					window.URL.revokeObjectURL(url);
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
					setLoading(false);
					resetDownloadStatus();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [downloadStatus]);

	return {
		loading,
	};
};

export default useDownloadWeblink;
