import { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { hasPrivileges } from 'utils/utils';
import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	MODULE_IDS,
	PASSWORD_VERIFICATION_ACTIONS,
	PASSWORD_VERIFICATION_TRANSACTIONS,
	Privileges,
	RouteUrl,
} from 'utils/enum';
import { useAppSelector } from 'hooks/stateHooks';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import { getModulesRequireAuth } from 'slices/configSlice';

import CustomButton from 'components/common/button/CustomButton';
import CustomLoader from 'components/common/loader/CustomLoader';
import ProductSkeleton from 'components/common/skeleton/ProductSkeleton';
import { IVedaPropertiesData, Idata } from 'components/common/model';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import PasswordVerification from 'components/login/PasswordVerification';
import ProductGroupListBox from './ProductGroupListBox';
import useGroupIdDetails from './hooks/useProductGroupIdDetails';
import IVedaPropertiesListBox from './IVedaPropertiesListBox';
import useGetAllConfigurableData from './hooks/useGetAllConfigurableData';
import useAddConfigurableData from './hooks/useAddConfigurableData';

const ProductGroupConfigurable = () => {
	const { t } = useTranslation('productGroup');
	const navigate = useNavigate();

	const location = useLocation();
	const getLoginUserData = useAppSelector(getUserLoginData);
	const [groupName, setgroupName] = useState<string>('');
	const [generalField, setgeneralField] = useState<any>([]);
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [configData, setConfigData] = useState<any>([]);

	const [groupId, setGroupId] = useState<any>(0);
	const [iVedaPropertiesField, setIVedaPropertiesField] = useState<IVedaPropertiesData[]>([]);
	const [iVedaPropertiesSelectedData, setIVedaPropertiesSelectedData] = useState<
		IVedaPropertiesData[]
	>([]);
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const [productConfigInfo, setProductConfigInfo] = useState<string>('');

	/**
	 * state for all the configurabled data
	 */
	const [generalFieldData, setGeneralFieldData] = useState<any>([]);
	const [iVedaFieldData, setIvedaFieldData] = useState<any>([]);

	/**
	 * reseting the submit state
	 */
	const resetAddProductConfigurableStatus = () => {
		setIsSubmitted(false);
	};

	/**
	 * hook for get all the configurabled data by id
	 */
	const { getAllConfigurableRes, getAllConfigurableResLoading } =
		useGetAllConfigurableData(groupId);

	/**
	 * saving configurabled data
	 */
	const { addConfigurableRes, addConfigurableResLoading } = useAddConfigurableData(
		isSubmitted,
		{ generalField, iVedaPropertiesField },
		groupId,
		resetAddProductConfigurableStatus,
	);

	/**
	 * hook for get all product group by ID
	 */
	const { resData, dataLoading } = useGroupIdDetails(groupId);

	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.PRODUCT_GROUP,
		ICON_DESCRIPTION_ID.PRODUCT_GROUP_CONFIGURABLE,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setProductConfigInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		if (resData) {
			setgroupName(resData[0]?.productGroup);
			setConfigData(resData[0]?.generalFields);
			setIVedaPropertiesSelectedData(resData[0]?.iVedaFields);
		}
	}, [resData]);

	/**
	 * set configurabled data
	 */
	useEffect(() => {
		if (getAllConfigurableRes) {
			setGeneralFieldData(getAllConfigurableRes[0]?.generalFields);
			setIvedaFieldData(getAllConfigurableRes[0]?.iVedaFields);
		}
	}, [getAllConfigurableRes]);

	useEffect(() => {
		setGroupId(location?.state && location?.state?.id);
	}, [location]);

	useEffect(() => {
		if (addConfigurableRes?.status) {
			navigate(RouteUrl.PRODUCTGROUP);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addConfigurableRes]);

	/**
	 * checking validation and submit form
	 */
	const handleSubmit = () => {
		if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
			setIsPasswordVerification(true);
		} else {
			setIsSubmitted(true);
		}
	};

	const getSelectedData = (data: Idata[]) => {
		setgeneralField(data);
	};

	const getSelectedIVedaPropertiesData = (data: Idata[]) => {
		setIVedaPropertiesField(data);
	};
	const handleToggle = () => {
		setIsPasswordVerification(false);
	};
	const handleVerificationSuccess = () => {
		// eslint-disable-next-line no-unused-expressions
		setIsSubmitted(true);
		handleToggle();
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<div className='page-content add-product-group-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={RouteUrl.PRODUCTGROUP} />
					<h3 className='canvas-title'>
						{t('CONFIGURABLE')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={productConfigInfo}
							moduleId={ICON_DESCRIPTION_ID.PRODUCT_GROUP_CONFIGURABLE}
						/>
					</h3>
				</div>
				{hasPrivileges(Privileges.AddProductGroup, getLoginUserData) && (
					<CustomButton
						buttonClassName='btn btn-primary h-44px icon-add-btn'
						disabled={addConfigurableResLoading}
						buttonName={
							addConfigurableResLoading ? (
								<CustomLoader />
							) : (
								// eslint-disable-next-line react/jsx-no-useless-fragment
								t('SAVE')
							)
						}
						handleClick={handleSubmit}
						type='button'
					/>
				)}
			</div>
			<div className='page-content-body'>
				<div className='box-container addGroup-box-container'>
					{dataLoading ? (
						<ProductSkeleton />
					) : (
						<div className='addGroup-box-container-inner product-group-scroll'>
							<div className='form-group'>
								<label className='form-label cstm-config-lbl'>
									{t('NAME_PRODUCT_GROUP')!!}
									<span className='end-user-product-header'> : {groupName}</span>
								</label>
							</div>

							<div className='product-group-list-box custom-product-configure'>
								<ProductGroupListBox
									getSelectedData={getSelectedData}
									groupData={generalFieldData}
									generalAttributesData={configData}
									loadingGeneralField={
										dataLoading || getAllConfigurableResLoading
									}
								/>
							</div>
							<div className='product-group-list-box style-type-2'>
								<IVedaPropertiesListBox
									getSelectedIVedaPropertiesData={getSelectedIVedaPropertiesData}
									iVedaPropertiesSelectedData={iVedaFieldData}
									iVedaPropertiesAttributesData={iVedaPropertiesSelectedData}
									loadingiVedaPropertiesField={
										dataLoading || getAllConfigurableResLoading
									}
								/>
							</div>
						</div>
					)}
				</div>
			</div>
			{isPasswordVerification &&
				modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT) && (
					<PasswordVerification
						onVerificationSuccess={handleVerificationSuccess}
						toggleDialog={handleToggle}
						actions={PASSWORD_VERIFICATION_ACTIONS.CONFIGURE}
						transactions={PASSWORD_VERIFICATION_TRANSACTIONS.PRODUCTGROUP}
						referenceId={groupId}
					/>
				)}
		</div>
	);
};

export default ProductGroupConfigurable;
