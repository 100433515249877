import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	productAreaIconsData: [],
	infoIconsDataStatus: false,
	l3SystemIconsData: [],
	settingsIconsData: [],
	dashboardIconsData: [],
	l4SystemIconsData: [],
};

export const infoIconsDataModuleSlice = createSlice({
	name: 'infoIconsDataModule',
	initialState,
	reducers: {
		setProductAreaIconsData: (state, action: PayloadAction<any>) => {
			state.productAreaIconsData = action.payload;
		},
		setInfoIconsDataStatus: (state, action: PayloadAction<any>) => {
			state.infoIconsDataStatus = action.payload;
		},
		setL3SystemIconsData: (state, action: PayloadAction<any>) => {
			state.l3SystemIconsData = action.payload;
		},
		setSettingsIconsData: (state, action: PayloadAction<any>) => {
			state.settingsIconsData = action.payload;
		},
		setDashboardIconsData: (state, action: PayloadAction<any>) => {
			state.dashboardIconsData = action.payload;
		},
		setL4SystemIconsData: (state, action: PayloadAction<any>) => {
			state.l4SystemIconsData = action.payload;
		},
	},
});

export const {
	setProductAreaIconsData,
	setInfoIconsDataStatus,
	setL3SystemIconsData,
	setSettingsIconsData,
	setDashboardIconsData,
	setL4SystemIconsData,
} = infoIconsDataModuleSlice.actions;

export const getProductAreaIconsData = (state: RootState) =>
	state.infoIconsDataModule.productAreaIconsData;
export const getInfoIconsDataStatus = (state: RootState) =>
	state.infoIconsDataModule.infoIconsDataStatus;
export const getL3SystemIconsData = (state: RootState) =>
	state.infoIconsDataModule.l3SystemIconsData;
export const getSettingsIconsData = (state: RootState) =>
	state.infoIconsDataModule.settingsIconsData;
export const getDashboardIconsData = (state: RootState) =>
	state.infoIconsDataModule.dashboardIconsData;
export const getL4SystemIconsData = (state: RootState) =>
	state.infoIconsDataModule.l4SystemIconsData;

export default infoIconsDataModuleSlice.reducer;
