const ConnectionMethodSkeleton = () => {
	return (
		<div className='dummy-skelton pad-connect '>
			<div className='box p-0'>
				<div className='skeleton p-0'>
					<div className='skeleton-left flex1 p-0 '>
						<div className='d-flex'>
							<div className='line connection-type w-50 me-2' />
							<div className='line connection-type w-50 me-2' />
							<div className='line connection-type w-50' />
						</div>
						<div className='d-flex'>
							<div className='line connection-type w-50 me-2' />
							<div className='line connection-type w-50' />
						</div>
						<div className='d-flex'>
							<div className='line connection-type w-50 me-2' />
							<div className='line connection-type w-50' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default ConnectionMethodSkeleton;
