import { useState, useEffect } from 'react';
import { useLocation } from 'react-router-dom';

import {
	Grid,
	GridToolbar,
	GridColumn as Column,
	GridFilterChangeEvent,
} from '@progress/kendo-react-grid';
import {
	CompositeFilterDescriptor,
	SortDescriptor,
	orderBy,
	filterBy,
	process,
} from '@progress/kendo-data-query';
import { columnProps, sortUpandDown, formateDate, timeFormat, filterOperators } from 'utils/utils';
import NoData from 'pages/NoData';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID } from 'utils/enum';
import { useTranslation } from 'react-i18next';
import GridSkeleton from 'components/common/skeleton/GridSkeleton';
import { SMALLGRIDPAGESIZE } from 'utils/constants';
import { PageState } from 'components/common/model';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';

import { Input } from '@progress/kendo-react-inputs';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat, getEnable24Hr } from 'slices/configSlice';
import {
	gridCommonDataStateChange,
	gridCommonExpandChange,
	gridCommonHeaderCellWithSortOption,
	gridCommonHeaderCellWithoutFilterOption,
	gridCommonPageChange,
	gridCommonSortChange,
	onCommonGridFilterChange,
	onCommonGridSearchChange,
} from 'components/common/grid/GridCommonFunctions';
import useGetFileHistoryData from './hooks/useGetFileHistoryData';

const initialDataState: PageState = { skip: 0, take: SMALLGRIDPAGESIZE };
const initialFilter: CompositeFilterDescriptor = {
	logic: 'and',
	filters: [{ field: 'ProductName', operator: 'contains', value: '' }],
};

const ExportHistoryL4 = () => {
	const [t] = useTranslation('productModule');
	const location = useLocation();

	const getCurrentDate = useAppSelector(getDataFormat);
	const enable24Hr = useAppSelector(getEnable24Hr);

	const [filter, setFilter] = useState(initialFilter);
	const [sortData, setSortData] = useState<Array<SortDescriptor>>([
		{ field: 'productName', dir: 'desc' },
	]);
	const [pagesizeValue, setPageSizeValue] = useState<number | string | undefined>();
	const [page, setPage] = useState<PageState>(initialDataState);
	const [sortDirection, setSortDirection] = useState<boolean>(true);

	const [fileHistoryData, setFileHistoryData] = useState<any>([]);
	const [fileHistoryDataCopy, setFileHistoryDataCopy] = useState<any>([]);

	const [searchValue, setSearchValue] = useState<any>();
	const [dataResult, setDataResult] = useState<any>(process(fileHistoryData, page));

	const [exportHistoryInfo, setExportHistoryInfo] = useState<string>('');

	const { fileHistoryResponseData, fileHistoryLoading } = useGetFileHistoryData(
		location?.state?.encLotNoId,
	);

	useEffect(() => {
		if (fileHistoryResponseData && fileHistoryResponseData.length > 0) {
			const tempData = fileHistoryResponseData.map(
				(item: any, index: number) => ({
					...item,
					slNo: index + 1,
					date: formateDate(item?.date, getCurrentDate),
					time: timeFormat(item.scanDate, enable24Hr),
					// stateString: item.isActive ? 'Active' : 'inactive',
				}),
				[],
			);

			setFileHistoryData(tempData);
			setFileHistoryDataCopy(tempData);
			setDataResult(tempData);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [fileHistoryResponseData]);

	// hook to update info icon description
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.EXPORT_HISTORY,
		ICON_DESCRIPTION_ID.EXPORT_HISTORY,
	);

	// sets icon description data in state
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setExportHistoryInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	// // filtering function based on redux state
	// useEffect(() => {
	// 	customFilteringFunction(filter, locationStatusFilterValue, 'isActive', setFilter);
	// 	// eslint-disable-next-line react-hooks/exhaustive-deps
	// }, [locationStatusFilterValue]);

	const handleSort = (field: any, dir: any) => {
		const dt = sortData;
		dt[0].field = field;
		dt[0].dir = dir ? 'asc' : 'desc';

		setSortDirection(!dir);

		setSortData(dt);
	};
	useEffect(() => {
		if (searchValue === '') {
			setFileHistoryData(fileHistoryDataCopy);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [searchValue]);

	useEffect(() => {
		sortUpandDown(sortData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [sortDirection]);

	return (
		<div className='page-content user-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{t('EXPORT_HISTORY')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={exportHistoryInfo}
							moduleId={ICON_DESCRIPTION_ID.EXPORT_HISTORY}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='box-container userlist-box-container l3-box-container'>
					{fileHistoryLoading ? (
						<GridSkeleton />
					) : (
						// eslint-disable-next-line react/jsx-no-useless-fragment
						<>
							{fileHistoryDataCopy && fileHistoryDataCopy?.length > 0 ? (
								<Grid
									className='default-kendo-grid'
									sortable={{
										allowUnsort: true,
									}}
									data={filterBy(
										orderBy(fileHistoryData, sortData),
										filter,
									).slice(page.skip, page.take + page.skip)}
									sort={sortData}
									onSortChange={(e: any) => gridCommonSortChange(e, setSortData)}
									pageable={{
										buttonCount: 5,
										info: false,
										type: 'numeric',
										pageSizes: false,
										previousNext: true,
										pageSizeValue: pagesizeValue,
									}}
									pageSize={page.take}
									skip={page.skip}
									take={page.take}
									total={filterBy(fileHistoryData, filter).length}
									onPageChange={(e: any) =>
										gridCommonPageChange(e, setPageSizeValue, setPage)
									}
									filter={filter}
									filterOperators={filterOperators}
									onFilterChange={(e: GridFilterChangeEvent) =>
										onCommonGridFilterChange(
											e,
											setFilter,
											page,
											setPage,
											SMALLGRIDPAGESIZE,
										)
									}
									style={{ height: '420px' }}
									onDataStateChange={(e: any) =>
										gridCommonDataStateChange(
											e,
											fileHistoryData,
											setDataResult,
											setPage,
											process,
										)
									}
									expandField='expanded'
									onExpandChange={(e: any) =>
										gridCommonExpandChange(e, setDataResult, dataResult)
									}>
									<GridToolbar className='default-kendoGrid-toolbar'>
										<div className='default-form-style w-100'>
											<div className='form-group search-box'>
												<label className='form-label'>{t('SEARCH')}</label>
												<Input
													className='form-control h-44px'
													value={searchValue}
													onChange={(ev: any) =>
														/* common search function for grid */

														onCommonGridSearchChange(
															ev,
															setSearchValue,
															fileHistoryDataCopy,
															setFileHistoryData,
															process,
															page,
															setPage,
															setDataResult,
															SMALLGRIDPAGESIZE,
														)
													}
												/>
											</div>
										</div>
									</GridToolbar>
									<Column
										field='slNo'
										title={t('SLNO')!!}
										width='110px'
										headerCell={(props: any) =>
											gridCommonHeaderCellWithoutFilterOption(
												props,
												handleSort,
												sortDirection,
												sortData,
											)
										}
										sortable
									/>
									<Column
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...columnProps('l4SystemName', filter)}
										headerCell={(props: any) =>
											gridCommonHeaderCellWithSortOption(
												props,
												handleSort,
												sortDirection,
												sortData,
											)
										}
										title={t('L4_SYSTEM_NAME')!!}
										width='300px'
									/>
									<Column
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...columnProps('location', filter)}
										headerCell={(props: any) =>
											gridCommonHeaderCellWithSortOption(
												props,
												handleSort,
												sortDirection,
												sortData,
											)
										}
										title={t('LOCATION')!!}
										width='300px'
									/>
									<Column
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...columnProps('date', filter)}
										headerCell={(props: any) =>
											gridCommonHeaderCellWithSortOption(
												props,
												handleSort,
												sortDirection,
												sortData,
											)
										}
										title={t('DATE')!!}
										width='300px'
									/>
									<Column
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...columnProps('time', filter)}
										headerCell={(props: any) =>
											gridCommonHeaderCellWithSortOption(
												props,
												handleSort,
												sortDirection,
												sortData,
											)
										}
										title={t('TIME')!!}
										width='250px'
									/>
									<Column
										// eslint-disable-next-line react/jsx-props-no-spreading
										{...columnProps('status', filter)}
										headerCell={(props: any) =>
											gridCommonHeaderCellWithSortOption(
												props,
												handleSort,
												sortDirection,
												sortData,
											)
										}
										title={t('STATUS')!!}
										width='250px'
									/>

									{/* <Column
										cell={CustomCell}
										sortable={false}
										field={t('ACTION')!!}
										filterable={false}
										filter='boolean'
										width='120px'
									/> */}
								</Grid>
							) : (
								<NoData />
							)}
						</>
					)}
				</div>
			</div>
		</div>
	);
};

export default ExportHistoryL4;
