import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';

import { ADD_MANUFACTURER_MANAGEMENT } from 'utils/serviceUrls';

import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR, RESULT_ID_105, SUCCESS, WARNING } from '../../../../utils/constants';

const useAddManufacturer = (manufacturerData: any, isSubmitted: boolean, setIsSubmitted: any) => {
	const { t } = useTranslation('manufacturerModule');

	const params = {
		name: manufacturerData.name ? manufacturerData.name?.trim() : null,
		street1Address: manufacturerData.street1Address
			? manufacturerData.street1Address?.trim()
			: null,
		street2Address: manufacturerData.street2Address
			? manufacturerData.street2Address?.trim()
			: null,
		zipCode: manufacturerData.zipCode ? manufacturerData.zipCode?.trim() : null,
		city: manufacturerData.city ? manufacturerData.city?.trim() : null,
		stateName: manufacturerData.stateName ? manufacturerData.stateName?.trim() : null,
		encCountryId: manufacturerData.encCountryId ? manufacturerData.encCountryId : null,
		subjectDescription: manufacturerData.subjectDescription
			? manufacturerData.subjectDescription?.trim()
			: null,
		gs1GtinPrefix: manufacturerData.gs1GtinPrefix ? manufacturerData.gs1GtinPrefix : null,
		contactName: manufacturerData.contactName ? manufacturerData.contactName?.trim() : null,
		emailId: manufacturerData.emailId ? manufacturerData.emailId?.trim() : null,
		phoneNo: manufacturerData.phoneNo ? manufacturerData.phoneNo?.trim() : null,
		identifierTypeList: manufacturerData.identifierTypeList
			? manufacturerData.identifierTypeList
			: null,
		isManufacturer: true,
		encManufacturerDataTypeId: manufacturerData?.encManufacturerDataTypeId
			? manufacturerData?.encManufacturerDataTypeId
			: null,
		encBusinessTypeId: manufacturerData?.encBusinessTypeId
			? manufacturerData?.encBusinessTypeId
			: null,
		actualFileName: manufacturerData?.actualFileName
			? manufacturerData?.actualFileName?.trim()
			: null,
		fileBase64: manufacturerData?.fileBase64 ? manufacturerData?.fileBase64 : null,
		encParentManufacturerId: manufacturerData?.encParentManufacturerId
			? manufacturerData?.encParentManufacturerId
			: null,
		district: manufacturerData?.district ? manufacturerData?.district?.trim() : null,
	};
	const [addManufacturerLoading, setAddManufacturerLoading] = useState<boolean>(false);
	const [addManufacturerResponse, setAddManufacturerResponse] = useState<any>({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSubmitted) {
			setAddManufacturerLoading(true);
			axiosPrivateInstance
				.post(ADD_MANUFACTURER_MANAGEMENT, params)
				.then((response: any) => {
					setAddManufacturerResponse(response);

					if (response?.status) {
						dispatch(setMessage(t('MANUFACTURER_ADDED_SUCCESSFULLY')));
						dispatch(setNotificationType(SUCCESS));
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText || 'MANUFACTURER_ADD_FAILED'),
							),
						);
						/**
						 * checking error result id to show alert as warning or error
						 */
						if (response?.output?.resultId === RESULT_ID_105) {
							dispatch(setNotificationType(WARNING));
						} else {
							dispatch(setNotificationType(ERROR));
						}
					}
				})
				.catch(() => {
					dispatch(setMessage(t('MANUFACTURER_ADD_FAILED')));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setAddManufacturerLoading(false);
					dispatch(setShowNotification(true));
				});
		}

		return () => {
			setIsSubmitted(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		addManufacturerLoading,
		addManufacturerResponse,
	};
};

export default useAddManufacturer;
