import { useNavigate, useParams } from 'react-router-dom';
import Spinner from 'components/common/Spinner';
import { useEffect, useState } from 'react';
import { storeRefreshToken, storeToken } from 'utils/utils';
import { useAppDispatch } from 'hooks/stateHooks';
import { setLoginValue } from 'slices/userLoginDataSlice';
import { RouteUrl } from 'utils/enum';
import CustomNotification from 'components/common/notification/CustomNotification';
import { useTranslation } from 'react-i18next';
import { ssoLoginTokenGenerateService } from './services/ssoAuthServices';

const SsoLoginSuccess = () => {
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const { t } = useTranslation('userModule');
	const { t: tSso } = useTranslation('sso');
	/**
	 * The page will show a loader during sso auth process
	 */
	const [loader, setLoader] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [errorTitle, setErrorTitle] = useState<string>('');
	const [showNotification, setShowNotification] = useState<boolean>(false);
	// 👇️ get token from url
	const params = useParams();
	let token: string = '';
	if (params?.token) {
		token = params.token;
	}

	/**
	 * Function to call a service to get token and refresh token
	 * @returns null
	 */
	const ssoLoginVerify = async (tokenParam: string) => {
		setLoader(true);
		const apiRes: any = await ssoLoginTokenGenerateService(tokenParam);
		
		if (apiRes === 'error') {
			setShowNotification(true);
			setErrorMsg(tSso('API_ERROR_MESSAGE')!!);
			setErrorTitle(tSso('API_ERROR_TITLE')!!);
			return true;
		}
		// resultId = 100 and corresponding cases omitted for now, as suggested by BE
		if ( apiRes?.token && apiRes?.refreshToken) {
			dispatch(setLoginValue(apiRes));
			storeToken(apiRes.token);
			storeRefreshToken(apiRes?.refreshToken);
			
			
			navigate(RouteUrl.DASHBOARD);
		} else if (apiRes?.resultId === 101) {
			setShowNotification(true);
			setErrorMsg(t(apiRes.resultText)!!);
			setErrorTitle('Invalid Username or Password'); // to be added in translations
		} else if (
			apiRes?.resultId === 102 ||
			apiRes?.resultId === 103 ||
			apiRes?.resultId === 104 ||
			apiRes?.resultId === 105
		) {
			setShowNotification(true);
			setErrorMsg(t(apiRes?.resultText)!!);
			setErrorTitle(t(apiRes?.resultText)!!);
		}
		if (apiRes?.resultId === 250) {
			navigate(RouteUrl.OTPVERIFICATION, {
				state: {
					id: apiRes?.uniqueId,
				},
			});
		}
		return true;
	};

	useEffect(() => {
		ssoLoginVerify(token);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);
	if (loader) {
		return <Spinner />;
	}
	return (
		<>
			{/* Kendo notification message */}
			{showNotification && (
				<CustomNotification
					message={errorMsg}
					title={errorTitle}
					onClose={() => setShowNotification(false)}
					closable
					type={{ style: 'error', icon: true }}
				/>
			)}
		</>
	);
};

export default SsoLoginSuccess;
