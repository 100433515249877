import { useTranslation } from 'react-i18next';
import CustomButton from 'components/common/button/CustomButton';
import { useAppSelector } from 'hooks/stateHooks';
import { getModulesRequireAuth } from 'slices/configSlice';
import { getPasswordVerificationAction } from 'utils/utils';
import {
	AWAITING_ACION_REPORT_TYPE,
	AWAITING_ACION_TYPES,
	AWAITING_ACTIONS_TYPES,
	MODE_VALUES,
	MODULE_IDS,
	PASSWORD_VERIFICATION_TRANSACTIONS,
} from 'utils/enum';
import CustomLoader from 'components/common/loader/CustomLoader';

const ReviewButton = ({
	item,
	setAction,
	handleReview,
	setPasswordVerificationActions,
	setPasswordVerificationTransactions,
	setIsPasswordVerification,
	loader,
	loaderType,
	// setLoader,
	setLoaderType,
	disabled,
}) => {
	const { t } = useTranslation('dashboard');
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);

	const handleAwaitReview = () => {
		// setLoader(true);
		setLoaderType(AWAITING_ACION_TYPES.REVIEW);
		const message = 'Reviewed';
		setAction(MODE_VALUES.REVIEW);

		// PRODUCT ORDER
		if (item?.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_ORDER) {
			if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)) {
				setIsPasswordVerification(true);
				setPasswordVerificationTransactions(
					PASSWORD_VERIFICATION_TRANSACTIONS.PRODUCTORDER,
				);
				setPasswordVerificationActions(getPasswordVerificationAction(item));
			} else {
				handleReview(message);
			}
		}
		// PO WEBLINK
		else if (item?.typeId === AWAITING_ACTIONS_TYPES.PO_WEBLINK) {
			if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_ORDER_MANAGEMENT)) {
				setIsPasswordVerification(true);
				setPasswordVerificationTransactions(PASSWORD_VERIFICATION_TRANSACTIONS.POWEBLINK);
				setPasswordVerificationActions(getPasswordVerificationAction(item));
			} else {
				handleReview(message);
			}
		}
		// PRODUCT
		else if (item?.typeId === AWAITING_ACTIONS_TYPES.PRODUCT) {
			if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
				setIsPasswordVerification(true);
				setPasswordVerificationTransactions(PASSWORD_VERIFICATION_TRANSACTIONS.PRODUCT);
				setPasswordVerificationActions(getPasswordVerificationAction(item));
			} else {
				handleReview(message);
			}
		}
		// PRODUCT WEBLINK
		else if (item?.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_WEBLINK) {
			if (modulesRequireAuth.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
				setIsPasswordVerification(true);
				setPasswordVerificationTransactions(
					PASSWORD_VERIFICATION_TRANSACTIONS.PRODUCTWEBLINK,
				);
				setPasswordVerificationActions(getPasswordVerificationAction(item));
			} else {
				handleReview(message);
			}
		}
		// WEBLINK VALIDATION
		else if (item?.typeId === AWAITING_ACTIONS_TYPES.WEBLINK_VALIDATION) {
			if (modulesRequireAuth.includes(MODULE_IDS.WEBLINK_VALIDATOR)) {
				setIsPasswordVerification(true);
				setPasswordVerificationTransactions(
					PASSWORD_VERIFICATION_TRANSACTIONS.WEBLINKVALIDATOR,
				);
				setPasswordVerificationActions(getPasswordVerificationAction(item));
			} else {
				handleReview(message);
			}
		}
		// REPORTS
		else if (item?.typeId === AWAITING_ACTIONS_TYPES.REPORT) {
			if (modulesRequireAuth.includes(MODULE_IDS.REPORTS)) {
				setIsPasswordVerification(true);
				setPasswordVerificationActions(getPasswordVerificationAction(item));

				if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
					setPasswordVerificationTransactions(
						PASSWORD_VERIFICATION_TRANSACTIONS.AUDITLOG,
					);
				} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
					setPasswordVerificationTransactions(
						PASSWORD_VERIFICATION_TRANSACTIONS.BATCHSUMMERYREPORT,
					);
				} else if (
					item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT
				) {
					setPasswordVerificationTransactions(
						PASSWORD_VERIFICATION_TRANSACTIONS.BATCHDETAILSREPORT,
					);
				}
			} else {
				handleReview(message);
			}
		}
	};
	return (
		<CustomButton
			buttonClassName='dsb-btn review'
			type='button'
			buttonName={
				loaderType === AWAITING_ACION_TYPES.REVIEW && loader ? (
					<span className='loader-primary'>
						<CustomLoader />
					</span>
				) : (
					t('REVIEW')
				)
			}
			handleClick={() => handleAwaitReview()}
			disabled={disabled}
		/>
	);
};

export default ReviewButton;
