const CreateL3Skeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0'>
				<div className='skeleton p-0 '>
					<div className='skeleton-left flex1 p-0 '>
						<div className='d-flex'>
							<div className='line product-line w-50 me-3' />
							<div className='line product-line w-16 me-2' />
							<div className='line product-line w-16 me-2' />
							<div className='line product-line w-16' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-25 me-2 h-7' />
							<div className='line product-line w-25 me-3 h-7' />
							<div className='right-flex-wrap '>
								<div className='d-flex w-100'>
									<div className='line product-line w-50 me-2' />
									<div className='line product-line w-50 ' />
								</div>
								<div className='d-flex w-100'>
									<div className='line product-line w-50 me-2' />
									<div className='line product-line w-50 ' />
								</div>
							</div>
						</div>
						<div className='d-flex'>
							<div className='line product-line w-24 me-2 ' />
							<div className='line product-line w-24 me-3' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-50 me-3' />
							<div className='line product-line w-50 ' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-50 me-3' />
							<div className='line product-line w-50 me-3 d-none' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-50 me-3' />
							<div className='line product-line w-50 me-3 d-none' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default CreateL3Skeleton;
