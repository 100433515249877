import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	showNotification: false,
	message: '',
	notificationType: '',
	notificationCount: 0,
	getAllNotification: false,
};

export const notificationModuleSlice = createSlice({
	name: 'notificationModule',
	initialState,
	reducers: {
		setNotificationCount: (state, action: PayloadAction<any>) => {
			state.notificationCount = action.payload >= 0 ? action.payload : 0;
		},
		setShowNotification: (state, action: PayloadAction<any>) => {
			state.showNotification = action.payload;
		},
		setMessage: (state, action: PayloadAction<any>) => {
			state.message = action.payload;
		},
		setNotificationType: (state, action: PayloadAction<any>) => {
			state.notificationType = action.payload;
		},
		setGetAllNotification: (state, action: PayloadAction<any>) => {
			state.getAllNotification = action.payload;
		},
	},
});

export const {
	setShowNotification,
	setMessage,
	setNotificationType,
	setNotificationCount,
	setGetAllNotification,
} = notificationModuleSlice.actions;

export const getNotificationCount = (state: RootState) => state.notification.notificationCount;
export const getShowNotification = (state: RootState) => state.notification.showNotification;
export const getMessage = (state: RootState) => state.notification.message;
export const getNotificationType = (state: RootState) => state.notification.notificationType;
export const getAllNotificationStatus = (state: RootState) => state.notification.getAllNotification;

export default notificationModuleSlice.reducer;
