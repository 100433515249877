import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	pGroupUpdateStatus: false,
};

export const productGroupSlice = createSlice({
	name: 'productGroup',
	initialState,
	reducers: {
		setpGroupUpdateStatus: (state, action: PayloadAction<any>) => {
			state.pGroupUpdateStatus = action.payload;
		},
		setpGroupStatusUpdate: (state, action: PayloadAction<any>) => {
			state.pGroupUpdateStatus = action.payload;
		},
	},
});

export const { setpGroupUpdateStatus, setpGroupStatusUpdate } = productGroupSlice.actions;

export const getpGroupUpdateStatus = (state: RootState) => state.productGroup.pGroupUpdateStatus;
export const getpGroupStatusUpdate = (state: RootState) => state.productGroup.pGroupUpdateStatus;

export default productGroupSlice.reducer;
