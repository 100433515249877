import { useState, memo, cloneElement, ReactElement } from 'react';
import { DropDownList } from '@progress/kendo-react-dropdowns';

import { ICustomAutoComplete } from '../model';
import CustomError from '../errorLabel/CustomError';

/**
 * Custom autocomplete/searchable dropdown component.
 *
 * @param {Object} props - The props passed to the component
 * @param {Function} props.handleDropDownChange - Change handler function
 * @param {any} props.dropdownValue - Selected value
 * @param {Array} props.options - Dropdown options data
 * @param {string} [props.className] - Optional CSS class name
 * @param {string} props.dataItemKey - Data item key field
 * @param {string} props.dataTextField - Data text field
 * @param {boolean} [props.disabled] - Whether disabled
 * @param {string} [props.label] - Label text
 * @param {boolean} [props.isMandatory] - Whether required
 * @param {string} [props.placeHolder] - Placeholder text
 * @param {Object} [props.popupSettings] - Popup settings
 * @param {any} [props.defaultValue] - Default selected value
 * @param {boolean} [props.returnOtherItems] - Allow custom values
 * @param {boolean} [props.isId=false] - Uses id instead of text value
 * @param {string} [props.error] - Error message
 * @param {Function} props.onFilterChange - Filter change handler
 * @param {boolean} [props.loading] - Loading indicator
 * @param {boolean} [props.isSearched=true] - Whether search is performed
 */
const CustomAutoComplete = ({
	handleDropDownChange,
	dropdownValue,
	options,
	className,
	dataItemKey,
	dataTextField,
	disabled,
	label,
	isMandatory,
	placeHolder,
	popupSettings,
	defaultValue,
	returnOtherItems,
	isId = false,
	error,
	onFilterChange,
	loading,
	isSearched = true,
}: ICustomAutoComplete) => {
	const [filterValue, setFilterValue] = useState('');

	/**
	 * Handle change event on dropdown.
	 * @param {Object} event - The change event object
	 */
	const onChange = (event: any) => {
		/**
		 * If returnOtherItems prop is passed, return event object along with other items
		 */
		if (returnOtherItems) {
			handleDropDownChange({ ...event, others: returnOtherItems });
		} else if (isId) {
			/**
			 * If isId prop is true, return just the id from the value
			 */
			handleDropDownChange(event.value?.[dataItemKey]);
		} else {
			/**
			 * Otherwise return the full value
			 */
			handleDropDownChange(event.value);
		}
		/** on selecting value from list searched field clearing */
		setFilterValue('');
	};

	/**
	 * @returns Selected value from options
	 */
	const selectedValue = () => {
		const val = options.filter((item: any) => {
			return item[dataItemKey] === dropdownValue;
		});
		if (val.length === 0) {
			return { [dataItemKey]: '', [dataTextField]: 'Please select' };
		}
		return val[0];
	};

	const handleFilterChange = (e: any) => {
		onFilterChange(e.filter.value);
		/**
		 * filter value setting to state to show in input field
		 */
		setFilterValue(e.filter.value);
	};
	/**
	 * Render no date on if no data. if server side search showing 3 letter input to search
	 */
	const listNoDataRender = (element: ReactElement<HTMLDivElement>) => {
		const noData = (
			<h4 style={{ fontSize: '1em' }}>
				{isSearched ? 'No data found.' : 'Please enter 3 letters to search.'}
			</h4>
		);

		return cloneElement(element, { ...element.props }, noData);
	};

	return (
		<>
			<label className='form-label'>{label}</label>
			{isMandatory && <span>*</span>}
			<div className={disabled ? 'kWrapper disabled' : 'kWrapper'}>
				<DropDownList
					listNoDataRender={listNoDataRender}
					disabled={disabled}
					popupSettings={popupSettings || {}}
					data={options}
					dataItemKey={dataItemKey}
					textField={dataTextField}
					value={isId ? selectedValue() : dropdownValue}
					onChange={onChange}
					className={disabled ? `${className} disabled` : className || ''}
					label={placeHolder || ''}
					filterable
					loading={loading}
					filter={filterValue}
					onFilterChange={handleFilterChange}
					defaultItem={
						!isId
							? defaultValue
							: { [dataItemKey]: '', [dataTextField]: 'Please select' }
					}
				/>
			</div>
			{error && error !== '' && <CustomError value={error} />}
		</>
	);
};
export default memo(CustomAutoComplete);
