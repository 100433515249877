import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/stateHooks';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_FILE_FAILURE_COUNT_URL } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from 'utils/constants';

const useGetFileFailedCount = () => {
	const { t } = useTranslation('fileFailure');
	const dispatch = useAppDispatch();
	const [fileFailureCountDataRes, setFileFailureCountDataRes] = useState<any>({});
	const [fileFailureCountLoading, setFileFailureCountLoading] = useState<boolean>(false);

	useEffect(() => {
		setFileFailureCountLoading(true);
		axiosPrivateInstance
			.post(GET_FILE_FAILURE_COUNT_URL, {})
			.then((response: any) => {
				if (response?.output && response?.status) {
					setFileFailureCountDataRes(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			})
			.finally(() => {
				setFileFailureCountLoading(false);
			});

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		fileFailureCountDataRes,
		fileFailureCountLoading,
	};
};

export default useGetFileFailedCount;
