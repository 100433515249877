const ProductLoaderSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0'>
				<div className='skeleton '>
					<div className='skeleton-left flex1 '>
						<div className='line product-line mbm-25' />
						<div className='line product-line mbm-25' />
						<div className='line product-line mbm-25' />
					</div>
					<div className='skeleton-left flex1 h-100'>
						<div className='line product-line mbm-25' />
						<div className='line product-line mbm-25' />
						<div className='line product-line mbm-25' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ProductLoaderSkeleton;
