import { useEffect, useState } from 'react';
import { axiosPrivateInstance } from 'libs/axios';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { APPROVE_WEBLINK_STATUS_IN_VALIDATOR } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

import { IResDataWeblink } from 'components/common/model';

const useWeblinkApprove = (isStatusSumitted: boolean, formData, updateWeblinkStatus: any) => {
	const { t } = useTranslation('l3System');
	const dispatch = useDispatch();

	const [approveLoading, setApproveLoading] = useState<boolean>(false);
	const [resApproveStatusData, setResApproveStatusData] = useState<IResDataWeblink>();

	useEffect(() => {
		if (isStatusSumitted) {
			setApproveLoading(true);
			setResApproveStatusData(undefined);
			axiosPrivateInstance
				.post(APPROVE_WEBLINK_STATUS_IN_VALIDATOR, formData)
				.then((response: any) => {
					setResApproveStatusData(response);
					if (response?.status) {
						dispatch(
							setMessage(t(response?.output?.resultText || 'WEBLINK_ACTION_SUCCESS')),
						);
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(t(response?.output?.resultText || 'WEBLINK_ACTION_FAILED')),
						);
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					setMessage(t('WEBLINK_ACTION_FAILED'));
				})
				.finally(() => {
					updateWeblinkStatus();
					setApproveLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isStatusSumitted]);

	return {
		approveLoading,
		resApproveStatusData,
	};
};

export default useWeblinkApprove;
