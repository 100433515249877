import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_IVEDA_SCHEDULE } from 'utils/serviceUrls';

const getAllIvedaScheduleService = async () => {
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(GET_ALL_IVEDA_SCHEDULE)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default getAllIvedaScheduleService;
