import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const BatchPO = lazy(() => import('components/batchPO/Index'));

const ProductOrder = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<BatchPO />
			</Suspense>
		</div>
	);
};
export default ProductOrder;
