import React from 'react';
import ReactDOM from 'react-dom/client';
import { BrowserRouter } from 'react-router-dom';

import { Provider } from 'react-redux';
import './index.scss';
import App from 'App';
import reportWebVitals from './reportWebVitals';
import '@progress/kendo-theme-default/dist/all.css';
import './libs/i18n/config';
import 'bootstrap/dist/css/bootstrap.min.css';
import { store } from './store';
import 'azure-maps-control/dist/atlas.min.css';

const root = ReactDOM.createRoot(document.getElementById('root') as HTMLElement);
root.render(
	<Provider store={store}>
		<BrowserRouter>
			{/* <React.StrictMode> */}
			<App />
			{/* </React.StrictMode> */}
		</BrowserRouter>
	</Provider>,
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
