import EndUserScanIndex from 'components/endUserScan/index';
import UseDemoWeblinkFinder from 'components/endUserScanDemo/Hooks/UseDemoWeblinkFinder';
import EndUserDemo from './EndUserDemo';

const EndUserScan = () => {
	const { isDemo, demoType } = UseDemoWeblinkFinder();
	if (isDemo) {
		return <EndUserDemo demoType={demoType} />;
	}
	return <EndUserScanIndex />;
};

export default EndUserScan;
