const ViewAllAwaitingActionSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0'>
				<div className='skeleton p-0'>
					<div className='skeleton-left flex1 p-0'>
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
						<div className='line product-line file-failed' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default ViewAllAwaitingActionSkeleton;
