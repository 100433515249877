import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_GENERAL_FIELD } from 'utils/serviceUrls';

import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';

const useGetAllGeneralFields = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('productGroup');

	const [generalAttributes, setgeneralAttributes] = useState<any>([]);
	const [loadingGeneralField, setloadingGeneralField] = useState<boolean>(false);

	useEffect(() => {
		setloadingGeneralField(true);
		axiosPrivateInstance
			.post(GET_ALL_GENERAL_FIELD)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setgeneralAttributes(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setloadingGeneralField(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		generalAttributes,
		loadingGeneralField,
	};
};

export default useGetAllGeneralFields;
