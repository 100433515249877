import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import CustomInput from 'components/common/forms/CustomInput';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';

import { getAvailablePoolSize, getweblinkPoolSize, setweblinkPoolSize } from 'slices/l3SystemSlice';
import { getL3SystemIconsData } from 'slices/infoIconsDataSlice';
import { ICON_DESCRIPTION_ID } from 'utils/enum';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';

const WeblinkSettings = ({ errorState }: any) => {
	const { t } = useTranslation('l3System');
	const dispatch = useAppDispatch();

	const weblinkPoolSize = useAppSelector(getweblinkPoolSize);
	const availablePoolSize = useAppSelector(getAvailablePoolSize);

	const l3SystemIconsData = useAppSelector(getL3SystemIconsData);
	const [webLinkSettingsInfo, setWebLinkSettingsInfo] = useState<string>('');

	// finds l3 connection method info from redux state
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		l3SystemIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			l3SystemIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.WEB_LINK_SETTINGS) {
					setWebLinkSettingsInfo(item?.iconDescription);
				}
			});
	}, [l3SystemIconsData]);

	const onWeblinkPoolSizeChange = (e: any) => {
		dispatch(setweblinkPoolSize(e.target.value));
	};

	return (
		<div className='section-wrapper'>
			<h4 className='form-section-title mb-3 mt-3 info-title'>
				{t('WEBLINK_SETTINGS_HEADING')}

				<CustomEditableTooltip
					showIcon='faInfoCircle'
					text={webLinkSettingsInfo}
					moduleId={ICON_DESCRIPTION_ID.WEB_LINK_SETTINGS}
				/>
			</h4>

			<div className='row'>
				<div className='col-lg-12 col-md-12 col-sm-12'>
					<div className='form-group input-type-number type-two'>
						<CustomInput
							className=''
							value={weblinkPoolSize}
							label={t('WEBLINK_POOLSIZE')!!}
							onChange={(e: any) => onWeblinkPoolSizeChange(e)}
							name='weblinkPoolSize'
							placeholder={t('WEBLINK_POOLSIZE')}
							required
							type='number'
							isMandatory
							minLength={1}
							maxLength={1000000}
							error={errorState.weblinkPoolSize}
						/>
					</div>
				</div>
			</div>
			{availablePoolSize !== '' && (
				<div className='row'>
					<div className='col-lg-12 col-md-12 col-sm-12'>
						<div className='form-group'>
							<label className='form-label'>
								{t('AVAILABLE_POOL_SIZE')} : {availablePoolSize}
							</label>
						</div>
					</div>
				</div>
			)}
		</div>
	);
};

export default WeblinkSettings;
