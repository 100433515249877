import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ADD_SERIAL_NUMBER } from 'utils/serviceUrls';
import { ERROR, RESULT_ID_105, WARNING } from 'utils/constants';

import { axiosPrivateInstance } from 'libs/axios';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useAddSerialNumberTemplate = (
	isSubmitted: boolean,
	formData: any,
	updateIsSerialNumberTemplateAdded: any,
) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('serialNumber');

	const [addSerialNumberTemplateResponse, setAddSerialNumberTemplateResponse] = useState<any>([]);
	const [addSerialNumberTemplateLoading, setAddSerialNumberTemplateLoading] =
		useState<boolean>(false);

	useEffect(() => {
		if (isSubmitted) {
			setAddSerialNumberTemplateLoading(true);
			axiosPrivateInstance
				.post(ADD_SERIAL_NUMBER, formData)
				.then((response: any) => {
					setAddSerialNumberTemplateResponse(response?.output);
					if (response?.status) {
						dispatch(setMessage(t('SERIAL_NUMBER_TEMPLATE_CREATION_SUCCESS')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(
								t(
									response?.output?.resultText ||
										'SERIAL_NUMBER_TEMPLATE_CREATION_FAILED',
								),
							),
						);
						dispatch(setShowNotification(true));
						if (response?.output?.resultId === RESULT_ID_105) {
							dispatch(setNotificationType(WARNING));
						} else {
							dispatch(setNotificationType(ERROR));
						}
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SERIAL_NUMBER_TEMPLATE_CREATION_FAILED')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setAddSerialNumberTemplateLoading(false);
					updateIsSerialNumberTemplateAdded();
				});
		}
		return () => {
			updateIsSerialNumberTemplateAdded();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		addSerialNumberTemplateResponse,
		addSerialNumberTemplateLoading,
	};
};

export default useAddSerialNumberTemplate;
