const AddProductGroupSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box'>
				<div className='skeleton h-34'>
					<div className='skeleton-left flex1 '>
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
					</div>
					<div className='skeleton-left flex1 h-100 resp-tab p-0'>
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddProductGroupSkeleton;
