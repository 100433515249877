import { faCalendar } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { IRecentProduct } from 'components/common/model';
import { useAppSelector } from 'hooks/stateHooks';
import { getDataFormat } from 'slices/configSlice';
import { utcFormat } from 'utils/utils';

const RecentProductCard = ({ product }: IRecentProduct) => {
	const dateFormat = useAppSelector(getDataFormat);

	return (
		<li className='min-h-auto list-style-2'>
			<p>
				<span className='product-name'>{product?.productName}</span>,
				<span>{product?.manufacturerName}</span>
			</p>
			<div className='dsb-date-container pb-0'>
				<div className='dsb-date-wrap'>
					<span className='dsb-value-lbl'>
						<span className='icon me-2'>
							<FontAwesomeIcon icon={faCalendar} />
						</span>
						{utcFormat(product?.createdDate, dateFormat)}
					</span>
				</div>
			</div>
		</li>
	);
};

export default RecentProductCard;
