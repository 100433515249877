const BatchTrailSkelton = () => {
	return (
		<div className='dummy-skelton '>
			<div className='box'>
				<div className='skeleton batch-detail-skeleton '>
					<div className='skeleton-left flex1 '>
						<div className='line product-line' />
						<div className='line product-line' />
					</div>
					<div className='skeleton-left flex1 '>
						<div className='line product-line' />
						<div className='line product-line' />
					</div>
					<div className='skeleton-left flex1 '>
						<div className='line product-line' />
					</div>

					<div className='skeleton-left flex1 h-100 p-0'>
						<div className='line product-line' />
					</div>
				</div>
				<div className='skeleton skeleton-left batch-detail-square'>
					<div className='square me-3' />
					<div className='square' />
				</div>
				<div className='skeleton skeleton-left batch-detail-square'>
					<div className='square' />
				</div>
			</div>
		</div>
	);
};

export default BatchTrailSkelton;
