import { useTranslation } from 'react-i18next';

import { utcFormat } from 'utils/utils';

import ScanHistoryList from 'components/common/scanHistory/ScanHistoryList';
import { END_USER_TRANSLATION_KEY } from 'utils/constants';
import DemoDownloadELeaflet from './DemoDownloadELeaflet';

const DemoScannedProductDetails = ({ productDetails, scanHistoryData, statusFieldShow }: any) => {
	const { t } = useTranslation('endUserScan');

	return (
		<>
			{statusFieldShow && (
				<span
					className={
						productDetails?.productStatus ? 'sv-btn  bg-success' : 'sv-btn bg-danger'
					}>
					{productDetails?.productStatus ? 'Valid' : 'Invalid'}
				</span>
			)}

			{/* <h3>{productDetails?.productName}</h3> */}
			{productDetails?.parentCompanyName && (
				<span className='company-name'>{productDetails?.parentCompanyName}</span>
			)}
			{/* <p>{productDetails?.productDescription}</p> */}
			<div className='scanned-card-wrap'>
				<div className='row row-gutter-10px'>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>Unique product identification code:</span>
							<span className='mfg-value-lbl'>
								{productDetails?.productCode || 'N/A'}
							</span>
						</div>
					</div>
					{/* <div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('GENERIC_NAME')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.genericName || 'N/A'}
							</span>
						</div>
					</div> */}
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>Name and Address of the manufacturer:</span>
							<span className='mfg-value-lbl'>
								{productDetails?.manufacturer} <br />
								{productDetails?.address}
								{/* {productDetails?.manufacturer && <br />}
								{productDetails?.address || 'N/A'} */}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>Batch number:</span>
							<span className='mfg-value-lbl'>{productDetails?.lotNo || 'N/A'}</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>Date of Manufacturing:</span>
							<span className='mfg-value-lbl'>
								{productDetails?.manufacturingDate
									? utcFormat(
											productDetails.manufacturingDate,
											productDetails?.manufacturingAndExpiryDateFormat,
									  )?.toUpperCase()
									: 'N/A'}
							</span>
						</div>
					</div>
					<div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>Date of Expiry:</span>
							<span className='mfg-value-lbl'>
								{productDetails?.expiryDate
									? utcFormat(
											productDetails.expiryDate,
											productDetails?.manufacturingAndExpiryDateFormat,
									  )?.toUpperCase()
									: 'N/A'}
							</span>
						</div>
					</div>
					{/* <div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('BRAND_NAME')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.brandName || 'N/A'}
							</span>
						</div>
					</div> */}
					{/* <div className='col-lg-3 col-md-6 col-6 col-sm-12 gutter-10px mb-10px'>
						<div className='scanned-products-card'>
							<span className='mfg-lbl'>{`${t('LICENSE_NO')}:`}</span>
							<span className='mfg-value-lbl'>
								{productDetails?.licenseNumber || 'N/A'}
							</span>
						</div>
					</div> */}
				</div>

				<div className='row row-gutter-10px'>
					<div className='col-lg-6 col-md-6 col-sm-6 gutter-10px'>
						<div className='download-product-file ms-0'>
							<DemoDownloadELeaflet
							// productName={productDetails.productName}
							/>
						</div>
					</div>
				</div>
			</div>

			{productDetails?.isEndUserScanEnabled && (
				<>
					<div className='scan-wrap'>
						<h4 className='scan-lbl'>{`${t('SCAN_HISTORY')}:`}</h4>
						<span className='scan-score'>
							{productDetails?.scanHistoryCount || 'N/A'}
						</span>
					</div>

					<div className='default-kendo-grid'>
						<ScanHistoryList
							scanHistoryData={scanHistoryData}
							translationkey={END_USER_TRANSLATION_KEY}
						/>
					</div>
				</>
			)}
		</>
	);
};

export default DemoScannedProductDetails;
