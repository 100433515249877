import LoginLogo from 'assets/images/ALSC-Logo-menu.svg';
import ForgotPassword from '../components/login/ForgotPassword';

const ForgotPasswordWrapper = () => {
	return (
		<div className='login-form forgot-form'>
			<div className='logo-box-sm'>
				<img src={LoginLogo} alt='Panax Pharma' />
			</div>
			<ForgotPassword />
		</div>
	);
};
export default ForgotPasswordWrapper;
