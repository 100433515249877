import { axiosPrivateInstance } from 'libs/axios';
import { ADD_PRODUCT_DETAILS } from 'utils/serviceUrls';

const addProductDetailsService = async (params: any) => {
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(ADD_PRODUCT_DETAILS, params)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default addProductDetailsService;
