import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { productFormValidations } from 'utils/utils';
import { IDENTIFIER_TYPE_GLN, MANUFACTURER_LOGO_NAME, PARTNER_DATA_TYPE } from 'utils/constants';
import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	IDENTIFIER_REGEX,
	IDENTIFIER_TYPE,
	MODULE_IDS,
	PASSWORD_VERIFICATION_ACTIONS,
	PASSWORD_VERIFICATION_TRANSACTIONS,
	RouteUrl,
} from 'utils/enum';
import {
	calculateCheckDigitFun,
	split12Digit,
	splitLastDigit,
	splitLocationReference,
	// sglnGenerate,
	// validateGLN,
	// validateSGLNExtension,
} from 'utils/gs1UtilFns';

import CustomButton from 'components/common/button/CustomButton';
import CustomInput from 'components/common/forms/CustomInput';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomError from 'components/common/errorLabel/CustomError';
import AddEditManufacturerSkeleton from 'components/common/skeleton/AddEditManufacturerSkeleton';
import CustomLoader from 'components/common/loader/CustomLoader';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import useGetAllCountry from 'components/tenant/hooks/useGetAllCountry';
import CustomDropdown from 'components/common/forms/CustomDropdown';

import { ImageCropper } from 'components/common/cropper/CustomImageCrop';
import PasswordVerification from 'components/login/PasswordVerification';
import { useAppSelector } from 'hooks/stateHooks';
import { getModulesRequireAuth } from 'slices/configSlice';
import useAddIdentifierType from 'components/location/hooks/useAddIdentifierType';
import ManufacturerSglngenerationSection from './ManufacturerSglngenerationSection';
import IdentifierGrid from './IdentifierGrid';
import ContactDetails from './ContactDetails';

import useUpdateManufacturer from './hooks/useUpdateManufacturer';
import useAddManufacturer from './hooks/useAddManufacturer';
import useGetManufacturerDetailsById from './hooks/useGetManufacturerDetailsById';
import useGetAllIdentifierType from './hooks/useGetAllIdentifierType';
import useGetAllDataType from './hooks/useGetAllDataType';
import useGetAllBusinessType from './hooks/useGetAllBusinessType';
import useGellAllParentCompany from './hooks/useGellAllParentCompany';

const AddEditManufacture = () => {
	const { t } = useTranslation('manufacturerModule');
	const location = useLocation();
	const navigate = useNavigate();
	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);

	const [manufacturerName, setManufacturerName] = useState<string>('');
	const [selectedManufacturerGroup, setSelectedManufacturerGroup] = useState<any>('');
	const [streetAddressA, setStreetAddressA] = useState<string>('');
	const [streetAddressB, setStreetAddressB] = useState<string>('');
	const [zip, setZip] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [selectedCountry, setSelectedCountry] = useState<any>('');
	const [subjectDescription, setSubjectDescription] = useState<string>('');
	const [gs1Prefix, setGs1Prefix] = useState<string>('');
	const [selectedIdentifierType, setSelectedIdentifierType] = useState<any>(IDENTIFIER_TYPE_GLN);
	const [identifierValue, setIdentifierValue] = useState<string>(''); // gln no
	const [sglnExtension, setSglnExtension] = useState<string | number>('');
	const [sgln, setSgln] = useState<string | number>('');
	const [contactName, setContactName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [telephone, setTelephone] = useState<string>('');
	const [selectedIdentifierTypeIds, setSelectedIdentifierTypeIds] = useState<any>([]);

	/** sgln section */
	const [identifierDataList, setIdentifierDataList] = useState<any>([]);
	const [selectedPrimaryIdentifier, setSelectedPrimaryIdentifier] = useState<any>('');
	const [countryList, setCountryList] = useState<any>([]);
	const [identifierTypeList, setIdentifierTypeList] = useState<any>([]);
	const [identifierTypeListCopy, setIdentifierTypeListCopy] = useState<any>([]);
	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [isEditSubmitted, setIsEditSubmitted] = useState<boolean>(false);
	const [allFieldsDisbled, setAllFieldsDisabled] = useState<boolean>(false);
	const [manufacturerAddEditInfo, setManufacturerAddEditInfo] = useState<string>('');

	const [dataTypeList, setDataTypeList] = useState<any>([]);
	const [dataType, setDataType] = useState<any>('');

	const [businessTypeList, setBusinessTypeList] = useState<any>([]);
	const [businessType, setBusinessType] = useState<any>('');

	const [logo, setLogo] = useState<string>('');

	const [manufacturerId, setManufacturerId] = useState<string>('');
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const [identifierPlaceholder, setIdentifierPlaceholder] = useState<string>('');
	const [identifierValueError, setIdentifierValueError] = useState<string>('');

	// parent company data states
	const [parentManufacturerData, setParentManufacturerData] = useState<any>([]);
	const [encParentManufacturerId, setParentEncManufacturerId] = useState<any>('');
	const [optionalFieldError, setOptionalFieldError] = useState<Record<string, string>>({});

	const [glnErrors, setGlnErrors] = useState<string>('');
	const [sglnErrors, setSglnErrors] = useState<string>('');
	const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);
	const [isAdded, setIsAdded] = useState<boolean>(false);

	const [district, setDistrict] = useState<string>('');

	/** hook to get data by Id */
	const { resData, manufacturerDetailsLoading } = useGetManufacturerDetailsById(
		location?.state?.encManufacturerId,
	);

	const { dropdownCountryValues, loading } = useGetAllCountry();
	/**
	 * API call for get all identifier type
	 */
	const { identifierTypeResponseData, identifierTypeloading } = useGetAllIdentifierType();
	/**
	 * API call for get all Data Type
	 */
	const { dataTypeLoading, dataTypeResponseData } = useGetAllDataType();
	/**
	 * API call for get all Business Type
	 */
	const { businessTypeLoading, businessTypeResponseData } = useGetAllBusinessType();
	/**
	 * API call for get all parent company details
	 */
	const { parentManufacturerResponseData, parentManufacturerLoading } = useGellAllParentCompany();

	/** sets icon description data in state */
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.MANUFACTURER,
		ICON_DESCRIPTION_ID.MANUFACTURER_ADD_EDIT,
	);

	const updateIsAddToDefault = () => {
		setIsAdded(false);
	};

	// api to add identifier type and value
	const { identifierValidationRes, identifierValidationResLoading } = useAddIdentifierType(
		{ encIdentifierId: selectedIdentifierType, identifierValue, sglnExtension, sgln },
		isAdded,
		updateIsAddToDefault,
	);

	// set enc Manufacturer Id to state
	useEffect(() => {
		setManufacturerId(location?.state?.encManufacturerId);
	}, [location?.state?.encManufacturerId]);

	/**
	 * Add manufacture hook
	 */
	const { addManufacturerLoading, addManufacturerResponse } = useAddManufacturer(
		{
			name: manufacturerName,
			manufacturerGroupId: selectedManufacturerGroup,
			street1Address: streetAddressA,
			street2Address: streetAddressB,
			zipCode: zip,
			city,
			stateName: state,
			encCountryId: selectedCountry,
			subjectDescription,
			gs1GtinPrefix: gs1Prefix,
			contactName,
			emailId: email,
			phoneNo: telephone,
			identifierTypeList: identifierDataList,
			encManufacturerDataTypeId: dataType,
			encBusinessTypeId: businessType,
			actualFileName: MANUFACTURER_LOGO_NAME,
			fileBase64: logo,
			encParentManufacturerId,
			district,
		},
		isSubmitted,
		setIsSubmitted,
	);

	/**
	 * Update manufacture hook
	 */
	const { updateManufacturerLoading, updateManufacturerResponse } = useUpdateManufacturer(
		{
			name: manufacturerName,
			manufacturerGroupId: selectedManufacturerGroup,
			street1Address: streetAddressA,
			street2Address: streetAddressB,
			zipCode: zip,
			city,
			stateName: state,
			encCountryId: selectedCountry,
			subjectDescription,
			gs1GtinPrefix: gs1Prefix,
			contactName,
			emailId: email,
			phoneNo: telephone,
			identifierTypeList: identifierDataList,
			encId: location?.state?.encManufacturerId,
			encManufacturerDataTypeId: dataType,
			encBusinessTypeId: businessType,
			actualFileName: MANUFACTURER_LOGO_NAME,
			fileBase64: logo,
			encParentManufacturerId,
			district,
		},
		isEditSubmitted,
		setIsEditSubmitted,
	);

	/**
	 * Icon discription response handling
	 */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setManufacturerAddEditInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	useEffect(() => {
		/**
		 * If identifier list have already GLN or SGLN, and GS1 prefix changes
		 * Delete the added GLN or SGLN
		 */
		if (identifierDataList && identifierDataList?.length > 0 && manufacturerId === '') {
			const modArray =
				identifierDataList &&
				identifierDataList?.length > 0 &&
				identifierDataList?.filter(
					(item: any) =>
						item?.encManufacturerIdentifierId !== IDENTIFIER_TYPE_GLN &&
						item?.encManufacturerIdentifierId !== IDENTIFIER_TYPE.SGLN,
				);

			setIdentifierDataList(modArray);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [gs1Prefix]);

	// // old function for sgln generate
	// /** for auto-populating sgln */
	// useEffect(() => {
	// 	if (validateGLN(identifierValue)) {
	// 		if (validateSGLNExtension(sglnExtension?.toString())) {
	// 			setSgln(sglnGenerate(identifierValue, sglnExtension?.toString()));
	// 		}
	// 	}
	// }, [identifierValue, sglnExtension]);

	// Sgln generate and validations checking
	useEffect(() => {
		if (selectedIdentifierType === IDENTIFIER_TYPE_GLN) {
			if (gs1Prefix && gs1Prefix?.length >= 4) {
				setFieldDisabled(false);
			} else {
				setFieldDisabled(true);
			}
			// GS1 prefix length
			const gs1StringLength = gs1Prefix?.length;

			// split gs1 prefix from identifier value function
			const splitIdentifierValue = (inputString) => {
				return inputString?.slice(0, Number(gs1StringLength));
			};

			// splitted value from identifier value
			const identifierGs1 = splitIdentifierValue(identifierValue);

			// condition checking for gs1prefix and splitted value from identifier value are same
			if (identifierGs1 === gs1Prefix) {
				// clearing error state if condition satisfy
				setGlnErrors('');

				if (identifierValue?.length === 13) {
					// referece number from entered gln value
					const locationReference = splitLocationReference(
						identifierValue,
						identifierGs1?.length,
					);
					// gln value without check digit
					const glnValue = split12Digit(identifierValue);
					// last digit in entered gln value
					const lastDigit = splitLastDigit(identifierValue);
					/**
					 * calulate check digit function
					 */
					const checkDigit = calculateCheckDigitFun(glnValue);

					// console.log(checkDigit, 'check digit');

					if (lastDigit == checkDigit) {
						setGlnErrors('');
						// if (sglnExtension) {
						const data = `${identifierGs1}.${locationReference}.${sglnExtension}`;
						const alphanumericCount = data?.replace(/\./g, '')?.length;

						setSgln(data);
						if (alphanumericCount > 12 && alphanumericCount <= 20) {
							setSglnErrors('');
						} else {
							setSglnErrors(t('NOT_VALID_SGLN')!!);
						}
						// } else {
						// 	setSgln('');
						// }
					} else {
						setGlnErrors(
							`${t('INVALID_CHECK_DIGIT')}, ${t('VALID_CHECK_DIGIT')}${checkDigit}`,
						);

						setSgln('');
					}
				} else if (identifierValue?.length > 13) {
					setGlnErrors(t('INVALID_GLN_NUMBER')!!);
				}
			} else if (
				identifierValue?.length === gs1StringLength ||
				identifierValue?.length > gs1StringLength
			) {
				setGlnErrors(t('GS1_VALUE_ERROR_MSG')!!);
			} else {
				setGlnErrors('');
			}
		} else {
			// field disable status change
			setFieldDisabled(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifierValue, sglnExtension, gs1Prefix, selectedIdentifierType]);

	/** for setting the country dropdown values */
	useEffect(() => {
		if (dropdownCountryValues) {
			setCountryList(dropdownCountryValues);
		}
	}, [dropdownCountryValues]);

	/** setting the identifier type dropdown values  */
	useEffect(() => {
		if (identifierTypeResponseData) {
			setIdentifierTypeList(identifierTypeResponseData); /** identifier type main state */
			setIdentifierTypeListCopy(
				identifierTypeResponseData,
			); /** identifier type copy state for removing the already selected values from dropdown */
		}
	}, [identifierTypeResponseData]);

	/** Add response handling */
	useEffect(() => {
		if (addManufacturerResponse?.status || updateManufacturerResponse?.status) {
			navigate(RouteUrl.MANUFACTURER);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addManufacturerResponse, updateManufacturerResponse]);

	/** sgln error state clearing */
	useEffect(() => {
		setErrorState((prev) => ({ ...prev, sgln: '' }));
	}, [sgln]);

	/** clearing the identifier type value section after adding one item */
	useEffect(() => {
		if (selectedIdentifierType === IDENTIFIER_TYPE_GLN) {
			setIdentifierValue('');
			setSgln('');
			setSglnExtension('0');
		} else {
			setIdentifierValue('');
			setSglnExtension('');
			setSgln('');
		}
	}, [selectedIdentifierType]);

	/** setting the id array of items without selected items in dropdown */
	useEffect(() => {
		const idArray =
			identifierDataList &&
			identifierDataList?.length > 0 &&
			identifierDataList?.map((item: any) => {
				return item?.encManufacturerIdentifierId;
			});
		if (idArray) {
			setSelectedIdentifierTypeIds(idArray);
		} else {
			setSelectedIdentifierTypeIds([]);
		}
	}, [identifierDataList]);

	/** setting the values to dropdown after removing the already selected values from dropdown */
	useEffect(() => {
		const filteredData =
			identifierTypeListCopy?.length > 0 &&
			identifierTypeListCopy?.filter(
				(item: any) =>
					!selectedIdentifierTypeIds?.includes(
						item?.encManufacturerIdentifierId?.toString(),
					),
			);
		// eslint-disable-next-line no-unused-expressions
		filteredData?.length >= 0 && setIdentifierTypeList(filteredData);
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIdentifierTypeIds, resData]);

	/** setting the Data Type dropdown values  */
	useEffect(() => {
		if (dataTypeResponseData) {
			setDataTypeList(dataTypeResponseData);
		}
	}, [dataTypeResponseData]);

	/** setting the Business Type dropdown values  */
	useEffect(() => {
		if (businessTypeResponseData) {
			setBusinessTypeList(businessTypeResponseData);
		}
	}, [businessTypeResponseData]);

	/** setting the parent company dropdown values  */
	useEffect(() => {
		if (parentManufacturerResponseData) {
			setParentManufacturerData(parentManufacturerResponseData);
		}
	}, [parentManufacturerResponseData]);

	/** reseting states */
	const reset = () => {
		setManufacturerName('');
		setSelectedManufacturerGroup('');
		setStreetAddressA('');
		setStreetAddressB('');
		setZip('');
		setCity('');
		setState('');
		setSelectedCountry('');
		setSubjectDescription('');
		if (location?.state?.type === 'add') {
			setGs1Prefix('');
		}
		setSelectedIdentifierType('');
		setIdentifierValue('');
		setSglnExtension('');
		setSgln('');
		setContactName('');
		setEmail('');
		setTelephone('');
		setErrorState({});
		setDataType('');
		setBusinessType('');
		setParentEncManufacturerId('');
		setDistrict('');
	};

	/** handle changes of input section */
	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
			switch (event.target.name) {
				case 'manufacturerName':
					setErrorState((prev) => ({ ...prev, manufacturerName: '' }));
					setManufacturerName(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'streetAddressA':
					setErrorState((prev) => ({ ...prev, streetAddressA: '' }));
					setStreetAddressA(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'streetAddressB':
					setErrorState((prev) => ({ ...prev, streetAddressB: '' }));
					setStreetAddressB(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'zip':
					setErrorState((prev) => ({ ...prev, zip: '' }));
					setZip(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'city':
					setErrorState((prev) => ({ ...prev, city: '' }));
					setCity(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'state':
					setErrorState((prev) => ({ ...prev, state: '' }));
					setState(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'subjectDescription':
					setErrorState((prev) => ({ ...prev, subjectDescription: '' }));
					setSubjectDescription(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'gs1Prefix':
					setErrorState((prev) => ({ ...prev, gs1Prefix: '' }));
					setGs1Prefix(event.target.value ? event.target.value?.toString() : '');

					if (selectedIdentifierType === IDENTIFIER_TYPE_GLN) {
						// clearing sgln field when identifier value change
						setIdentifierValue('');
						// clearing sgln field when identifier value changes
						setSgln('');
						// setSglnExtension('');
						// ......
					}
					break;
				case 'identifierValue':
					// clearing state on change function
					setIdentifierValueError('');
					if (selectedIdentifierType === IDENTIFIER_TYPE_GLN) {
						setSgln('');
						// setSglnExtension('');
						// ......
					}
					setErrorState((prev) => ({ ...prev, identifierValue: '' }));
					setIdentifierValue(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'sglnExtension':
					setErrorState((prev) => ({ ...prev, sglnExtension: '' }));
					setSglnExtension(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'contactName':
					setErrorState((prev) => ({ ...prev, contactName: '' }));
					setContactName(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'email':
					setErrorState((prev) => ({ ...prev, email: '' }));
					setEmail(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'telephone':
					setErrorState((prev) => ({ ...prev, telephone: '' }));
					setTelephone(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'district':
					setErrorState((prev) => ({ ...prev, district: '' }));
					setDistrict(event.target.value ? event.target.value?.toString() : '');
					break;
				default:
					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	/** setting states to input fields from getbyId hook */
	useEffect(() => {
		if (resData) {
			setManufacturerName(resData?.name);
			setSelectedManufacturerGroup(resData?.manufacturerGroupId);
			setStreetAddressA(resData?.street1Address);
			setStreetAddressB(resData?.street2Address);
			setZip(resData?.zipCode);
			setCity(resData?.city);
			setState(resData?.stateName);
			setSelectedCountry(resData?.encCountryId);
			setSubjectDescription(resData?.subjectDescription);
			setGs1Prefix(resData?.gs1GtinPrefix);
			setIdentifierDataList(resData?.identifierTypeList);
			setContactName(resData?.contactName);
			setEmail(resData?.emailId ? resData?.emailId : '');
			setTelephone(resData?.phoneNo ? resData?.phoneNo : '');
			setAllFieldsDisabled(resData?.isDisabled);
			setDataType(resData?.encManufacturerDataTypeId);
			setBusinessType(resData?.encBusinessTypeId);
			setLogo(resData?.fileBase64);
			setParentEncManufacturerId(resData?.encParentManufacturerId);
			setDistrict(resData?.district);
		}
		return () => {
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resData]);

	/** clearing the error state of identifierTypeList */
	useEffect(() => {
		if (identifierTypeList?.length > 0) {
			setErrorState((prev) => ({ ...prev, identifierTypeList: '' }));
		}
	}, [identifierTypeList]);

	/** handle change fn of country dropdown */
	const onCountryChange = (event: any) => {
		setSelectedCountry(event);
		setErrorState((prev) => ({ ...prev, selectedCountry: '' }));
	};

	/** handle change fn of identifier type change  */
	const onIdentifierTypeChange = (event: any) => {
		// to prevent duplication of identifier type
		if (!selectedIdentifierTypeIds?.includes(event)) {
			setSelectedIdentifierType(event);
		}
		setErrorState((prev) => ({ ...prev, selectedIdentifierType: '' }));
		if (event === IDENTIFIER_TYPE.AR_CUIT) {
			setIdentifierPlaceholder(t('IDENTIFIER_AR_CUIT_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.BR_CNPJ) {
			setIdentifierPlaceholder(t('IDENTIFIER_BR_CNPJ_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.BR_CPF) {
			setIdentifierPlaceholder(t('IDENTIFIER_BR_CPF_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.DUNS) {
			setIdentifierPlaceholder(t('IDENTIFIER_DUNS_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.DUNS_PLUS_4) {
			setIdentifierPlaceholder(t('IDENTIFIER_DUNS_4_PLACEHOLDER')!!);
		} else {
			setIdentifierPlaceholder('');
		}

		// clearing error state on onChange function
		setIdentifierValueError('');
		setGlnErrors('');
		setSglnErrors('');

		if (selectedIdentifierType === IDENTIFIER_TYPE_GLN) {
			// clearing sgln field when identifier value change
			setSgln('');
			// setSglnExtension('');
			// ......
		}
	};

	/** handle change fn of Data Type dropdown */
	const onDataTypeChange = (event: any) => {
		setDataType(event);
		setErrorState((prev) => ({ ...prev, dataType: '' }));

		// setting parent company empty on data type change
		setParentEncManufacturerId('');
	};

	/** handle change fn of Business Type dropdown */
	const onBusinessTypeChange = (event: any) => {
		setBusinessType(event);
		setErrorState((prev) => ({ ...prev, businessType: '' }));
	};

	/** handle change fn of Data Type dropdown */
	const onParentCompanyChange = (event: any) => {
		setOptionalFieldError((prev) => ({ ...prev, encParentManufacturerId: '' }));
		setParentEncManufacturerId(event);
	};

	// setting the data to list in the grid
	useEffect(() => {
		if (identifierValidationRes?.status) {
			setIdentifierDataList((prev: any) => [
				...prev,
				{
					encManufacturerIdentifierId: selectedIdentifierType,
					identifierValue,
					sglnExtension: sglnExtension !== '' ? sglnExtension : null,
					sgln: sgln !== '' ? sgln : null,
					manufacturerIdentifier:
						identifierTypeList &&
						identifierTypeList?.length > 0 &&
						identifierTypeList?.filter(
							(item: any) =>
								item?.encManufacturerIdentifierId === selectedIdentifierType,
						)[0]?.manufacturerIdentifier,
					isPrimary: false,
				},
			]);
			setSelectedIdentifierType('');
			setIdentifierValue('');
			setSglnExtension('');
			setSgln('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifierValidationRes]);

	// common function for triggering the api
	const identifierTypeDataHandler = () => {
		setIsAdded(true);
	};

	/** identifier type section adding function */
	const handleAddSglnToGrid = () => {
		switch (selectedIdentifierType) {
			case IDENTIFIER_TYPE.GLN:
				const glnError = productFormValidations(
					{
						selectedIdentifierType,
						identifierValue,
						sglnExtension,
						sgln,
					},
					{
						selectedIdentifierType: { required: true },
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 13,
							minlength: 13,
						},
						sglnExtension: {
							required: true,
							alphanumericWithoutSpace: true,
						},
						sgln: {
							required: true,
						},
					},
				);
				setErrorState(glnError);

				if (Object.keys(glnError)?.length === 0 && glnErrors === '' && sglnErrors === '') {
					// function to trigger the api
					identifierTypeDataHandler();
				}
				break;

			case IDENTIFIER_TYPE.AR_CUIT:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexArCuit = IDENTIFIER_REGEX.AR_CUIT;

				// Checking the input value matches the pattern
				const isValidPatternArCuit = regexArCuit.test(identifierValue);

				if (identifierValue === '' && !isValidPatternArCuit) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternArCuit) {
					setIdentifierValueError(t('IDENTIFIER_AR_CUIT_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.AM_TIN:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const amTinError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 8,
							minlength: 8,
						},
					},
				);
				setErrorState(amTinError);
				if (Object.keys(amTinError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}
				break;

			case IDENTIFIER_TYPE.BR_CNPJ:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexBrCnpj = IDENTIFIER_REGEX.BR_CNPJ;

				// Checking the input value matches the pattern
				const isValidPatternBrCnpj = regexBrCnpj.test(identifierValue);

				if (identifierValue === '' && !isValidPatternBrCnpj) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternBrCnpj) {
					setIdentifierValueError(t('IDENTIFIER_BR_CNPJ_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.BR_CPF:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexBrCpf = IDENTIFIER_REGEX.BR_CPF;

				// Checking the input value matches the pattern
				const isValidPatternBrCpf = regexBrCpf.test(identifierValue);

				if (identifierValue === '' && !isValidPatternBrCpf) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternBrCpf) {
					setIdentifierValueError(t('IDENTIFIER_BR_CPF_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.BY_TIN:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const byTinError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 9,
							minlength: 9,
						},
					},
				);
				setErrorState(byTinError);
				if (Object.keys(byTinError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.DE_FA_REG_NUM:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const deIfaRegNumError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 6,
							minlength: 6,
						},
					},
				);
				setErrorState(deIfaRegNumError);
				if (Object.keys(deIfaRegNumError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.DEA:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const deaError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							alphanumericWithoutSpace: true,
							maxlength: 9,
							minlength: 9,
						},
					},
				);
				setErrorState(deaError);
				if (Object.keys(deaError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.DUNS:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexDuns = IDENTIFIER_REGEX.DUNS;

				// Checking the input value matches the pattern
				const isValidPatternDuns = regexDuns.test(identifierValue);

				if (identifierValue === '' && !isValidPatternDuns) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternDuns) {
					setIdentifierValueError(t('IDENTIFIER_DUNS_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.DUNS_PLUS_4:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexDuns4 = IDENTIFIER_REGEX.DUNS_4;

				// Checking the input value matches the pattern
				const isValidPattern = regexDuns4.test(identifierValue);
				if (identifierValue === '' && !isValidPattern) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPattern) {
					setIdentifierValueError(t('IDENTIFIER_DUNS_4_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.SGLN:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));
				// function to check the gs1 the identifier value matching with gs1 prefix
				const validateSglnwithGs1 = () => {
					// GS1 prefix length
					const gs1StringLength = gs1Prefix?.length;

					// split gs1 prefix from identifier value function
					const splitIdentifierValue = (inputString) => {
						return inputString?.slice(0, Number(gs1StringLength));
					};
					// splitted value from identifier value
					return splitIdentifierValue(identifierValue);
				};
				const validateSglnString = (inputStr) => {
					const regexSgln = IDENTIFIER_REGEX.SGLN;
					// Count the number of letters (excluding dots)
					const sglnLetterCount = inputStr?.replace(/\./g, '')?.length;
					// Check if the input matches the regex
					const sglnStringTest = regexSgln.test(inputStr);
					// Check if the total letters (excluding dots) are between 13 and 20, and there are exactly 2 dots
					return (
						sglnLetterCount >= 13 &&
						sglnLetterCount <= 20 &&
						sglnStringTest &&
						(inputStr?.match(/\./g) || [])?.length === 2
					);
				};

				/**
				 * Function for checking first dot (.) position in identifier value
				 * @returns string length before the first dot
				 */

				const validateGs1Format = () => {
					const dotIndex = identifierValue?.indexOf('.');

					let lengthBeforeDot = 0;

					if (dotIndex !== -1) {
						lengthBeforeDot = dotIndex;
					}

					return lengthBeforeDot;
				};

				const isValidPatternSgln = validateSglnString(identifierValue);
				// Function call to check if SGLN matches with GS1 Prefix
				const isSglnMatchWithGs1 = validateSglnwithGs1();

				// function call for gs1 length check before first dot
				const gs1FormatCheck = validateGs1Format();

				if (identifierValue === '' && isValidPatternSgln) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (isSglnMatchWithGs1 !== gs1Prefix) {
					setIdentifierValueError(t('GS1_VALUE_ERROR_MSG')!!);
				}
				// validation checking for gs1 position
				else if (gs1FormatCheck !== gs1Prefix?.length) {
					setIdentifierValueError(t('GS1_VALUE_ERROR_MSG')!!);
				} else if (isValidPatternSgln) {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				} else {
					setIdentifierValueError(t('NOT_VALID_SGLN')!!);
				}
				break;

			default:
				const error = productFormValidations(
					{
						selectedIdentifierType,
						identifierValue,
					},
					{
						selectedIdentifierType: { required: true },
						identifierValue: { required: true },
					},
				);
				setErrorState(error);
				if (Object.keys(error)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}
				break;
		}
	};

	/** identifier type section delete fn */
	const handleDeleteSglnSection = (id: any) => {
		const modArray =
			identifierDataList &&
			identifierDataList?.length > 0 &&
			identifierDataList?.filter((item: any) => item?.encManufacturerIdentifierId !== id);

		setIdentifierDataList(modArray);
	};

	/** identifier grid onchange function */
	const handlePrimaryIdentifierGridChange = (event: any) => {
		setSelectedPrimaryIdentifier(event);

		const modArray =
			identifierDataList &&
			identifierDataList?.length > 0 &&
			identifierDataList?.map((item: any) => {
				return {
					...item,
					isPrimary:
						item?.encManufacturerIdentifierId === event?.encManufacturerIdentifierId,
				};
			});
		setIdentifierDataList(modArray);
	};

	const handleClear = () => {
		reset();
	};

	/** validation of input fields */
	const handleSubmit = () => {
		const error = productFormValidations(
			{
				manufacturerName,
				telephone,
				email,
				gs1Prefix,
				selectedIdentifierType,
				identifierValue,
				sglnExtension,
				sgln,
				identifierTypeList,

				dataType,
				businessType,
				encParentManufacturerId,
				subjectDescription,
				streetAddressA,
				streetAddressB,
				zip,
				city,
				state,
				contactName,
				district,
			},
			{
				manufacturerName: { maxlength: 150, required: true },
				subjectDescription: { maxlength: 250 },
				streetAddressA: { maxlength: 250 },
				streetAddressB: { maxlength: 250 },
				zip: { maxlength: 150 },
				district: { maxlength: 150 },

				city: { maxlength: 150 },
				state: { maxlength: 150 },
				email: { emailOnly: true },
				telephone: {
					required: false,
					negativeValue: true,
					numbersOnly: true,
					maxlength: 10,
				},
				gs1Prefix: {
					required: true,
					negativeValue: true,
					numbersOnly: true,
					maxlength: 12,
					minlength: 4,
				},
				selectedIdentifierType: {
					required: identifierDataList && identifierDataList?.length === 0,
				},
				identifierValue: {
					required: identifierDataList && identifierDataList?.length === 0,
				},
				sglnExtension: {
					required:
						selectedIdentifierType === IDENTIFIER_TYPE_GLN &&
						identifierDataList &&
						identifierDataList?.length === 0,
					alphanumericWithoutSpace: true,
				},
				sgln: {
					required:
						selectedIdentifierType === IDENTIFIER_TYPE_GLN &&
						identifierDataList &&
						identifierDataList?.length === 0,
				},
				dataType: { required: true },
				businessType: { required: true },
				contactName: { maxlength: 150 },
			},
		);

		setErrorState(error);
		if (identifierDataList?.length === 0) {
			setErrorState((prev) => ({
				...prev,
				identifierTypeList: t('PLEASE_ADD_ATLEAST_ONE_IDENTIFIER_TYPE'),
			}));
		}

		const optionalFieldErrorMsg = productFormValidations(
			{
				encParentManufacturerId,
			},
			{
				encParentManufacturerId: { required: true },
			},
		);
		setOptionalFieldError(optionalFieldErrorMsg);

		let sortedOptionalFieldError: any = [];
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (dataType === PARTNER_DATA_TYPE) {
			sortedOptionalFieldError = { ...optionalFieldErrorMsg };
		} else {
			sortedOptionalFieldError = {};
		}

		if (
			Object.keys(error)?.length === 0 &&
			identifierDataList?.length > 0 &&
			Object.keys(sortedOptionalFieldError)?.length === 0
		) {
			if (location?.state?.type === 'add') {
				if (modulesRequireAuth?.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					setIsSubmitted(true);
				}
			} else if (location?.state?.type === 'edit') {
				if (modulesRequireAuth?.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					setIsEditSubmitted(true);
				}
			}
		}
	};

	// Logo Change Function
	const handleLogoChange = (event: any) => {
		// setErrorState((prev) => ({ ...prev, logo: '' }));
		setLogo(event);
	};

	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	const handleVerificationSuccess = () => {
		// eslint-disable-next-line no-unused-expressions
		location?.state?.type === 'add' ? setIsSubmitted(true) : setIsEditSubmitted(true);
		handleToggle();
	};

	return (
		<div className='page-content add-product-group-module add-manufacturer-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{location?.state?.type === 'add'
							? `${t('CREATE_MANUFACTURER')}`
							: `${t('EDIT_MANUFACTURER')}`}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={manufacturerAddEditInfo}
							moduleId={ICON_DESCRIPTION_ID.MANUFACTURER_ADD_EDIT}
						/>
					</h3>
				</div>
			</div>

			<div className='page-content-body'>
				<div className='box-container product-order-details-container'>
					{manufacturerDetailsLoading ? (
						<AddEditManufacturerSkeleton />
					) : (
						<form className='h-100'>
							<fieldset className='k-form-fieldset default-form-style tenant-content-body light-bg-form-wrapper add-location-body'>
								<div className='section-wrapper mb-0 border-bottom-radius-0 pb-0'>
									<div className='row'>
										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.manufacturerName &&
														errorState?.manufacturerName !== ''
															? 'input-validation-error'
															: ''
													}
													value={manufacturerName}
													onChange={handleChange}
													name='manufacturerName'
													label={t('MANUFACTURER_NAME')!!}
													placeholder={t('ENTER_MANUFACTURER_NAME')}
													required
													type='text'
													error={errorState?.manufacturerName}
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' ||
														allFieldsDisbled
													}
													className={
														errorState?.gs1Prefix &&
														errorState?.gs1Prefix !== ''
															? 'input-validation-error'
															: ''
													}
													value={gs1Prefix}
													onChange={handleChange}
													name='gs1Prefix'
													label={t('GS1_PREFIX')!!}
													placeholder={t('GS1_PREFIX')}
													required
													type='number'
													error={errorState?.gs1Prefix}
													isMandatory
												/>
											</div>
										</div>
										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<label className='form-label'>
													{t('DATA_TYPE')}
												</label>
												<CustomDropdown
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													isId
													popupSettings={{
														popupClass: 'default-select-dropdown',
													}}
													className={`form-control default-select-dropdown ${
														errorState?.dataType &&
														errorState?.dataType !== ''
															? 'input-validation-error'
															: ''
													}`}
													isMandatory
													options={dataTypeList}
													dropdownValue={dataType}
													handleDropDownChange={onDataTypeChange}
													dataItemKey='encManufacturerDataTypeId'
													dataTextField='manufacturerDataType'
													loading={dataTypeLoading}
												/>

												{errorState?.dataType &&
													errorState?.dataType !== '' && (
														<CustomError value={errorState?.dataType} />
													)}
											</div>
										</div>
										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<label className='form-label'>
													{t('BUSINESS_TYPE')}
												</label>
												<CustomDropdown
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													isId
													popupSettings={{
														popupClass: 'default-select-dropdown',
													}}
													className={`form-control default-select-dropdown ${
														errorState?.businessType &&
														errorState?.businessType !== ''
															? 'input-validation-error'
															: ''
													}`}
													isMandatory
													options={businessTypeList}
													dropdownValue={businessType}
													handleDropDownChange={onBusinessTypeChange}
													dataItemKey='encBusinessTypeId'
													dataTextField='businessType'
													loading={businessTypeLoading}
												/>

												{errorState?.businessType &&
													errorState?.businessType !== '' && (
														<CustomError
															value={errorState?.businessType}
														/>
													)}
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.subjectDescription &&
														errorState?.subjectDescription !== ''
															? 'input-validation-error two-row-height'
															: 'two-row-height'
													}
													value={subjectDescription}
													onChange={(e: any) => handleChange(e)}
													label={t('SUBJECT_DESCRIPTION')!!}
													placeholder={t('SUBJECT_DESCRIPTION')!!}
													textAreaName='subjectDescription'
													textAreaId='subjectDescription'
													required={false}
													cols={30}
													rows={5}
													error={errorState?.subjectDescription}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.streetAddressA &&
														errorState?.streetAddressA !== ''
															? 'input-validation-error two-row-height'
															: 'two-row-height'
													}
													value={streetAddressA}
													onChange={(e: any) => handleChange(e)}
													label={t('STREET_ADDRESS_A')!!}
													placeholder={t('STREET_ADDRESS_A')!!}
													textAreaName='streetAddressA'
													textAreaId='streetAddressA'
													required
													cols={30}
													rows={5}
													error={errorState?.streetAddressA}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.streetAddressB &&
														errorState?.streetAddressB !== ''
															? 'input-validation-error two-row-height'
															: 'two-row-height'
													}
													value={streetAddressB}
													onChange={(e: any) => handleChange(e)}
													label={t('STREET_ADDRESS_B')!!}
													placeholder={t('STREET_ADDRESS_B')!!}
													textAreaName='streetAddressB'
													textAreaId='streetAddressB'
													required
													cols={30}
													rows={5}
													error={errorState?.streetAddressB}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.city && errorState?.city !== ''
															? 'input-validation-error'
															: ''
													}
													value={city}
													onChange={handleChange}
													name='city'
													label={t('CITY')!!}
													placeholder={t('CITY')}
													required
													type='text'
													error={errorState?.city}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.zip && errorState?.zip !== ''
															? 'input-validation-error'
															: ''
													}
													value={zip}
													onChange={handleChange}
													name='zip'
													label={t('ZIP')!!}
													placeholder={t('ZIP')}
													required
													type='text'
													error={errorState?.zip}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.district &&
														errorState?.district !== ''
															? 'input-validation-error'
															: ''
													}
													value={district}
													onChange={handleChange}
													name='district'
													label={t('DISTRICT')!!}
													placeholder={t('DISTRICT')}
													required
													type='text'
													error={errorState?.district}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.state &&
														errorState?.state !== ''
															? 'input-validation-error'
															: ''
													}
													value={state}
													onChange={handleChange}
													name='state'
													label={t('STATE')!!}
													placeholder={t('STATE')}
													required
													type='text'
													error={errorState?.state}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<label className='form-label'>{t('COUNTRY')}</label>
												<CustomDropdown
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													isId
													popupSettings={{
														popupClass: 'default-select-dropdown',
													}}
													className={`form-control default-select-dropdown ${
														errorState?.selectedCountry &&
														errorState?.selectedCountry !== ''
															? 'input-validation-error'
															: ''
													}`}
													dropdownValue={selectedCountry}
													handleDropDownChange={onCountryChange}
													options={countryList}
													dataItemKey='encCountryId'
													dataTextField='countryName'
													loading={loading}
												/>

												{errorState?.selectedCountry &&
													errorState?.selectedCountry !== '' && (
														<CustomError
															value={errorState?.selectedCountry}
														/>
													)}
											</div>
										</div>

										{/* checking condition for datatype , if data type is patner showing this field */}
										{dataType === PARTNER_DATA_TYPE && (
											<div className='col-xl-3 col-lg-3 col-sm-6'>
												<div className='form-group'>
													<label className='form-label'>
														{t('PARENT_COMPANY')}
													</label>
													<CustomDropdown
														disabled={
															location?.state?.type === 'edit' &&
															allFieldsDisbled
														}
														isId
														popupSettings={{
															popupClass: 'default-select-dropdown',
														}}
														className={`form-control default-select-dropdown ${
															optionalFieldError?.encParentManufacturerId &&
															optionalFieldError?.encParentManufacturerId !==
																''
																? 'input-validation-error'
																: ''
														}`}
														isMandatory
														options={parentManufacturerData}
														dropdownValue={encParentManufacturerId}
														handleDropDownChange={onParentCompanyChange}
														dataItemKey='encManufacturerId'
														dataTextField='manufacturerName'
														loading={parentManufacturerLoading}
													/>

													{optionalFieldError?.encParentManufacturerId &&
														optionalFieldError?.encParentManufacturerId !==
															'' && (
															<CustomError
																value={
																	optionalFieldError?.encParentManufacturerId
																}
															/>
														)}
												</div>
											</div>
										)}
									</div>
								</div>
								<div className='position-relative section-wrapper-inner border-top-radius-0 pt-0'>
									<div className='row'>
										<ManufacturerSglngenerationSection
											selectedIdentifierType={selectedIdentifierType}
											identifierValue={identifierValue}
											sglnExtension={sglnExtension}
											sgln={sgln}
											identifierTypeList={identifierTypeList}
											handleChange={handleChange}
											onIdentifierTypeChange={onIdentifierTypeChange}
											errorState={errorState}
											handleAddSglnToGrid={handleAddSglnToGrid}
											allDisabled={
												location?.state?.type === 'edit' && allFieldsDisbled
											}
											identifierTypeloading={identifierTypeloading}
											identifierPlaceholder={identifierPlaceholder}
											identifierValueError={identifierValueError}
											glnErrors={glnErrors}
											sglnErrors={sglnErrors}
											fieldDisabled={fieldDisabled}
											buttonLoading={identifierValidationResLoading}
										/>
									</div>
								</div>

								<IdentifierGrid
									selectedPrimaryIdentifier={selectedPrimaryIdentifier}
									handlePrimaryIdentifierGridChange={
										handlePrimaryIdentifierGridChange
									}
									identifierData={identifierDataList}
									handleDeleteSglnSection={handleDeleteSglnSection}
									allDisabled={
										location?.state?.type === 'edit' && allFieldsDisbled
									}
								/>
								<div className='section-wrapper mt-3'>
									<ContactDetails
										contactName={contactName}
										email={email}
										telephone={telephone}
										handleChange={handleChange}
										errorState={errorState}
										allDisabled={
											location?.state?.type === 'edit' && allFieldsDisbled
										}
									/>

									<div className='col-xl-6 col-lg-6'>
										<div className='form-group'>
											<label className='form-label'>
												{t('COMPANY_LOGO')}
											</label>
											<div className='inner-column-wrapper validation-wrapper user-height h-30'>
												<ImageCropper
													file={logo}
													onFileChange={handleLogoChange}
												/>
											</div>
										</div>
									</div>

									<div className='bottom-btn-group bottom-manufacture-end'>
										<CustomButton
											disabled={
												location?.state?.type === 'edit' && allFieldsDisbled
											}
											buttonClassName='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-outline-primary h-44px w-auto icon-add-btn'
											type='button'
											buttonName={t('CLEAR')}
											handleClick={handleClear}
										/>

										<CustomButton
											disabled={
												(location?.state?.type === 'edit' &&
													allFieldsDisbled) ||
												addManufacturerLoading ||
												updateManufacturerLoading
											}
											buttonClassName='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px w-auto icon-add-btn'
											type='button'
											buttonName={
												addManufacturerLoading ||
												updateManufacturerLoading ? (
													<CustomLoader />
												) : (
													t('SUBMIT')
												)
											}
											handleClick={handleSubmit}
										/>
									</div>
								</div>
							</fieldset>
						</form>
					)}
					{isPasswordVerification &&
						modulesRequireAuth?.includes(MODULE_IDS.PRODUCT_MANAGEMENT) && (
							<PasswordVerification
								onVerificationSuccess={handleVerificationSuccess}
								toggleDialog={handleToggle}
								actions={
									location?.state?.type === 'add'
										? PASSWORD_VERIFICATION_ACTIONS.CREATE
										: PASSWORD_VERIFICATION_ACTIONS.EDIT
								}
								transactions={PASSWORD_VERIFICATION_TRANSACTIONS.MANUFACTURER}
								referenceId={manufacturerId}
							/>
						)}
				</div>
			</div>
		</div>
	);
};

export default AddEditManufacture;
