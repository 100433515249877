const SettingsSkeleton = () => {
	return (
		<div className='dummy-skelton settings-skeleton'>
			<div className='box'>
				<div className='skeleton '>
					<div className='skeleton-left flex1 '>
						<div className='line product-line' />
						<div className='d-flex'>
							<div className='line product-line w-50 me-2' />
							<div className='line product-line w-50' />
						</div>
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='d-flex'>
							<div className='line product-line w-50 me-2' />
							<div className='line product-line w-50' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-50 me-2' />
							<div className='line product-line w-50' />
						</div>
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
					</div>
					<div className='skeleton-left flex1 h-100 resp-tab'>
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='line product-line' />
						<div className='d-flex'>
							<div className='line product-line w-50 me-2' />
							<div className='line product-line w-50' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-50 me-2' />
							<div className='line product-line w-50' />
						</div>
						<div className='line product-line h-16' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default SettingsSkeleton;
