import { useState, useEffect } from 'react';

import { UPDATE_THEME_URL } from 'utils/serviceUrls';
import { axiosPrivateInstance } from '../libs/axios/index';

/**
 *
 * @param darkThemeEnabled -key which indicates whether darktheme is enabled or not
 * @param darkThemeClicked -key which indicates whether darktheme switch is clicked or not
 * @param updateThemeChangeClick  -dark theme  button click change function
 * @returns theme switch response and theme switch loading
 */
const useThemeSwitcher = (
	darkThemeEnabled: boolean,
	darkThemeClicked: boolean,
	setDarkThemeClicked: any,
) => {
	const [themeSwitchResponse, setThemeSwitchResponse] = useState<boolean>(false);
	const [themeSwitchloading, setThemeSwitchLoading] = useState<boolean>(false);

	const params = {
		theme: darkThemeEnabled,
	};

	useEffect(() => {
		if (darkThemeClicked) {
			setThemeSwitchLoading(true);
			axiosPrivateInstance
				.post(UPDATE_THEME_URL, params)
				.then((response: any) => {
					if (response?.status) {
						setThemeSwitchResponse(true);
					} else {
						setThemeSwitchResponse(false);
					}
				})
				.catch(() => {
					setThemeSwitchResponse(false);
				})
				.finally(() => {
					setThemeSwitchLoading(false);
				});
		}

		return () => {
			setThemeSwitchLoading(false);
			setDarkThemeClicked(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [darkThemeClicked]);

	return {
		themeSwitchResponse,
		themeSwitchloading,
	};
};
export default useThemeSwitcher;
