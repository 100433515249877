import ErrorBoundaryWrapper from 'libs/errorBoundary/ErrorBoundaryWrapper';
import { Outlet } from 'react-router-dom';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import {
	getMessage,
	getNotificationType,
	getShowNotification,
	setMessage,
	setNotificationType,
	setShowNotification,
} from 'slices/roleModuleSlice';
import CustomNotification from 'components/common/notification/CustomNotification';
import FooterContainer from './Footer';
import Sidebar from './LeftSidebar';

const Index = () => {
	const dispatch = useAppDispatch();
	const showNotification = useAppSelector(getShowNotification);
	const getErrorMsg = useAppSelector(getMessage);
	const getNotifiType = useAppSelector(getNotificationType);

	const handleClose = () => {
		dispatch(setShowNotification(false));
		dispatch(setMessage(''));
		dispatch(setNotificationType(''));
	};

	return (
		<ErrorBoundaryWrapper>
			{showNotification && (
				<CustomNotification
					message={getErrorMsg}
					onClose={handleClose}
					closable
					type={{ style: getNotifiType, icon: true }}
				/>
			)}
			<div className='login-wrapper'>
				<div className='login-container'>
					<Sidebar />
					<Outlet />
				</div>
				<FooterContainer />
			</div>
		</ErrorBoundaryWrapper>
	);
};
export default Index;
