import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const Index = lazy(() => import('components/productModule/packageLevel/index'));
const PackageLevel = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<Index />;
			</Suspense>
		</div>
	);
};
export default PackageLevel;
