/**
 * ScanHistoryList component to display a list of scan history data in a grid. Uses virtual scroller for performance.
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.scanHistoryData - The data for the scan history.
 * @param {string} props.translationkey - The translation key for i18next translations.
 * @returns {JSX.Element} - The rendered component.
 */
import { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { Grid, GridColumn } from '@progress/kendo-react-grid';

import { SMALLGRIDPAGESIZE } from 'utils/constants';

const ScanHistoryList = ({ scanHistoryData, translationkey }: any) => {
	const { t } = useTranslation(`${translationkey}`);

	// State to manage the pagination and virtual scrolling.
	const [skip, setSkip] = useState<number>(0);
	const pageSize = SMALLGRIDPAGESIZE;

	// Callback function for handling page changes in the grid.
	const pageChange = (event: any) => {
		setSkip(event.page.skip);
	};

	return (
		<Grid
			className='default-kendo-grid audit-report-grid'
			style={{ height: '420px' }}
			data={scanHistoryData}
			total={
				scanHistoryData && scanHistoryData[0] ? scanHistoryData?.length : SMALLGRIDPAGESIZE
			}
			rowHeight={50}
			skip={skip}
			pageSize={pageSize}
			scrollable='virtual'
			onPageChange={pageChange}>
			<GridColumn field='location' title={t('LOCATION')!!} />
			<GridColumn field='date' title={t('DATE')!!} />
			<GridColumn field='time' title={t('TIME')!!} />
			{/* <GridColumn field='latitude' title={t('LATITUDE')!!} />
			<GridColumn field='longitude' title={t('LONGITUDE')!!} /> */}
		</Grid>
	);
};

export default ScanHistoryList;
