import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_USER_DETAILS_URL } from 'utils/serviceUrls';
import { AUTH_TOKEN_NAME, STATUS_CODE_200, STATUS_CODE_400 } from 'utils/constants';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetUserData = (paramData: any) => {
	const token = localStorage.getItem(`${AUTH_TOKEN_NAME}`);

	const dispatch = useAppDispatch();
	const { t } = useTranslation('userModule');

	const [userDetailsResponse, setUserDetailsResponse] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);

	useEffect(() => {
		if (paramData) {
			setLoading(true);
			axiosPrivateInstance
				.post(GET_USER_DETAILS_URL)
				.then((response: any) => {
					if (response?.statusCode === STATUS_CODE_200) {
						setUserDetailsResponse(response);
					} else {
						setUserDetailsResponse({ status: STATUS_CODE_400 });
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					setUserDetailsResponse({ status: STATUS_CODE_400 });
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [paramData]);

	return {
		loading,
		userDetailsResponse,
		token,
	};
};

export default useGetUserData;
