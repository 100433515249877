import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CustomInput from 'components/common/forms/CustomInput';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import AddEditLocationSkeleton from 'components/common/skeleton/AddEditLocationSkeleton';
import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID } from 'utils/enum';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import { Badge } from '@progress/kendo-react-indicators';
import useGetLocationById from './hooks/useGetLocationById';
import IdentifierGrid from './IdentifierGrid';
import ContactDetails from './ContactDetails';

const ViewLocation = () => {
	const { t } = useTranslation('locationModule');
	const location = useLocation();

	const [locationName, setLocationName] = useState<string>('');
	const [streetAddressA, setStreetAddressA] = useState<string>('');
	const [streetAddressB, setStreetAddressB] = useState<string>('');
	const [postalCode, setPostalCode] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [selectedCountry, setSelectedCountry] = useState<any>('');

	const [gs1Prefix, setGs1Prefix] = useState<string | number>('');
	const [contactName, setContactName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [telephone, setTelephone] = useState<string>('');
	const [subjectDescription, setSubjectDescription] = useState<string>('');

	/** sgln section */
	const [identifierDataList, setIdentifierDataList] = useState<any>([]);

	const [allFieldsDisbled, setAllFieldsDisabled] = useState<boolean>(false);

	const [locationAddEditInfo, setLocationAddEditInfo] = useState<string>('');

	// new fields for manufacturer
	const [district, setDistrict] = useState<string>('');
	const [village, setVillage] = useState<string>('');
	const [houseNumber, setHouseNumber] = useState<string>('');
	const [township, setTownship] = useState<string>('');

	const [companyEmail, setcompanyEmail] = useState<string>('');
	const [companyURL, setcompanyURL] = useState<string>('');
	const [buildingNumber, setBuildingNumber] = useState<string>('');
	const [plantNumber, setPlantNumber] = useState<string>('');
	const [floorNumber, setFloorNumber] = useState<string>('');
	const [taxRegCode, setTaxRegCode] = useState<string>('');
	const [premisesCode, setPremisesCode] = useState<string>('');
	const [ownerName, setOwnerName] = useState<string>('');

	/** hook to get data by Id */
	const { resData, locationLoading } = useGetLocationById(location?.state?.locationId);

	/** sets icon description data in state */
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.LOCATION_DETAILS,
		ICON_DESCRIPTION_ID.LOCATION_ADD_EDIT,
	);

	/** icnon description adding */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setLocationAddEditInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	// fuction for check the condition for optional fields required or not
	const handleIsMandatory = () => {
		if (
			streetAddressA ||
			streetAddressB ||
			city ||
			selectedCountry ||
			district ||
			village ||
			houseNumber ||
			township ||
			state ||
			postalCode ||
			floorNumber ||
			plantNumber ||
			buildingNumber ||
			taxRegCode ||
			premisesCode
		) {
			return true;
		}
		return false;
	};

	// optional required fields function call in fields change
	useEffect(() => {
		handleIsMandatory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		subjectDescription,
		streetAddressA,
		streetAddressB,
		city,
		district,
		village,
		houseNumber,
		township,
		state,
		postalCode,
		selectedCountry,
		buildingNumber,
		plantNumber,
		floorNumber,
		premisesCode,
		taxRegCode,
	]);

	/** setting states to input fields from getbyId hook */
	useEffect(() => {
		if (resData) {
			setLocationName(resData?.locationName);
			setSubjectDescription(resData?.subjectDescription);
			setStreetAddressA(resData?.street1Address);
			setStreetAddressB(resData?.street2Address);
			setPostalCode(resData?.zipCode);
			setCity(resData?.city);
			setState(resData?.stateName);
			setSelectedCountry(resData?.countryName);
			setGs1Prefix(resData?.gs1GtinPrefix);
			setIdentifierDataList(resData?.identifierTypeList);
			setContactName(resData?.contactName);
			setEmail(resData?.emailId ? resData?.emailId : '');
			setTelephone(resData?.phoneNo ? resData?.phoneNo : '');
			setAllFieldsDisabled(resData?.isDisabled);

			setDistrict(resData?.district);
			setVillage(resData?.villege);
			setHouseNumber(resData?.houseNumber);
			setTownship(resData?.townShip);
			setcompanyEmail(resData?.companyEmail ? resData?.companyEmail : '');
			setcompanyURL(resData?.companyUrl ? resData?.companyUrl : '');
			setBuildingNumber(resData?.buildingNumber);
			setPlantNumber(resData?.plantNumber);
			setFloorNumber(resData?.floorNumber);
			setTaxRegCode(resData?.taxRegistrationCode);
			setPremisesCode(resData?.premisesCode);
			setOwnerName(resData?.ownerName);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resData]);

	return (
		<div className='page-content add-product-group-module'>
			<div className='page-header' style={{ position: 'relative' }}>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{location?.state?.type === 'view' && `${t('VIEW_LOCATION')}`}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={locationAddEditInfo}
							moduleId={ICON_DESCRIPTION_ID.LOCATION_ADD_EDIT}
						/>
					</h3>
					{allFieldsDisbled && (
						<Badge rounded='small' themeColor='secondary' className='partner-badge'>
							{t('LOCATION_UNDER_USE')}
						</Badge>
					)}
				</div>
			</div>

			<div className='page-content-body'>
				<div className='box-container product-order-details-container'>
					{locationLoading ? (
						<AddEditLocationSkeleton />
					) : (
						<form className='h-100'>
							<fieldset className='k-form-fieldset default-form-style tenant-content-body light-bg-form-wrapper add-location-body'>
								<div className='section-wrapper mb-0 border-bottom-radius-0 pb-0'>
									<div className='row'>
										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={locationName}
													onChange={() => {}}
													name='locationName'
													label={t('LOCATION_NAME')!!}
													placeholder={t('LOCATION_NAME')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={ownerName}
													onChange={() => {}}
													name='ownerName'
													label={t('OWNER_NAME')!!}
													placeholder={t('OWNER_NAME')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={gs1Prefix}
													onChange={() => {}}
													name='gs1Prefix'
													label={t('GS1_PREFIX')!!}
													placeholder={t('GS1_PREFIX')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>
										<div className='col-xl-4 col-lg-4 col-sm-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled
													className=' two-row-height'
													value={subjectDescription}
													onChange={() => {}}
													label={t('SUBJECT_DESCRIPTION')!!}
													placeholder={t('SUBJECT_DESCRIPTION')!!}
													textAreaName='subjectDescription'
													textAreaId='subjectDescription'
													required={false}
													cols={30}
													rows={5}
												/>
											</div>
										</div>
										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled
													value={streetAddressA}
													onChange={() => {}}
													label={t('STREET_ADDRESS_A')!!}
													placeholder={t('STREET_ADDRESS_A')!!}
													textAreaName='streetAddressA'
													textAreaId='streetAddressA'
													required
													cols={30}
													rows={5}
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled
													className=' two-row-height'
													value={streetAddressB}
													onChange={() => {}}
													label={t('STREET_ADDRESS_B')!!}
													placeholder={t('STREET_ADDRESS_B')!!}
													textAreaName='streetAddressB'
													textAreaId='streetAddressB'
													required
													cols={30}
													rows={5}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={houseNumber}
													onChange={() => {}}
													name='houseNumber'
													label={t('HOUSE_NUMBER')!!}
													placeholder={t('HOUSE_NUMBER')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>
										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={village}
													onChange={() => {}}
													name='village'
													label={t('VILLAGE')!!}
													placeholder={t('VILLAGE')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={township}
													onChange={() => {}}
													name='township'
													label={t('TOWNSHIP')!!}
													placeholder={t('TOWNSHIP')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={city}
													onChange={() => {}}
													name='city'
													label={t('CITY')!!}
													placeholder={t('CITY')}
													required
													type='text'
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={district}
													onChange={() => {}}
													name='district'
													label={t('DISTRICT')!!}
													placeholder={t('DISTRICT')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={state}
													onChange={() => {}}
													name='state'
													label={t('STATE')!!}
													placeholder={t('STATE')}
													required
													type='text'
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={postalCode}
													onChange={() => {}}
													name='postalCode'
													label={t('POSTAL_CODE')!!}
													placeholder={t('POSTAL_CODE')}
													required
													type='text'
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={selectedCountry}
													onChange={() => {}}
													name='countryName'
													label={t('COUNTRY')!!}
													placeholder={t('COUNTRY')}
													required
													type='text'
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={companyEmail}
													onChange={() => {}}
													name='companyEmail'
													label={t('COMPANY_EMAIL')!!}
													placeholder={t('COMPANY_EMAIL')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={companyURL}
													onChange={() => {}}
													name='companyURL'
													label={t('COMPANY_URL')!!}
													placeholder={t('COMPANY_URL')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={buildingNumber}
													onChange={() => {}}
													name='buildingNumber'
													label={t('BUILDING_NUMBER')!!}
													placeholder={t('BUILDING_NUMBER')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={plantNumber}
													onChange={() => {}}
													name='plantNumber'
													label={t('PLANT_NUMBER')!!}
													placeholder={t('PLANT_NUMBER')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={floorNumber}
													onChange={() => {}}
													name='floorNumber'
													label={t('FLOOR_NUMBER')!!}
													placeholder={t('FLOOR_NUMBER')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={taxRegCode}
													onChange={() => {}}
													name='taxRegCode'
													label={t('TAX_REG_CODE')!!}
													placeholder={t('TAX_REG_CODE')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled
													value={premisesCode}
													onChange={() => {}}
													name='premisesCode'
													label={t('PREMISES_CODE')!!}
													placeholder={t('PREMISES_CODE')}
													required
													type='text'
													// isMandatory
												/>
											</div>
										</div>
									</div>
								</div>

								<IdentifierGrid identifierData={identifierDataList} allDisabled />
								<div className='section-wrapper mt-3'>
									<ContactDetails
										contactName={contactName}
										email={email}
										telephone={telephone}
										handleChange={() => {}}
										allDisabled
									/>
								</div>
							</fieldset>
						</form>
					)}
				</div>
			</div>
		</div>
	);
};

export default ViewLocation;
