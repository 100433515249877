import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
// import {
//   format as formatDate,
//   formatRelative,
//   formatDistance,
//   isDate
// } from 'date-fns';
// import { enUS, es, pt, fr, de, ko, uk } from 'date-fns/locale';

// const locales = { enUS, es, pt, fr, de, ko, uk };

// english
import mainEn from './locales/en/translations.json';
import userModuleEnglish from '../../components/common/translations/userModule/en/translations.json';
import roleModuleEnglish from '../../components/common/translations/roleModule/en/translations.json';
import productModuleEnglish from '../../components/common/translations/productmodule/en/translation.json';
import manufacturerModuleEnglish from '../../components/common/translations/manufacturerModule/en/translations.json';
import authenticationModuleEnglish from '../../components/common/translations/authentication/en/translations.json';
import productGroupEnglish from '../../components/common/translations/productGroup/en/translations.json';
import packageLevelModuleEnglish from '../../components/common/translations/packageLevelModule/en/translations.json';
import l3SystemEnglish from '../../components/common/translations/l3System/en/translations.json';
import l4English from '../../components/common/translations/l4Module/en/translations.json';
import batchPOEnglish from '../../components/common/translations/batchPOModule/en/translations.json';
import dashboardEnglish from '../../components/common/translations/dashboard/en/translations.json';
import fileFailureEnglish from '../../components/common/translations/fileFailure/en/translations.json';
import epcisEnglish from '../../components/common/translations/epcisModule/en/translations.json';
import notificationEnglish from '../../components/common/translations/notification/en/translations.json';
import endUserScanEnglish from '../../components/common/translations/endUserScan/en/translations.json';
import reportsEnglish from '../../components/common/translations/reports/en/translations.json';
import ModuleConfigurationEnglish from '../../components/common/translations/moduleConfiguration/en/translations.json';
import locationEnglish from '../../components/common/translations/locationModule/en/translations.json';
import l4SystemEnglish from '../../components/common/translations/l4System/en/translations.json';
import serialNumberEnglish from '../../components/common/translations/serialNumberModule/en/translations.json';
import SSOEnglish from '../../components/common/translations/sso/en/translations.json';
import gs1WeblinkEnglish from '../../components/common/translations/gs1Weblink/en/translations.json';

// german
import mainGerman from './locales/german/translations.json';
import userModuleGerman from '../../components/common/translations/userModule/german/translations.json';
import roleModuleGerman from '../../components/common/translations/roleModule/german/translations.json';
import manufacturerModuleGerman from '../../components/common/translations/manufacturerModule/german/translations.json';
import productModuleGerman from '../../components/common/translations/productmodule/german/translation.json';
import authenticationModuleGerman from '../../components/common/translations/authentication/german/translations.json';
import productGroupGerman from '../../components/common/translations/productGroup/german/translations.json';
import packageLevelModuleGerman from '../../components/common/translations/packageLevelModule/german/translations.json';
import l3SystemGerman from '../../components/common/translations/l3System/german/translations.json';
import batchPOGerman from '../../components/common/translations/batchPOModule/german/translations.json';
import l4German from '../../components/common/translations/l4Module/german/translations.json';
import dashboardGerman from '../../components/common/translations/dashboard/german/translations.json';
import fileFailureGerman from '../../components/common/translations/fileFailure/german/translations.json';
import epcisGerman from '../../components/common/translations/epcisModule/german/translations.json';
import notificationGerman from '../../components/common/translations/notification/german/translations.json';
import endUserScanGerman from '../../components/common/translations/endUserScan/german/translations.json';
import reportsGerman from '../../components/common/translations/reports/german/translations.json';
import ModuleConfigurationGerman from '../../components/common/translations/moduleConfiguration/german/translations.json';
import locationGerman from '../../components/common/translations/locationModule/german/translations.json';
import l4SystemGerman from '../../components/common/translations/l4System/german/translations.json';

// chinese_simplified
import mainChineseS from './locales/chinese_s/translations.json';
import userModuleChineseS from '../../components/common/translations/userModule/chinese_s/translations.json';
import roleModuleChineseS from '../../components/common/translations/roleModule/chinese_s/translations.json';
import manufacturerModuleChineseS from '../../components/common/translations/manufacturerModule/chinese_s/translations.json';
import productModuleChineseS from '../../components/common/translations/productmodule/chinese_s/translations.json';
import authenticationModuleChineseS from '../../components/common/translations/authentication/chinese_s/translations.json';
import productGroupChineseS from '../../components/common/translations/productGroup/chinese_s/translations.json';
import packageLevelModuleChineseS from '../../components/common/translations/packageLevelModule/chinese_s/translations.json';
import l3SystemChineseS from '../../components/common/translations/l3System/chinese_s/translations.json';
import batchPOChineseS from '../../components/common/translations/batchPOModule/chinese_s/translations.json';
import l4ChineseS from '../../components/common/translations/l4Module/chinese_s/translations.json';
import dashboardChineseS from '../../components/common/translations/dashboard/chinese_s/translations.json';
import fileFailureChineseS from '../../components/common/translations/fileFailure/chinese_s/translations.json';
import epcisChineseS from '../../components/common/translations/epcisModule/chinese_s/translations.json';
import notificationChineseS from '../../components/common/translations/notification/chinese_s/translations.json';
import endUserScanChineseS from '../../components/common/translations/endUserScan/chinese_s/translations.json';
import reportsChineseS from '../../components/common/translations/reports/chinese_s/translations.json';
import ModuleConfigurationChineseS from '../../components/common/translations/moduleConfiguration/chinese_s/translations.json';
import locationChineseS from '../../components/common/translations/locationModule/chinese_s/translations.json';
import l4SystemChinese from '../../components/common/translations/l4System/chinese_s/translations.json';

// chinese_t
import mainChineseT from './locales/chinese_t/translations.json';
import userModuleChineseT from '../../components/common/translations/userModule/chinese_t/translations.json';
import roleModuleChineseT from '../../components/common/translations/roleModule/chinese_t/translations.json';
import manufacturerModuleChineseT from '../../components/common/translations/manufacturerModule/chinese_t/translations.json';
import productModuleChineseT from '../../components/common/translations/productmodule/chinese_t/translations.json';
import authenticationModuleChineseT from '../../components/common/translations/authentication/chinese_t/translations.json';
import productGroupChineseT from '../../components/common/translations/productGroup/chinese_t/translations.json';
import packageLevelModuleChineseT from '../../components/common/translations/packageLevelModule/chinese_t/translations.json';
import l3SystemChineseT from '../../components/common/translations/l3System/chinese_t/translations.json';
import batchPOChineseT from '../../components/common/translations/batchPOModule/chinese_t/translations.json';
import l4ChineseT from '../../components/common/translations/l4Module/chinese_t/translations.json';
import dashboardChineseT from '../../components/common/translations/dashboard/chinese_t/translations.json';
import fileFailureChineseT from '../../components/common/translations/fileFailure/chinese_t/translations.json';
import epcisChineseT from '../../components/common/translations/epcisModule/chinese_t/translations.json';
import notificationChineseT from '../../components/common/translations/notification/chinese_t/translations.json';
import endUserScanChineseT from '../../components/common/translations/endUserScan/chinese_t/translations.json';
import reportsChineseT from '../../components/common/translations/reports/chinese_t/translations.json';
import ModuleConfigurationChineseT from '../../components/common/translations/moduleConfiguration/chinese_t/translations.json';
import locationChineseT from '../../components/common/translations/locationModule/chinese_t/translations.json';
import l4SystemChineseT from '../../components/common/translations/l4System/chinese_t/translations.json';

// poland
import mainPoland from './locales/poland/translations.json';
import userModulePoland from '../../components/common/translations/userModule/poland/translations.json';
import roleModulePoland from '../../components/common/translations/roleModule/poland/translations.json';
import manufacturerModulePoland from '../../components/common/translations/manufacturerModule/poland/translations.json';
import productModulePoland from '../../components/common/translations/productmodule/poland/translations.json';
import authenticationModulePoland from '../../components/common/translations/authentication/poland/translations.json';
import productGroupPoland from '../../components/common/translations/productGroup/poland/translations.json';
import packageLevelModulePoland from '../../components/common/translations/packageLevelModule/poland/translations.json';
import l3SystemPoland from '../../components/common/translations/l3System/poland/translations.json';
import batchPOPoland from '../../components/common/translations/batchPOModule/poland/translations.json';
import l4Poland from '../../components/common/translations/l4Module/poland/translations.json';
import dashboardPoland from '../../components/common/translations/dashboard/poland/translations.json';
import fileFailurePoland from '../../components/common/translations/fileFailure/poland/translations.json';
import epcisPoland from '../../components/common/translations/epcisModule/poland/translations.json';
import notificationPoland from '../../components/common/translations/notification/poland/translations.json';
import endUserScanPoland from '../../components/common/translations/endUserScan/poland/translations.json';
import reportsPoland from '../../components/common/translations/reports/poland/translations.json';
import ModuleConfigurationPoland from '../../components/common/translations/moduleConfiguration/poland/translations.json';
import locationPoland from '../../components/common/translations/locationModule/poland/translations.json';
import l4SystemPoland from '../../components/common/translations/l4System/poland/translations.json';

// finland
import mainFinland from './locales/finland/translations.json';
import userModuleFinland from '../../components/common/translations/userModule/finland/translations.json';
import roleModuleFinland from '../../components/common/translations/roleModule/finland/translations.json';
import manufacturerModuleFinland from '../../components/common/translations/manufacturerModule/finland/translations.json';
import productModuleFinland from '../../components/common/translations/productmodule/finland/translations.json';
import authenticationModuleFinland from '../../components/common/translations/authentication/finland/translations.json';
import productGroupFinland from '../../components/common/translations/productGroup/finland/translations.json';
import packageLevelModuleFinland from '../../components/common/translations/packageLevelModule/finland/translations.json';
import l3SystemFinland from '../../components/common/translations/l3System/finland/translations.json';
import batchPOFinland from '../../components/common/translations/batchPOModule/finland/translations.json';
import l4Finland from '../../components/common/translations/l4Module/finland/translations.json';
import dashboardFinland from '../../components/common/translations/dashboard/finland/translations.json';
import fileFailureFinland from '../../components/common/translations/fileFailure/finland/translations.json';
import epcisFinland from '../../components/common/translations/epcisModule/finland/translations.json';
import notificationFinland from '../../components/common/translations/notification/finland/translations.json';
import endUserScanFinland from '../../components/common/translations/endUserScan/finland/translations.json';
import reportsFinland from '../../components/common/translations/reports/finland/translations.json';
import ModuleConfigurationFinland from '../../components/common/translations/moduleConfiguration/finland/translations.json';
import locationFinland from '../../components/common/translations/locationModule/finland/translations.json';
import l4SystemFinland from '../../components/common/translations/l4System/finland/translations.json';

// russia
import mainRussia from './locales/russia/translations.json';
import userModuleRussia from '../../components/common/translations/userModule/russia/translations.json';
import roleModuleRussia from '../../components/common/translations/roleModule/russia/translations.json';
import manufacturerModuleRussia from '../../components/common/translations/manufacturerModule/russia/translations.json';
import productModuleRussia from '../../components/common/translations/productmodule/russia/translations.json';
import authenticationModuleRussia from '../../components/common/translations/authentication/russia/translations.json';
import productGroupRussia from '../../components/common/translations/productGroup/russia/translations.json';
import packageLevelModuleRussia from '../../components/common/translations/packageLevelModule/russia/translations.json';
import l3SystemRussia from '../../components/common/translations/l3System/russia/translations.json';
import batchPORussia from '../../components/common/translations/batchPOModule/russia/translations.json';
import l4Russia from '../../components/common/translations/l4Module/russia/translations.json';
import dashboardRussia from '../../components/common/translations/dashboard/russia/translations.json';
import fileFailureRussia from '../../components/common/translations/fileFailure/russia/translations.json';
import epcisRussia from '../../components/common/translations/epcisModule/russia/translations.json';
import notificationRussia from '../../components/common/translations/notification/russia/translations.json';
import endUserScanRussia from '../../components/common/translations/endUserScan/russia/translations.json';
import reportsRussia from '../../components/common/translations/reports/russia/translations.json';
import ModuleConfigurationRussia from '../../components/common/translations/moduleConfiguration/russia/translations.json';
import locationRussia from '../../components/common/translations/locationModule/russia/translations.json';
import l4SystemRussia from '../../components/common/translations/l4System/russia/translations.json';

i18n.use(initReactI18next).init({
	fallbackLng: 'en',
	lng: 'en',
	resources: {
		en: {
			main: mainEn,
			userModule: userModuleEnglish,
			roleModule: roleModuleEnglish,
			productModule: productModuleEnglish,
			manufacturerModule: manufacturerModuleEnglish,
			productGroup: productGroupEnglish,
			packageLevelModule: packageLevelModuleEnglish,
			authModule: authenticationModuleEnglish,
			l3System: l3SystemEnglish,
			batchPOModule: batchPOEnglish,
			l4Module: l4English,
			dashboard: dashboardEnglish,
			fileFailure: fileFailureEnglish,
			epcisModule: epcisEnglish,
			notification: notificationEnglish,
			endUserScan: endUserScanEnglish,
			reports: reportsEnglish,
			moduleConfiguration: ModuleConfigurationEnglish,
			locationModule: locationEnglish,
			l4System: l4SystemEnglish,
			serialNumber: serialNumberEnglish,
			sso: SSOEnglish,
			gs1Weblink: gs1WeblinkEnglish,
		},
		de: {
			main: mainGerman,
			userModule: userModuleGerman,
			roleModule: roleModuleGerman,
			productModule: productModuleGerman,
			manufacturerModule: manufacturerModuleGerman,
			authModule: authenticationModuleGerman,
			productGroup: productGroupGerman,
			packageLevelModule: packageLevelModuleGerman,
			l3System: l3SystemGerman,
			batchPOModule: batchPOGerman,
			l4Module: l4German,
			dashboard: dashboardGerman,
			fileFailure: fileFailureGerman,
			epcisModule: epcisGerman,
			notification: notificationGerman,
			endUserScan: endUserScanGerman,
			reports: reportsGerman,
			moduleConfiguration: ModuleConfigurationGerman,
			l4System: l4SystemGerman,
			locationGermanModule: locationGerman,
		},
		zh_CN: {
			main: mainChineseS,
			userModule: userModuleChineseS,
			roleModule: roleModuleChineseS,
			productModule: productModuleChineseS,
			manufacturerModule: manufacturerModuleChineseS,
			authModule: authenticationModuleChineseS,
			productGroup: productGroupChineseS,
			packageLevelModule: packageLevelModuleChineseS,
			l3System: l3SystemChineseS,
			batchPOModule: batchPOChineseS,
			l4Module: l4ChineseS,
			dashboard: dashboardChineseS,
			fileFailure: fileFailureChineseS,
			epcisModule: epcisChineseS,
			notification: notificationChineseS,
			endUserScan: endUserScanChineseS,
			reports: reportsChineseS,
			moduleConfiguration: ModuleConfigurationChineseS,
			l4System: l4SystemChinese,
			locationChineseModuleS: locationChineseS,
		},

		zh_TW: {
			main: mainChineseT,
			userModule: userModuleChineseT,
			roleModule: roleModuleChineseT,
			productModule: productModuleChineseT,
			manufacturerModule: manufacturerModuleChineseT,
			authModule: authenticationModuleChineseT,
			productGroup: productGroupChineseT,
			packageLevelModule: packageLevelModuleChineseT,
			l3System: l3SystemChineseT,
			batchPOModule: batchPOChineseT,
			l4Module: l4ChineseT,
			dashboard: dashboardChineseT,
			fileFailure: fileFailureChineseT,
			epcisModule: epcisChineseT,
			notification: notificationChineseT,
			endUserScan: endUserScanChineseT,
			reports: reportsChineseT,
			moduleConfiguration: ModuleConfigurationChineseT,
			l4System: l4SystemChineseT,
			locationChineseModuleT: locationChineseT,
		},

		pl_PL: {
			main: mainPoland,
			userModule: userModulePoland,
			roleModule: roleModulePoland,
			productModule: productModulePoland,
			manufacturerModule: manufacturerModulePoland,
			authModule: authenticationModulePoland,
			productGroup: productGroupPoland,
			packageLevelModule: packageLevelModulePoland,
			l3System: l3SystemPoland,
			batchPOModule: batchPOPoland,
			l4Module: l4Poland,
			dashboard: dashboardPoland,
			fileFailure: fileFailurePoland,
			epcisModule: epcisPoland,
			notification: notificationPoland,
			endUserScan: endUserScanPoland,
			reports: reportsPoland,
			moduleConfiguration: ModuleConfigurationPoland,
			l4System: l4SystemPoland,
			locationPolandModule: locationPoland,
		},

		fi: {
			main: mainFinland,
			userModule: userModuleFinland,
			roleModule: roleModuleFinland,
			productModule: productModuleFinland,
			manufacturerModule: manufacturerModuleFinland,
			authModule: authenticationModuleFinland,
			productGroup: productGroupFinland,
			packageLevelModule: packageLevelModuleFinland,
			l3System: l3SystemFinland,
			batchPOModule: batchPOFinland,
			l4Module: l4Finland,
			dashboard: dashboardFinland,
			fileFailure: fileFailureFinland,
			epcisModule: epcisFinland,
			notification: notificationFinland,
			endUserScan: endUserScanFinland,
			reports: reportsFinland,
			moduleConfiguration: ModuleConfigurationFinland,
			l4System: l4SystemFinland,
			locationFinlandModule: locationFinland,
		},

		ru: {
			main: mainRussia,
			userModule: userModuleRussia,
			roleModule: roleModuleRussia,
			productModule: productModuleRussia,
			manufacturerModule: manufacturerModuleRussia,
			authModule: authenticationModuleRussia,
			productGroup: productGroupRussia,
			packageLevelModule: packageLevelModuleRussia,
			l3System: l3SystemRussia,
			batchPOModule: batchPORussia,
			l4Module: l4Russia,
			dashboard: dashboardRussia,
			fileFailure: fileFailureRussia,
			epcisModule: epcisRussia,
			notification: notificationRussia,
			endUserScan: endUserScanRussia,
			reports: reportsRussia,
			moduleConfiguration: ModuleConfigurationRussia,
			l4System: l4SystemRussia,
			locationRussianModule: locationRussia,
		},
	},
	ns: [
		'main',
		'userModule',
		'roleModule',
		'productModule',
		'manufacturerModule',
		'authModule',
		'l3System',
		'batchPOModule',
		'l4Module',
		'dashboard',
		'fileFailure',
		'epcisModule',
		'notification',
		'endUserScan',
		'reports',
		'l4System',
		'locationModule',
		'sso',
	],
	defaultNS: [
		'main',
		'userModule',
		'roleModule',
		'productModule',
		'manufacturerModule',
		'productGroup',
		'packageLevelModule',
		'authModule',
		'l3System',
		'batchPOModule',
		'l4Module',
		'dashboard',
		'fileFailure',
		'epcisModule',
		'notification',
		'endUserScan',
		'reports',
		'l4System',
		'locationModule',
		'sso',
	],

	// interpolation: {
	//   escapeValue: false,

	//   format: function (value, format, lng) {
	//     if (isDate(value)) {
	//       const locale = locales[lng];
	//       return formatDate(value, format, { locale });
	//     }
	//     return value;
	//   }
	// },
});

i18n.languages = ['en', 'de', 'zh_CN', 'zh_TW', 'pl_PL', 'fi', 'ru'];

export default i18n;
