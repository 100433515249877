import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/stateHooks';

import PasswordVerification from 'components/login/PasswordVerification';
import {
	getDataFormat,
	//  getModulesRequireAuth
} from 'slices/configSlice';
// import { getUserLoginData } from 'slices/userLoginDataSlice';
import {
	AWAITING_ACION_REPORT_TYPE,
	// AWAITING_ACION_TYPES,
	AWAITING_ACTIONS_TYPES,
	MODE_VALUES,
	// MODULE_IDS,
	NOTIFICATION_TYPES,
	// PASSWORD_VERIFICATION_TRANSACTIONS,
	// Privileges,
	RouteUrl,
	WEBLINK_OPTION_STATUS,
	WEBLINK_TYPE,
	WEBLINK_TYPE_ID,
	WEBLINK_WORKFLOW_TYPE_ID,
} from 'utils/enum';
import {
	utcFormat,
	// getPasswordVerificationAction,
	// hasPrivileges,
} from 'utils/utils';
import NotificationTextCreation from 'components/common/notification/NotificationTextCreation';
import updateApprovePOStatusService from 'components/batchPO/services/updateApprovePOStatusService';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import updateReviewPOStatusService from 'components/batchPO/services/updateReviewPOStatusService';
import updateReturnedPOStatusService from 'components/batchPO/services/updateReturnedPOStatusService';
import updateReviewStatusService from 'components/productModule/addProducts/services/updateReviewStatusService';
import updateApproveStatusService from 'components/productModule/addProducts/services/updateApproveStatusService';
import updateReturnStatusService from 'components/productModule/addProducts/services/updateReturnedStatusService';
import updateApproveWeblinkStatusService from 'components/batchPO/services/updateApproveWeblinkStatusService ';
import updateReturnedWeblinkStatusService from 'components/batchPO/services/updateReturnedWeblinkStatusService';
import updateReviewWeblinkStatusService from 'components/batchPO/services/updateReviewWeblinkStatusService';
import reviewAuditTrailService from 'components/auditTrail/services/reviewAuditTrailService';
import approveAuditTrailService from 'components/auditTrail/services/approveAuditTrailService';
import returnAuditTrailService from 'components/auditTrail/services/returnAuditTrailService';
import useWeblinkApprove from 'components/l3System/weblink-validator/hook/useWeblinkApprove';
import useWeblinkReview from 'components/l3System/weblink-validator/hook/useWeblinkReview';
import useWeblinkReject from 'components/l3System/weblink-validator/hook/useWeblinkReject';
import AwaitingActionButtons from './AwaitingActionButtons';

const AwaitingReviewCard = ({ item, setIsUpdated }) => {
	const { t } = useTranslation('dashboard');
	const { t: tProductModule } = useTranslation('productModule');
	const { t: tProductOrderModule } = useTranslation('batchPOModule');
	const { t: tReports } = useTranslation('reports');

	const navigate = useNavigate();
	const dispatch = useDispatch();
	// const modulesRequireAuth = useAppSelector(getModulesRequireAuth);
	const dateFormat = useAppSelector(getDataFormat);
	// const getLoginUserData = useAppSelector(getUserLoginData);

	// const [isAwaitingActionStatus, setIsAwaitingActionStatus] = useState<boolean>(false);
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const [isReview, setIsReview] = useState<boolean>(false);
	const [isApprove, setIsApprove] = useState<boolean>(false);
	const [action, setAction] = useState<string>('');
	const [passwordVerificationActions, setPasswordVerificationActions] = useState<number>();
	const [passwordVerificationTransactions, setPasswordVerificationTransactions] =
		useState<number>();
	const [loader, setLoader] = useState<boolean>(false);
	const [loaderType, setLoaderType] = useState<string>('');

	/**
	 * Weblink Validation API calls handling states
	 */
	const [isApproveSubmitted, setIsApproveSubmitted] = useState<boolean>(false);
	const [isReviewSubmitted, setIsReviewSubmitted] = useState<boolean>(false);
	const [isRejectSubmitted, setIsRejectSubmitted] = useState<boolean>(false);

	const [encWebLinkId, setEncWebLinkId] = useState<string>('');
	const [webLinkComment, setWebLinkComment] = useState<string>('');
	const [isWeblinkActive, setIsWeblinkActive] = useState<boolean>(false);

	const reviewActionTypes = [
		NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION,
	];
	const approveActionTypes = [
		NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION,
		NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION,
		NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION,

		NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION,

		NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION,
		NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION,
	];

	useEffect(() => {
		if (reviewActionTypes.includes(item?.type)) {
			setIsReview(true);
			setIsApprove(false);
		} else if (approveActionTypes.includes(item?.type)) {
			setIsApprove(true);
			setIsReview(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [item]);

	const handleLoaderReset = () => {
		setLoader(false);
		setLoaderType('');
	};

	// update Product Approve Status API

	const updateProductApproveStatus = async (
		id: string,
		message: string,
		isProductUpdate: boolean,
	) => {
		const approveStatusRes = await updateApproveStatusService(id, message, isProductUpdate);
		if (approveStatusRes?.output?.status) {
			setIsUpdated(true);
			dispatch(
				setMessage(
					tProductModule(
						approveStatusRes?.output?.resultText || 'PRODUCT_APPROVE_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductModule(
						approveStatusRes?.output?.resultText || 'PRODUCT_APPROVE_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// update Product Review Status API

	const updateProductReviewStatus = async (
		id: string,
		message: string,
		isProductUpdate: boolean,
	) => {
		const reviewStatus = await updateReviewStatusService(id, message, isProductUpdate);
		if (reviewStatus?.output?.status) {
			setIsUpdated(true);
			dispatch(
				setMessage(
					tProductModule(reviewStatus?.output?.resultText || 'PRODUCT_REVIEW_SUCCESS'),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductModule(reviewStatus?.output?.resultText || 'PRODUCT_REVIEW_FAILED'),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// update Product Reject Status API
	const updateProductReturnStatus = async (
		id: string,
		message: string,
		isProductUpdate: boolean,
	) => {
		const returnStatusRes = await updateReturnStatusService(id, message, isProductUpdate);
		if (returnStatusRes?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductModule(returnStatusRes?.output?.resultText || 'PRODUCT_REJECT_SUCCESS'),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductModule(returnStatusRes?.output?.resultText || 'PRODUCT_REJECT_FAILED'),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// update PO Approve Status API

	const updatePoApproveStatus = async (id: string, message: string, workflowId: number) => {
		const approveStatusRes = await updateApprovePOStatusService(id, message, workflowId);
		if (approveStatusRes?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductOrderModule(
						approveStatusRes?.output?.resultText || 'PRODUCT_ORDER_APPROVE_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductOrderModule(
						approveStatusRes?.output?.resultText || 'PRODUCT_ORDER_APPROVE_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// update PO Review Status API

	const updatePoReviewStatus = async (id: string, message: string, workflowId: number) => {
		const reviewStatus = await updateReviewPOStatusService(id, message, workflowId);
		if (reviewStatus?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductOrderModule(
						reviewStatus?.output?.resultText || 'PRODUCT_ORDER_REVIEW_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductOrderModule(
						reviewStatus?.output?.resultText || 'PRODUCT_ORDER_REVIEW_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// update PO Reject Status API

	const updatePOReturnStatus = async (id: string, message: string, workflowId: number) => {
		const returnStatusRes = await updateReturnedPOStatusService(id, message, workflowId);
		if (returnStatusRes?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductOrderModule(
						returnStatusRes?.output?.resultText || 'PRODUCT_ORDER_REJECT_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductOrderModule(
						returnStatusRes?.output?.resultText || 'PRODUCT_ORDER_REJECT_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// Weblink Approve API

	const updateWeblinkApproveStatus = async (
		id: string,
		message: string,
		workflowId: number,
		typeId: number,
	) => {
		const approveStatusRes = await updateApproveWeblinkStatusService(
			id,
			message,
			workflowId,
			typeId,
		);
		if (approveStatusRes?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductOrderModule(
						approveStatusRes?.output?.resultText || 'WEBLINK_APPROVE_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductOrderModule(
						approveStatusRes?.output?.resultText || 'WEBLINK_APPROVE_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// Weblink Review API

	const updateWeblinkReviewStatus = async (
		id: string,
		message: string,
		workflowId: number,
		typeId: number,
	) => {
		const reviewStatus = await updateReviewWeblinkStatusService(
			id,
			message,
			workflowId,
			typeId,
		);
		if (reviewStatus?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductOrderModule(
						reviewStatus?.output?.resultText || 'WEBLINK_REVIEW_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductOrderModule(
						reviewStatus?.output?.resultText || 'WEBLINK_REVIEW_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// Weblink Reject API
	const updateWeblinkReturnStatus = async (
		id: string,
		message: string,
		workflowId: number,
		typeId: number,
	) => {
		const returnStatusRes = await updateReturnedWeblinkStatusService(
			id,
			message,
			workflowId,
			typeId,
		);
		if (returnStatusRes?.output?.status) {
			setIsUpdated(true);

			dispatch(
				setMessage(
					tProductOrderModule(
						returnStatusRes?.output?.resultText || 'WEBLINK_REJECT_SUCCESS',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));
			handleLoaderReset();
		} else {
			dispatch(
				setMessage(
					tProductOrderModule(
						returnStatusRes?.output?.resultText || 'WEBLINK_REJECT_FAILED',
					),
				),
			);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			handleLoaderReset();
		}
	};

	// Report Reject API
	const updateReturnStatus = async (id: any, message: any) => {
		const returnStatusRes = await returnAuditTrailService(id, message);
		if (returnStatusRes?.output?.status) {
			setIsUpdated(true);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));

			if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
				dispatch(setMessage(tReports('AUDIT_TRAIL_REJECT_SUCCESS')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
				dispatch(setMessage(tReports('BATCH_SUMMARY_REJECT_SUCCESS')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT) {
				dispatch(setMessage(tReports('BATCH_DETAILS_REPORT_REJECT_SUCCESS')));
			}
			handleLoaderReset();
		} else {
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));

			if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
				dispatch(setMessage(tReports('AUDIT_TRAIL_REJECT_FAILED')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
				dispatch(setMessage(tReports('BATCH_SUMMARY_REJECT_FAILED')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT) {
				dispatch(setMessage(tReports('BATCH_DETAILS_REPORT_REJECT_FAILED')));
			}
			handleLoaderReset();
		}
	};

	// Report Approve API
	const updateApproveStatus = async (id: string, message: string) => {
		const approveStatusRes = await approveAuditTrailService(id, message);
		if (approveStatusRes?.output?.status) {
			setIsUpdated(true);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));

			if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
				dispatch(setMessage(tReports('AUDIT_TRAIL_APPROVE_SUCCESS')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
				dispatch(setMessage(tReports('BATCH_SUMMARY_APPROVE_SUCCESS')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT) {
				dispatch(setMessage(tReports('BATCH_DETAILS_REPORT_APPROVE_SUCCESS')));
			}
			handleLoaderReset();
		} else {
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));

			if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
				dispatch(setMessage(tReports('AUDIT_TRAIL_APPROVE_FAILED')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
				dispatch(setMessage(tReports('BATCH_SUMMARY_APPROVE_FAILED')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT) {
				dispatch(setMessage(tReports('BATCH_DETAILS_REPORT_APPROVE_FAILED')));
			}
			handleLoaderReset();
		}
	};

	// Report Review API
	const updateReviewStatus = async (id: any, message: any) => {
		const reviewStatus = await reviewAuditTrailService(id, message);
		if (reviewStatus?.output?.status) {
			setIsUpdated(true);
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('success'));

			if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
				dispatch(setMessage(tReports('AUDIT_TRAIL_REVIEW_SUCCESS')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
				dispatch(setMessage(tReports('BATCH_SUMMARY_REVIEW_SUCCESS')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT) {
				dispatch(setMessage(tReports('BATCH_DETAILS_REPORT_REVIEW_SUCCESS')));
			}
			handleLoaderReset();
		} else {
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));

			if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
				dispatch(setMessage(tReports('AUDIT_TRAIL_REVIEW_FAILED')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
				dispatch(setMessage(tReports('BATCH_SUMMARY_REVIEW_FAILED')));
			} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT) {
				dispatch(setMessage(tReports('BATCH_DETAILS_REPORT_REVIEW_FAILED')));
			}
			handleLoaderReset();
		}
	};

	/** callbacks of hooks to reset the trigger function */
	const updateApproveWeblinkStatus = () => {
		setIsApproveSubmitted(false);

		setIsUpdated(true);
		setLoader(false);
		setLoaderType('');
		setEncWebLinkId('');
		setWebLinkComment('');
	};
	const updateReviewWeblinkStatus = () => {
		setIsReviewSubmitted(false);

		setIsUpdated(true);
		setLoader(false);
		setLoaderType('');
		setEncWebLinkId('');
		setWebLinkComment('');
	};
	const updateRejectWeblinkStatus = () => {
		setIsRejectSubmitted(false);

		setIsUpdated(true);
		setLoader(false);
		setLoaderType('');
		setEncWebLinkId('');
		setWebLinkComment('');
	};

	/**
	 *  Weblink Validation Approve API
	 */

	useWeblinkApprove(
		isApproveSubmitted,
		{
			encWeblinkId: encWebLinkId,
			workFlowTypeId: isWeblinkActive
				? WEBLINK_OPTION_STATUS.ACTIVATE
				: WEBLINK_OPTION_STATUS.DEACTIVE,
			webLinkComment,
		},
		updateApproveWeblinkStatus,
	);

	/**
	 *  Weblink Validation Review API
	 */

	useWeblinkReview(
		isReviewSubmitted,
		{
			encWeblinkId: encWebLinkId,
			workFlowTypeId: isWeblinkActive
				? WEBLINK_OPTION_STATUS.ACTIVATE
				: WEBLINK_OPTION_STATUS.DEACTIVE,
			webLinkComment,
		},
		updateReviewWeblinkStatus,
	);

	/**
	 *  Weblink Validation Reject API
	 */

	useWeblinkReject(
		isRejectSubmitted,
		{
			encWeblinkId: encWebLinkId,
			workFlowTypeId: isWeblinkActive
				? WEBLINK_OPTION_STATUS.ACTIVATE
				: WEBLINK_OPTION_STATUS.DEACTIVE,
			webLinkComment,
		},
		updateRejectWeblinkStatus,
	);

	const handleApprove = (message: string) => {
		setLoader(true);
		// Product
		if (item?.type === NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION) {
			const isProductUpdate = true;
			updateProductApproveStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductApproveStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductApproveStatus(item.encId, message, isProductUpdate);
		}

		// Product Order
		if (item?.type === NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION) {
			const workflowId = 1;
			updatePoApproveStatus(item.encId, message, workflowId);
		} else if (item?.type === NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION) {
			const workflowId = 2;
			updatePoApproveStatus(item.encId, message, workflowId);
		} else if (item?.type === NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION) {
			const workflowId = 3;
			updatePoApproveStatus(item.encId, message, workflowId);
		}

		// Weblink

		if (item?.type === NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION) {
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE;
			const typeId = WEBLINK_TYPE_ID.PRODUCT;

			updateWeblinkApproveStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
			const typeId = WEBLINK_TYPE_ID.PRODUCT;

			updateWeblinkApproveStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const typeId = WEBLINK_TYPE_ID.PRODUCT_ORDER;
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE;
			updateWeblinkApproveStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const typeId = WEBLINK_TYPE_ID.PRODUCT_ORDER;
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
			updateWeblinkApproveStatus(item.encId, message, workflowId, typeId);
		}

		// Audit Trail

		if (item?.type === NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION) {
			updateApproveStatus(item.encId, message);
		}

		// Weblink Validation

		if (item?.type === NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION) {
			setEncWebLinkId(item.encId);
			setWebLinkComment(message);
			setIsApproveSubmitted(true);
			setIsWeblinkActive(true);
		} else if (
			item?.type === NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			setEncWebLinkId(item.encId);
			setWebLinkComment(message);
			setIsApproveSubmitted(true);
			setIsWeblinkActive(false);
		}
	};

	const handleReview = (message: string) => {
		setLoader(true);
		// Product
		if (item?.type === NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION) {
			const isProductUpdate = false;
			updateProductReviewStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductReviewStatus(item.encId, message, isProductUpdate);
		} else if (item?.type === NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION) {
			const isProductUpdate = true;
			updateProductReviewStatus(item.encId, message, isProductUpdate);
		}

		// Product Order
		if (item?.type === NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION) {
			const workflowId = 1;
			updatePoReviewStatus(item.encId, message, workflowId);
		} else if (item?.type === NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION) {
			const workflowId = 2;
			updatePoReviewStatus(item.encId, message, workflowId);
		} else if (item?.type === NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION) {
			const workflowId = 3;
			updatePoReviewStatus(item.encId, message, workflowId);
		}

		// Weblink

		if (item?.type === NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION) {
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE;
			const typeId = WEBLINK_TYPE_ID.PRODUCT;

			updateWeblinkReviewStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
			const typeId = WEBLINK_TYPE_ID.PRODUCT;

			updateWeblinkReviewStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const typeId = WEBLINK_TYPE_ID.PRODUCT_ORDER;
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE;
			updateWeblinkReviewStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const typeId = WEBLINK_TYPE_ID.PRODUCT_ORDER;
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
			updateWeblinkReviewStatus(item.encId, message, workflowId, typeId);
		}

		// Audit Trial
		if (item?.type === NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION) {
			updateReviewStatus(item.encId, message);
		}

		// Weblink Validation

		if (item?.type === NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION) {
			setEncWebLinkId(item.encId);
			setWebLinkComment(message);
			setIsReviewSubmitted(true);
			setIsWeblinkActive(true);
		} else if (
			item?.type === NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			setEncWebLinkId(item.encId);
			setWebLinkComment(message);
			setIsReviewSubmitted(true);
			setIsWeblinkActive(false);
		}
	};

	const handleReject = (message: string) => {
		setLoader(true);
		// Product
		if (item?.type === NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION) {
			const isProductUpdate = true;
			updateProductReturnStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductReturnStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductReturnStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductReturnStatus(item.encId, message, isProductUpdate);
		} else if (
			item?.type === NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const isProductUpdate = false;
			updateProductReturnStatus(item.encId, message, isProductUpdate);
		} else if (item?.type === NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION) {
			const isProductUpdate = true;
			updateProductReturnStatus(item.encId, message, isProductUpdate);
		}

		// Product Order
		if (
			item?.type === NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION
		) {
			const workflowId = 1;
			updatePOReturnStatus(item.encId, message, workflowId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const workflowId = 2;
			updatePOReturnStatus(item.encId, message, workflowId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION
		) {
			const workflowId = 3;
			updatePOReturnStatus(item.encId, message, workflowId);
		}

		// weblink
		if (
			item?.type === NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE;
			const typeId = WEBLINK_TYPE_ID.PRODUCT;

			updateWeblinkReturnStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
			const typeId = WEBLINK_TYPE_ID.PRODUCT;

			updateWeblinkReturnStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const typeId = WEBLINK_TYPE_ID.PRODUCT_ORDER;
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.ACTIVATE;
			updateWeblinkReturnStatus(item.encId, message, workflowId, typeId);
		} else if (
			item?.type ===
				NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			const typeId = WEBLINK_TYPE_ID.PRODUCT_ORDER;
			const workflowId = WEBLINK_WORKFLOW_TYPE_ID.DEACTIVATE;
			updateWeblinkReturnStatus(item.encId, message, workflowId, typeId);
		}

		// Audit Trail
		if (
			item?.type === NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION
		) {
			updateReturnStatus(item.encId, message);
		}

		// Weblink Validation

		if (
			item?.type === NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			setEncWebLinkId(item.encId);
			setWebLinkComment(message);
			setIsRejectSubmitted(true);
			setIsWeblinkActive(true);
		} else if (
			item?.type === NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION ||
			item?.type === NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION
		) {
			setEncWebLinkId(item.encId);
			setWebLinkComment(message);
			setIsRejectSubmitted(true);
			setIsWeblinkActive(false);
		}
	};

	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	const handleVerificationSuccess = (remark: string) => {
		if (action === MODE_VALUES.APPROVE) {
			handleApprove(remark);
		} else if (action === MODE_VALUES.REVIEW) {
			handleReview(remark);
		} else if (action === MODE_VALUES.REJECT) {
			handleReject(remark);
		}
		handleToggle();
	};

	const handleNavigateToProductPage = () => {
		// eslint-disable-next-line default-case
		switch (item?.type) {
			case NOTIFICATION_TYPES.PRODUCT_APPROVE_NOFICATION_ACTION:
				navigate(RouteUrl.ADDPRODUCTS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						productId: item.encId,
						label: t('APPROVE_PRODUCT_TITLE'),
						isProduct: true,
					},
				});
				break;
			case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.ADDPRODUCTS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						productId: item.encId,
						label: t('APPROVE_PRODUCT_TITLE'),
						isProduct: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.ADDPRODUCTS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						productId: item.encId,
						label: t('APPROVE_PRODUCT_TITLE'),
						isProduct: false,
					},
				});
				break;
			case NOTIFICATION_TYPES.PRODUCT_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.ADDPRODUCTS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						productId: item.encId,
						label: t('REVIEW_PRODUCT_TITLE'),
						isProduct: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PRODUCT_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.ADDPRODUCTS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						productId: item.encId,
						label: t('REVIEW_PRODUCT_TITLE'),
						isProduct: false,
					},
				});
				break;
			case NOTIFICATION_TYPES.PRODUCT_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.ADDPRODUCTS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						productId: item.encId,
						label: t('REVIEW_PRODUCT_TITLE'),
						isProduct: true,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_APPROVE_NOFICATION_ACTION:
				navigate(RouteUrl.PODETAILS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						isProductOrder: true,
					},
				});
				break;
			case NOTIFICATION_TYPES.PO_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.PODETAILS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						isProductOrder: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.PODETAILS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						isProductOrder: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.PODETAILS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						iid: item.encId,
						isProductOrder: true,
					},
				});
				break;
			case NOTIFICATION_TYPES.PO_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.PODETAILS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						id: item.encId,
						isProductOrder: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.PODETAILS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						id: item.encId,
						isProductOrder: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT,
						isWeblinkActive: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT,
						isWeblinkActive: true,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT_ORDER,
						isWeblinkActive: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.APPROVE,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT_ORDER,
						isWeblinkActive: true,
					},
				});
				break;

			case NOTIFICATION_TYPES.WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT,
						isWeblinkActive: false,
					},
				});

				break;

			case NOTIFICATION_TYPES.WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT,
						isWeblinkActive: true,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_WEBLINK_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT_ORDER,
						isWeblinkActive: false,
					},
				});
				break;

			case NOTIFICATION_TYPES.PO_WEBLINK_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKSTATUS, {
					state: {
						mode: MODE_VALUES.REVIEW,
						id: item.encId,
						type: WEBLINK_TYPE.PRODUCT_ORDER,
						isWeblinkActive: true,
					},
				});
				break;

			case NOTIFICATION_TYPES.REPORT_REVIEW_NOTIFICATION_ACTION:
				if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
					navigate(RouteUrl.AUDIT_SHOW, {
						state: {
							mode: MODE_VALUES.REVIEW,
							id: item.encId,
						},
					});
				} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
					navigate(RouteUrl.GENERATE_BATCH_SUMMARY, {
						state: {
							mode: MODE_VALUES.REVIEW,
							id: item.encId,
						},
					});
				} else if (
					item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT
				) {
					navigate(RouteUrl.BATCH_DETAILS_SHOW, {
						state: {
							mode: MODE_VALUES.REVIEW,
							id: item.encId,
						},
					});
				}

				break;

			case NOTIFICATION_TYPES.REPORT_APPROVE_NOFICATION_ACTION:
				if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG) {
					navigate(RouteUrl.AUDIT_SHOW, {
						state: {
							mode: MODE_VALUES.APPROVE,
							id: item.encId,
						},
					});
				} else if (item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT) {
					navigate(RouteUrl.GENERATE_BATCH_SUMMARY, {
						state: {
							mode: MODE_VALUES.APPROVE,
							id: item.encId,
						},
					});
				} else if (
					item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT
				) {
					navigate(RouteUrl.BATCH_DETAILS_SHOW, {
						state: {
							mode: MODE_VALUES.APPROVE,
							id: item.encId,
						},
					});
				}
				break;

			case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKVALIDATOR, {
					state: {
						id: item.encId,
						weblink: item.customName1,
					},
				});
				break;
			case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_REVIEW_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKVALIDATOR, {
					state: {
						id: item.encId,
						weblink: item.customName1,
					},
				});
				break;
			case NOTIFICATION_TYPES.WEBLINKID_ACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKVALIDATOR, {
					state: {
						id: item.encId,
						weblink: item.customName1,
					},
				});
				break;
			case NOTIFICATION_TYPES.WEBLINKID_DEACTIVATION_APPROVAL_NOTIFICATION_ACTION:
				navigate(RouteUrl.WEBLINKVALIDATOR, {
					state: {
						id: item.encId,
						weblink: item.customName1,
					},
				});
				break;
		}
	};

	return (
		<>
			<li>
				<div
					className='dsb-list-top'
					onClick={handleNavigateToProductPage}
					onKeyDown={handleNavigateToProductPage}
					tabIndex={0}
					role='button'>
					<div className='description'>{NotificationTextCreation(item.type, item)}</div>
					<div className='dsb-date-container'>
						<div className='dsb-date-wrap'>
							<span className='dsb-lbl'>{t('DATE')}</span>
							<span className='dsb-value-lbl'>
								{/* converting date format from utc to local */}
								{utcFormat(item?.actionDate, dateFormat)}
							</span>
						</div>
						{item?.customName3 && (
							<div className='dsb-date-wrap'>
								<span className='dsb-lbl'>
									{item?.typeId === AWAITING_ACTIONS_TYPES.REPORT
										? t('TYPE')
										: t('LOCATION')}
								</span>
								<span className='dsb-value-lbl'>{item?.customName3}</span>
							</div>
						)}
					</div>
				</div>
				<AwaitingActionButtons
					item={item}
					setAction={setAction}
					setPasswordVerificationActions={setPasswordVerificationActions}
					setPasswordVerificationTransactions={setPasswordVerificationTransactions}
					setIsPasswordVerification={setIsPasswordVerification}
					loader={loader}
					loaderType={loaderType}
					setLoaderType={setLoaderType}
					isReview={isReview}
					isApprove={isApprove}
					handleReject={handleReject}
					handleReview={handleReview}
					handleApprove={handleApprove}
				/>
			</li>
			{isPasswordVerification && (
				<PasswordVerification
					onVerificationSuccess={handleVerificationSuccess}
					toggleDialog={handleToggle}
					actions={passwordVerificationActions}
					transactions={passwordVerificationTransactions}
					referenceId={item.encId}
				/>
			)}
		</>
	);
};
export default AwaitingReviewCard;
