import { useTranslation } from 'react-i18next';

import CustomButton from 'components/common/button/CustomButton';
import CustomInput from 'components/common/forms/CustomInput';
import CustomError from 'components/common/errorLabel/CustomError';
import { IDENTIFIER_TYPE } from 'utils/enum';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomDropDown from '../common/forms/CustomDropdown';

const LocationSglngenerationSection = ({
	selectedIdentifierType,
	identifierValue,
	sglnExtension,
	sgln,
	identifierTypeList,
	handleChange,
	onIdentifierTypeChange,
	errorState,
	handleAddSglnToGrid,
	allDisabled,
	identifierTypeloading,
	identifierPlaceholder,
	identifierValueError,
	glnErrors,
	sglnErrors,
	fieldDisabled,
	buttonLoading,
}: any) => {
	const { t } = useTranslation('locationModule');

	return (
		<>
			<div className='col-xl-3 col-lg-3 col-sm-6'>
				<div className='form-group'>
					<label className='form-label'>{t('COMPANY_LOCATION_ID')}</label>
					<CustomDropDown
						disabled={allDisabled}
						isId
						popupSettings={{
							popupClass: 'default-select-dropdown',
						}}
						className={`form-control default-select-dropdown ${
							errorState?.selectedIdentifierType &&
							errorState?.selectedIdentifierType !== ''
								? 'input-validation-error'
								: ''
						}`}
						dropdownValue={selectedIdentifierType}
						isMandatory
						handleDropDownChange={onIdentifierTypeChange}
						options={identifierTypeList}
						dataItemKey='encManufacturerIdentifierId'
						dataTextField='manufacturerIdentifier'
						loading={identifierTypeloading}
					/>

					{errorState?.selectedIdentifierType &&
						errorState?.selectedIdentifierType !== '' && (
							<CustomError value={errorState?.selectedIdentifierType} />
						)}
				</div>
			</div>

			<div className='col-xl-3 col-lg-3 col-sm-6'>
				<div className='form-group'>
					<CustomInput
						disabled={allDisabled || fieldDisabled}
						className={
							(errorState?.identifierValue && errorState?.identifierValue !== '') ||
							identifierValueError !== '' ||
							glnErrors !== ''
								? 'input-validation-error'
								: ''
						}
						value={identifierValue}
						onChange={handleChange}
						name='identifierValue'
						label={t('IDENTIFIER_VALUE')!!}
						placeholder={identifierPlaceholder || t('IDENTIFIER_VALUE')}
						required={false}
						type='text'
						error={errorState?.identifierValue || identifierValueError || glnErrors}
						isMandatory
					/>
				</div>
			</div>

			{selectedIdentifierType === IDENTIFIER_TYPE.GLN && (
				<div className='col-xl-3 col-lg-3 col-sm-6'>
					<div className='form-group'>
						<CustomInput
							disabled={allDisabled || fieldDisabled}
							className={
								errorState?.sglnExtension && errorState?.sglnExtension !== ''
									? 'input-validation-error'
									: ''
							}
							value={sglnExtension}
							onChange={handleChange}
							name='sglnExtension'
							label={t('SGLN_EXTENSION')!!}
							placeholder={t('SGLN_EXTENSION')}
							required={false}
							type='text'
							error={errorState?.sglnExtension}
							isMandatory
						/>
					</div>
				</div>
			)}

			{selectedIdentifierType === IDENTIFIER_TYPE.GLN && (
				<div className='col-xl-3 col-lg-3 col-sm-6'>
					<div className='form-group'>
						<CustomInput
							disabled // auto populated
							className={
								(errorState?.sgln && errorState?.sgln !== '') || sglnErrors !== ''
									? 'input-validation-error'
									: ''
							}
							value={sgln}
							onChange={() => {}}
							name='sgln'
							label={t('SGLN')!!}
							placeholder={t('SGLN')}
							required={false}
							type='text'
							error={errorState?.sgln || sglnErrors}
							isMandatory
						/>
					</div>
				</div>
			)}
			<div className='bottom-btn-group position-relative-item button-pos-end pb-1 mb-3'>
				<CustomButton
					disabled={allDisabled}
					buttonClassName='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px icon-add-btn w-auto '
					type='button'
					buttonName={buttonLoading ? <CustomLoader /> : t('ADD')}
					handleClick={handleAddSglnToGrid}
				/>
				{errorState?.identifierTypeList && errorState?.identifierTypeList !== '' && (
					<CustomError value={errorState?.identifierTypeList} />
				)}
			</div>
		</>
	);
};

export default LocationSglngenerationSection;
