/**
 * CustomErrorModal component to display an error modal.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.content - The content to display in the modal body.
 * @param {Function} props.confirmClickNo - Callback function triggered when the 'CANCEL' button is clicked.
 * @param {boolean} props.isModalVisible - Whether the modal is visible or not.
 * @returns {JSX.Element} - The rendered component displaying the error modal.
 */
import { useTranslation } from 'react-i18next';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';

import WarnIconImage from 'assets/images/error-image.svg';
import CustomModal from './CustomModal';
import { ICustomSuccessModal } from '../model';

const CustomErrorModal = ({ content, confirmClickNo, isModalVisible }: ICustomSuccessModal) => {
	const { t } = useTranslation('userModule');

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{isModalVisible && (
				<CustomModal
					className='alert-modal'
					title='Error'
					toggleDialog={(e) => confirmClickNo(e)}
					closeIcon>
					<div className='error-modal modal-content'>
						<div className='modal-body-content text-center'>
							<img src={WarnIconImage} alt='Archieve' />
							<h4>{t('Error')}</h4>
							<p>{t(content)}</p>
						</div>

						{/* Render the action bar with 'CANCEL' button */}
						<DialogActionsBar>
							<button
								type='button'
								className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px'
								onClick={(e) => confirmClickNo(e)}>
								{t('CLOSE')}
							</button>
						</DialogActionsBar>
					</div>
				</CustomModal>
			)}
		</>
	);
};
export default CustomErrorModal;
