import { Suspense, lazy } from 'react';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';

const ProductList = lazy(() => import('components/productModule/product/ProductList'));

const Product = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<ProductList />
			</Suspense>
		</div>
	);
};

export default Product;
