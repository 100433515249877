import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';

import CustomInput from 'components/common/forms/CustomInput';
import {
	getSoapIdentifier,
	getSoapPassword,
	getSoapPasswordErrMsg,
	getSoapUsername,
	setSoapIdentifier,
	setSoapPassword,
	setSoapPasswordErrMsg,
	setSoapUsername,
} from 'slices/l3SystemSlice';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import { getL3SystemIconsData } from 'slices/infoIconsDataSlice';
import { ICON_DESCRIPTION_ID } from 'utils/enum';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import ShowIcon from 'components/common/fontAwesome/ShowIcon';

const SoapConfiguration = ({ errorState }: any) => {
	const { t } = useTranslation('l3System');
	const dispatch = useAppDispatch();

	const username = useAppSelector(getSoapUsername);
	const password = useAppSelector(getSoapPassword);
	const identifier = useAppSelector(getSoapIdentifier);

	const soapError = useAppSelector(getSoapPasswordErrMsg);

	const getUserDetails = useAppSelector(getUserLoginData);

	const l3SystemIconsData = useAppSelector(getL3SystemIconsData);
	const [soapConfigInfo, setSoapConfigInfo] = useState<string>('');

	const [soapPasswordShown, setSoapPasswordShown] = useState<boolean>(false);

	// finds l3 connection method info from redux state
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		l3SystemIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			l3SystemIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.SOAP_CONFIG) {
					setSoapConfigInfo(item?.iconDescription);
				}
			});
	}, [l3SystemIconsData]);

	useEffect(() => {
		if (getUserDetails) {
			dispatch(setSoapIdentifier(getUserDetails?.tenantCode));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getUserDetails.tenantCode]);

	const onSoapUsernameChange = (e: any) => {
		dispatch(setSoapUsername(e.target.value));
	};

	const onSoapPasswordChange = (e: any) => {
		dispatch(setSoapPassword(e.target.value));
		dispatch(setSoapPasswordErrMsg(''));
	};

	const onSoapIdentifierChange = (e: any) => {
		dispatch(setSoapIdentifier(e.target.value));
	};

	const toggleSoapPasswordVisiblity = () => {
		setSoapPasswordShown((prevState) => !prevState);
	};

	return (
		<div className='section-wrapper'>
			<h4 className='form-section-title mb-3 mt-3 info-title'>
				{t('SOAP_CONFIG_HEADING')}
				<CustomEditableTooltip
					showIcon='faInfoCircle'
					text={soapConfigInfo}
					moduleId={ICON_DESCRIPTION_ID.SOAP_CONFIG}
				/>
			</h4>
			<div className='row'>
				<div className='col-lg-12 col-md-12 col-sm-6'>
					<div className='form-group'>
						<CustomInput
							className=''
							value={username}
							label={t('USERNAME')!!}
							onChange={(e: any) => onSoapUsernameChange(e)}
							name='username'
							placeholder={t('USERNAME')}
							required
							type='text'
							isMandatory
							error={errorState.soapUsername}
						/>
					</div>
				</div>
				<div className='col-lg-12 col-md-12 col-sm-6'>
					<div className='form-group password-form-group'>
						<CustomInput
							className=''
							value={password}
							label={t('PASSWORD')!!}
							onChange={(e: any) => onSoapPasswordChange(e)}
							name='password'
							placeholder={t('PASSWORD')}
							required
							type={soapPasswordShown ? 'text' : 'password'}
							isMandatory
							error={soapError}
						/>

						<i
							className='show-password'
							role='button'
							onKeyDown={toggleSoapPasswordVisiblity}
							tabIndex={0}
							onClick={toggleSoapPasswordVisiblity}>
							{soapPasswordShown ? (
								<ShowIcon name='faEye' />
							) : (
								<ShowIcon name='faEyeSlash' />
							)}
						</i>
					</div>
				</div>
				<div className='col-lg-12 col-md-12 col-sm-6'>
					<div className='form-group'>
						<CustomInput
							className=''
							value={identifier}
							label={t('TENANT_ID')!!}
							onChange={(e: any) => onSoapIdentifierChange(e)}
							name='identifier'
							placeholder={t('TENANT_ID')}
							required
							type='text'
							isMandatory
							disabled
							error={errorState.soapIdentifier}
							maxLength={12} // max 12 characters allowed for SOAP identifier
						/>
					</div>
				</div>
			</div>
		</div>
	);
};

export default SoapConfiguration;
