import React, { useMemo } from 'react';
import { useTranslation } from 'react-i18next';
import { RE_DIGIT } from 'utils/constants';

export type InputType = {
	value: string;
	valueLength: number;
	// eslint-disable-next-line no-unused-vars
	onChange: (value: string) => void;
	submitClicked: boolean;
};

const OtpVerification = ({ value, valueLength, onChange, submitClicked }: InputType) => {
	const { t } = useTranslation('l4Module');

	const valueItems = useMemo(() => {
		const valueArray = value.split('');
		const items: Array<string> = [];

		// eslint-disable-next-line no-plusplus
		for (let i = 0; i < valueLength; i++) {
			const char = valueArray[i];

			if (RE_DIGIT.test(char)) {
				items.push(char);
			} else {
				items.push('');
			}
		}

		return items;
	}, [value, valueLength]);

	const inputOnChange = (e: React.ChangeEvent<HTMLInputElement>, idx: number) => {
		const { target } = e;
		let targetValue = target.value.trim();
		const isTargetValueDigit = RE_DIGIT.test(targetValue);

		if (!isTargetValueDigit && targetValue !== '') {
			return;
		}

		targetValue = isTargetValueDigit ? targetValue : ' ';

		if (!RE_DIGIT.test(targetValue)) {
			return;
		}

		const newValue = value.substring(0, idx) + targetValue + value.substring(idx + 1);

		onChange(newValue);
		if (!isTargetValueDigit) {
			return;
		}

		const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
		if (nextElementSibling) {
			nextElementSibling.focus();
		}
	};

	const inputOnKeyDown = (e: React.KeyboardEvent<HTMLElement>, idx: number) => {
		const target = e.target as HTMLInputElement;
		const previousElementSibling = target.previousElementSibling as HTMLInputElement | null;
		const nextElementSibling = target.nextElementSibling as HTMLInputElement | null;
		/**
		 * checks if its a backspace key or arrow keys,  else exit
		 */

		if (e.key !== 'Backspace') {
			/**
			 * navigte focus with arrow keys
			 */
			if (e.key === 'ArrowRight') {
				if (nextElementSibling) {
					nextElementSibling.focus();
				}

				return;
			}
			if (e.key === 'ArrowLeft') {
				if (previousElementSibling) {
					previousElementSibling.focus();
				}
				return;
			}
			return;
		}

		/**
		 * remove the digit when backspace pressed
		 */
		const part1 = value.substring(0, idx);
		const part2 = value.substring(idx + 1, value.length);
		const finalStr = part1 + part2;
		onChange(finalStr);

		/**
		 * Shifts the focus into previous input field
		 */

		if (previousElementSibling) {
			previousElementSibling.focus();
		}
	};

	return (
		<div className='form-group otp-group'>
			{valueItems.map((digit, idx) => (
				<input
					// eslint-disable-next-line react/no-array-index-key
					key={idx}
					type='text'
					inputMode='numeric'
					autoComplete='one-time-code'
					pattern='\d{1}'
					maxLength={valueLength}
					className={
						submitClicked && !digit ? 'otp-input input-validation-error' : 'otp-input'
					}
					value={digit}
					onChange={(e) => inputOnChange(e, idx)}
					onKeyDown={(e) => inputOnKeyDown(e, idx)}
				/>
			))}
			{submitClicked && value.length < 6 ? (
				<p className='mt-3 error-msg pos-left'>{t('FIELD_REQUIRED')}</p>
			) : (
				<> </>
			)}
		</div>
	);
};

export default OtpVerification;
