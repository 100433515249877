import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivateInstance } from 'libs/axios';

import { UPDATE_PRODUCT_GROUP } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';
import { API_OUTPUT_RESULT_ID_100 } from 'utils/constants';

const useUpdateProductGroup = (
	isEditSubmitted: boolean,
	formData: any,
	prevFormData: any,
	resetEditProductGroupStatus: any,
) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('productGroup');

	const [updateloading, setUpdateLoading] = useState<boolean>(false);
	const [updateProductGroupResponse, setUpdateProductGroupResponse] = useState<any>({});

	useEffect(() => {
		if (isEditSubmitted) {
			setUpdateLoading(true);
			axiosPrivateInstance
				.post(UPDATE_PRODUCT_GROUP, {
					current: formData,
					previous: prevFormData,
				})
				.then((response: any) => {
					setUpdateProductGroupResponse(response?.output);
					if (response?.output?.resultId === API_OUTPUT_RESULT_ID_100) {
						dispatch(setMessage(t('PRODUCT_GROUP_UPDATED_SUCCESSFULLY')));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText || 'PRODUCT_GROUP_NOT_UPDATED'),
							),
						);
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('PRODUCT_GROUP_NOT_UPDATED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setUpdateLoading(false);
					resetEditProductGroupStatus();
					dispatch(setShowNotification(true));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isEditSubmitted]);

	return {
		updateProductGroupResponse,
		updateloading,
	};
};

export default useUpdateProductGroup;
