import React, { useState, createRef, memo } from 'react';
import Cropper, { ReactCropperElement } from 'react-cropper';
import { useTranslation } from 'react-i18next';
import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faXmark } from '@fortawesome/free-solid-svg-icons';

import { FILE_SIZE_1024, IMAGE_SUPPORT_FILES, MAX_FILE_SIZE } from 'utils/constants';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

import CustomModal from '../modals/CustomModal';
import 'cropperjs/dist/cropper.css';

const close = <FontAwesomeIcon icon={faXmark} />;

/**
 * Custom image cropper modal component.
 * Allows cropping and previewing image before upload.
 * onFileChange is a callback function which returns the base64 string of the cropped image.
 */
export const ImageCropper = ({ file, onFileChange }: { file: any; onFileChange: any }) => {
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();

	const [image, setImage] = useState('');
	const [visible, setVisible] = React.useState<boolean>(false);

	/**
	 * Ref to access cropper element
	 */
	const cropperRef = createRef<ReactCropperElement>();

	/**
	 * Ref to access file input element
	 */
	const inputRef = createRef<HTMLInputElement>();
	const [imageName, setImageName] = useState('');

	/**
	 * Toggle modal visibility
	 */
	const toggleDialog = () => {
		if (inputRef?.current?.value) inputRef.current.value = '';
		setVisible(!visible);
	};

	/**
	 * Reset state and close modal with clearing the image reference in input.
	 */
	const cancelDialog = () => {
		if (inputRef?.current?.value) inputRef.current.value = '';
		setVisible(!visible);
	};

	/**
	 * Handles image file change event
	 * @param {Event} e - Change event
	 */
	const onChange = (e: any) => {
		e.preventDefault();
		let files;
		if (e.dataTransfer) {
			files = e.dataTransfer.files;
		} else if (e.target) {
			files = e.target.files;
		}
		const reader = new FileReader();

		// Array of allowed file types
		const allowedTypes = ['image/svg+xml', 'image/png', 'image/jpeg', 'image/webp'];
		const fileData = e.target.files[0];

		// Check if the selected file type is allowed
		if (!allowedTypes.includes(fileData?.type)) {
			// handle invalid file type error
			// Resetting the input ref to clear the selected image : Fix to onChange not getting triggered even validation failed and same selected.
			if (inputRef?.current?.value) inputRef.current.value = '';
			dispatch(setMessage(t('FILE_TYPE_NOT_SUPPORTED')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
			return;
		}

		const fileSizeInKB = e.target.files[0] && e.target.files[0].size / FILE_SIZE_1024; // convert the file size into byte to kb
		const fileSizeInMB = fileSizeInKB / FILE_SIZE_1024; // convert kb to mb
		if (fileSizeInMB > MAX_FILE_SIZE) {
			// Resetting the input ref to clear the selected image : Fix to onChange not getting triggered even validation failed and same selected.
			if (inputRef?.current?.value) inputRef.current.value = '';
			dispatch(setMessage(t('FILE_SIZE_LIMIT_EXCEEDED')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType('error'));
		} else if (e.target.files[0]) {
			setVisible(true);
			// Read the file and set the result as the image
			reader.onload = () => {
				setImage(reader.result as any);
			};
			reader.readAsDataURL(files[0]);
			setImageName(e.target.files[0]?.name);
		}
	};

	/**
	 * Get the cropped image data when crop is completed
	 */
	const getCropData = () => {
		if (typeof cropperRef?.current?.cropper !== 'undefined') {
			onFileChange(cropperRef.current?.cropper?.getCroppedCanvas()?.toDataURL(), imageName);
		}
		setVisible(!visible);
		setImage('');
		if (inputRef?.current?.value) inputRef.current.value = '';
	};

	/**
	 * Reset state when modal is closed and references
	 */
	const handleClose = () => {
		onFileChange('');
		setImage('');
		setImageName('');
		if (inputRef?.current?.value) inputRef.current.value = '';
	};

	return (
		<div className='image-main-wrapper'>
			{/* Design part of image upload */}
			<div className='default-upload-wrapper'>
				<div className='browse-outer'>
					<div className='browse-inner'>
						<p>{t('DRAG_AND_DROP')!!}</p>&nbsp;
						<label className='browse-btn'>
							<span>{t('CHOOSE_FILE')!!}</span>
						</label>
						&nbsp;
						<p>{t('TO_UPLOAD')!!}</p>
					</div>
					<p>
						{t('MAX_FILE_SIZE')!!} {t('SUPPORTED_FILES_FORMATS')!!}{' '}
						{IMAGE_SUPPORT_FILES}
					</p>
				</div>
				{/* Invisible input to upload image */}
				<input
					ref={inputRef}
					type='file'
					accept='image/svg+xml, image/png, image/jpeg, image/webp'
					onChange={onChange}
					alt=' '
					id='file-upload-btn'
					className='dragdrop'
				/>
			</div>
			{/* Image cropper modal */}
			{visible && (
				<CustomModal
					className='alert-modal cropper'
					title='confirmation Modal'
					toggleDialog={() => toggleDialog()}
					closeIcon>
					<Cropper
						ref={cropperRef}
						style={{ height: 400, width: '100%' }}
						zoomTo={0.5}
						initialAspectRatio={16 / 8}
						preview=''
						src={image}
						// viewMode={1}
						minCropBoxHeight={10}
						minCropBoxWidth={10}
						background={false}
						responsive
						autoCropArea={1}
						guides={false}
						checkOrientation={false} // https://github.com/fengyuanchen/cropperjs/issues/671
					/>

					<div>
						<div className='box' style={{ width: '50%', float: 'right' }}>
							<div
								className='img-preview'
								style={{ width: '100%', float: 'left', height: '300px' }}
							/>
						</div>
						<div
							className='box'
							style={{ width: '50%', float: 'right', height: '300px' }}
						/>
					</div>
					{/* Modal dialog action buttons */}
					<DialogActionsBar>
						<button
							type='button'
							className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-outline-secondary h-44px'
							onClick={cancelDialog}>
							{t('NO')}
						</button>
						<button
							type='button'
							className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-success h-44px'
							onClick={getCropData}>
							{t('OK')}
						</button>
					</DialogActionsBar>
				</CustomModal>
			)}
			{/* Showing file preview after uploading */}
			{file ? (
				<div className='image-wrapper'>
					<img className='crop-image' src={file} alt='' />
					<span
						className='close-btn'
						onClick={handleClose}
						onKeyDown={handleClose}
						role='button'
						tabIndex={0}>
						{close}
					</span>
				</div>
			) : null}
		</div>
	);
};

export default memo(ImageCropper);
