import { useEffect, useState } from 'react';

import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_NOTIFICATIONS } from 'utils/serviceUrls';
import { INotificationList } from 'components/common/model';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { getAllNotificationStatus, setGetAllNotification } from 'slices/notificationSlice';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

import { CANCELED_ERROR, ERROR } from 'utils/constants';
/**
 *hook for getting notification list
 * @param start-start date of date picker,null for dropdown case
 * @param end-end date of date picker,null for dropdown case
 * @param view- key to check whether its view all page or not
 * @returns loading state and notification list
 */
const useGetAllNotifications = (start: any, end: any, view: boolean) => {
	const [notificationList, setNotificationList] = useState<INotificationList[]>([]);
	const [loading, setLoading] = useState<boolean>(true);
	const { t } = useTranslation('dashboard');

	const dispatch = useAppDispatch();

	const getAllNotification = useAppSelector(getAllNotificationStatus);

	const ApiRespone = async (controller) => {
		setLoading(true);

		const { signal } = controller;
		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(
				GET_ALL_NOTIFICATIONS,
				{
					fromDate: start,
					toDate: end,
					viewAll: view,
				},
				{ signal },
			)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setNotificationList(response?.output);
					dispatch(setGetAllNotification(false));
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch((error: any) => {
				if (error?.name === CANCELED_ERROR) {
					return;
				}

				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			})
			.finally(() => {
				setLoading(false);
			});
	};

	useEffect(() => {
		const controller = new AbortController();
		ApiRespone(controller);
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	useEffect(() => {
		const controller = new AbortController();
		if (getAllNotification) {
			ApiRespone(controller);
		}
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [getAllNotification]);

	return {
		notificationList,
		loading,
	};
};

export default useGetAllNotifications;
