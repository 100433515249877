import { FC, useCallback, useState, ChangeEvent, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
import { useNavigate } from 'react-router-dom';

import { Form, FormElement } from '@progress/kendo-react-form';
import { formValidations, getBaseUrl } from 'utils/utils';
import { RouteUrl } from 'utils/enum';

import CustomNotification from 'components/common/notification/CustomNotification';
import CustomError from 'components/common/errorLabel/CustomError';
import CustomSuccessModal from 'components/common/modals/CustomSuccessModal';
import CustomLoader from 'components/common/loader/CustomLoader';
import CustomInput from '../common/forms/CustomInput';
import CustomButton from '../common/button/CustomButton';
import useForgotPassword from './hooks/useForgotPassword';

const ForgotPassword: FC = () => {
	const { t } = useTranslation('userModule');

	// states
	const [email, setEmail] = useState<string>('');
	const [errorState, setErrorState] = useState<Record<string, string>>({});
	const [paramData, setParamData] = useState(null);
	const [submitClicked, setSubmitClicked] = useState<boolean>();
	const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);
	const [errorMsg, setErrorMsg] = useState<string>('');
	const [errorTitle, setErrorTitle] = useState<string>('');
	const [showNotification, setShowNotification] = useState<boolean>(false);

	const navigate = useNavigate();

	const baseURL = getBaseUrl();

	// State reset function
	const resetState = () => {
		setEmail('');
	};
	/**
	 * callback function to reset the button click status
	 */
	const updateResetPasswordClick = () => {
		setSubmitClicked(false);
		// reset email field after API response
		resetState();
	};

	const { forgotPasswordRes, forgotPasswordLoading } = useForgotPassword(
		submitClicked,
		paramData,
		updateResetPasswordClick,
	);

	useEffect(() => {
		if (forgotPasswordRes?.status) {
			setResetPasswordModal(forgotPasswordRes?.status);
		} else if (forgotPasswordRes) {
			setShowNotification(true);
			setErrorTitle(t('ERROR')!!);
			setErrorMsg(t('UNAUTHORIZED_USER_ERROR_MESSAGE')!!);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forgotPasswordRes]);

	// Handle change functions
	const handleEmailChange = useCallback((event: ChangeEvent<HTMLInputElement>) => {
		setErrorState((prev) => ({ ...prev, email: '' }));
		setEmail(event.target.value ? event.target.value.toString() : '');
	}, []);

	const handleSubmit = () => {
		const error = formValidations(
			{ email },
			{
				email: ['required', 'email'],
			},
		);

		setErrorState(error);
		if (Object.keys(error).length === 0) {
			const param: any = {
				email,
				baseUrl: `${baseURL}/reset-password`,
				url: 'string',
			};
			setParamData(param);
			setSubmitClicked(true);
		}
	};

	const handleClose = () => {
		navigate(RouteUrl.LOGINPAGE);
		setResetPasswordModal(false);
	};

	const handleNotificationClose = () => {
		setShowNotification(false);
	};

	return (
		<>
			<Form
				render={() => (
					<FormElement style={{ maxWidth: 650 }}>
						<fieldset className='k-form-fieldset'>
							<div className='form-group'>
								<CustomInput
									name='email'
									value={email}
									label={t('USER_EMAIL')!!}
									placeholder={t('USER_EMAIL')!!}
									required={false}
									type='text'
									onChange={handleEmailChange}
									className={
										errorState.email === '' || errorState.email === undefined
											? ''
											: 'input-validation-error'
									}
								/>
								{errorState?.email && errorState?.email !== '' && (
									<CustomError value={errorState.email} />
								)}
							</div>
						</fieldset>
						<div className='k-form-buttons'>
							<CustomButton
								buttonClassName='btn btn-primary h-44px w-100'
								handleClick={handleSubmit}
								type='submit'
								buttonName={forgotPasswordLoading ? <CustomLoader /> : t('SUBMIT')}
							/>
						</div>
					</FormElement>
				)}
			/>
			{resetPasswordModal && (
				<CustomSuccessModal
					isModalVisible={resetPasswordModal}
					content={t('PASSWORD_RESET_LINK_SUCCESS_MESSAGE')}
					confirmClickNo={handleClose}
				/>
			)}
			{showNotification && (
				<CustomNotification
					message={errorMsg}
					title={errorTitle}
					onClose={handleNotificationClose}
					closable
					type={{ style: 'error', icon: true }}
				/>
			)}
		</>
	);
};
export default ForgotPassword;
