/**
 * Custom No Data component.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.notificationType - The type of notification to display (e.g., "Error", "Warning", etc.).
 * @returns {JSX.Element} - The rendered component.
 */

const CustomNoData = ({ notificationMsg }: any) => {
	return <div className='text-danger'>{`${notificationMsg}`}</div>;
};

export default CustomNoData;
