import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_CONNECTIONS } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetAllConnections = () => {
	const { t } = useTranslation('l4System');
	const dispatch = useDispatch();
	const [connectionList, setConnectionList] = useState<any>([]);
	const [connectionListLoading, setConnectionListLoading] = useState<boolean>(false);

	const ApiRespone = async () => {
		setConnectionListLoading(true);
		await axiosPrivateInstance
			.post(GET_ALL_CONNECTIONS)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setConnectionList(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setConnectionListLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		connectionList,
		connectionListLoading,
	};
};
export default useGetAllConnections;
