/**
 * Custom switch component.
 * Renders a Kendo Switch with custom props for label, style, events, etc.
 * @param {Object} props - The props passed to the component.
 * @param {boolean} props.disabled - Whether the switch is disabled or not.
 * @param {string} props.name - Name attribute for the switch.
 * @param {string} props.className - Class name for the switch.
 * @param {boolean} props.value - The value to be shown on the switch.
 * @param {Function} props.handleBlur - Callback function triggered on blur event.
 * @param {Function} props.handleChange - Callback function triggered on change event.
 * @param {Function} props.handleFocus - Callback function triggered on focus event.
 * @param {string} props.onLabel - Label text to display when the switch is in the 'on' state.
 * @param {string} props.offLabel - Label text to display when the switch is in the 'off' state.
 * @param {('full'|'round'|'none')} props.trackRounded - The style of the track (full, round, or none).
 * @param {('full'|'round'|'none')} props.thumbRounded - The style of the thumb (full, round, or none).
 * @param {('small'|'medium'|'large')} props.size - The size of the switch (small, medium, or large).
 * @param {string} props.eachSwitchName - A custom name for the individual switch, if part of a group.
 * @param {boolean} props.checked - Whether the switch is checked or not.
 * @returns {JSX.Element} - The rendered component.
 */
import { FocusEvent, memo } from 'react';
import { Switch, SwitchChangeEvent } from '@progress/kendo-react-inputs';

import { ICustomSwitch } from '../model';

const CustomSwitch = ({
	disabled,
	name,
	className,
	value,
	handleBlur,
	handleChange,
	handleFocus,
	onLabel,
	offLabel,
	trackRounded,
	thumbRounded,
	size,
	eachSwitchName,
	checked,
}: ICustomSwitch) => {
	/**
	 * Handle blur event on switch.If handleBlur callback is defined, call it.
	 * @param {FocusEvent<HTMLSpanElement>} event - The blur event.
	 */
	const onBlur = (event: FocusEvent<HTMLSpanElement>) => {
		// eslint-disable-next-line no-unused-expressions
		handleBlur ? handleBlur(event) : null;
	};

	/**
	 * Handle change event on switch.Call handleChange handler if defined
	 * @param {SwitchChangeEvent} event - The change event.
	 */
	const onChange = (event: SwitchChangeEvent) => {
		handleChange(event, eachSwitchName);
	};

	/**
	 * Handle focus event on switch. If handleFocus callback is defined, call it.
	 * @param {FocusEvent<HTMLSpanElement>} event - The focus event.
	 */
	const onFocus = (event: FocusEvent<HTMLSpanElement>) => {
		// eslint-disable-next-line no-unused-expressions
		handleFocus ? handleFocus(event) : null;
	};
	return (
		<Switch
			disabled={disabled}
			name={name}
			className={disabled ? `${className} disabled` : className || ''}
			value={value}
			checked={checked || false}
			onBlur={(event: FocusEvent<HTMLSpanElement>) => onBlur(event)}
			onChange={(event: SwitchChangeEvent) => onChange(event)}
			onFocus={(event: FocusEvent<HTMLSpanElement>) => onFocus(event)}
			onLabel={onLabel || ''}
			offLabel={offLabel || ''}
			size={size || 'small'}
			thumbRounded={thumbRounded || 'full'}
			trackRounded={trackRounded || 'full'}
		/>
	);
};
export default memo(CustomSwitch);
