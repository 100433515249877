import { utcFormat, utcTime } from 'utils/utils';
import {
	GridColumnMenuWrapper,
	GridFilterChangeEvent,
	GridHeaderCellProps,
	GridPageChangeEvent,
	GridSortChangeEvent,
} from '@progress/kendo-react-grid';
import { PagerTargetEvent } from '@progress/kendo-react-data-tools';
import { PageState } from '../model';

/**
 * Handles date range filtering for grid component
 *
 * @param {any} dateFilterValue - Selected date range value
 * @param {any} filter - Current filter state
 * @param {Function} setFilter - Setter for filter state
 * @param {string} dateFieldKeyName - Date field name in grid
 * @param {PageState} pageState - Current pagination state
 * @param {Function} setPageState - Setter for pagination state
 * @param {number} gridPageSize - Grid page size
 */

export const gridDateFilter = (
	dateFilterValue: any,
	filter: any,
	setFilter: any,
	dateFieldKeyName: string,
	pageState: PageState,
	setPageState: any,
	gridPageSize: number,
) => {
	// Create temporary filter to avoid mutating original
	const tempFilter = { ...filter };

	// Reset pagination on filter change
	const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };

	if (dateFilterValue) {
		// Check if date filter already exists
		let isAlreadyIn: number | null = null;
		if (filter) {
			filter?.filters?.forEach((item: any, index: number) => {
				if (item?.filters?.length > 0) {
					item?.filters?.forEach((val: any) => {
						if (val?.field === dateFieldKeyName) {
							isAlreadyIn = index;
						}
					});
				}
			});

			// Date filter already exists, update existing

			if (isAlreadyIn !== null && isAlreadyIn >= 0) {
				if (dateFilterValue.start) {
					// Update start date
					tempFilter?.filters?.splice(isAlreadyIn - 1, 1, {
						logic: 'and',
						filters: [
							{
								field: dateFieldKeyName,
								operator: 'gt',
								value: dateFilterValue.start,
							},
						],
					});
				}
				if (dateFilterValue.end) {
					// Update end date
					tempFilter?.filters?.splice(isAlreadyIn, 1, {
						logic: 'and',
						filters: [
							{
								field: dateFieldKeyName,
								operator: 'lt',
								value: dateFilterValue.end,
							},
						],
					});
				}
			} else {
				// Date filter doesn't exist, add new

				if (dateFilterValue.start) {
					tempFilter?.filters?.push({
						logic: 'and',
						filters: [
							{
								field: dateFieldKeyName,
								operator: 'gt',
								value: dateFilterValue.start,
							},
						],
					});
				}
				if (dateFilterValue.end) {
					tempFilter?.filters?.push({
						logic: 'and',
						filters: [
							{
								field: dateFieldKeyName,
								operator: 'lt',
								value: dateFilterValue.end,
							},
						],
					});
				}
			}
			// Update filter and reset pagination
			setFilter(tempFilter);
			setPageState(clearedPagerDataState);
		} else {
			// No existing filter, create from scratch
			setFilter({
				filters: [
					{
						logic: 'and',
						filters: [
							{
								field: dateFieldKeyName,
								operator: 'gt',
								value: dateFilterValue.start,
							},
						],
					},
					{
						logic: 'and',
						filters: [
							{
								field: dateFieldKeyName,
								operator: 'lt',
								value: dateFilterValue.end,
							},
						],
					},
				],
				logic: 'and',
			});
			setPageState(clearedPagerDataState);
		}
	} else if (filter) {
		// Clear existing date filters if no value selected

		filter?.filters?.forEach((item: any, index: number) => {
			if (item && item?.filters?.length > 0) {
				item?.filters?.forEach((val: any) => {
					if (val.field === dateFieldKeyName) {
						tempFilter.filters.splice(index, 2);
					}
				});
			}
		});

		setFilter(tempFilter);
		setPageState(clearedPagerDataState);
	}
};

/* handle filtering of options like {active, inactive}, {created, reviewed, approoved etc..} */
/**
 *
 * @param filterValue filter value state
 * @param filter filter state
 * @param setFilter filter setting state
 * @param fieldKeyName key name of the grid field
 * @param pageState page value - skip and take
 * @param setPageState page values settig state
 * @param gridPageSize pagination size for grid
 * @return changes the filter state and the pagination state
 */

export const gridOptionsTypeFilter = (
	filterValue: any,
	filter: any,
	setFilter: any,
	fieldKeyName: string,
	pageState: PageState,
	setPageState: any,
	gridPageSize: number,
) => {
	const tempFilter = { ...filter };
	const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };
	if (filterValue) {
		let isAlreadyIn: number | null = null;
		if (filter) {
			filter?.filters.forEach((item: any, index: number) => {
				if (item.filters?.length > 0) {
					item.filters.forEach((val: any) => {
						if (val.field === fieldKeyName) {
							isAlreadyIn = index;
						}
					});
				}
			});
			/* alreadyin is executed when the value is already selected */

			if (isAlreadyIn !== null && isAlreadyIn >= 0) {
				tempFilter?.filters.splice(isAlreadyIn, 1, filterValue);
			} else {
				tempFilter?.filters.push(filterValue);
			}

			setFilter(tempFilter);

			setPageState(clearedPagerDataState);
		} else {
			/* initial condition when value is not selected */

			setFilter({
				filters: [
					{
						logic: 'and',
						filters: [filterValue.filters[0]],
					},
				],
				logic: 'and',
			});
			setPageState(clearedPagerDataState);
		}
	} else if (filter) {
		filter?.filters.forEach((item: any, index: number) => {
			if (item.filters?.length > 0) {
				item.filters.forEach((val: any) => {
					if (val.field === fieldKeyName) {
						tempFilter.filters.splice(index, 1);
					}
				});
			}
		});

		setFilter(tempFilter);
		setPageState(clearedPagerDataState);
	}
};

const isValidDateFormat = (dateString: string) => {
	const dateRegex = /^\d{4}-\d{2}-\d{2}T\d{2}:\d{2}:\d{2}(\.\d{1,3})?$/;

	if (!dateRegex.test(dateString)) {
		return false;
	}
	return true;
};

/* Search handling function for grid common search */
/**
 *
 * @param ev  event
 * @param setSearchValue search string
 * @param gridData grid data state
 * @param currentDateFormat current selected date format
 * @param enable24Hr 24 hr enabled or disabled state
 * @param setGridData grid data setting state
 * @param process process function of kendo grid
 * @param pageState page state with skip and take values
 * @param setPageState page state value setting state
 * @param setDataResult set grid result data setting state
 * @param gridPageSize  pagination size for grid
 * @returns updates the state of grid and changes pagination
 */
export const onCommonGridSearchWithFieldName = (
	ev: any,
	setSearchValue: any,
	gridData: any,
	setGridData: any,
	pageState: PageState,
	setPageState: any,
	gridPageSize: number,
	searchProperties: string[],
	getCurrentDate?: any,
	enable24Hr?: any,
) => {
	const { value } = ev;
	setSearchValue(ev.value);
	const newData = gridData.filter((item: any) => {
		let match = false; // eslint-disable-next-line guard-for-in, no-restricted-syntax
		searchProperties.forEach((property) => {
			if (item[property] !== null) {
				/* for searching date strings - valid date check added and date string matching is checked */
				if (
					isValidDateFormat(item[property]) &&
					// eslint-disable-next-line no-restricted-globals
					!isNaN(Date.parse(item[property])) &&
					getCurrentDate &&
					enable24Hr !== null
				) {
					const modDate = `${utcFormat(item[property], getCurrentDate)} ${utcTime(
						item[property],
						enable24Hr,
					)}`;
					if (
						modDate.toString().toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >=
						0
					) {
						match = true;
					}
				} else {
					/* for searching normal texts - string matching condition check */

					if (
						item[property]
							.toString()
							.toLocaleLowerCase()
							.indexOf(value.toLocaleLowerCase()) >= 0
					) {
						match = true;
					}
					if (
						item[property].toLocaleDateString &&
						item[property].toLocaleDateString().indexOf(value) >= 0
					) {
						match = true;
					}
				}
			}
		});
		return match;
	});
	setGridData(newData);
	const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };
	setPageState(clearedPagerDataState);
};

/* Search handling function for grid common search */
/**
 *
 * @param ev  event
 * @param setSearchValue search string
 * @param gridData grid data state
 * @param currentDateFormat current selected date format
 * @param enable24Hr 24 hr enabled or disabled state
 * @param setGridData grid data setting state
 * @param process process function of kendo grid
 * @param pageState page state with skip and take values
 * @param setPageState page state value setting state
 * @param setDataResult set grid result data setting state
 * @param gridPageSize  pagination size for grid
 * @returns updates the state of grid and changes pagination
 */
export const onCommonGridSearchChange = (
	ev: any,
	setSearchValue: any,
	gridData: any,
	setGridData: any,
	process: any,
	pageState: PageState,
	setPageState: any,
	setDataResult: any,
	gridPageSize: number,
	getCurrentDate?: any,
	enable24Hr?: any,
) => {
	const { value } = ev;
	setSearchValue(ev.value);
	const newData = gridData.filter((item: any) => {
		let match = false; // eslint-disable-next-line guard-for-in, no-restricted-syntax
		for (const property in item) {
			if (item[property] !== null) {
				/* for searching normal texts - string matching condition check */

				if (
					item[property]
						.toString()
						.toLocaleLowerCase()
						.indexOf(value.toLocaleLowerCase()) >= 0
				) {
					match = true;
				}
				if (
					item[property].toLocaleDateString &&
					item[property].toLocaleDateString().indexOf(value) >= 0
				) {
					match = true;
				}

				/* for searching date strings - valid date check added and date string matching is checked */

				if (
					// eslint-disable-next-line no-restricted-globals
					!isNaN(Date.parse(item[property])) &&
					getCurrentDate &&
					enable24Hr !== null
				) {
					const modDate = `${utcFormat(item[property], getCurrentDate)} ${utcTime(
						item[property],
						enable24Hr,
					)}`;
					if (
						modDate.toString().toLocaleLowerCase().indexOf(value.toLocaleLowerCase()) >=
						0
					) {
						match = true;
					}
				}
			}
		}
		return match;
	});
	setGridData(newData);
	const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };
	const processedData = process(newData, clearedPagerDataState);
	setDataResult(processedData);
	setPageState(clearedPagerDataState);
};

/**
 *Grid filter handling function
 * @param e event
 * @param setFilter filter value setting function
 * @param pageState pageState with skip and take values
 * @param setPageState page state setting functiom
 * @param gridPageSize grid pagination size
 * @return updates filter state and pagination
 */
export const onCommonGridFilterChange = (
	e: GridFilterChangeEvent,
	setFilter: any,
	pageState: PageState,
	setPageState: any,
	gridPageSize: number,
) => {
	// eslint-disable-next-line array-callback-return, consistent-return
	const trimmedFilterArray = e.filter?.filters.map((item: any, k: any) => {
		if (k === 0) {
			return e.filter?.filters[0];
		}
		return {
			logic: 'and', // eslint-disable-next-line array-callback-return, consistent-return
			filters: item?.filters.map((itm: any, k2: any) => {
				if (k2 === 0) {
					return {
						field: itm?.field,
						operator: itm?.operator,
						value:
							typeof itm?.value === 'string'
								? itm?.value.toString().trim()
								: itm?.value,
					};
				}
			}),
		};
	}); // @ts-ignore
	setFilter({ ...e.filter, filters: trimmedFilterArray });
	const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };
	setPageState(clearedPagerDataState);
};

/**
 * grid common page change handle function
 * @param event event
 * @param setPageSizeValue page size setting state
 * @param setPageState page state setting function (skip and take)
 */
export const gridCommonPageChange = (
	event: GridPageChangeEvent,
	setPageSizeValue: any,
	setPageState: any,
) => {
	const targetEvent = event.targetEvent as PagerTargetEvent;
	const take = targetEvent.value === 'All' ? 77 : event.page.take;
	if (targetEvent.value) {
		setPageSizeValue(targetEvent.value);
	}
	setPageState({ ...event.page, take });
};

/**
 * common data state handling function for grid
 * @param event event
 * @param gridData grid data state
 * @param setDataResult data result setting state
 * @param setPageState page state setting function
 * @param process process function of kendo grid
 */
export const gridCommonDataStateChange = (
	event: any,
	gridData: any,
	setDataResult: any,
	setPageState: any,
	process: any,
) => {
	setDataResult(process(gridData, event.dataState));
	setPageState(event.dataState);
};

/**
 * expand change handle function for grid
 * @param event event
 * @param setDataResult grid data result setting function
 * @param dataResult data result setting state
 */
export const gridCommonExpandChange = (event: any, setDataResult: any, dataResult: any) => {
	const isExpanded =
		event.dataItem.expanded === undefined ? event.dataItem.aggregates : event.dataItem.expanded;
	event.dataItem.expanded = !isExpanded;
	setDataResult({ ...dataResult });
};

/**
 * grid custom cell with sorting without filter - common function
 * @param props props
 * @param handleSort handlesort function
 * @param sortDirection sorting direction - asc or desc
 * @param sortData sortdata state
 * @returns it returns a jsx element as custom cell with sort icon
 */
export const gridCommonHeaderCellWithoutFilterOption = (
	props: GridHeaderCellProps,
	handleSort: any,
	sortDirection: any,
	sortData: any,
) => {
	return (
		<div className='sort-wrapper'>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<a onClick={() => handleSort(props.field, sortDirection)}>{props.title}</a>
			<div
				onClick={() => handleSort(props.field, sortDirection)}
				className='sort-icon-wrap'
				role='button'
				tabIndex={0}
				onKeyDown={() => handleSort(props.field, sortDirection)}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-has-content */}
				<a
					className={
						sortData[0]?.field === props.field && sortData[0]?.dir === 'asc'
							? 'chevron-arrow-up chevron_active'
							: 'chevron-arrow-up'
					}
					id={`arrow_asc_${props.field}`}
				/>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-has-content */}
				<a
					className={
						sortData[0]?.field === props.field && sortData[0]?.dir === 'desc'
							? 'chevron-arrow-down chevron_active'
							: 'chevron-arrow-down'
					}
					id={`arrow_desc_${props.field}`}
				/>
			</div>
		</div>
	);
};

/**
 * grid custom cell with sort and filter - common function
 * @param props props
 * @param handleSort sort handling function
 * @param sortDirection sort direction - asc or desc
 * @param sortData sortata state
 * @returns it returns a jsx element with sort and filter option
 */
export const gridCommonHeaderCellWithSortOption = (
	props: GridHeaderCellProps,
	handleSort: any,
	sortDirection: any,
	sortData: any,
) => {
	return (
		<div className='sort-wrapper'>
			{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions */}
			<a onClick={() => handleSort(props.field, sortDirection)}>{props.title}</a>
			<div
				onClick={() => handleSort(props.field, sortDirection)}
				className='sort-icon-wrap'
				role='button'
				tabIndex={0}
				onKeyDown={() => handleSort(props.field, sortDirection)}>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-has-content */}
				<a
					className={
						sortData[0]?.field === props.field && sortData[0]?.dir === 'asc'
							? 'chevron-arrow-up chevron_active'
							: 'chevron-arrow-up'
					}
					id={`arrow_asc_${props.field}`}
				/>
				{/* eslint-disable-next-line jsx-a11y/anchor-is-valid, jsx-a11y/click-events-have-key-events, jsx-a11y/no-static-element-interactions, jsx-a11y/anchor-has-content */}
				<a
					className={
						sortData[0]?.field === props.field && sortData[0]?.dir === 'desc'
							? 'chevron-arrow-down chevron_active'
							: 'chevron-arrow-down'
					}
					id={`arrow_desc_${props.field}`}
				/>
			</div>
			{/* eslint-disable-next-line react/jsx-props-no-spreading */}
			<GridColumnMenuWrapper {...props.columnMenuWrapperProps} />
		</div>
	);
};

/**
 * grid sort handle changing function
 * @param event event
 * @param setSortData sort data setting state
 *@returns changes sort data state
 */
export const gridCommonSortChange = (event: GridSortChangeEvent, setSortData: any) => {
	setSortData(event.sort);
};

/**
 * Handles date range filtering for grid component (Server side)
 *
 * @param {any} dateFilterValue - Selected date range value
 * @param {any} filter - Current filter state
 * @param {Function} setFilter - Setter for filter state
 * @param {string} dateFieldKeyName - Date field name in grid
 * @param {PageState} pageState - Current pagination state
 * @param {Function} setPageState - Setter for pagination state
 * @param {number} gridPageSize - Grid page size
 */
export const gridCommonServerSideDateFilter = (
	dateFilterValue: any,
	filter: any,
	setFilter: any,
	dateFieldKeyName: string,
	pageState: PageState,
	setPageState: any,
	gridPageSize: number,
) => {
	// Create temporary filter to avoid mutating original

	const tempFilter = { ...filter };

	// Reset pagination on filter change
	const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };

	if (dateFilterValue) {
		// Check if date filter already exists

		let isAlreadyIn: number | null = null;
		if (filter) {
			filter?.filters.forEach((item: any, index: number) => {
				if (item.filters.length > 0) {
					item.filters.forEach((val: any) => {
						if (val.field === dateFieldKeyName) {
							isAlreadyIn = index;
						}
					});
				}
			});

			// Date filter already exists, update existing

			if (isAlreadyIn !== null && isAlreadyIn >= 0) {
				tempFilter?.filters.splice(isAlreadyIn, 1, dateFilterValue);
			} else {
				tempFilter?.filters.push(dateFilterValue);
			}

			setFilter(tempFilter);
			setPageState(clearedPagerDataState);
		} else {
			setFilter({
				filters: [
					{
						logic: 'and',
						filters: [dateFilterValue.filters[0]],
					},
				],
				logic: 'and',
			});
			setPageState(clearedPagerDataState);
		}
	} else if (filter) {
		filter?.filters.forEach((item: any, index: number) => {
			if (item.filters.length > 0) {
				item.filters.forEach((val: any) => {
					if (val.field === dateFieldKeyName) {
						tempFilter.filters.splice(index, 1);
					}
				});
			}
		});

		// Update filter and reset pagination

		setFilter(tempFilter);
		setPageState(clearedPagerDataState);
	}
};

/**
 *Grid filter change common function (Server side) which handles the filter states of grid
 * @param e event
 * @param setFilter filter value setting function
 * @param pageState pageState with skip and take values
 * @param setPageState page state setting functiom
 * @param gridPageSize grid pagination size
 * @return updates filter state and pagination
 */
export const gridCommonServerSideFilterChange = (
	e: GridFilterChangeEvent,
	setFilter: any,
	pageState: PageState,
	setPageState: any,
	gridPageSize: number,
) => {
	// eslint-disable-next-line array-callback-return, consistent-return
	if (e.filter) {
		const trimmedFilterArray = e.filter?.filters.map((item: any, k: any) => {
			if (k === 0) {
				return e.filter?.filters[0];
			}

			return {
				logic: 'and',
				// eslint-disable-next-line array-callback-return, consistent-return
				filters: item?.filters.map((itm: any, k2: any) => {
					if (k2 === 0) {
						return {
							field: itm?.field,
							operator: itm?.operator,
							value:
								typeof itm?.value === 'string'
									? itm?.value.toString().trim()
									: itm?.value,
						};
					}
				}),
			};
		});

		// @ts-ignore
		setFilter({ ...e.filter, filters: trimmedFilterArray });
		const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };
		setPageState(clearedPagerDataState);
	} else {
		setFilter(e.filter);
		const clearedPagerDataState = { ...pageState, take: gridPageSize, skip: 0 };
		setPageState(clearedPagerDataState);
	}
};
