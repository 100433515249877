import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	details: {},
	passwordVerificationClicked: false,
};

export const authUserSlice = createSlice({
	name: 'authUser',
	initialState,
	reducers: {
		setUserDetails: (state, action: PayloadAction<any>) => {
			state.details = action.payload;
		},
		setPasswordVerificationClicked: (state, action: PayloadAction<any>) => {
			state.passwordVerificationClicked = action.payload;
		},
	},
});

export const { setUserDetails, setPasswordVerificationClicked } = authUserSlice.actions;

export const getAuthUserDetails = (state: RootState) => state.authUser.details;
export const getPasswordVerificationClicked = (state: RootState) =>
	state.authUser.passwordVerificationClicked;

export default authUserSlice.reducer;
