const CommonSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0'>
				<div className='skeleton p-0 '>
					<div className='skeleton-left flex1 p-0'>
						<div className='square opacity-3' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default CommonSkeleton;
