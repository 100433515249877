import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_MANUFACTURERS_DROP_DOWN } from 'utils/serviceUrls';

const getAllManufacturerDetailsService = async () => {
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(GET_ALL_MANUFACTURERS_DROP_DOWN)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default getAllManufacturerDetailsService;
