import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { useAppSelector } from 'hooks/stateHooks';
import { ICON_DESCRIPTION_ID, Privileges, RouteUrl } from 'utils/enum';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import { getDashboardIconsData } from 'slices/infoIconsDataSlice';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import { hasPrivileges } from 'utils/utils';
import RecentlyAddedProduct from './RecentlyAddedProduct';

const RecentProductContainer = () => {
	const { t } = useTranslation('dashboard');
	const navigate = useNavigate();
	const getLoginUserData = useAppSelector(getUserLoginData);
	const handleViewAllClick = () => {
		navigate(RouteUrl.PRODUCTMODULE);
	};

	const dashboardAreaIconsData = useAppSelector(getDashboardIconsData);

	const [productRecentlyAddedInfo, setProductRecentlyAddedInfo] = useState<string>('');

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		dashboardAreaIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			dashboardAreaIconsData?.map((item: any) => {
				if (item.encIconId === ICON_DESCRIPTION_ID.PRODUCT_RECENTLY_ADDED) {
					setProductRecentlyAddedInfo(item?.iconDescription);
				}
			});
	}, [dashboardAreaIconsData]);

	return (
		<>
			<div className='page-header p-0'>
				<h3>
					{t('PRODUCT_RECENTLY_ADDED')}

					<CustomEditableTooltip
						showIcon='faInfoCircle'
						text={productRecentlyAddedInfo}
						moduleId={ICON_DESCRIPTION_ID.PRODUCT_RECENTLY_ADDED}
					/>
				</h3>
				{hasPrivileges(Privileges.ProductListView, getLoginUserData) && (
					<button type='button' onClick={handleViewAllClick} className='btn view-all'>
						{t('VIEW_ALL')!!}
					</button>
				)}
			</div>
			<RecentlyAddedProduct />
		</>
	);
};

export default RecentProductContainer;
