/**
 * CustomQRCode component to render a QR code.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.value - The data to be encoded in the QR code.
 * @param {number} props.size - The size of the QR code in pixels (default is 200).
 * @param {string} props.color - The color of the QR code modules (e.g., "#000000").
 * @param {string} props.background - The background color of the QR code (e.g., "#FFFFFF").
 * @param {boolean} props.overlay - Whether to show a transparent overlay over the QR code (default is false).
 * @param {string} props.border - The border color of the QR code (e.g., "#CCCCCC").
 * @returns {JSX.Element} - The rendered QR code.
 */
import { QRCode } from '@progress/kendo-react-barcodes';
import { ICustomQRCode } from '../model';

const CustomQRCode = ({ value, size, color, background, overlay, border }: ICustomQRCode) => {
	return (
		<QRCode
			size={size || 200}
			value={value || ''}
			color={color}
			background={background}
			overlay={overlay}
			border={border}
		/>
	);
};

export default CustomQRCode;
