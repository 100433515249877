import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	roleUpdateStatus: false,
	updateButtonClicked: false,
	roleAddStatus: false,
	addButtonClicked: false,
	showNotification: false,
	message: '',
	notificationType: '',
};

export const roleModuleSlice = createSlice({
	name: 'roleModule',
	initialState,
	reducers: {
		setRoleUpdateStatus: (state, action: PayloadAction<any>) => {
			state.roleUpdateStatus = action.payload;
		},
		setUpdateButtonStatus: (state, action: PayloadAction<any>) => {
			state.updateButtonClicked = action.payload;
		},
		setRoleAddStatus: (state, action: PayloadAction<any>) => {
			state.roleAddStatus = action.payload;
		},
		setAddButtonStatus: (state, action: PayloadAction<any>) => {
			state.addButtonClicked = action.payload;
		},
		setShowNotification: (state, action: PayloadAction<any>) => {
			state.showNotification = action.payload;
		},
		setMessage: (state, action: PayloadAction<any>) => {
			state.message = action.payload;
		},
		setNotificationType: (state, action: PayloadAction<any>) => {
			state.notificationType = action.payload;
		},
	},
});

export const {
	setRoleUpdateStatus,
	setUpdateButtonStatus,
	setRoleAddStatus,
	setAddButtonStatus,
	setShowNotification,
	setMessage,
	setNotificationType,
} = roleModuleSlice.actions;

export const getRoleUpdateStatus = (state: RootState) => state.roleModule.roleUpdateStatus;
export const getUpdateButtonStatus = (state: RootState) => state.roleModule.updateButtonClicked;
export const getRoleAddStatus = (state: RootState) => state.roleModule.roleAddStatus;
export const getAddButtonStatus = (state: RootState) => state.roleModule.addButtonClicked;
export const getShowNotification = (state: RootState) => state.roleModule.showNotification;
export const getMessage = (state: RootState) => state.roleModule.message;
export const getNotificationType = (state: RootState) => state.roleModule.notificationType;

export default roleModuleSlice.reducer;
