/**
 * Custom button component.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.buttonClassName - Class name for the button.
 * @param {boolean} props.disabled - Whether the button is disabled or not.
 * @param {string} props.buttonName - The label/text displayed on the button.
 * @param {Function} props.handleClick - Callback function triggered on click event.
 * @param {string} props.type - Button type (e.g., 'button', 'submit', 'reset').
 * @param {string} props.icon - Name of the FontAwesome icon to be displayed (if any).
 * @returns {JSX.Element} The rendered component.
 */
import { MouseEvent, memo } from 'react';
import { Button } from '@progress/kendo-react-buttons';

import { ICustomButton } from '../model';
import ShowIcon from '../fontAwesome/ShowIcon';

const CustomButton = ({
	buttonClassName,
	disabled,
	buttonName,
	handleClick,
	type,
	icon,
}: ICustomButton) => {
	/**
	 * Handle click event.
	 * @param {MouseEvent<HTMLButtonElement>} event - The click event.
	 */
	const onClick = (event: MouseEvent<HTMLButtonElement>) => {
		handleClick(event);
	};
	return (
		<Button
			className={
				disabled
					? `${buttonClassName} disabled`
					: buttonClassName || 'btn btn-primary h-44px icon-add-btn'
			}
			disabled={disabled}
			onClick={(event: MouseEvent<HTMLButtonElement>) => onClick(event)}
			type={type}>
			{icon && <ShowIcon name={icon} />}
			{buttonName}
		</Button>
	);
};
export default memo(CustomButton);
