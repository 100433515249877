import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_DASHBOARD_AWAITING_ACTION } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { CANCELED_ERROR } from 'utils/constants';

// custom hook for calling get all products

const useGetAllAwaitingActionStatus = (
	isUpdated: boolean,
	updateIsUpdated: any,
	fromDate: any,
	toDate: any,
	viewAll: boolean,
) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('dashboard');
	const [awaitingActionsData, setAwaitingActionsData] = useState<any>([]);
	const [loading, setLoading] = useState<boolean>(true);

	useEffect(() => {
		const controller = new AbortController();
		const { signal } = controller;
		if (isUpdated) {
			setLoading(true);
			axiosPrivateInstance
				.post(
					GET_ALL_DASHBOARD_AWAITING_ACTION,
					{
						fromDate,
						toDate,
						viewAll,
					},
					{ signal },
				)
				.then((response: any) => {
					if (response?.output && response?.status) {
						updateIsUpdated();
						setAwaitingActionsData(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch((error: any) => {
					if (error?.name === CANCELED_ERROR) {
						return;
					}
					updateIsUpdated();
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setLoading(false);
				});
		}
		return () => controller.abort();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isUpdated]);

	return {
		awaitingActionsData,
		loading,
	};
};

export default useGetAllAwaitingActionStatus;
