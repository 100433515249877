import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_OWNER_NAME_URL } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from '../../../utils/constants';

const useGetAllOwnerName = () => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('locationModule');

	const [ownerNameResponseData, setOwnerNameResponseData] = useState<any>([]);
	const [ownerNameloading, setOwnerNameLoading] = useState<boolean>(true);

	const ApiRespone = async () => {
		setOwnerNameLoading(true);

		// eslint-disable-next-line no-unused-vars
		await axiosPrivateInstance
			.post(GET_ALL_OWNER_NAME_URL)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setOwnerNameResponseData(response?.output);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType(ERROR));
			})
			.finally(() => {
				setOwnerNameLoading(false);
			});
	};

	useEffect(() => {
		ApiRespone();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		ownerNameResponseData,
		ownerNameloading,
	};
};

export default useGetAllOwnerName;
