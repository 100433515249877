import { Outlet } from 'react-router-dom';

const Index = () => {
	return (
		<div className='login-wrapper'>
			<div className='login-container justify-content-center'>
				<Outlet />
			</div>
		</div>
	);
};
export default Index;
