import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_L3_SYSTEM_BY_ID } from 'utils/serviceUrls';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetL3SystemById = (l3ById: any) => {
	const { t } = useTranslation('l3System');
	const dispatch = useAppDispatch();
	const [l3Loading, setL3Loading] = useState<boolean>(false);
	const [resData, setResData] = useState<any>();

	useEffect(() => {
		if (l3ById !== 0) {
			setL3Loading(true);
			axiosPrivateInstance
				.post(GET_ALL_L3_SYSTEM_BY_ID, { encSystemID: l3ById })
				.then((response: any) => {
					if (response?.output && response?.status) {
						setResData(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setL3Loading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [l3ById]);

	return {
		l3Loading,
		resData,
	};
};

export default useGetL3SystemById;
