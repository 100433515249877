import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_DOSAGE } from 'utils/serviceUrls';

const getAllDosageService = async () => {
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(GET_ALL_DOSAGE)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default getAllDosageService;
