import { useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { RouteUrl } from 'utils/enum';

import CustomButton from 'components/common/button/CustomButton';

const MultipleLogin = () => {
	const { t } = useTranslation('userModule');
	const navigate = useNavigate();

	/**
	 * login button functionality to redirect to login page
	 */

	const onClickLogin = () => {
		navigate(RouteUrl.LOGINPAGE);
	};

	return (
		<div className='login-form forgot-form d-flex justify-content-center align-items-center flex-column'>
			<h6>{t('WARNING_TEXT')}</h6>
			<CustomButton
				buttonClassName='btn btn-primary h-44px icon-add-btn w-auto'
				type='button'
				buttonName={t('LOGIN')}
				handleClick={onClickLogin}
				icon=''
			/>
		</div>
	);
};
export default MultipleLogin;
