import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from 'store';

const initialState: any = {
	isVisible: false,
	expiryCount: 0,
};

const loginExpirySlice = createSlice({
	name: 'loginExpiryModule',
	initialState,
	reducers: {
		setIsmodalVisible: (state, action: PayloadAction<any>) => {
			state.isVisible = action.payload;
		},
		setExpiryDaysCount: (state, action: PayloadAction<any>) => {
			state.expiryCount = action.payload;
		},
	},
});
export const getIsVisible = (state: RootState) => state.loginExpiryModule.isVisible;
export const getExpiryCount = (state: RootState) => state.loginExpiryModule.expiryCount;
export const { setIsmodalVisible, setExpiryDaysCount } = loginExpirySlice.actions;
export default loginExpirySlice.reducer;
