import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_AUDIT_TRAIL_REVIEW_STATUS } from 'utils/serviceUrls';

const reviewAuditTrailService = async (id: any, message: any) => {
	const paramData = {
		encReportId: id,
		comment: message,
	};
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(UPDATE_AUDIT_TRAIL_REVIEW_STATUS, paramData)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default reviewAuditTrailService;
