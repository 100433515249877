import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_PRODUCT_GROUP_BY_ID } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGroupIdDetails = (grpId: any) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('productGroup');

	const [dataLoading, setDataLoading] = useState<boolean>(false);
	const [resData, setResData] = useState<any>();

	useEffect(() => {
		if (grpId !== 0) {
			setDataLoading(true);
			axiosPrivateInstance
				.post(GET_ALL_PRODUCT_GROUP_BY_ID, { encProductGroupId: grpId })
				.then((response: any) => {
					if (response?.output && response?.status) {
						setResData(response?.output);
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setDataLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [grpId]);

	return {
		dataLoading,
		resData,
	};
};

export default useGroupIdDetails;
