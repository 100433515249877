import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_PO_SERIAL_NUMBER_LIST } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

const useGetSerialNumberList = (encPOId: string) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('batchPOModule');

	const [serialNumberListData, setSerialNumberListData] = useState<any>([]);
	const [serialNumberListLoading, setSerialNumberListLoading] = useState<boolean>(false);

	useEffect(() => {
		if (encPOId) {
			setSerialNumberListLoading(true);
			axiosPrivateInstance
				.post(GET_PO_SERIAL_NUMBER_LIST, { encLotNumberId: encPOId })
				.then((response: any) => {
					setSerialNumberListLoading(false);
					setSerialNumberListData(response.output);
				})
				.catch(() => {
					setSerialNumberListLoading(false);
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [encPOId]);

	return {
		serialNumberListData,
		serialNumberListLoading,
	};
};

export default useGetSerialNumberList;
