/**
 * CustomNotification component to display custom notifications.
 * @param {Object} props - The props passed to the component.
 * @param {boolean} props.closable - Whether the notification is closable or not.
 * @param {Function} props.onClose - Callback function triggered when the notification is closed.
 * @param {string} props.message - The message to be displayed in the notification.
 * @param {Object} props.type - The type of the notification (e.g., ERROR, SUCCESS, WARNING).
 * @returns {JSX.Element} - The rendered component displaying the custom notification.
 */
import { memo } from 'react';
import { createPortal } from 'react-dom';
import { useTranslation } from 'react-i18next';
import { Notification } from '@progress/kendo-react-notification';
import { Fade } from '@progress/kendo-react-animation';
import { ERROR, SUCCESS, WARNING } from 'utils/constants';
import { ICustomNotification } from '../model';

const CustomNotification = ({ closable, onClose, message, type }: ICustomNotification) => {
	const { t } = useTranslation('dashboard');
	/**
	 * Automatically close the notification after 3 seconds (3000 ms).
	 */
	setTimeout(onClose, 3000);

	/**
	 * Determine the CSS class for the notification wrapper based on the type.
	 * @returns {string} - The CSS class name for the notification wrapper.
	 */
	const handleWrapperType = () => {
		let wrapper = 'error-wrapper';
		if (type?.style === SUCCESS) {
			wrapper = 'success-wrapper';
		} else if (type?.style === WARNING) {
			wrapper = 'warning-wrapper';
		}
		return wrapper;
	};

	return (
		<>
			{/* Render the notification using createPortal */}
			{createPortal(
				<Fade className={`custom-toastify-container ${handleWrapperType()}`}>
					{/* Render the KendoReact Notification component */}
					<Notification type={type} closable={closable} onClose={onClose}>
						<div className='toast-wrap'>
							{type?.style === ERROR && (
								<span className='invalid-head'>{t('ERROR')}</span>
							)}
							{type?.style === SUCCESS && (
								<span className='invalid-head'>{t('SUCCESS')}</span>
							)}
							{type?.style === WARNING && (
								<span className='invalid-head'>{t('WARNING')}</span>
							)}
							{/* <span className='invalid-head'>{title || ''}</span> */}
							<span className='invalid-text'>{message || ''}</span>
						</div>
					</Notification>
				</Fade>,
				document.getElementById('portal-root') as HTMLElement,
			)}
		</>
	);
};

export default memo(CustomNotification);
