import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import { lazy, Suspense } from 'react';

const PORetrieval = lazy(() => import('components/batchPO/PORetrieval'));

const PORetrievalPage = () => {
	return (
		<div className='page-content user-module'>
			<Suspense fallback={<CommonSkeleton />}>
				<PORetrieval />
			</Suspense>
		</div>
	);
};
export default PORetrievalPage;
