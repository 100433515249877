/**
 * Custom hook for updating L3 system data
 * @param {object} formData - Form data object
 * @param {boolean} updateL3SubmitButtonStatus - Submit button status
 * @returns {object} - Object containing update API response and loading state
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useAppSelector } from 'hooks/stateHooks';
import { useDispatch } from 'react-redux';

import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_L3_SYSTEM } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { getUpdateL3SubmitButtonStatus, setUpdateL3SubmitButtonStatus } from 'slices/l3SystemSlice';
import * as constants from '../../../utils/constants';

const useUpdateL3System = (formData: any) => {
	const { t } = useTranslation('l3System');
	const updateL3SubmitButtonStatus = useAppSelector(getUpdateL3SubmitButtonStatus);
	const [updateL3loading, setUpdateL3Loading] = useState<boolean>(false);
	const [updateL3Response, setUpdateL3Response] = useState<any>({});
	const dispatch = useDispatch();

	const params: any = {
		url: '',
		agent: '',
		encSystemID: formData.encSystemID,
		l3SystemName: formData.l3SystemName,
		address: formData.address,
		encCountryId: formData.country,
		description: formData.description,
		location: formData.l3Location,
		connectionMethodID: formData.selectedConnectionType,
		connectionDetails: {
			host: formData.sftpHost,
			userName: formData.sftpUsername,
			password: formData.sftpPassword,
			portNo: formData.sftpPortNo === '' ? null : formData.sftpPortNo,
			identifier: formData.restApiTenantId,
		},
		soapConfigurationDetails: {
			Identifier: formData.soapIdentifier,
			userName: formData.soapUsername,
			password: formData.soapPassword,
		},
		webLinkSettingsDetails: {
			weblinkPoolSize: formData.weblinkPoolSize,
		},
	};

	useEffect(() => {
		if (updateL3SubmitButtonStatus) {
			setUpdateL3Loading(true);
			axiosPrivateInstance
				.post(UPDATE_L3_SYSTEM, params)
				.then((response: any) => {
					setUpdateL3Response(response);

					if (response?.statusCode === constants.STATUS_CODE_200) {
						dispatch(setMessage(t('L3_SYSTEM_UPDATED_SUCCESSFULLY')));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(setMessage(t(response.errorMessage || 'L3_SYSTEM_NOT_UPDATED')));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('L3_SYSTEM_NOT_UPDATED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					dispatch(setUpdateL3SubmitButtonStatus(false));
					dispatch(setShowNotification(true));
					setUpdateL3Loading(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [updateL3SubmitButtonStatus]);

	return {
		updateL3Response,
		updateL3loading,
	};
};

export default useUpdateL3System;
