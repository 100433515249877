import { AWAITING_ACION_REPORT_TYPE, AWAITING_ACTIONS_TYPES, Privileges } from 'utils/enum';
import { hasPrivileges } from 'utils/utils';
import { useAppSelector } from 'hooks/stateHooks';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import RejectButton from './ReportAction/RejectButton';
import ReviewButton from './ReportAction/ReviewButton';
import ApproveButton from './ReportAction/ApproveButton';

const AwaitingActionButtons = ({
	item,
	setAction,
	handleReject,
	setPasswordVerificationActions,
	setPasswordVerificationTransactions,
	setIsPasswordVerification,
	loader,
	loaderType,
	setLoaderType,
	isReview,
	isApprove,
	handleReview,
	handleApprove,
}) => {
	const getLoginUserData = useAppSelector(getUserLoginData);

	return (
		<div className='dsb-list-bottom'>
			{/* showing return button -  product   */}
			{item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT &&
				hasPrivileges(Privileges.ProductReturnPrivilege, getLoginUserData) && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}
			{/* showing return button -  PO */}
			{item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_ORDER &&
				hasPrivileges(Privileges.BatchPOReturnPrivilege, getLoginUserData) && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing return button -   PO weblink or product weblink  */}

			{(item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_WEBLINK ||
				item.typeId === AWAITING_ACTIONS_TYPES.PO_WEBLINK) &&
				hasPrivileges(Privileges.weblinkReject, getLoginUserData) && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing return button - AuditTrial Report  */}

			{item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.AuditReportReturn, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing return button - Batch Summary Report  */}

			{item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.BatchSummaryReturn, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing return button - Batch Detail Report  */}

			{item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.BatchDetailReturn, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing return button -  Weblink Validation */}
			{item.typeId === AWAITING_ACTIONS_TYPES.WEBLINK_VALIDATION &&
				hasPrivileges(Privileges.weblinkReject, getLoginUserData) && (
					<RejectButton
						item={item}
						setAction={setAction}
						handleReject={handleReject}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing review button -  product  */}
			{isReview &&
				item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT &&
				hasPrivileges(Privileges.ProductReviewerPrivilage, getLoginUserData) && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}
			{/* showing review button -  PO   */}
			{isReview &&
				item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_ORDER &&
				hasPrivileges(Privileges.BatchPOReviewPrivilege, getLoginUserData) && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing review button -   PO weblink or product weblink  */}

			{isReview &&
				(item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_WEBLINK ||
					item.typeId === AWAITING_ACTIONS_TYPES.PO_WEBLINK) &&
				hasPrivileges(Privileges.weblinkReview, getLoginUserData) && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing review button - AuditTrial Report  */}

			{isReview &&
				item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.AuditReportReview, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing review button - Batch Summary Report  */}

			{isReview &&
				item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.BatchSummaryReview, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing review button - Batch Detail Report  */}

			{isReview &&
				item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.BatchDetailReview, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing review button -  Weblink Validation  */}
			{isReview &&
				item.typeId === AWAITING_ACTIONS_TYPES.WEBLINK_VALIDATION &&
				hasPrivileges(Privileges.weblinkReview, getLoginUserData) && (
					<ReviewButton
						item={item}
						setAction={setAction}
						handleReview={handleReview}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing approve button -  product   */}
			{isApprove &&
				item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT &&
				hasPrivileges(Privileges.ProductApprovePrivilege, getLoginUserData) && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing approve button - for product order  */}
			{isApprove &&
				item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_ORDER &&
				hasPrivileges(Privileges.BatchPOApprovePrivilege, getLoginUserData) && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing approve button -   PO weblink or product weblink  */}
			{isApprove &&
				(item.typeId === AWAITING_ACTIONS_TYPES.PRODUCT_WEBLINK ||
					item.typeId === AWAITING_ACTIONS_TYPES.PO_WEBLINK) &&
				hasPrivileges(Privileges.weblinkApprove, getLoginUserData) && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing approve button - AuditTrial Report  */}
			{isApprove &&
				item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.AuditReportApprove, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.AUDIT_LOG && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}
			{/* showing approve button - Batch Summary Report  */}
			{isApprove &&
				item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.BatchSummaryApprove, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.BATCH_REPORT && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}
			{/* showing approve button - Batch Detail Report  */}
			{isApprove &&
				item.typeId === AWAITING_ACTIONS_TYPES.REPORT &&
				hasPrivileges(Privileges.BatchDetailApprove, getLoginUserData) &&
				item?.customName2 === AWAITING_ACION_REPORT_TYPE.PRODUCT_BATCH_PACK_REPORT && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						// setLoader={setLoader}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}

			{/* showing approve button -  product   */}
			{isApprove &&
				item.typeId === AWAITING_ACTIONS_TYPES.WEBLINK_VALIDATION &&
				hasPrivileges(Privileges.weblinkApprove, getLoginUserData) && (
					<ApproveButton
						item={item}
						setAction={setAction}
						handleApprove={handleApprove}
						setPasswordVerificationActions={setPasswordVerificationActions}
						setPasswordVerificationTransactions={setPasswordVerificationTransactions}
						setIsPasswordVerification={setIsPasswordVerification}
						loader={loader}
						loaderType={loaderType}
						setLoaderType={setLoaderType}
						disabled={loader}
					/>
				)}
		</div>
	);
};

export default AwaitingActionButtons;
