import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	packageLevelUpdateStatus: false,
};

export const packageLevelModuleSlice = createSlice({
	name: 'packageLevelModule',
	initialState,
	reducers: {
		setPackageLevelUpdateStatus: (state, action: PayloadAction<any>) => {
			state.packageLevelUpdateStatus = action.payload;
		},
	},
});

export const { setPackageLevelUpdateStatus } = packageLevelModuleSlice.actions;

export const getPackageLevelUpdateStatus = (state: RootState) =>
	state.packageLevelModule.packageLevelUpdateStatus;

export default packageLevelModuleSlice.reducer;
