/**
 * Component for a Kendo Text Editor.
 * @param {Object} props - The props passed to the component.
 * @param {string} props.text - The initial text content of the editor.
 * @param {function} props.updateText - The callback function to update the editor's text content.
 * @param {boolean} props.infoIconDataLoading - A boolean indicating whether the data is being loaded.
 * @param {function} props.handleErrorUpdate - The callback function to handle error updates in the editor.
 */

import { useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { Editor, EditorTools, EditorUtils } from '@progress/kendo-react-editor';

import { Button } from '@progress/kendo-react-buttons';
import CustomButton from '../button/CustomButton';
import CustomLoader from '../loader/CustomLoader';
import ShowIcon from '../fontAwesome/ShowIcon';

const KendoTextEditor = ({
	text,
	updateText,
	infoIconDataLoading,
	handleErrorUpdate,
	copyrightAddEdit,
}: any) => {
	const { t } = useTranslation('productModule');
	const editor = useRef<any>(null);

	const { Bold, Italic, Underline, UnorderedList, Superscript } = EditorTools;

	/**
	 * Handles the update of the text in the editor.
	 */
	const handleUpdate = () => {
		updateText(editor.current);
	};

	/**
	 * Handles the onChange event of the editor to handle error updates.
	 */
	const handleOnChange = () => {
		handleErrorUpdate();
	};

	// custom text insert to kendo editor function
	// eslint-disable-next-line react/no-unstable-nested-components
	const createInsertTextTool = (settings) => (props) => {
		const { view } = props;
		const nodeType = view && view.state.schema.nodes[settings.nodeType];
		const canInsert = view && EditorUtils.canInsert(view.state, nodeType);
		return (
			<Button
				onClick={() => EditorUtils.insertNode(view, view.state.schema.text(settings.text))}
				disabled={!canInsert}
				onMouseDown={(e) => e.preventDefault()}
				onPointerDown={(e) => e.preventDefault()}
				{...settings.props}>
				<ShowIcon name={settings?.props?.buttonIcon} />
			</Button>
		);
	};

	// insert text tool for copyright
	const InsertCopyrightTextTool = createInsertTextTool({
		nodeType: 'text',
		text: ' ©',
		props: {
			// icon: 'k-icon k-i-thumbnails-up',
			title: 'Copyright',
			buttonIcon: 'faCopyright',
		},
	});

	// insert text tool for trademark

	const InsertTMTextTool = createInsertTextTool({
		nodeType: 'text',
		text: ' TM',
		props: {
			// icon: 'k-icon k-i-thumbnails-up',
			title: 'Trademark',
			buttonIcon: 'faTrademark',
		},
	});

	// insert text tool for Registered

	const InsertRegisteredTextTool = createInsertTextTool({
		nodeType: 'text',
		text: ' ®',
		props: {
			// icon: 'k-icon k-i-thumbnails-up',
			title: 'Registered',
			buttonIcon: 'faRegistered',
		},
	});

	return (
		<>
			<Editor
				ref={editor}
				// condition checking for showing tools in editor
				tools={
					copyrightAddEdit
						? [
								[Underline],
								[Superscript],
								[InsertCopyrightTextTool],
								[InsertRegisteredTextTool],
								[InsertTMTextTool],
						  ]
						: [Bold, Italic, Underline, UnorderedList]
				}
				contentStyle={{
					height: 200,
					width: 200,
				}}
				defaultContent={text}
				onChange={handleOnChange}
			/>

			<CustomButton
				type='button'
				buttonClassName='btn btn-primary updateBtn'
				buttonName={
					// eslint-disable-next-line no-nested-ternary
					infoIconDataLoading ? (
						<CustomLoader />
					) : copyrightAddEdit ? (
						t('SAVE')
					) : (
						t('UPDATE')!!
					)
				}
				handleClick={handleUpdate}
			/>
		</>
	);
};
export default KendoTextEditor;
