const AddEditManufacturerSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0'>
				<div className='skeleton p-0 '>
					<div className='skeleton-left flex1 p-0 '>
						<div className='d-flex'>
							<div className='line product-line w-25 me-2' />
							<div className='line product-line w-25  me-2' />
							<div className='line product-line w-25  me-2' />
							<div className='line product-line w-25 ' />
						</div>
						<div className='d-flex'>
							<div className='line manufacturer-line w-33 me-2' />
							<div className='line manufacturer-line w-33  me-2' />
							<div className='line manufacturer-line w-33' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-25 me-2' />
							<div className='line product-line w-25 me-2' />
							<div className='line product-line w-25 me-2' />
							<div className='line product-line w-25 ' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-25 me-2' />
							<div className='line product-line w-25 me-2' />
							<div className='line product-line w-25 me-2 opacity-0' />
							<div className='line product-line w-25 opacity-0' />
						</div>
						<div className='d-flex'>
							<div className='line manufacturer-box w-100 m' />
						</div>
						<div className='d-flex'>
							<div className='line product-line w-33  me-2' />
							<div className='line product-line w-33  me-2' />
							<div className='line product-line w-33 ' />
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddEditManufacturerSkeleton;
