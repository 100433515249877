const WeblinkSkeleton = () => {
	return (
		<div className='dummy-skelton p-0'>
			<div className='box p-0'>
				<div className='skeleton h-100 align-items-start p-0'>
					<div className='skeleton-left flex1 '>
						<div className='square h-30' />
					</div>
					<div className='skeleton-right flex4 h-100'>
						<div className='reset-square' />
					</div>
				</div>
			</div>
		</div>
	);
};

export default WeblinkSkeleton;
