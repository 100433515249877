
import { useLocation } from 'react-router-dom';

const UseDemoWeblinkFinder =()=>{

    const location = useLocation()
	
	const {pathname, search} = location

	const path1 = '/01/12342312334561/10/123'
	const search1 = '?17=240828'

	const path2 = '/01/12342312334561/10/243'
	const search2 = '?17=231220'

	const path3 = '/01/12342312334561/10/777'
	const search3 = '?17=240820'

    let isDemo = false
    let demoType = 0;

	switch (pathname) {
		case path1:
			if(search === search1){
				isDemo = true
                demoType = 1
			}
			
			break;
		case path2:
			if(search === search2){
				isDemo = true
                demoType = 2
			}
			
			break;
		case path3:
			if(search === search3){
				isDemo = true
                demoType = 3
			}
			
			break;
	
		default:
            isDemo = false
            demoType = 0
	}

    return {
        isDemo, demoType
    }




}
export default UseDemoWeblinkFinder