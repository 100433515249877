import { ChangeEvent, useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import CustomButton from 'components/common/button/CustomButton';
import CustomInput from 'components/common/forms/CustomInput';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomError from 'components/common/errorLabel/CustomError';
import AddEditLocationSkeleton from 'components/common/skeleton/AddEditLocationSkeleton';
import CustomLoader from 'components/common/loader/CustomLoader';
import { productFormValidations } from 'utils/utils';
import {
	ICON_DESCRIPTION_AREA_ID,
	ICON_DESCRIPTION_ID,
	IDENTIFIER_REGEX,
	IDENTIFIER_TYPE,
	MODULE_IDS,
	PASSWORD_VERIFICATION_ACTIONS,
	PASSWORD_VERIFICATION_TRANSACTIONS,
	RouteUrl,
} from 'utils/enum';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import useGetAllCountry from 'components/tenant/hooks/useGetAllCountry';
import {
	calculateCheckDigitFun,
	split12Digit,
	splitLastDigit,
	splitLocationReference,
	// sglnGenerate,
	// validateGLN,
	// validateSGLNExtension,
} from 'utils/gs1UtilFns';
import { Badge } from '@progress/kendo-react-indicators';
import useGetAllIdentifierType from 'components/productModule/manufacturer/hooks/useGetAllIdentifierType';
import { useAppSelector } from 'hooks/stateHooks';
import { getModulesRequireAuth } from 'slices/configSlice';
import PasswordVerification from 'components/login/PasswordVerification';
import CustomDropDown from '../common/forms/CustomDropdown';
import useGetLocationById from './hooks/useGetLocationById';
import useAddLocation from './hooks/useAddLocation';
import useUpdateLocation from './hooks/useUpdateLocation';
import LocationSglngenerationSection from './LocationSglngenerationSection';
import IdentifierGrid from './IdentifierGrid';
import ContactDetails from './ContactDetails';
import useGetAllOwnerName from './hooks/useGetAllOwnerName';
import useAddIdentifierType from './hooks/useAddIdentifierType';

const AddEditLocation = () => {
	const { t } = useTranslation('locationModule');
	const location = useLocation();
	const navigate = useNavigate();

	const modulesRequireAuth = useAppSelector(getModulesRequireAuth);

	const [locationName, setLocationName] = useState<string>('');
	const [selectedOwnerName, setSelectedOwnerName] = useState<any>('');
	const [streetAddressA, setStreetAddressA] = useState<string>('');
	const [streetAddressB, setStreetAddressB] = useState<string>('');
	const [postalCode, setPostalCode] = useState<string>('');
	const [city, setCity] = useState<string>('');
	const [state, setState] = useState<string>('');
	const [selectedCountry, setSelectedCountry] = useState<any>('');

	const [gs1Prefix, setGs1Prefix] = useState<string>('');
	const [selectedIdentifierType, setSelectedIdentifierType] = useState<any>(IDENTIFIER_TYPE.GLN);
	const [identifierValue, setIdentifierValue] = useState<string>(''); // gln no
	const [sglnExtension, setSglnExtension] = useState<string | number>('');
	const [sgln, setSgln] = useState<string | number>('');
	const [contactName, setContactName] = useState<string>('');
	const [email, setEmail] = useState<string>('');
	const [telephone, setTelephone] = useState<string>('');
	// state for subject description field
	const [subjectDescription, setSubjectDescription] = useState<string>('');

	const [selectedIdentifierTypeIds, setSelectedIdentifierTypeIds] = useState<any>([]);

	/** sgln section */
	const [identifierDataList, setIdentifierDataList] = useState<any>([]);
	const [selectedPrimaryIdentifier, setSelectedPrimaryIdentifier] = useState<any>('');

	const [ownerNameDataList, setOwnerNameDataList] = useState<any>([]);
	const [countryList, setCountryList] = useState<any>([]);
	const [identifierTypeList, setIdentifierTypeList] = useState<any>([]);
	const [identifierTypeListCopy, setIdentifierTypeListCopy] = useState<any>([]);

	const [errorState, setErrorState] = useState<Record<string, string>>({});

	const [isSubmitted, setIsSubmitted] = useState<boolean>(false);
	const [isEditSubmitted, setIsEditSubmitted] = useState<boolean>(false);

	const [allFieldsDisbled, setAllFieldsDisabled] = useState<boolean>(false);

	const [locationAddEditInfo, setLocationAddEditInfo] = useState<string>('');

	// new fields for manufacturer
	const [district, setDistrict] = useState<string>('');
	const [village, setVillage] = useState<string>('');
	const [houseNumber, setHouseNumber] = useState<string>('');
	const [township, setTownship] = useState<string>('');

	const [companyEmail, setcompanyEmail] = useState<string>('');
	const [companyURL, setcompanyURL] = useState<string>('');
	const [buildingNumber, setBuildingNumber] = useState<string>('');
	const [plantNumber, setPlantNumber] = useState<string>('');
	const [floorNumber, setFloorNumber] = useState<string>('');
	const [taxRegCode, setTaxRegCode] = useState<string>('');
	const [premisesCode, setPremisesCode] = useState<string>('');
	const [identifierPlaceholder, setIdentifierPlaceholder] = useState<string>('');
	const [identifierValueError, setIdentifierValueError] = useState<string>('');
	const [isAdded, setIsAdded] = useState<boolean>(false);
	const [glnErrors, setGlnErrors] = useState<string>('');
	const [sglnErrors, setSglnErrors] = useState<string>('');

	const [fieldDisabled, setFieldDisabled] = useState<boolean>(false);

	// state for optional fields error messages
	const [optionalFieldError, setOptionalFieldError] = useState<Record<string, string>>({});
	// state for password verification
	const [isPasswordVerification, setIsPasswordVerification] = useState<boolean>(false);
	const [locationId, setLocationId] = useState<string>('');

	/** hook to get data by Id */
	const { resData, locationLoading } = useGetLocationById(location?.state?.locationId);

	const { ownerNameResponseData, ownerNameloading } = useGetAllOwnerName();
	const { dropdownCountryValues, loading } = useGetAllCountry();
	/**
	 * API call for get all identifier type
	 */
	const { identifierTypeResponseData, identifierTypeloading } = useGetAllIdentifierType();

	/** sets icon description data in state */
	const { iconDescriptionResponse } = useGetIconDescription(
		ICON_DESCRIPTION_AREA_ID.LOCATION_DETAILS,
		ICON_DESCRIPTION_ID.LOCATION_ADD_EDIT,
	);

	/** hook to add location */
	const { addLocationLoading, addLocationResponse } = useAddLocation(
		{
			locationName,
			encOwnerNameId: selectedOwnerName,
			street1Address: streetAddressA,
			street2Address: streetAddressB,
			postalCode,
			city,
			stateRegion: state,
			encCountryId: selectedCountry,
			subjectDescription,
			gs1CompanyPrefix: gs1Prefix,
			contactName,
			contactEmail: email,
			contactPhone: telephone,
			identifierTypeList: identifierDataList,
			// new fields
			district,
			villege: village,
			houseNumber,
			townShip: township,

			companyEmail,
			companyUrl: companyURL,
			buildingNumber,
			plantNumber,
			floorNumber,
			taxRegistrationCode: taxRegCode,
			premisesCode,
		},
		isSubmitted,
		setIsSubmitted,
	);

	/** hook to update location */
	const { updateLocationLoading, updateLocationResponse } = useUpdateLocation(
		{
			encLocationId: location?.state?.locationId,
			locationName,
			encOwnerNameId: selectedOwnerName,
			street1Address: streetAddressA,
			street2Address: streetAddressB,
			postalCode,
			city,
			stateRegion: state,
			encCountryId: selectedCountry,
			subjectDescription,
			gs1CompanyPrefix: gs1Prefix,
			contactName,
			contactEmail: email,
			contactPhone: telephone,
			identifierTypeList: identifierDataList,
			// new fields
			district,
			villege: village,
			houseNumber,
			townShip: township,

			companyEmail,
			companyUrl: companyURL,
			buildingNumber,
			plantNumber,
			floorNumber,
			taxRegistrationCode: taxRegCode,
			premisesCode,
		},
		isEditSubmitted,
		setIsEditSubmitted,
	);

	const updateIsAddToDefault = () => {
		setIsAdded(false);
	};

	// api to add identifier type and value
	const { identifierValidationRes, identifierValidationResLoading } = useAddIdentifierType(
		{ encIdentifierId: selectedIdentifierType, identifierValue, sglnExtension, sgln },
		isAdded,
		updateIsAddToDefault,
	);

	/** icnon description adding */
	useEffect(() => {
		if (iconDescriptionResponse) {
			// eslint-disable-next-line no-unused-expressions
			iconDescriptionResponse?.output &&
				setLocationAddEditInfo(iconDescriptionResponse?.output[0]?.iconDescription);
		}
	}, [iconDescriptionResponse]);

	/** owner name dropdown data adding */
	useEffect(() => {
		if (ownerNameResponseData) {
			setOwnerNameDataList(ownerNameResponseData);
		}
	}, [ownerNameResponseData]);

	// set enc location Id to state
	useEffect(() => {
		setLocationId(location?.state?.locationId);
	}, [location?.state?.locationId]);

	// old function for sgln generate
	// /** for auto-populating sgln */
	// useEffect(() => {
	// 	if (validateGLN(identifierValue)) {
	// 		if (validateSGLNExtension(sglnExtension?.toString())) {
	// 			setSgln(sglnGenerate(identifierValue, sglnExtension?.toString()));
	// 		}
	// 	}
	// }, [identifierValue, sglnExtension]);

	/** for auto-populating sgln */

	useEffect(() => {
		if (selectedIdentifierType === IDENTIFIER_TYPE.GLN) {
			if (gs1Prefix) {
				setFieldDisabled(false);
			} else {
				setFieldDisabled(true);
			}
			// GS1 prefix length
			const gs1StringLength = gs1Prefix?.length;

			// split gs1 prefix from identifier value function
			const splitIdentifierValue = (inputString) => {
				return inputString?.slice(0, Number(gs1StringLength));
			};

			// splitted value from identifier value
			const identifierGs1 = splitIdentifierValue(identifierValue);

			// condition checking for gs1prefix and splitted value from identifier value are same
			if (identifierGs1 === gs1Prefix) {
				// clearing error state if condition satisfy
				setGlnErrors('');
				if (identifierValue?.length === 13) {
					// referece number from entered gln value
					const locationReference = splitLocationReference(
						identifierValue,
						identifierGs1?.length,
					);

					// gln value without check digit
					const glnValue = split12Digit(identifierValue);

					// last digit in entered gln value
					const lastDigit = splitLastDigit(identifierValue);

					// calulate check digit function
					const checkDigit = calculateCheckDigitFun(glnValue);

					if (lastDigit == checkDigit) {
						setGlnErrors('');
						// if (sglnExtension) {
						const data = `${identifierGs1}.${locationReference}.${sglnExtension}`;
						const alphanumericCount = data?.replace(/\./g, '')?.length;

						setSgln(data);
						if (alphanumericCount > 12 && alphanumericCount <= 20) {
							setSglnErrors('');
						} else {
							setSglnErrors(t('NOT_VALID_SGLN')!!);
						}
						// } else {
						// setSgln('');
						// }
					} else {
						setGlnErrors(
							`${t('INVALID_CHECK_DIGIT')}, ${t('VALID_CHECK_DIGIT')}${checkDigit}`,
						);

						setSgln('');
					}
				} else if (identifierValue?.length > 13) {
					setGlnErrors(t('INVALID_GLN_NUMBER')!!);
				}
			} else if (
				identifierValue?.length === gs1StringLength ||
				identifierValue?.length > gs1StringLength
			) {
				setGlnErrors(t('GS1_VALUE_ERROR_MSG')!!);
			} else {
				setGlnErrors('');
			}
		} else {
			// field disable status change
			setFieldDisabled(false);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifierValue, sglnExtension, gs1Prefix, selectedIdentifierType]);

	/** setting the country dropdown  values */
	useEffect(() => {
		if (dropdownCountryValues) {
			setCountryList(dropdownCountryValues);
		}
	}, [dropdownCountryValues]);

	/** setting the identifier type dropdown values  */
	useEffect(() => {
		if (identifierTypeResponseData) {
			setIdentifierTypeList(identifierTypeResponseData); /** identifier type main state */
			setIdentifierTypeListCopy(
				identifierTypeResponseData,
			); /** identifier type copy state for removing the apready selected values from dropdown */
		}
	}, [identifierTypeResponseData]);

	/** redirecting to listing page */
	useEffect(() => {
		if (addLocationResponse?.status || updateLocationResponse?.status) {
			navigate(RouteUrl.LOCATION_DETAILS);
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [addLocationResponse, updateLocationResponse]);

	/** sgln error state clearing */
	useEffect(() => {
		setErrorState((prev) => ({ ...prev, sgln: '' }));
	}, [sgln]);

	/** clearing the identifier type value section after adding one item */

	useEffect(() => {
		if (selectedIdentifierType === IDENTIFIER_TYPE.GLN) {
			setIdentifierValue('');
			setSgln('');
			setSglnExtension('0');
		} else {
			setIdentifierValue('');
			setSglnExtension('');
			setSgln('');
		}
	}, [selectedIdentifierType]);

	/** setting the id array of items without selected items in dropdown */
	useEffect(() => {
		const idArray =
			identifierDataList &&
			identifierDataList?.length > 0 &&
			identifierDataList?.map((item: any) => {
				return item?.encLocationIdentifierId;
			});
		if (idArray) {
			setSelectedIdentifierTypeIds(idArray);
		} else {
			setSelectedIdentifierTypeIds([]);
		}
	}, [identifierDataList]);

	/** setting the values to dropdown after removing the already selected values from dropdown */
	useEffect(() => {
		const filteredData =
			identifierTypeListCopy?.length > 0 &&
			identifierTypeListCopy?.filter(
				(item: any) =>
					!selectedIdentifierTypeIds?.includes(
						item?.encManufacturerIdentifierId?.toString(),
					),
			);
		// eslint-disable-next-line no-unused-expressions
		filteredData?.length >= 0 && setIdentifierTypeList(filteredData);

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [selectedIdentifierTypeIds, resData]);

	/** clearing the gs1 prefix error state */
	useEffect(() => {
		setErrorState((prev) => ({ ...prev, gs1Prefix: '' }));
	}, [gs1Prefix]);

	/** clearing the identifier type list error state */
	useEffect(() => {
		if (identifierTypeList?.length > 0) {
			setErrorState((prev) => ({ ...prev, identifierTypeList: '' }));
		}
	}, [identifierTypeList]);

	/** reseting states */
	const reset = () => {
		setLocationName('');
		if (location?.state?.type === 'add') {
			setSelectedOwnerName('');
			setGs1Prefix('');
		}
		setStreetAddressA('');
		setStreetAddressB('');
		setPostalCode('');
		setCity('');
		setState('');
		setSelectedCountry('');
		setSubjectDescription('');
		setSelectedIdentifierType('');
		setIdentifierValue('');
		setSglnExtension('');
		setSgln('');
		setContactName('');
		setEmail('');
		setTelephone('');
		setErrorState({});

		setDistrict('');
		setVillage('');
		setHouseNumber('');
		setTownship('');

		setcompanyEmail('');
		setcompanyURL('');
		setBuildingNumber('');
		setPlantNumber('');
		setFloorNumber('');
		setTaxRegCode('');
		setPremisesCode('');
	};

	// fuction for check the condition for optional fields required or not
	const handleIsMandatory = () => {
		if (
			streetAddressA ||
			streetAddressB ||
			city ||
			selectedCountry ||
			district ||
			village ||
			houseNumber ||
			township ||
			state ||
			postalCode ||
			floorNumber ||
			plantNumber ||
			buildingNumber ||
			taxRegCode ||
			premisesCode
		) {
			return true;
		}
		return false;
	};

	// optional required fields function call in fields change

	useEffect(() => {
		handleIsMandatory();
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [
		streetAddressA,
		streetAddressB,
		city,
		district,
		village,
		houseNumber,
		township,
		state,
		postalCode,
		selectedCountry,
		buildingNumber,
		plantNumber,
		floorNumber,
		premisesCode,
		taxRegCode,
	]);

	/** handle changes of input section */
	const handleChange = useCallback(
		(event: ChangeEvent<HTMLInputElement> | ChangeEvent<HTMLTextAreaElement>) => {
			switch (event.target.name) {
				case 'locationName':
					setErrorState((prev) => ({ ...prev, locationName: '' }));
					setLocationName(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'subjectDescription':
					setErrorState((prev) => ({ ...prev, subjectDescription: '' }));
					setSubjectDescription(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'streetAddressA':
					setOptionalFieldError((prev) => ({ ...prev, streetAddressA: '' }));
					setStreetAddressA(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'streetAddressB':
					setErrorState((prev) => ({ ...prev, streetAddressB: '' }));
					setStreetAddressB(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'postalCode':
					setOptionalFieldError((prev) => ({ ...prev, postalCode: '' }));
					setPostalCode(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'city':
					setOptionalFieldError((prev) => ({ ...prev, city: '' }));
					setCity(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'state':
					setOptionalFieldError((prev) => ({ ...prev, state: '' }));
					setState(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'gs1Prefix':
					setErrorState((prev) => ({ ...prev, gs1Prefix: '' }));
					setGs1Prefix(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'identifierValue':
					// clearing state on change function
					setIdentifierValueError('');
					if (selectedIdentifierType === IDENTIFIER_TYPE.GLN) {
						// clearing sgln field when identifier value changes
						setSgln('');
						// setSglnExtension('');
						// ......
					}
					setErrorState((prev) => ({ ...prev, identifierValue: '' }));
					setIdentifierValue(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'sglnExtension':
					setErrorState((prev) => ({ ...prev, sglnExtension: '' }));
					setSglnExtension(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'contactName':
					setErrorState((prev) => ({ ...prev, contactName: '' }));
					setContactName(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'email':
					setErrorState((prev) => ({ ...prev, email: '' }));
					setEmail(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'telephone':
					setErrorState((prev) => ({ ...prev, telephone: '' }));
					setTelephone(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'district':
					setErrorState((prev) => ({ ...prev, district: '' }));
					setDistrict(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'village':
					setErrorState((prev) => ({ ...prev, village: '' }));
					setVillage(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'houseNumber':
					setErrorState((prev) => ({ ...prev, houseNumber: '' }));
					setHouseNumber(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'township':
					setErrorState((prev) => ({ ...prev, township: '' }));
					setTownship(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'companyEmail':
					setErrorState((prev) => ({ ...prev, companyEmail: '' }));
					setcompanyEmail(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'companyURL':
					setErrorState((prev) => ({ ...prev, companyURL: '' }));
					setcompanyURL(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'buildingNumber':
					setErrorState((prev) => ({ ...prev, buildingNumber: '' }));
					setBuildingNumber(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'plantNumber':
					setErrorState((prev) => ({ ...prev, plantNumber: '' }));
					setPlantNumber(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'floorNumber':
					setErrorState((prev) => ({ ...prev, floorNumber: '' }));
					setFloorNumber(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'taxRegCode':
					setErrorState((prev) => ({ ...prev, taxRegCode: '' }));
					setTaxRegCode(event.target.value ? event.target.value?.toString() : '');
					break;
				case 'premisesCode':
					setErrorState((prev) => ({ ...prev, premisesCode: '' }));
					setPremisesCode(event.target.value ? event.target.value?.toString() : '');
					break;
				default:
					break;
			}
		},
		// eslint-disable-next-line react-hooks/exhaustive-deps
		[],
	);

	/** setting states to input fields from getbyId hook */
	useEffect(() => {
		if (resData) {
			setLocationName(resData?.locationName);
			setSelectedOwnerName(resData?.encOwnerNameId);
			setStreetAddressA(resData?.street1Address);
			setStreetAddressB(resData?.street2Address);
			setPostalCode(resData?.zipCode);
			setCity(resData?.city);
			setState(resData?.stateName);
			setSelectedCountry(resData?.encCountryId);
			setSubjectDescription(resData?.subjectDescription);
			setGs1Prefix(resData?.gs1GtinPrefix);
			setIdentifierDataList(resData?.identifierTypeList);
			setContactName(resData?.contactName);
			setEmail(resData?.emailId ? resData?.emailId : '');
			setTelephone(resData?.phoneNo ? resData?.phoneNo : '');
			setAllFieldsDisabled(resData?.isDisabled);

			setDistrict(resData?.district);
			setVillage(resData?.villege);
			setHouseNumber(resData?.houseNumber);
			setTownship(resData?.townShip);
			setcompanyEmail(resData?.companyEmail ? resData?.companyEmail : '');
			setcompanyURL(resData?.companyUrl ? resData?.companyUrl : '');
			setBuildingNumber(resData?.buildingNumber);
			setPlantNumber(resData?.plantNumber);
			setFloorNumber(resData?.floorNumber);
			setTaxRegCode(resData?.taxRegistrationCode);
			setPremisesCode(resData?.premisesCode);
		}
		return () => {
			reset();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [resData]);

	/** owner name handle change fn */
	const onOwnerNameChange = (event: any) => {
		setSelectedOwnerName(event);
		const selectedPartnerGtinNo: string =
			ownerNameDataList?.length > 0 &&
			ownerNameDataList?.filter((item: any) => item?.encOwnerNameId === event)[0]
				?.gs1GtinPrefix;
		if (selectedPartnerGtinNo) {
			setGs1Prefix(selectedPartnerGtinNo);
		} else {
			setGs1Prefix('');
		}
		setErrorState((prev) => ({ ...prev, selectedOwnerName: '' }));

		/**
		 * If identifier list have already GLN , Deleting it when Gs1 prefix change
		 */
		if (identifierDataList && identifierDataList?.length > 0) {
			const modArray =
				identifierDataList &&
				identifierDataList?.length > 0 &&
				identifierDataList?.filter(
					(item: any) =>
						item?.encLocationIdentifierId !== IDENTIFIER_TYPE.GLN &&
						item?.encLocationIdentifierId !== IDENTIFIER_TYPE.SGLN,
				);

			setIdentifierDataList(modArray);
		}
	};

	/** country handle change fn */
	const onCountryChange = (event: any) => {
		setSelectedCountry(event);
		setOptionalFieldError((prev) => ({ ...prev, selectedCountry: '' }));
	};

	/** identifier type handle change fn */
	const onIdentifierTypeChange = (event: any) => {
		// to prevent duplication of identifier type
		if (!selectedIdentifierTypeIds?.includes(event)) {
			setSelectedIdentifierType(event);
		}
		setErrorState((prev) => ({ ...prev, selectedIdentifierType: '' }));

		if (event === IDENTIFIER_TYPE.AR_CUIT) {
			setIdentifierPlaceholder(t('IDENTIFIER_AR_CUIT_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.BR_CNPJ) {
			setIdentifierPlaceholder(t('IDENTIFIER_BR_CNPJ_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.BR_CPF) {
			setIdentifierPlaceholder(t('IDENTIFIER_BR_CPF_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.DUNS) {
			setIdentifierPlaceholder(t('IDENTIFIER_DUNS_PLACEHOLDER')!!);
		} else if (event === IDENTIFIER_TYPE.DUNS_PLUS_4) {
			setIdentifierPlaceholder(t('IDENTIFIER_DUNS_4_PLACEHOLDER')!!);
		} else {
			setIdentifierPlaceholder('');
		}

		// clearing error state on-onChange function
		setIdentifierValueError('');
		setGlnErrors('');
		setSglnErrors('');

		if (selectedIdentifierType === IDENTIFIER_TYPE.GLN) {
			// clearing sgln field when identifier value change
			setSgln('');
			// setSglnExtension('');
			// ......
		}
	};

	// setting the data to list in the grid
	useEffect(() => {
		if (identifierValidationRes?.status) {
			setIdentifierDataList((prev: any) => [
				...prev,

				{
					encLocationIdentifierId: selectedIdentifierType,
					identifierValue,
					sglnExtension: sglnExtension !== '' ? sglnExtension : null,
					sgln: sgln !== '' ? sgln : null,
					locationIdentifierValue:
						identifierTypeList &&
						identifierTypeList?.length > 0 &&
						identifierTypeList?.filter(
							(item: any) =>
								item?.encManufacturerIdentifierId === selectedIdentifierType,
						)[0]?.manufacturerIdentifier,
					isPrimary: false,
				},
			]);
			setSelectedIdentifierType('');
			setIdentifierValue('');
			setSglnExtension('');
			setSgln('');
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [identifierValidationRes]);

	// common function for triggering the api
	const identifierTypeDataHandler = () => {
		setIsAdded(true);
	};

	/** identifier type section adding function */
	const handleAddSglnToGrid = () => {
		switch (selectedIdentifierType) {
			case IDENTIFIER_TYPE.GLN:
				const glnError = productFormValidations(
					{
						selectedIdentifierType,
						identifierValue,
						sglnExtension,
						sgln,
					},
					{
						selectedIdentifierType: { required: true },
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 13,
							minlength: 13,
						},
						sglnExtension: {
							required: true,
							alphanumericWithoutSpace: true,
						},
						sgln: {
							required: true,
						},
					},
				);
				setErrorState(glnError);
				if (Object.keys(glnError)?.length === 0 && glnErrors === '' && sglnErrors === '') {
					// function to trigger the api
					identifierTypeDataHandler();
				}
				break;

			case IDENTIFIER_TYPE.AR_CUIT:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexArCuit = IDENTIFIER_REGEX.AR_CUIT;

				// Checking the input value matches the pattern
				const isValidPatternArCuit = regexArCuit.test(identifierValue);

				if (identifierValue === '' && !isValidPatternArCuit) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternArCuit) {
					setIdentifierValueError(t('IDENTIFIER_AR_CUIT_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.AM_TIN:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const amTinError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 8,
							minlength: 8,
						},
					},
				);
				setErrorState(amTinError);
				if (Object.keys(amTinError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.BR_CNPJ:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexBrCnpj = IDENTIFIER_REGEX.BR_CNPJ;

				// Checking the input value matches the pattern
				const isValidPatternBrCnpj = regexBrCnpj.test(identifierValue);

				if (identifierValue === '' && !isValidPatternBrCnpj) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternBrCnpj) {
					setIdentifierValueError(t('IDENTIFIER_BR_CNPJ_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.BR_CPF:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexBrCpf = IDENTIFIER_REGEX.BR_CPF;

				// Checking the input value matches the pattern
				const isValidPatternBrCpf = regexBrCpf.test(identifierValue);

				if (identifierValue === '' && !isValidPatternBrCpf) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternBrCpf) {
					setIdentifierValueError(t('IDENTIFIER_BR_CPF_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.BY_TIN:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const byTinError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 9,
							minlength: 9,
						},
					},
				);
				setErrorState(byTinError);
				if (Object.keys(byTinError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.DE_FA_REG_NUM:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const deFaRegNumerror = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							negativeValue: true,
							numbersOnly: true,
							maxlength: 6,
							minlength: 6,
						},
					},
				);
				setErrorState(deFaRegNumerror);
				if (Object.keys(deFaRegNumerror)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.DEA:
				// clearing identifierValue errorstate
				setIdentifierValueError('');

				const deaError = productFormValidations(
					{
						identifierValue,
					},
					{
						identifierValue: {
							required: true,
							alphanumericWithoutSpace: true,
							maxlength: 9,
							minlength: 9,
						},
					},
				);
				setErrorState(deaError);
				if (Object.keys(deaError)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}

				break;

			case IDENTIFIER_TYPE.DUNS:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexDuns = IDENTIFIER_REGEX.DUNS;

				// Checking the input value matches the pattern
				const isValidPatternDuns = regexDuns.test(identifierValue);

				if (identifierValue === '' && !isValidPatternDuns) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPatternDuns) {
					setIdentifierValueError(t('IDENTIFIER_DUNS_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.DUNS_PLUS_4:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));

				// regular expression to match the pattern
				const regexDuns4 = IDENTIFIER_REGEX.DUNS_4;

				// Checking the input value matches the pattern
				const isValidPattern = regexDuns4.test(identifierValue);
				if (identifierValue === '' && !isValidPattern) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (!isValidPattern) {
					setIdentifierValueError(t('IDENTIFIER_DUNS_4_VALIDATION_MSG')!!);
				} else {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				}

				break;

			case IDENTIFIER_TYPE.SGLN:
				// clearing identifierValue errorstate
				setErrorState((prev) => ({ ...prev, identifierValue: '' }));
				// function to check the gs1 the identifier value matching with gs1 prefix
				const validateSglnwithGs1 = () => {
					// GS1 prefix length
					const gs1StringLength = gs1Prefix?.length;

					// split gs1 prefix from identifier value function
					const splitIdentifierValue = (inputString) => {
						return inputString?.slice(0, Number(gs1StringLength));
					};
					// splitted value from identifier value
					return splitIdentifierValue(identifierValue);
				};
				const validateSglnString = (inputStr) => {
					const regexSgln = IDENTIFIER_REGEX.SGLN;
					// Count the number of letters (excluding dots)
					const sglnLetterCount = inputStr?.replace(/\./g, '')?.length;
					// Check if the input matches the regex
					const sglnStringTest = regexSgln.test(inputStr);
					// Check if the total letters (excluding dots) are between 13 and 20, and there are exactly 2 dots
					return (
						sglnLetterCount >= 13 &&
						sglnLetterCount <= 20 &&
						sglnStringTest &&
						(inputStr?.match(/\./g) || [])?.length === 2
					);
				};

				/**
				 * Function for checking first dot (.) position in identifier value
				 * @returns string length before the first dot
				 */
				const validateGs1Format = () => {
					const dotIndex = identifierValue?.indexOf('.');

					let lengthBeforeDot = 0;

					if (dotIndex !== -1) {
						lengthBeforeDot = dotIndex;
					}

					return lengthBeforeDot;
				};

				const isValidPatternSgln = validateSglnString(identifierValue);

				// Function call to check if SGLN matches with GS1 Prefix
				const isSglnMatchWithGs1 = validateSglnwithGs1();

				// function call for gs1 length check before first dot
				const gs1FormatCheck = validateGs1Format();

				if (identifierValue === '' && isValidPatternSgln) {
					setIdentifierValueError(t('FIELD_REQUIRED')!!);
				} else if (isSglnMatchWithGs1 !== gs1Prefix) {
					setIdentifierValueError(t('GS1_VALUE_ERROR_MSG')!!);
				}
				// validation checking for gs1 position
				else if (gs1FormatCheck !== gs1Prefix?.length) {
					setIdentifierValueError(t('GS1_VALUE_ERROR_MSG')!!);
				} else if (isValidPatternSgln) {
					// clearing error state
					setIdentifierValueError('');

					// function to trigger the api
					identifierTypeDataHandler();

					// set identifier placeholder empty on add
					setIdentifierPlaceholder('');
				} else {
					setIdentifierValueError(t('NOT_VALID_SGLN')!!);
				}
				break;

			default:
				const error = productFormValidations(
					{
						selectedIdentifierType,
						identifierValue,
					},
					{
						selectedIdentifierType: { required: true },
						identifierValue: { required: true },
					},
				);
				setErrorState(error);
				if (Object.keys(error)?.length === 0) {
					// function to trigger the api
					identifierTypeDataHandler();
				}
				break;
		}
	};

	/** identifier type section delete fn */
	const handleDeleteSglnSection = (id: any) => {
		const modArray =
			identifierDataList &&
			identifierDataList?.length > 0 &&
			identifierDataList?.filter((item: any) => item?.encLocationIdentifierId !== id);

		setIdentifierDataList(modArray);
	};

	/** identifier grid onchange function */
	const handlePrimaryIdentifierGridChange = (event: any) => {
		setSelectedPrimaryIdentifier(event);

		const modArray =
			identifierDataList &&
			identifierDataList?.length > 0 &&
			identifierDataList?.map((item: any) => {
				return {
					...item,
					isPrimary: item?.encLocationIdentifierId === event?.encLocationIdentifierId,
				};
			});
		setIdentifierDataList(modArray);
	};

	const handleClear = () => {
		reset();
	};

	/** validation of input fields */
	const handleSubmit = () => {
		const error = productFormValidations(
			{
				locationName,
				selectedOwnerName,
				telephone,
				email,
				gs1Prefix,
				selectedIdentifierType,
				identifierValue,
				sglnExtension,
				sgln,
				identifierTypeList,
				companyEmail,
				companyURL,
				subjectDescription,
				streetAddressB,
				contactName,
				district,
				village,
				houseNumber,
				township,
				buildingNumber,
				plantNumber,
				floorNumber,
				taxRegCode,
				premisesCode,
			},
			{
				locationName: { required: true, maxlength: 150 },
				subjectDescription: { maxlength: 250 },
				selectedOwnerName: { required: true },
				streetAddressB: { maxlength: 250 },
				contactName: { maxlength: 150 },
				district: { maxlength: 150 },
				village: { maxlength: 150 },
				houseNumber: { maxlength: 150 },
				township: { maxlength: 150 },
				buildingNumber: { maxlength: 150 },
				plantNumber: { maxlength: 150 },
				floorNumber: { maxlength: 150 },
				taxRegCode: { maxlength: 150 },
				premisesCode: { maxlength: 150 },

				gs1Prefix: { required: true },
				email: { emailOnly: true },
				telephone: {
					required: false,
					negativeValue: true,
					numbersOnly: true,
					maxlength: 10,
				},
				selectedIdentifierType: {
					required: identifierDataList && identifierDataList?.length === 0,
				},
				identifierValue: {
					required: identifierDataList && identifierDataList?.length === 0,
				},
				sglnExtension: {
					required:
						selectedIdentifierType === IDENTIFIER_TYPE.GLN &&
						identifierDataList &&
						identifierDataList?.length === 0,
					alphanumericWithoutSpace: true,
				},
				sgln: {
					required:
						selectedIdentifierType === IDENTIFIER_TYPE.GLN &&
						identifierDataList &&
						identifierDataList?.length === 0,
				},
				companyEmail: { emailOnly: true },
				companyURL: { urlOnly: true },
			},
		);

		setErrorState(error);

		/**
		 * checking validation for optional fields
		 *  */
		const optionalFieldErrorMsg = productFormValidations(
			{
				streetAddressA,
				city,
				state,
				postalCode,
				selectedCountry,
			},
			{
				streetAddressA: { required: true, maxlength: 250 },
				city: { required: true, maxlength: 150 },
				state: { required: true, maxlength: 150 },
				postalCode: { required: true, maxlength: 150 },
				selectedCountry: { required: true },
			},
		);
		setOptionalFieldError(optionalFieldErrorMsg);

		let sortedOptionalFieldError: any = [];
		// eslint-disable-next-line @typescript-eslint/no-use-before-define
		if (handleIsMandatory()) {
			sortedOptionalFieldError = { ...optionalFieldErrorMsg };
		} else {
			sortedOptionalFieldError = {};
		}

		if (identifierDataList?.length === 0) {
			setErrorState((prev) => ({
				...prev,
				identifierTypeList: t('PLEASE_ADD_ATLEAST_ONE_IDENTIFIER_TYPE'),
			}));
		}

		if (
			Object.keys(error)?.length === 0 &&
			identifierDataList?.length > 0 &&
			Object.keys(sortedOptionalFieldError)?.length === 0
		) {
			if (location?.state?.type === 'add') {
				if (modulesRequireAuth?.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					setIsSubmitted(true);
				}
			} else if (location?.state?.type === 'edit') {
				if (modulesRequireAuth?.includes(MODULE_IDS.PRODUCT_MANAGEMENT)) {
					setIsPasswordVerification(true);
				} else {
					setIsEditSubmitted(true);
				}
			}
		}
	};

	/**
	 * password verificatrion toggle function
	 */
	const handleToggle = () => {
		setIsPasswordVerification(false);
	};

	/**
	 * fuction for handle password verification success
	 */
	const handleVerificationSuccess = () => {
		// eslint-disable-next-line no-unused-expressions
		location?.state?.type === 'add' ? setIsSubmitted(true) : setIsEditSubmitted(true);
		handleToggle();
	};

	return (
		<div className='page-content add-product-group-module'>
			<div className='page-header' style={{ position: 'relative' }}>
				<div className='back-btn-header'>
					<PageBackButton url={-1} />
					<h3>
						{location?.state?.type === 'add'
							? `${t('CREATE_LOCATION')}`
							: `${t('EDIT_LOCATION')}`}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={locationAddEditInfo}
							moduleId={ICON_DESCRIPTION_ID.LOCATION_ADD_EDIT}
						/>
					</h3>
					{allFieldsDisbled && (
						<Badge rounded='small' themeColor='secondary' className='partner-badge'>
							{t('LOCATION_UNDER_USE')}
						</Badge>
					)}
				</div>
			</div>

			<div className='page-content-body'>
				<div className='box-container product-order-details-container'>
					{locationLoading ? (
						<AddEditLocationSkeleton />
					) : (
						<form className='h-100'>
							<fieldset className='k-form-fieldset default-form-style tenant-content-body light-bg-form-wrapper add-location-body'>
								<div className='section-wrapper mb-0 border-bottom-radius-0 pb-0'>
									<div className='row'>
										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.locationName &&
														errorState?.locationName !== ''
															? 'input-validation-error'
															: ''
													}
													value={locationName}
													onChange={handleChange}
													name='locationName'
													label={t('LOCATION_NAME')!!}
													placeholder={t('LOCATION_NAME')}
													required
													type='text'
													error={errorState?.locationName}
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<label className='form-label'>
													{t('OWNER_NAME')}
												</label>
												<CustomDropDown
													isId
													disabled={
														location?.state?.type === 'edit' ||
														allFieldsDisbled
													}
													popupSettings={{
														popupClass: 'default-select-dropdown',
													}}
													className={`form-control default-select-dropdown ${
														errorState?.selectedOwnerName &&
														errorState?.selectedOwnerName !== ''
															? 'input-validation-error'
															: ''
													}`}
													dropdownValue={selectedOwnerName}
													handleDropDownChange={onOwnerNameChange}
													options={ownerNameDataList}
													dataItemKey='encOwnerNameId'
													dataTextField='ownerName'
													loading={ownerNameloading}
													isMandatory
												/>

												{errorState?.selectedOwnerName &&
													errorState?.selectedOwnerName !== '' && (
														<CustomError
															value={errorState?.selectedOwnerName}
														/>
													)}
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-12'>
											<div className='form-group'>
												<CustomInput
													disabled
													className={
														errorState?.gs1Prefix &&
														errorState?.gs1Prefix !== ''
															? 'input-validation-error'
															: ''
													}
													value={gs1Prefix}
													onChange={() => {}}
													name='gs1Prefix'
													label={t('GS1_PREFIX')!!}
													placeholder={t('GS1_PREFIX')}
													required
													type='text'
													error={errorState?.gs1Prefix}
													isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-12'>
											<div className='form-group'>
												<CustomTextArea
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.subjectDescription &&
														errorState?.subjectDescription !== ''
															? 'input-validation-error two-row-height'
															: 'two-row-height'
													}
													value={subjectDescription}
													onChange={(e: any) => handleChange(e)}
													label={t('SUBJECT_DESCRIPTION')!!}
													placeholder={t('SUBJECT_DESCRIPTION')!!}
													textAreaName='subjectDescription'
													textAreaId='subjectDescription'
													required={false}
													cols={30}
													rows={5}
													error={errorState?.subjectDescription}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													value={streetAddressA}
													className={
														handleIsMandatory() &&
														optionalFieldError?.streetAddressA &&
														optionalFieldError?.streetAddressA !== ''
															? 'input-validation-error two-row-height'
															: 'two-row-height'
													}
													onChange={(e: any) => handleChange(e)}
													label={t('STREET_ADDRESS_A')!!}
													placeholder={t('STREET_ADDRESS_A')!!}
													textAreaName='streetAddressA'
													textAreaId='streetAddressA'
													required
													cols={30}
													rows={5}
													error={
														handleIsMandatory()
															? optionalFieldError?.streetAddressA
															: ''
													}
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomTextArea
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.streetAddressB &&
														errorState?.streetAddressB !== ''
															? 'input-validation-error two-row-height'
															: 'two-row-height'
													}
													value={streetAddressB}
													onChange={(e: any) => handleChange(e)}
													label={t('STREET_ADDRESS_B')!!}
													placeholder={t('STREET_ADDRESS_B')!!}
													textAreaName='streetAddressB'
													textAreaId='streetAddressB'
													required
													cols={30}
													rows={5}
													error={errorState?.streetAddressB}
												/>
											</div>
										</div>

										<div className='col-xl-4 col-lg-4 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.houseNumber &&
														errorState?.houseNumber !== ''
															? 'input-validation-error'
															: ''
													}
													value={houseNumber}
													onChange={handleChange}
													name='houseNumber'
													label={t('HOUSE_NUMBER')!!}
													placeholder={t('HOUSE_NUMBER')}
													required
													type='text'
													error={errorState?.houseNumber}
													// isMandatory
												/>
											</div>
										</div>
										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.village &&
														errorState?.village !== ''
															? 'input-validation-error'
															: ''
													}
													value={village}
													onChange={handleChange}
													name='village'
													label={t('VILLAGE')!!}
													placeholder={t('VILLAGE')}
													required
													type='text'
													error={errorState?.village}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.township &&
														errorState?.township !== ''
															? 'input-validation-error'
															: ''
													}
													value={township}
													onChange={handleChange}
													name='township'
													label={t('TOWNSHIP')!!}
													placeholder={t('TOWNSHIP')}
													required
													type='text'
													error={errorState?.township}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														handleIsMandatory() &&
														optionalFieldError?.city &&
														optionalFieldError?.city !== ''
															? 'input-validation-error'
															: ''
													}
													value={city}
													onChange={handleChange}
													name='city'
													label={t('CITY')!!}
													placeholder={t('CITY')}
													required
													type='text'
													error={
														handleIsMandatory()
															? optionalFieldError?.city
															: ''
													}
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.district &&
														errorState?.district !== ''
															? 'input-validation-error'
															: ''
													}
													value={district}
													onChange={handleChange}
													name='district'
													label={t('DISTRICT')!!}
													placeholder={t('DISTRICT')}
													required
													type='text'
													error={errorState?.district}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														handleIsMandatory() &&
														optionalFieldError?.state &&
														optionalFieldError?.state !== ''
															? 'input-validation-error'
															: ''
													}
													value={state}
													onChange={handleChange}
													name='state'
													label={t('STATE_REGION')!!}
													placeholder={t('STATE_REGION')}
													required
													type='text'
													error={
														handleIsMandatory()
															? optionalFieldError?.state
															: ''
													}
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														handleIsMandatory() &&
														optionalFieldError?.postalCode &&
														optionalFieldError?.postalCode !== ''
															? 'input-validation-error'
															: ''
													}
													value={postalCode}
													onChange={handleChange}
													name='postalCode'
													label={t('POSTAL_CODE')!!}
													placeholder={t('POSTAL_CODE')}
													required
													type='text'
													error={
														handleIsMandatory()
															? optionalFieldError?.postalCode
															: ''
													}
													isMandatory={handleIsMandatory()}
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<label className='form-label'>{t('COUNTRY')}</label>
												<CustomDropDown
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													isId
													popupSettings={{
														popupClass: 'default-select-dropdown',
													}}
													className={`form-control default-select-dropdown ${
														handleIsMandatory() &&
														optionalFieldError?.selectedCountry &&
														optionalFieldError?.selectedCountry !== ''
															? 'input-validation-error'
															: ''
													}`}
													dropdownValue={selectedCountry}
													handleDropDownChange={onCountryChange}
													options={countryList}
													dataItemKey='encCountryId'
													dataTextField='countryName'
													loading={loading}
													isMandatory={handleIsMandatory()}
												/>

												{handleIsMandatory() &&
													optionalFieldError?.selectedCountry &&
													optionalFieldError?.selectedCountry !== '' && (
														<CustomError
															value={
																optionalFieldError?.selectedCountry
															}
														/>
													)}
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.companyEmail &&
														errorState?.companyEmail !== ''
															? 'input-validation-error'
															: ''
													}
													value={companyEmail}
													onChange={handleChange}
													name='companyEmail'
													label={t('COMPANY_EMAIL')!!}
													placeholder={t('COMPANY_EMAIL')}
													required
													type='text'
													error={errorState?.companyEmail}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.companyURL &&
														errorState?.companyURL !== ''
															? 'input-validation-error'
															: ''
													}
													value={companyURL}
													onChange={handleChange}
													name='companyURL'
													label={t('COMPANY_URL')!!}
													placeholder={t('COMPANY_URL')}
													required
													type='text'
													error={errorState?.companyURL}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.buildingNumber &&
														errorState?.buildingNumber !== ''
															? 'input-validation-error'
															: ''
													}
													value={buildingNumber}
													onChange={handleChange}
													name='buildingNumber'
													label={t('BUILDING_NUMBER')!!}
													placeholder={t('BUILDING_NUMBER')}
													required
													type='text'
													error={errorState?.buildingNumber}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.plantNumber &&
														errorState?.plantNumber !== ''
															? 'input-validation-error'
															: ''
													}
													value={plantNumber}
													onChange={handleChange}
													name='plantNumber'
													label={t('PLANT_NUMBER')!!}
													placeholder={t('PLANT_NUMBER')}
													required
													type='text'
													error={errorState?.plantNumber}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.floorNumber &&
														errorState?.floorNumber !== ''
															? 'input-validation-error'
															: ''
													}
													value={floorNumber}
													onChange={handleChange}
													name='floorNumber'
													label={t('FLOOR_NUMBER')!!}
													placeholder={t('FLOOR_NUMBER')}
													required
													type='text'
													error={errorState?.floorNumber}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.taxRegCode &&
														errorState?.taxRegCode !== ''
															? 'input-validation-error'
															: ''
													}
													value={taxRegCode}
													onChange={handleChange}
													name='taxRegCode'
													label={t('TAX_REG_CODE')!!}
													placeholder={t('TAX_REG_CODE')}
													required
													type='text'
													error={errorState?.taxRegCode}
													// isMandatory
												/>
											</div>
										</div>

										<div className='col-xl-3 col-lg-3 col-sm-6'>
											<div className='form-group'>
												<CustomInput
													disabled={
														location?.state?.type === 'edit' &&
														allFieldsDisbled
													}
													className={
														errorState?.premisesCode &&
														errorState?.premisesCode !== ''
															? 'input-validation-error'
															: ''
													}
													value={premisesCode}
													onChange={handleChange}
													name='premisesCode'
													label={t('PREMISES_CODE')!!}
													placeholder={t('PREMISES_CODE')}
													required
													type='text'
													error={errorState?.premisesCode}
													// isMandatory
												/>
											</div>
										</div>
									</div>
								</div>
								<div className='position-relative section-wrapper-inner border-top-radius-0 pt-0'>
									<div className='row'>
										<LocationSglngenerationSection
											selectedIdentifierType={selectedIdentifierType}
											identifierValue={identifierValue}
											sglnExtension={sglnExtension}
											sgln={sgln}
											identifierTypeList={identifierTypeList}
											handleChange={handleChange}
											onIdentifierTypeChange={onIdentifierTypeChange}
											errorState={errorState}
											handleAddSglnToGrid={handleAddSglnToGrid}
											allDisabled={
												location?.state?.type === 'edit' && allFieldsDisbled
											}
											identifierTypeloading={identifierTypeloading}
											identifierPlaceholder={identifierPlaceholder}
											identifierValueError={identifierValueError}
											buttonLoading={identifierValidationResLoading}
											glnErrors={glnErrors}
											sglnErrors={sglnErrors}
											fieldDisabled={fieldDisabled}
										/>
									</div>
								</div>

								<IdentifierGrid
									selectedPrimaryIdentifier={selectedPrimaryIdentifier}
									handlePrimaryIdentifierGridChange={
										handlePrimaryIdentifierGridChange
									}
									identifierData={identifierDataList}
									handleDeleteSglnSection={handleDeleteSglnSection}
									allDisabled={
										location?.state?.type === 'edit' && allFieldsDisbled
									}
								/>
								<div className='section-wrapper mt-3'>
									<ContactDetails
										contactName={contactName}
										email={email}
										telephone={telephone}
										handleChange={handleChange}
										errorState={errorState}
										allDisabled={
											location?.state?.type === 'edit' && allFieldsDisbled
										}
									/>

									<div className='bottom-btn-group bottom-manufacture-end'>
										<CustomButton
											disabled={
												location?.state?.type === 'edit' && allFieldsDisbled
											}
											buttonClassName='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-outline-primary h-44px w-auto icon-add-btn'
											type='button'
											buttonName={t('CLEAR')}
											handleClick={handleClear}
										/>

										<CustomButton
											disabled={
												location?.state?.type === 'edit' && allFieldsDisbled
											}
											buttonClassName='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-primary h-44px w-auto icon-add-btn'
											type='button'
											buttonName={
												addLocationLoading || updateLocationLoading ? (
													<CustomLoader />
												) : (
													t('SUBMIT')
												)
											}
											handleClick={handleSubmit}
										/>
									</div>
								</div>
							</fieldset>
						</form>
					)}
					{/* Password verification component */}
					{isPasswordVerification &&
						modulesRequireAuth?.includes(MODULE_IDS.PRODUCT_MANAGEMENT) && (
							<PasswordVerification
								onVerificationSuccess={handleVerificationSuccess}
								toggleDialog={handleToggle}
								actions={
									location?.state?.type === 'add'
										? PASSWORD_VERIFICATION_ACTIONS.CREATE
										: PASSWORD_VERIFICATION_ACTIONS.EDIT
								}
								transactions={PASSWORD_VERIFICATION_TRANSACTIONS.LOCATION}
								referenceId={locationId}
							/>
						)}
				</div>
			</div>
		</div>
	);
};
export default AddEditLocation;
