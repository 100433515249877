import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivateInstance } from 'libs/axios';

import { ADD_CONGFIGURABLE_ATTRIBUTE } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';
import { ERROR, RESULT_ID_105, WARNING } from 'utils/constants';

const useAddConfigurableData = (
	isSubmitted: boolean,
	formData: any,
	groupId: string,
	resetAddProductConfigurableStatus: any,
) => {
	const dispatch = useAppDispatch();
	const { t } = useTranslation('productGroup');

	const params = {
		encProductGroupId: groupId,
		generalFieldIds:
			formData?.generalField &&
			formData?.generalField?.map((item: any) => {
				return item?.generalFieldId?.toString();
			}),
		iVedaFieldIds:
			formData?.iVedaPropertiesField &&
			formData?.iVedaPropertiesField?.map((item: any) => {
				return item?.generalFieldId?.toString();
			}),
	};

	const [addConfigurableRes, setAddConfigurableRes] = useState<any>([]);
	const [addConfigurableResLoading, setAddConfigurableResLoading] = useState<boolean>(false);

	useEffect(() => {
		if (isSubmitted) {
			setAddConfigurableResLoading(true);
			axiosPrivateInstance
				.post(ADD_CONGFIGURABLE_ATTRIBUTE, params)
				.then((response: any) => {
					if (response?.output && response?.status) {
						dispatch(setMessage(t('CONFIGURATION_SAVED_SUCCESSFULLY')));
						dispatch(setNotificationType('success'));
						setAddConfigurableRes(response?.output);
					} else {
						dispatch(setMessage(t(response?.output?.resultText || 'FAILED')));
						dispatch(setNotificationType('error'));
						if (response?.output?.resultId === RESULT_ID_105) {
							dispatch(setNotificationType(WARNING));
						} else {
							dispatch(setNotificationType(ERROR));
						}
					}
				})
				.catch(() => {
					dispatch(setMessage(t('FAILED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setAddConfigurableResLoading(false);
					resetAddProductConfigurableStatus();
					dispatch(setShowNotification(true));
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		addConfigurableRes,
		addConfigurableResLoading,
	};
};

export default useAddConfigurableData;
