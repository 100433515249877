import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { RootState } from '../store';

const initialState: any = {
	tenantAddStatus: false,
	addButtonClicked: false,
	editButtonClicked: false,
	tenantUpdateStatus: false,
	activateButtonStatus: false,
	refreshTenantList: false,
	searchTenantButton: false,
	showNotification: false,
	message: '',
	notificationType: '',
};

export const tenantModuleSlice = createSlice({
	name: 'tenantModule',
	initialState,
	reducers: {
		setTenantAddStatus: (state, action: PayloadAction<any>) => {
			state.tenantAddStatus = action.payload;
		},
		setAddButtonStatus: (state, action: PayloadAction<any>) => {
			state.addButtonClicked = action.payload;
		},
		setEditButtonStatus: (state, action: PayloadAction<any>) => {
			state.editButtonClicked = action.payload;
		},
		setTenantEditStatus: (state, action: PayloadAction<any>) => {
			state.tenantUpdateStatus = action.payload;
		},
		setActivateTenantUpdateStatus: (state, action: PayloadAction<any>) => {
			state.activateButtonStatus = action.payload;
		},
		setRefreshTenantList: (state, action: PayloadAction<any>) => {
			state.refreshTenantList = action.payload;
		},
		setSearchTenantButton: (state, action: PayloadAction<any>) => {
			state.searchTenantButton = action.payload;
		},
		setShowNotification: (state, action: PayloadAction<any>) => {
			state.showNotification = action.payload;
		},
		setMessage: (state, action: PayloadAction<any>) => {
			state.message = action.payload;
		},
		setNotificationType: (state, action: PayloadAction<any>) => {
			state.notificationType = action.payload;
		},
	},
});

export const {
	setTenantAddStatus,
	setAddButtonStatus,
	setEditButtonStatus,
	setTenantEditStatus,
	setActivateTenantUpdateStatus,
	setRefreshTenantList,
	setSearchTenantButton,
	setShowNotification,
	setMessage,
	setNotificationType,
} = tenantModuleSlice.actions;

export const getTenantAddStatus = (state: RootState) => state.tenantModule.tenantAddStatus;
export const getAddButtonStatus = (state: RootState) => state.tenantModule.addButtonClicked;
export const getEditButtonStatus = (state: RootState) => state.tenantModule.editButtonClicked;
export const getTenantEditStatus = (state: RootState) => state.tenantModule.tenantUpdateStatus;
export const getActivateTenantStatus = (state: RootState) =>
	state.tenantModule.activateButtonStatus;
export const getRefreshTenantStatus = (state: RootState) => state.tenantModule.refreshTenantList;
export const getSearchTenantButton = (state: RootState) => state.tenantModule.searchTenantButton;
export const getShowNotification = (state: RootState) => state.roleModule.showNotification;
export const getMessage = (state: RootState) => state.roleModule.message;
export const getNotificationType = (state: RootState) => state.roleModule.notificationType;

export default tenantModuleSlice.reducer;
