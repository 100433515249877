/**
 * CustomModal component for rendering a customizable modal/dialog.
 * @param {Object} props - The props passed to the component.
 * @param {string} [props.title=''] - The title of the modal.
 * @param {boolean} [props.closeIcon=false] - Whether to display the close icon in the modal header.
 * @param {Function} props.toggleDialog - Callback function triggered when the modal is closed.
 * @param {ReactNode} props.children - The content inside the modal.
 * @param {string} [props.className=''] - Additional CSS class to apply to the modal.
 * @param {string | number} [props.height] - The height of the modal.
 * @param {string | number} [props.width] - The width of the modal.
 * @returns {JSX.Element} - The rendered component displaying the modal/dialog.
 */
import * as React from 'react';

import { Dialog, DialogCloseEvent } from '@progress/kendo-react-dialogs';
import { ICustomModal } from '../model';

const CustomModal = ({
	title,
	closeIcon,
	toggleDialog,
	children,
	className,
	height,
	width,
}: ICustomModal) => {
	const handleClose = (event: DialogCloseEvent) => {
		toggleDialog(event);
	};

	return (
		<Dialog
			title={title || ''}
			onClose={(event: DialogCloseEvent) => handleClose(event)}
			closeIcon={closeIcon || false}
			className={className || ''}
			width={width}
			height={height}>
			{children}
		</Dialog>
	);
};
export default React.memo(CustomModal);
