import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';

import CustomError from 'components/common/errorLabel/CustomError';
import CustomDropDown from 'components/common/forms/CustomDropdown';
import CustomInput from 'components/common/forms/CustomInput';
import CustomTextArea from 'components/common/forms/CustomTextArea';
import useGetAllCountry from 'components/tenant/hooks/useGetAllCountry';
import {
	getAddress,
	getCountry,
	getDescription,
	getL3Location,
	getL3SystemName,
	setAddress,
	setCountry,
	setDescription,
	setL3Location,
	setL3SystemName,
} from 'slices/l3SystemSlice';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import { getL3SystemIconsData } from 'slices/infoIconsDataSlice';
import { ICON_DESCRIPTION_ID } from 'utils/enum';

const CreateL3Config = ({ errorState, l3ById }: any) => {
	const { t } = useTranslation('l3System');
	const dispatch = useAppDispatch();
	const { dropdownCountryValues, loading } = useGetAllCountry();
	const l3SystemName = useAppSelector(getL3SystemName);
	const address = useAppSelector(getAddress);
	const description = useAppSelector(getDescription);
	const country = useAppSelector(getCountry);
	const l3Location = useAppSelector(getL3Location);
	const l3SystemIconsData = useAppSelector(getL3SystemIconsData);
	const [l3AddEditInfo, setL3AddEditInfo] = useState<string>('');

	// finds l3 add edit info from redux state
	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		l3SystemIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			l3SystemIconsData?.map((item: any) => {
				if (item?.encIconId === ICON_DESCRIPTION_ID.L3_ADD_EDIT) {
					setL3AddEditInfo(item?.iconDescription);
				}
			});
	}, [l3SystemIconsData]);

	const onL3SystemChange = (e: any) => {
		dispatch(setL3SystemName(e.target.value));
	};

	const onAddressChange = (e: any) => {
		dispatch(setAddress(e.target.value));
	};

	const onDescriptionChange = (e: any) => {
		dispatch(setDescription(e.target.value));
	};

	const onCountryChange = (e: any) => {
		dispatch(setCountry(e));
	};

	const onLocationChange = (e: any) => {
		dispatch(setL3Location(e.target.value));
	};

	return (
		<>
			<h4 className='form-section-title mb-3 info-title'>
				{l3ById !== 0 ? `${t('UPDATE_L3_SYSTEM')}` : `${t('CREATE_L3_SYSTEM')}`}
				<CustomEditableTooltip
					showIcon='faInfoCircle'
					text={l3AddEditInfo}
					moduleId={ICON_DESCRIPTION_ID.L3_ADD_EDIT}
				/>
			</h4>
			<div className='section-wrapper'>
				<div className='form-group'>
					<CustomInput
						className=''
						value={l3SystemName}
						label={t('L3SYSTEM_NAME')!!}
						onChange={(e: any) => onL3SystemChange(e)}
						name='l3System'
						placeholder={t('L3SYSTEM_NAME')}
						required
						type='text'
						isMandatory
						error={errorState.l3SystemName}
					/>
				</div>
				<div className='row'>
					<div className='col-xl-6 col-lg-6 col-md-12 col-sm-6'>
						<div className='form-group'>
							<CustomTextArea
								className=' two-row-height'
								value={description}
								onChange={(e: any) => onDescriptionChange(e)}
								label={t('DESCRIPTION')!!}
								placeholder={t('DESCRIPTION')!!}
								textAreaName='description'
								textAreaId='description'
								required
								cols={30}
								rows={5}
								isMandatory
								error={errorState.description}
							/>
						</div>
					</div>
					<div className='col-xl-6 col-lg-6 col-md-12 col-sm-6'>
						<div className='form-group'>
							<CustomTextArea
								className=' two-row-height'
								value={address}
								onChange={(e: any) => onAddressChange(e)}
								label={t('ADDRESS')!!}
								placeholder={t('ADDRESS')!!}
								textAreaName='address'
								textAreaId='address'
								required
								cols={30}
								rows={5}
								isMandatory
								error={errorState.address}
							/>
						</div>
					</div>
				</div>

				<div className='row'>
					<div className='col-lg-6 col-md-6 col-sm-6'>
						<div className='form-group'>
							<CustomDropDown
								popupSettings={{ popupClass: 'default-select-dropdown' }}
								isId
								className='form-control default-select-dropdown'
								dropdownValue={country}
								label={t('COUNTRY')!!}
								isMandatory
								handleDropDownChange={(e: any) => onCountryChange(e)}
								options={dropdownCountryValues}
								dataItemKey='encCountryId'
								dataTextField='countryName'
								loading={loading}
							/>
							{errorState?.country && errorState?.country !== '' && (
								<CustomError value={errorState?.country} />
							)}
						</div>
					</div>
					<div className='col-lg-6 col-md-6 col-sm-6'>
						<div className='form-group'>
							<CustomInput
								className=''
								value={l3Location}
								label={t('LOCATION')!!}
								onChange={(e: any) => onLocationChange(e)}
								name='location'
								placeholder={t('LOCATION')}
								required
								type='text'
								error={errorState.location}
							/>
						</div>
					</div>
				</div>
			</div>
		</>
	);
};

export default CreateL3Config;
