import { GridColumnMenuFilter, GridColumnMenuProps } from '@progress/kendo-react-grid';
/**
 * To Remove the second filter option since it is not needed for our system
 * @param props grid column menu props passed from kendo
 * @returns Jsx of column filter menu hiding the second filter section
 */
const GridColumnMenu = (props: GridColumnMenuProps) => {
	/**
	 * Renders the column menu.
	 */
	return (
		<div>
			<GridColumnMenuFilter {...props} expanded hideSecondFilter />
		</div>
	);
};

export default GridColumnMenu;
