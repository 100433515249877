import { ReactNode } from 'react';
import { ErrorBoundary } from 'react-error-boundary';
import ErrorImage from 'assets/images/error-image.svg';

interface ErrorProps {
	error: any;
	resetErrorBoundary: any;
}
// eslint-disable-next-line no-unused-vars
const ErrorFallback = ({ error, resetErrorBoundary }: ErrorProps) => {
	return (
		<div className='error-page'>
			<div className='error-body text-center'>
				<img src={ErrorImage} alt='Error' />
				<h1>Something Went Wrong</h1>
				<button
					type='button'
					className='btn-primary btn h-44px'
					onClick={resetErrorBoundary}>
					Try again
				</button>
			</div>
		</div>
	);
};

interface Props {
	children: ReactNode;
}
const ErrorBoundaryWrapper = ({ children }: Props) => {
	return (
		<ErrorBoundary
			FallbackComponent={ErrorFallback}
			onReset={() => {
				// reset the state of your app so the error doesn't happen again
				window.location.reload();
			}}>
			{children}
		</ErrorBoundary>
	);
};

export default ErrorBoundaryWrapper;
