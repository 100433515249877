// eslint-disable-next-line eslint-comments/disable-enable-pair
/* eslint-disable no-nested-ternary */
import { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { DialogActionsBar } from '@progress/kendo-react-dialogs';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { getBaseUrl } from 'utils/utils';
import { getExpiryCount, getIsVisible, setIsmodalVisible } from 'slices/expirySlice';
import { getUserLoginData } from 'slices/userLoginDataSlice';
import CustomSuccessModal from 'components/common/modals/CustomSuccessModal';
import CustomModal from 'components/common/modals/CustomModal';
import CustomLoader from 'components/common/loader/CustomLoader';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { ERROR } from 'utils/constants';
import ResetIconImage from 'assets/images/reset-password-icon.svg';
import useForgotPassword from './hooks/useForgotPassword';
import useGetPasswordExpiryAlert from './hooks/useGetPasswordExpiryAlert';

const LoginExpiryAlert = () => {
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();

	// state for triggering the api after the resetPassword button clicked
	const [resetPasswordClicked, setResetPasswordClicked] = useState<boolean>(false);

	// state for setting the user data and passing it into the api
	const [resetPasswordData, setResetPaswordData] = useState<any>(null);

	// state for triggerinf the success message modal after clicking the resetPassword
	const [resetPasswordModal, setResetPasswordModal] = useState<boolean>(false);

	/**
	 * callback function to reset the button click status
	 */
	const updateResetPasswordClick = () => {
		setResetPasswordClicked(false);
	};

	/**
	 * api call for resetting the password
	 */
	const { forgotPasswordRes, forgotPasswordLoading } = useForgotPassword(
		resetPasswordClicked,
		resetPasswordData,
		updateResetPasswordClick,
	);

	/**
	 * api call to get the password expiry day status and count of the user
	 */
	useGetPasswordExpiryAlert();

	/**
	 * passing resetPassword response for opening success modal
	 */
	useEffect(() => {
		if (forgotPasswordRes?.statusCode === '200') {
			setResetPasswordModal(forgotPasswordRes?.status);
		} else if (forgotPasswordRes) {
			dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
			dispatch(setShowNotification(true));
			dispatch(setNotificationType(ERROR));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [forgotPasswordRes]);

	// accessing the modal global state to render the jsx conditionally
	const isModalVisible = useAppSelector(getIsVisible);
	// accessing the globally declared days count from the api
	const daysCount = useAppSelector(getExpiryCount);
	// getting the logged user data from gloablly declared state to pass the information after clicking the resetPassword
	const userData: any = useAppSelector(getUserLoginData);
	const baseURL = getBaseUrl();

	/**
	 *  reset password  dropddown button button item click function
	 *  /* @param e- event of function
	 */
	const confirmResetPasswordClick = (event: any) => {
		const param: any = {
			email: userData?.email,
			baseUrl: `${baseURL}/reset-password`,
		};
		setResetPasswordClicked(true);
		event.stopPropagation();
		event.preventDefault();
		setResetPaswordData(param);
	};

	/**
	 * modal close function
	 */
	const confirmClickNo = (event: any) => {
		dispatch(setIsmodalVisible(false));
		event.stopPropagation();
		event.preventDefault();
	};

	/**
	 * success modal close function
	 */
	const handleClose = () => {
		setResetPasswordModal(false);
		dispatch(setIsmodalVisible(false));
	};

	return (
		// eslint-disable-next-line react/jsx-no-useless-fragment
		<>
			{isModalVisible && (
				<CustomModal
					className='alert-modal'
					title='Lock Modal'
					toggleDialog={() => {}}
					closeIcon={false}>
					<div className='modal-content'>
						<div className='modal-body-content text-center'>
							<img src={ResetIconImage} alt='ConfirmationImage' />
							<h4>{t('RESET_PASSWORD')}</h4>
							<p>
								{daysCount < 1
									? t('RESET_PASSWORD_CONTENT_DAYS_COUNT_ZERO')
									: daysCount === 1
									? t('RESET_PASSWORD_CONTENT_DAYS_COUNT_ONE', { daysCount })
									: t('RESET_PASSWORD_CONTENT_DAYS_COUNT_GREATER_THAN_ONE', {
											daysCount,
									  })}
								{t('RESET_PASSWORD_CONTENT_SECTION_TWO')}
							</p>
						</div>
						<DialogActionsBar>
							<button
								type='button'
								className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn btn-outline-secondary h-44px '
								onClick={(e) => confirmClickNo(e)}
								disabled={forgotPasswordLoading}>
								{t('CLOSE')}
							</button>
							<button
								type='button'
								className='k-button k-button-md k-rounded-md k-button-solid k-button-solid-base btn h-44px btn-success'
								onClick={(e) => confirmResetPasswordClick(e)}
								disabled={forgotPasswordLoading}>
								{/* Show loader if 'loader' prop is true, otherwise show 'LABEL_YES' */}
								{forgotPasswordLoading ? <CustomLoader /> : t('RESET')}
							</button>
						</DialogActionsBar>
					</div>
				</CustomModal>
			)}
			{resetPasswordModal && (
				<CustomSuccessModal
					isModalVisible={resetPasswordModal}
					content={t('PASSWORD_RESET_LINK_SUCCESS_MESSAGE')}
					confirmClickNo={handleClose}
					closeIcon={false}
				/>
			)}
		</>
	);
};

export default LoginExpiryAlert;
