import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { UPDATE_GS1_WEBLINK } from 'utils/serviceUrls';
import { axiosPrivateInstance } from 'libs/axios';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import {
	getProductSideBarIsActive,
	getProductSideBarIsSubmited,
	setProductSideBarIsActive,
	setProductSideBarIsSubmited,
	setProductSideBarValue,
} from 'slices/productmoduleSlice';
import { useAppSelector } from 'hooks/stateHooks';

const useSaveGs1 = (isSubmitted: boolean, formData: any, updateIsGs1WeblinkSubmitted: any) => {
	const dispatch = useDispatch();
	const { t } = useTranslation('gs1Weblink');
	const isActive = useAppSelector(getProductSideBarIsActive);
	const isSubmittedVal = useAppSelector(getProductSideBarIsSubmited);
	const [submitGs1WeblinkResponse, setSubmitGs1WeblinkResponse] = useState<any>([]);
	const [gs1WeblinkSubmited, setGs1WeblinkSubmited] = useState<boolean>(false);
	const [submitGs1WeblinkResponseLoading, setSubmitGs1WeblinkesponseLoading] =
		useState<boolean>(false);

	useEffect(() => {
		if (isSubmitted) {
			setSubmitGs1WeblinkesponseLoading(true);
			axiosPrivateInstance
				.post(UPDATE_GS1_WEBLINK, formData)
				.then((response: any) => {
					setGs1WeblinkSubmited(true);
					setSubmitGs1WeblinkResponse(response.output);
					if (response?.status) {
						dispatch(
							setProductSideBarIsActive({ ...isActive, productPackaging: true }),
						);
						dispatch(setProductSideBarIsSubmited({ ...isSubmittedVal, gs1: true }));
						dispatch(setProductSideBarValue('2'));
						dispatch(setMessage(t('GS1_WEBLINK_UPDATE_SUCCESS')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('success'));
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText || 'GS1_WEBLINK_UPDATE_FAILED'),
							),
						);
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('GS1_WEBLINK_UPDATE_FAILED')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					setSubmitGs1WeblinkesponseLoading(false);
					updateIsGs1WeblinkSubmitted();
				});
		}
		return () => {
			updateIsGs1WeblinkSubmitted();
		};
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		gs1WeblinkSubmited,
		submitGs1WeblinkResponse,
		submitGs1WeblinkResponseLoading,
	};
};

export default useSaveGs1;
