import WeblinkPage from 'components/batchPO/WeblinkPage';

const WeblinkStatus = () => {
	return (
		<div className='page-content user-module'>
			<WeblinkPage />
		</div>
	);
};
export default WeblinkStatus;
