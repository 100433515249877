import { Suspense, lazy } from 'react';
import SettingsSkeleton from 'components/common/skeleton/SettingsSkeleton';

const Index = lazy(() => import('components/settings/AdminConfig'));

const SettingsPage = () => {
	return (
		<div className='page-content settings-page'>
			<Suspense fallback={<SettingsSkeleton />}>
				<Index />
			</Suspense>
		</div>
	);
};

export default SettingsPage;
