import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';

import { GET_ALL_GLOBAL_CONFIG } from 'utils/serviceUrls';
import { getisConfigSet } from 'slices/configSlice';
import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { AUTH_TOKEN_NAME } from 'utils/constants';
import { useTranslation } from 'react-i18next';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';

/**
 * Custom hook to fetch all global configuration data
 *
 * @returns {Object} - Object containing:
 *  configList: Array of config objects
 *  configLoading: Boolean flag for loading state
 *  configLoaded: Boolean flag for loaded state
 */
const useFetchAllConfig = () => {
	// Get isConfigSet flag and auth token from Redux store
	const isConfigSet = useAppSelector(getisConfigSet);
	const token = localStorage.getItem(`${AUTH_TOKEN_NAME}`);

	// Translation hook and Redux dispatch
	const { t } = useTranslation('userModule');
	const dispatch = useAppDispatch();

	const [configList, setConfigList] = useState<any>([]);
	const [configLoading, setConfigLoading] = useState<boolean>(false);
	const [configLoaded, setConfigLoaded] = useState<boolean>(false);

	// Effect to fetch config if not already set
	useEffect(() => {
		if (!isConfigSet && token) {
			// Set loading state
			setConfigLoading(true);
			// Make API request
			axiosPrivateInstance
				.post(GET_ALL_GLOBAL_CONFIG, { url: 'tenant.com' })
				.then((response: any) => {
					if (response?.output?.length > 0) {
						// Set config list if data exists
						setConfigList(response?.output);
					} else {
						// Handle error state
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					// Handle error state
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					// Set loaded state
					setConfigLoaded(true);
					setConfigLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isConfigSet, token]);

	return {
		configList,
		configLoading,
		configLoaded,
	};
};

export default useFetchAllConfig;
