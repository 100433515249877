/**
 * Component for a custom spinner using Kendo Loader.
 * Displays a spinning loader with a converging effect.
 * @returns {JSX.Element} - The rendered component.
 */
import { Loader } from '@progress/kendo-react-indicators';

const Spinner = () => {
	return (
		<div className='custom-loader-spinner type-1'>
			<Loader size='medium' themeColor='primary' type='converging-spinner' />
		</div>
	);
};
export default Spinner;
