import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_USER_DETAILS_URL } from 'utils/serviceUrls';
import { AUTH_TOKEN_NAME } from 'utils/constants';
import {
	setThemeValue,
	setUserLoginData,
	setUserLoginDataStatus,
	setUserSavedLanguageValue,
} from 'slices/userLoginDataSlice';
import { useAppDispatch } from 'hooks/stateHooks';
import { RouteUrl } from 'utils/enum';

/**
 * @returns  token,userdetails response and loading state
 */
const useGetUserDetails = () => {
	const token = localStorage.getItem(`${AUTH_TOKEN_NAME}`);
	const dispatch = useAppDispatch();
	const navigate = useNavigate();
	const [userDetailsResponse, setUserDetailsResponse] = useState<any>();
	const [loading, setLoading] = useState<boolean>(false);
	useEffect(() => {
		if (token) {
			setLoading(true);
			axiosPrivateInstance
				.post(GET_USER_DETAILS_URL)
				.then((response: any) => {
					setUserDetailsResponse(response);
					if (response?.status) {
						const apiLoginResponse: any = {
							token,
							firstName: response?.output?.firstName,
							middleName: response?.output?.middleName,
							lastName: response?.output?.lastName,
							email: response?.output?.email,
							tenantName: response?.output?.tenantName,
							tenantCode: response?.output?.tenantCode,
							// setting privileges listed to a particular user
							listPrivilege: response?.output?.privilegeDetails.map((item: any) => {
								return item.encPrivilegeId;
							}),
							userType: response?.output?.userType,
						};
						// eslint-disable-next-line no-unused-expressions
						dispatch(setUserLoginDataStatus(true));
						dispatch(setUserLoginData(JSON.stringify(apiLoginResponse)));
						// setting theme and language from response
						if (response?.output) {
							dispatch(setThemeValue(response?.output?.theme));
							dispatch(setUserSavedLanguageValue(response?.output?.encLanguageId));
						}
					} else {
						navigate(RouteUrl.LOGINPAGE);
					}
				})
				.catch((error: any) => {
					localStorage.clear();
					navigate(RouteUrl.LOGINPAGE);
					setUserDetailsResponse(error.response);
				})
				.finally(() => {
					setLoading(false);
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [token]);
	return { loading, userDetailsResponse, token };
};
export default useGetUserDetails;
