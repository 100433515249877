import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_PRODUCT_ORDER_RETRUNED_STATUS } from 'utils/serviceUrls';

const updateReturnedPOStatusService = async (id: any, message: any, isProductUpdate: number) => {
	const paramData = {
		encLotNumberId: id,
		statusId: 5,
		comment: message,
		workFlowTypeId: isProductUpdate,
	};
	// eslint-disable-next-line no-return-await
	return await axiosPrivateInstance
		.post(UPDATE_PRODUCT_ORDER_RETRUNED_STATUS, paramData)
		.then((response: any) => {
			return response;
		})
		.catch(() => {
			return false;
		});
};

export default updateReturnedPOStatusService;
