import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { axiosPrivateInstance } from 'libs/axios';

import { UPDATE_FAILED_FILE_NOTIFICATION_CLEAR } from 'utils/serviceUrls';
import { useAppDispatch } from 'hooks/stateHooks';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { setFileFailureNotificationCount } from 'slices/fileFailureSlice';
import { ERROR } from '../../../utils/constants';

const useUpdateFailedFileNotificationClear = (
	isClearAllSubmitted: boolean,
	updateIsClearAllSubmitted: any,
) => {
	const { t } = useTranslation('fileFailure');

	const dispatch = useAppDispatch();

	const [filesFailedNotificationClearRes, setFilesFailedNotificationClearRes] = useState<any>({});
	const [filesFailedNotificationClearLoading, setFilesFailedNotificationClearLoading] =
		useState<boolean>(true);

	/**
	 * setting requestId as string 0 for clear the notification in Back end , as per the backend discussion
	 */
	const params = {
		requestId: '0',
	};

	useEffect(() => {
		if (isClearAllSubmitted) {
			setFilesFailedNotificationClearLoading(true);
			axiosPrivateInstance
				.post(UPDATE_FAILED_FILE_NOTIFICATION_CLEAR, params)
				.then((response: any) => {
					if (response?.output && response?.status) {
						setFilesFailedNotificationClearRes(response?.output);
						dispatch(setFileFailureNotificationCount(0));
					} else {
						dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
						dispatch(setShowNotification(true));
						dispatch(setNotificationType(ERROR));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					setFilesFailedNotificationClearLoading(false);
					updateIsClearAllSubmitted();
				});
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isClearAllSubmitted]);

	return {
		filesFailedNotificationClearRes,
		filesFailedNotificationClearLoading,
	};
};

export default useUpdateFailedFileNotificationClear;
