/**
 * Custom multi-select component for array values.
 * @param {Object} props - The props passed to the component.
 * @param {Array} props.options - The list of options for the multi-select.
 * @param {Array} props.selected - The selected values in the multi-select.
 * @param {Function} props.onChange - Callback function triggered when the selection in the multi-select changes.
 * @param {string} props.placeholder - The placeholder text to display when no option is selected.
 * @param {string} props.label - The label text to display for the multi-select.
 * @param {string} props.className - Class name to apply custom styles to the multi-select.
 * @param {boolean} props.isMandatory - Whether the multi-select is mandatory or not.
 * @param {boolean} props.disabled - Whether the multi-select is disabled or not.
 * @returns {JSX.Element} The rendered component.
 */
import { memo, ReactElement, cloneElement } from 'react';
import { ListItemProps, MultiSelectChangeEvent } from '@progress/kendo-react-dropdowns';
import { MultiSelect } from '@progress/kendo-react-dropdowns/dist/npm/MultiSelect/MultiSelect';

import { ICustomMultiSelectArray } from '../model';

const CustomMultiSelectArray = ({
	options,
	selected,
	onChange,
	placeholder,
	label,
	className,
	isMandatory,
	disabled,
}: ICustomMultiSelectArray) => {
	/**
	 * Handles change event on multi-select.
	 * Calls onChange callback.
	 */
	const handleChange = (event: MultiSelectChangeEvent) => {
		onChange(event.value);
	};

	/**
	 * Renders a select item with checkbox input.
	 *
	 * @param {ReactElement} li - The li element
	 * @param {ListItemProps} itemProps - The props for the list item
	 *
	 * @returns {ReactElement} li element with checkbox input child
	 */
	const itemRender = (li: ReactElement<HTMLLIElement>, itemProps: ListItemProps) => {
		const itemChildren = (
			<div className='k-animation-container-select-box'>
				<input
					type='checkbox'
					name={itemProps.dataItem}
					checked={selected.includes(itemProps.dataItem)}
					onChange={(e: any) => itemProps.onClick(itemProps.index, e)}
				/>
				<span className='custom-checkbox-label'>{itemProps.dataItem}</span>
			</div>
		);
		return cloneElement(li, li.props, itemChildren);
	};

	return (
		<>
			<label className='form-label'>{label}</label>
			{isMandatory && <span>*</span>}
			<MultiSelect
				className={`default-multi-select ${className || ''}`}
				data={options}
				onChange={handleChange}
				itemRender={itemRender}
				value={selected}
				placeholder={placeholder}
				disabled={disabled}
			/>
		</>
	);
};

export default memo(CustomMultiSelectArray);
