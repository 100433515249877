import { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { UPDATE_LOCATION_URL } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { SUCCESS, ERROR, RESULT_ID_105, WARNING } from '../../../utils/constants';

const useUpdateLocation = (locationData: any, isSubmitted: boolean, setIsSubmitted: any) => {
	const { t } = useTranslation('locationModule');

	const params = {
		// locationName: locationData.locationName,
		// encOwnerNameId: locationData.encOwnerNameId,
		// street1Address: locationData.street1Address,
		// street2Address: locationData.street2Address,
		// zipCode: locationData.zipCode,
		// city: locationData.city,
		// stateName: locationData.stateName,
		// encCountryId: locationData.encCountryId,
		// subjectDescription: locationData.subjectDescription,
		// gs1GtinPrefix: locationData.gs1GtinPrefix,
		// contactName: locationData.contactName,
		// emailId: locationData.emailId,
		// phoneNo: locationData.phoneNo,
		// identifierTypeList: locationData.identifierTypeList,
		// encLocationId: locationData.encLocationId,

		encLocationId: locationData?.encLocationId,
		locationName: locationData?.locationName?.trim(),
		encOwnerNameId: locationData?.encOwnerNameId ? locationData?.encOwnerNameId : null,
		subjectDescription: locationData?.subjectDescription?.trim(),
		street1Address: locationData?.street1Address?.trim(),
		street2Address: locationData?.street2Address?.trim(),
		postalCode: locationData?.postalCode?.trim(),
		city: locationData?.city?.trim(),
		stateRegion: locationData?.stateRegion?.trim(),
		encCountryId: locationData?.encCountryId ? locationData?.encCountryId : null,
		gs1CompanyPrefix: locationData?.gs1CompanyPrefix,
		contactName: locationData?.contactName?.trim(),
		contactEmail: locationData?.contactEmail?.trim(),
		contactPhone: locationData?.contactPhone?.trim(),
		identifierTypeList: locationData?.identifierTypeList,
		district: locationData?.district?.trim(),
		villege: locationData?.villege?.trim(),
		houseNumber: locationData?.houseNumber?.trim(),
		townShip: locationData?.townShip?.trim(),
		companyEmail: locationData?.companyEmail?.trim(),
		companyUrl: locationData?.companyUrl?.trim(),
		buildingNumber: locationData?.buildingNumber?.trim(),
		plantNumber: locationData?.plantNumber?.trim(),
		floorNumber: locationData?.floorNumber?.trim(),
		taxRegistrationCode: locationData?.taxRegistrationCode?.trim(),
		premisesCode: locationData?.premisesCode?.trim(),
	};
	const [updateLocationLoading, setUpdateLocationLoading] = useState<boolean>(false);
	const [updateLocationResponse, setUpdateLocationResponse] = useState<any>({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (isSubmitted) {
			setUpdateLocationLoading(true);
			axiosPrivateInstance
				.post(UPDATE_LOCATION_URL, params)
				.then((response: any) => {
					setUpdateLocationResponse(response);

					if (response?.status) {
						dispatch(setMessage(t('LOCATION_UPDATED_SUCCESSFULLY')));

						dispatch(setNotificationType(SUCCESS));
					} else {
						dispatch(
							setMessage(
								t(response?.output?.resultText || 'LOCATION_UPDATING_FAILED'),
							),
						);
						if (response?.output?.resultId === RESULT_ID_105) {
							dispatch(setNotificationType(WARNING));
						} else {
							dispatch(setNotificationType(ERROR));
						}
					}
				})
				.catch(() => {
					dispatch(setMessage(t('LOCATION_UPDATING_FAILED')));
					dispatch(setNotificationType(ERROR));
				})
				.finally(() => {
					dispatch(setShowNotification(true));
					setUpdateLocationLoading(false);
				});
		}

		return () => {
			setIsSubmitted(false);
		};

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [isSubmitted]);

	return {
		updateLocationLoading,
		updateLocationResponse,
	};
};

export default useUpdateLocation;
