import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { CONFIG_IDS } from 'utils/enum';
import { RootState } from '../store';

const initialState: any = {
	isConfigSet: true,
	noOfWrongAttempts: 3,
	autoLockout: 30,
	dataFormat: 'dd/MM/yyyy',
	passwordNumeric: true,
	passwordSpecialChar: true,
	passwordExpiryDays: '5',
	passwordExpiryNotificationDays: '10',
	passwordExpiryNotification: false,
	enable24Hr: true,
	passwordCapital: true,
	passwordMinChar: 8,
	passwordMaxChar: 16,
	forcePasswordChange: false,
	logo: '',
	language: 1,
	modulesRequireAuth: [],
	reviewStatus: false,
	inclusionCharacters: '',
	identifierLimit: 15,
	favIcon: '',
	tenantTitle: '',
	darkMode: false,
	gs1Url: false,
	updateSettingsLoading: false,
	allowWithSerialNumber: false,

	modifiedDateValue: '',
	modifiedDateFilterValue: '',
	weblinkSettingsListUpdate: false,
};

export const configSlice = createSlice({
	name: 'authUser',
	initialState,
	reducers: {
		setIsConfigSet: (state, action: PayloadAction<any>) => {
			state.isConfigSet = action.payload;
		},
		setDarkMode: (state, action: PayloadAction<any>) => {
			state.darkMode = action.payload;
		},
		setUpdateSettingLoading: (state, action: PayloadAction<any>) => {
			state.updateSettingsLoading = action.payload;
		},
		setGlobalConfig: (state, action: PayloadAction<any>) => {
			if (action.payload?.length > 0) {
				action.payload.forEach((item: any) => {
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_EXPIRY) {
						state.passwordExpiryDays = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_EXPIRY_NOTIFICATION_DAYS) {
						state.passwordExpiryNotificationDays = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_MINLENGTH) {
						state.passwordMinChar = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_MAXLENGTH) {
						state.passwordMaxChar = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.ALLOWED_WRONG_ATTEMPT) {
						state.noOfWrongAttempts = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.AUTO_LOCK_TIME) {
						state.autoLockout = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.TENANT_LOGO) {
						state.logo = item.keyValue;
					}
					if (item.encConfigurationId === CONFIG_IDS.DATE_FORMAT) {
						state.dataFormat = item.keyValue;
					}
					if (item.encConfigurationId === CONFIG_IDS.ENABLE_24_HRS) {
						state.enable24Hr = String(item.keyValue).toLowerCase() === 'true';
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_EXPIRY_NOTIFICATION) {
						state.passwordExpiryNotification =
							String(item.keyValue).toLowerCase() === 'true';
					}

					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_ONE_NUMERIC) {
						state.passwordNumeric = String(item.keyValue).toLowerCase() === 'true';
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_ONE_SPECIAL_CHAR) {
						state.passwordSpecialChar = String(item.keyValue).toLowerCase() === 'true';
					}
					if (item.encConfigurationId === CONFIG_IDS.PASSWORD_ONE_CAPITAL_LETTER) {
						state.passwordCapital = String(item.keyValue).toLowerCase() === 'true';
					}

					if (item.encConfigurationId === CONFIG_IDS.MODULE_PERMISSIONS) {
						state.modulesRequireAuth = item.keyValue.split(',');
					}
					if (item.encConfigurationId === CONFIG_IDS.LANGUAGE) {
						state.language = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.REVIEW) {
						state.reviewStatus = !(String(item.keyValue).toLowerCase() === 'true');
					}
					if (item.encConfigurationId === CONFIG_IDS.INCLUSION_CHARACTER) {
						state.inclusionCharacters = item.keyValue;
					}
					if (item.encConfigurationId === CONFIG_IDS.IDENTIFIER_LIMIT) {
						state.identifierLimit = Number(item.keyValue);
					}
					if (item.encConfigurationId === CONFIG_IDS.IS_GS1_URL) {
						state.gs1Url = String(item.keyValue).toLowerCase() === 'true';
					}
					if (item.encConfigurationId === CONFIG_IDS.ALLOW_SERIAL_NUMBER) {
						state.allowWithSerialNumber =
							String(item.keyValue).toLowerCase() === 'true';
					}

					if (item.encConfigurationId === CONFIG_IDS.FAVICONS) {
						state.favIcon = item?.keyValue ? item?.keyValue : '';
					}
					if (item.encConfigurationId === CONFIG_IDS.TENANT_TITLE) {
						state.tenantTitle = item?.keyValue ? item?.keyValue : '';
					}
					state.isConfigSet = true;
				});
			}
		},

		setModifiedDateValue: (state, action: PayloadAction<any>) => {
			state.modifiedDateValue = action.payload;
		},
		setModifiedDateFilterValue: (state, action: PayloadAction<any>) => {
			state.modifiedDateFilterValue = action.payload;
		},
		setWeblinkSettingsListUpdate: (state, action: PayloadAction<any>) => {
			state.weblinkSettingsListUpdate = action.payload;
		},
	},
});

export const {
	setGlobalConfig,
	setIsConfigSet,
	setDarkMode,
	setUpdateSettingLoading,
	setModifiedDateValue,
	setModifiedDateFilterValue,
	setWeblinkSettingsListUpdate,
} = configSlice.actions;

export const getNumberWrongAttempts = (state: RootState) => state.globalConfig.noOfWrongAttempts;
export const getisConfigSet = (state: RootState) => state.globalConfig.isConfigSet;
export const getAllowedNoOfWrongAttempts = (state: RootState) =>
	state.globalConfig.noOfWrongAttempts;
export const getAutoLockoutTime = (state: RootState) => state.globalConfig.autoLockout;
export const getDataFormat = (state: RootState) => state.globalConfig.dataFormat;
export const getPasswordNumeric = (state: RootState) => state.globalConfig.passwordNumeric;
export const getPasswordSpecialChar = (state: RootState) => state.globalConfig.passwordSpecialChar;
export const getPasswordExpiryDays = (state: RootState) => state.globalConfig.passwordExpiryDays;
export const getPasswordExpiryNotificationDays = (state: RootState) =>
	state.globalConfig.passwordExpiryNotificationDays;
export const getPasswordExpiryNotification = (state: RootState) =>
	state.globalConfig.passwordExpiryNotification;
export const getEnable24Hr = (state: RootState) => state.globalConfig.enable24Hr;
export const getPasswordCapital = (state: RootState) => state.globalConfig.passwordCapital;
export const getPasswordMinChar = (state: RootState) => state.globalConfig.passwordMinChar;
export const getPasswordMaxChar = (state: RootState) => state.globalConfig.passwordMaxChar;
export const getForcePasswordChange = (state: RootState) => state.globalConfig.forcePasswordChange;
export const getLogo = (state: RootState) => state.globalConfig.logo;
export const getLanguage = (state: RootState) => state.globalConfig.language;
export const getModulesRequireAuth = (state: RootState) => state.globalConfig.modulesRequireAuth;
export const getReviewStatus = (state: RootState) => state.globalConfig.reviewStatus;
export const getWeblinkInclusionCharacters = (state: RootState) =>
	state.globalConfig.inclusionCharacters;
export const getWeblinkIdentifierLimit = (state: RootState) => state.globalConfig.identifierLimit;
export const getDarkMode = (state: RootState) => state.globalConfig.darkMode;
export const getGs1Url = (state: RootState) => state.globalConfig.gs1Url;
export const getUpdateSettingsLoading = (state: RootState) =>
	state.globalConfig.updateSettingsLoading;
export const getAllowSerialNumber = (state: RootState) => state.globalConfig.allowWithSerialNumber;
export const getFavIcon = (state: RootState) => state.globalConfig.favIcon;
export const getTenantTitle = (state: RootState) => state.globalConfig.tenantTitle;

export const getModifiedDateValue = (state: RootState) => state.globalConfig.modifiedDateValue;
export const getModifiedDateFilterValue = (state: RootState) =>
	state.globalConfig.modifiedDateFilterValue;
export const getWeblinkSettingsListUpdate = (state: RootState) =>
	state.globalConfig.weblinkSettingsListUpdate;

export default configSlice.reducer;
