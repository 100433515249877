/**
 * Custom hook for updating L3 system data
 * @param {object} formData - Form data object
 * @param {boolean} getCreateL3SubmitButtonStatus - Submit button status
 * @returns {object} - Object containing update API response and loading state
 */
import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { useDispatch } from 'react-redux';
import { useAppSelector } from 'hooks/stateHooks';

import { axiosPrivateInstance } from 'libs/axios';

import { ADD_L3_SYSTEM } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { getCreateL3SubmitButtonStatus, setCreateL3SubmitButtonStatus } from 'slices/l3SystemSlice';

const useAddL3System = (formData: any) => {
	const { t } = useTranslation('l3System');
	const createL3SubmitButtonStatus = useAppSelector(getCreateL3SubmitButtonStatus);
	const params = {
		l3SystemName: formData.l3SystemName,
		address: formData.address,
		// encCountryId: formData.country.encCountryId,
		encCountryId: formData.country,
		description: formData.description,
		location: formData.l3Location,
		connectionMethodID: formData.selectedConnectionType,
		connectionDetails: {
			host: formData.sftpHost,
			userName: formData.sftpUsername,
			password: formData.sftpPassword,
			portNo: formData.sftpPortNo === '' ? null : formData.sftpPortNo,
			identifier: formData.restApiTenantId,
		},
		soapConfigurationDetails: {
			userName: formData.soapUsername,
			password: formData.soapPassword,
			identifier: formData.soapIdentifier,
		},
		webLinkSettingsDetails: {
			weblinkPoolSize: formData.weblinkPoolSize,
		},
	};
	const [loading, setLoading] = useState<boolean>(false);
	const [addpL3Response, setAddL3Response] = useState<any>({});
	const dispatch = useDispatch();

	useEffect(() => {
		if (createL3SubmitButtonStatus) {
			setLoading(true);
			axiosPrivateInstance
				.post(ADD_L3_SYSTEM, params)
				.then((response: any) => {
					setAddL3Response(response);

					if (response?.status) {
						dispatch(
							setMessage(
								t(response?.errorMessage || 'L3_SYSTEM_CREATED_SUCCESSFULLY'),
							),
						);

						dispatch(setNotificationType('success'));
					} else {
						dispatch(setMessage(t(response?.errorMessage || 'L3_SYSTEM_NOT_ADDED')));

						dispatch(setNotificationType('error'));
					}
				})
				.catch(() => {
					dispatch(setMessage(t('L3_SYSTEM_NOT_ADDED')));
					dispatch(setNotificationType('error'));
				})
				.finally(() => {
					dispatch(setCreateL3SubmitButtonStatus(false));
					dispatch(setShowNotification(true));
					setLoading(false);
				});
		}

		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [createL3SubmitButtonStatus]);

	return {
		addpL3Response,
		loading,
	};
};

export default useAddL3System;
