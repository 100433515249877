import { useEffect, useState } from 'react';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_MODULE_SIZE } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useTranslation } from 'react-i18next';
import { useAppDispatch } from 'hooks/stateHooks';

const useGetAllModuleSize = () => {
	const { t } = useTranslation('dashboard');
	const dispatch = useAppDispatch();
	const [moduleSizeLoading, setModuleSizeLoading] = useState<boolean>(false);
	const [moduleSizeResponse, setModuleSizeResponse] = useState<
		{ encLotNumberId: string; lotNumber: string }[]
	>([]);
	useEffect(() => {
		setModuleSizeLoading(true);
		axiosPrivateInstance
			.post(GET_ALL_MODULE_SIZE)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setModuleSizeResponse(response.output ? response.output : []);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setModuleSizeLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		moduleSizeLoading,
		moduleSizeResponse,
	};
};

export default useGetAllModuleSize;
