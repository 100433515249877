import { useEffect, useState, lazy, Suspense } from 'react';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { ICON_DESCRIPTION_AREA_ID, ICON_DESCRIPTION_ID, RouteUrl } from 'utils/enum';
import { getProductAreaIconsData, setProductAreaIconsData } from 'slices/infoIconsDataSlice';
import {
	getExpanded,
	getIVedaPropertiesStatus,
	getProductSideBarIsActive,
	getProductSideBarIsSubmited,
	getProductSideBarValue,
	setELeafletFileName,
	setExpanded,
	setProductSideBarIsActive,
	setProductSideBarIsSubmited,
	setProductSideBarValue,
} from 'slices/productmoduleSlice';

import { useAppDispatch, useAppSelector } from 'hooks/stateHooks';
import { PanelBar, PanelBarItem } from '@progress/kendo-react-layout';
import CommonSkeleton from 'components/common/skeleton/CommonSkeleton';
import PageBackButton from 'components/common/pageBackButton/PageBackButton';
import useGetIconDescription from 'components/common/tooltip/hooks/useGetIconDescription';
import CustomEditableTooltip from 'components/common/tooltip/CustomEditableTooltip';
import ProductPrnSkeleton from 'components/common/skeleton/ProductPrnSkeleton';
import Gs1Skelton from 'components/common/skeleton/Gs1Skelton';
import General from './General';
import Gs1Weblink from './Gs1Weblink';

// const Hrf = lazy(() => import('./Hrf'));
const PrnComponent = lazy(() => import('./PrnComponent'));
const ProductPacking = lazy(() => import('./ProductPackingWrapper'));

const AddproductsCustom = () => {
	const { t } = useTranslation('productModule');
	const dispatch = useAppDispatch();
	const expanded = useAppSelector(getExpanded);
	const submitStatus = useAppSelector(getProductSideBarIsSubmited);
	const isActive = useAppSelector(getProductSideBarIsActive);
	const value = useAppSelector(getProductSideBarValue);
	const location = useLocation();
	const iVedaPropertiesStatus = useAppSelector(getIVedaPropertiesStatus);

	const [mode, setMode] = useState<any>('add');
	const [activeLink, setActiveLink] = useState<any>('common');
	const [addProductInfo, setAddProductInfo] = useState<string>('');

	const ProductAreaIconsData = useAppSelector(getProductAreaIconsData);
	const { iconDescriptionResponse } = useGetIconDescription(ICON_DESCRIPTION_AREA_ID.PRODUCT);

	useEffect(() => {
		return () => {
			dispatch(setELeafletFileName(null));
		};
	}, [dispatch]);

	// sets icon description data in redux
	useEffect(() => {
		if (iconDescriptionResponse) {
			dispatch(setProductAreaIconsData(iconDescriptionResponse?.output));
		}
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, [iconDescriptionResponse]);

	useEffect(() => {
		// eslint-disable-next-line no-unused-expressions
		ProductAreaIconsData?.length > 0 &&
			// eslint-disable-next-line array-callback-return
			ProductAreaIconsData?.map((item: any) => {
				if (item?.encIconId === ICON_DESCRIPTION_ID.PRODUCT_ADD) {
					setAddProductInfo(item?.iconDescription);
				}
			});
	}, [ProductAreaIconsData]);

	useEffect(() => {
		if (location?.state) setMode(location?.state?.mode);
	}, [location]);

	useEffect(() => {
		setActiveLink('common');
	}, [value]);

	useEffect(() => {
		if (mode) {
			if (mode !== 'add') {
				dispatch(
					setProductSideBarIsActive({
						general: true,
						productPackaging: true,
						hrf: true,
						prn: true,
						gs1: true,
					}),
				);
				dispatch(
					setProductSideBarIsSubmited({
						general: true,
						productPackaging: true,
						hrf: true,
						prn: true,
						gs1: true,
					}),
				);
			}
			if (mode === 'add') {
				dispatch(
					setProductSideBarIsActive({
						general: true,
						productPackaging: false,
						hrf: false,
						prn: false,
						gs1: false,
					}),
				);
				dispatch(
					setProductSideBarIsSubmited({
						general: false,
						productPackaging: false,
						hrf: false,
						prn: false,
						gs1: false,
					}),
				);
				dispatch(setProductSideBarValue('1'));
			}
		}
	}, [dispatch, mode]);
	/** handle change function  */
	const handleChange = (params: any) => {
		if (params === '1') {
			dispatch(setProductSideBarIsActive({ ...isActive, general: true }));
			dispatch(setProductSideBarValue('1'));
		} else if (params === '5' && submitStatus.general) {
			dispatch(setProductSideBarIsActive({ ...isActive, gs1: true }));
			dispatch(setProductSideBarValue('5'));
		} else if (params === '2' && submitStatus.gs1) {
			dispatch(setProductSideBarIsActive({ ...isActive, productPackaging: true }));
			dispatch(setProductSideBarValue('2'));
		}
		/**
		 * hiding hrf section
		 */
		// else if (params === '3' && submitStatus.productPackaging) {
		// 	dispatch(setProductSideBarIsActive({ ...isActive, hrf: true }));
		// 	dispatch(setProductSideBarValue('3'));
		// }
		else if (params === '4' && submitStatus.productPackaging) {
			dispatch(setProductSideBarIsActive({ ...isActive, prn: true }));
			dispatch(setProductSideBarValue('4'));
		}
	};

	const handleCommonLink = () => {
		setActiveLink('common');
		if (document?.getElementById('commonAttrHeading')) {
			if (mode !== 'add' && !expanded?.includes('.0')) {
				const tempExpand: any[] = [...expanded];
				tempExpand.push('.0');
				dispatch(setExpanded(tempExpand));
			}
			document
				?.getElementById('commonAttrHeading')!!
				.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
		}
	};
	const handleGenaralLink = () => {
		setActiveLink('general');

		if (document?.getElementById('genaralHeading')) {
			if (mode !== 'add' && !expanded?.includes('.1')) {
				const tempExpand: any[] = [...expanded];
				tempExpand.push('.1');
				dispatch(setExpanded(tempExpand));
			}
			document
				?.getElementById('genaralHeading')!!
				.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
		}
	};
	const handleIvedaLink = () => {
		setActiveLink('iveda');

		if (document?.getElementById('ivedaHeading')) {
			if (mode !== 'add' && !expanded?.includes('.2')) {
				const tempExpand: any[] = [...expanded];
				tempExpand.push('.2');
				dispatch(setExpanded(tempExpand));
			}
			document
				?.getElementById('ivedaHeading')!!
				.scrollIntoView({ behavior: 'smooth', block: 'center', inline: 'nearest' });
		}
	};

	return (
		<div className='page-content add-product-module'>
			<div className='page-header'>
				<div className='back-btn-header'>
					<PageBackButton url={RouteUrl.PRODUCTMODULE} />

					<h3>
						{mode === 'add' && t('ADD_PRODUCT_TITLE')}
						{mode === 'edit' && t('EDIT_PRODUCT_TITLE')}
						{mode === 'view' && t('VIEW_PRODUCT_TITLE')}
						{mode === 'review' && t('REVIEW_PRODUCT_TITLE')}
						{mode === 'approve' && t('APPROVE_PRODUCT_TITLE')}
						<CustomEditableTooltip
							showIcon='faInfoCircle'
							text={addProductInfo}
							moduleId={ICON_DESCRIPTION_ID.PRODUCT_ADD}
						/>
					</h3>
				</div>
			</div>
			<div className='page-content-body'>
				<div className='row add-product-row'>
					<div className='col-xl-3 col-lg-4 col-md-5 col-sm-4 left-column'>
						<div className='stepper-navigation'>
							<div
								className='left-box'
								tabIndex={0}
								role='button'
								onKeyDown={() => handleChange('1')}
								onClick={() => handleChange('1')}>
								<PanelBar>
									<PanelBarItem
										expanded
										title={
											<div
												className={`panel-link-btn ${
													// eslint-disable-next-line no-nested-ternary
													value === '1'
														? 'active'
														: submitStatus?.general
														? 'completed'
														: ''
												}`}>
												{t('GENERAL')}
											</div>
										}>
										<div className='panel-btn-group vertical '>
											<button
												className={`btn ${
													activeLink === 'common' ? 'active' : ''
												}`}
												type='button'
												onClick={handleCommonLink}>
												{t('COMMON_ATTRIBUTES')}
											</button>
											<button
												className={`btn ${
													activeLink === 'general' ? 'active' : ''
												}`}
												type='button'
												onClick={handleGenaralLink}>
												{t('GENERAL')}
											</button>
											{iVedaPropertiesStatus && (
												<button
													className={`btn ${
														activeLink === 'iveda' ? 'active' : ''
													}`}
													type='button'
													onClick={handleIvedaLink}>
													{t('I_VEDA_PROPERTIES')}
												</button>
											)}
										</div>
									</PanelBarItem>
								</PanelBar>
							</div>

							<div
								tabIndex={0}
								role='button'
								onKeyDown={() => handleChange('5')}
								onClick={() => handleChange('5')}
								className={`panel-link-btn ${
									// eslint-disable-next-line no-nested-ternary
									value === '5' ? 'active' : submitStatus?.gs1 ? 'completed' : ''
								}`}>
								{t('GS1_WEBLINK')}
							</div>

							<div
								tabIndex={0}
								role='button'
								onKeyDown={() => handleChange('2')}
								onClick={() => handleChange('2')}
								className={`panel-link-btn ${
									// eslint-disable-next-line no-nested-ternary
									value === '2'
										? 'active'
										: submitStatus?.productPackaging
										? 'completed'
										: ''
								}`}>
								{t('PRODUCT_PACKING')}
							</div>

							{/* hiding HRF section */}

							{/* <div
								tabIndex={0}
								role='button'
								onKeyDown={() => handleChange('3')}
								onClick={() => handleChange('3')}
								className={`panel-link-btn ${
									// eslint-disable-next-line no-nested-ternary
									value === '3' ? 'active' : submitStatus?.hrf ? 'completed' : ''
								}`}>
								{t('HRF')}
							</div> */}

							<div
								tabIndex={0}
								role='button'
								onKeyDown={() => handleChange('4')}
								onClick={() => handleChange('4')}
								className={`panel-link-btn ${
									// eslint-disable-next-line no-nested-ternary
									value === '4' ? 'active' : submitStatus?.prn ? 'completed' : ''
								}`}>
								{t('PRN')}
							</div>
						</div>
					</div>
					<div className='col-xl-9 col-lg-8 col-md-7 col-sm-8 right-column'>
						<div className='product-form-wrapper theme-bg-color'>
							<form className='default-form-style'>
								{value === '1' && <General />}
								{value === '2' && (
									<Suspense fallback={<CommonSkeleton />}>
										<ProductPacking />
									</Suspense>
								)}

								{/* hiding HRF section */}

								{/* {value === '3' && (
									<div className='h-100'>
										<Suspense fallback={<CommonSkeleton />}>
											<Hrf />
										</Suspense>
									</div>
								)} */}

								{value === '4' && (
									<div className='prn-wrapper'>
										<Suspense fallback={<ProductPrnSkeleton />}>
											<PrnComponent />
										</Suspense>
									</div>
								)}

								{value === '5' && (
									<Suspense fallback={<Gs1Skelton />}>
										<Gs1Weblink />
									</Suspense>
								)}
							</form>
						</div>
					</div>
				</div>
			</div>
		</div>
	);
};

export default AddproductsCustom;
