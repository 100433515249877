import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';

import { axiosPrivateInstance } from 'libs/axios';
import { GET_ALL_WEBLINK_TYPE } from 'utils/serviceUrls';
import { setMessage, setNotificationType, setShowNotification } from 'slices/roleModuleSlice';
import { useAppDispatch } from 'hooks/stateHooks';

const useGetAllWeblinkType = () => {
	const { t } = useTranslation('dashboard');
	const dispatch = useAppDispatch();
	const [weblinkTypeLoading, setWeblinkTypeLoading] = useState<boolean>(false);
	const [weblinkTypeResponse, setWeblinkTypeResponse] = useState<
		{ encLotNumberId: string; lotNumber: string }[]
	>([]);
	useEffect(() => {
		setWeblinkTypeLoading(true);
		axiosPrivateInstance
			.post(GET_ALL_WEBLINK_TYPE)
			.then((response: any) => {
				if (response?.output && response?.status) {
					setWeblinkTypeResponse(response.output ? response.output : []);
				} else {
					dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
					dispatch(setShowNotification(true));
					dispatch(setNotificationType('error'));
				}
			})
			.catch(() => {
				dispatch(setMessage(t('SOMETHING_WENT_WRONG')));
				dispatch(setShowNotification(true));
				dispatch(setNotificationType('error'));
			})
			.finally(() => {
				setWeblinkTypeLoading(false);
			});
		// eslint-disable-next-line react-hooks/exhaustive-deps
	}, []);

	return {
		weblinkTypeLoading,
		weblinkTypeResponse,
	};
};

export default useGetAllWeblinkType;
